import React, { useEffect, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import {
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import useStyles from './styles'
import { getRoomCapacity } from '../../../utils/checkin'
import * as ROUTES from '../../../config/routes'
import ShowModal from '../../../components/modal'
import cardReservation from '../../../assets/CardReservation.svg'

export default ({
  areaName,
  date,
  isFull,
  step,
  isEdit,
  roomID,
  room,
  handleRoomChange,
  handleLotationChange,
  isClickable,
  roomSelected,
  reservation,
  reservations,
}) => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const { authUser } = useContext(SessionContext)
  const [lotation, setLotation] = useState(0)
  const history = useHistory()
  //used in meeting room reservation hour selection.
  const timeStamp = parseInt(date)
  const id = useState(reservation.id ? reservation.id : 0)
  const selectDay = new Date(timeStamp)
  const today = new Date()
  const day = selectDay.getDate()
  const year = selectDay.getFullYear()
  const month = selectDay.getMonth()
  const [open, setOpen] = useState(false)
  const [openHasReservation, setOpenHasReservation] = useState(false)
  const [isFullDayChecked, setFullDayChecked] = useState(false)
  const [meetingRes, setMeetingRes] = useState([])
  const [initHourSlot, setInitHourSlot] = useState(-1) //slot position of init hour selected
  const [endHourSlot, setEndHourSlot] = useState(-1) //slot position of end hour selected
  const [initSlots, setInitSlots] = useState([]) //array of init hour
  const [endSlots, setEndSlots] = useState([]) //array of end hour
  const [isFullDayDisabled, setFullDayDisabled] = useState(true)
  const [secondSelectBlocked, setSecondSelectBlocked] = useState(true)
  const [selectFieldInitHour, setSelectFieldInitHour] = useState('')
  const [selectFieldEndHour, setSelectFieldEndHour] = useState('')
  const [userReservation, setUserReservation] = useState([])

  let hours = []
  let hour = 7

  for (let i = 0; i < 24; i += 2) {
    hours[i] = hour + ':00'

    hours[i + 1] = hour + ':30'

    hour++
  }
  //function to calc slot array position
  const calcTime = (stringTime, type) => {
    let refTimeSlot = (parseInt(stringTime) * 60 - 7 * 60) / 30
    if (type === 'end') {
      if (parseInt(stringTime.slice(2)) === 30 || parseInt(stringTime.slice(3)) === 30) {
        setEndHourSlot(refTimeSlot + 1)
      } else {
        setEndHourSlot(refTimeSlot)
      }
    } else {
      if (parseInt(stringTime.slice(2)) === 30 || parseInt(stringTime.slice(3)) === 30) {
        setInitHourSlot(refTimeSlot + 1)
      } else {
        setInitHourSlot(refTimeSlot)
      }
    }
  }

  //function to get the reservations and set the slots
  function createInitSlots() {
    let slots = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1]

    if (selectDay.getDate() == today.getDate()) {
      calcTime(`${today.getHours()}:${today.getMinutes()}`, 'init')
      for (let i = 0; i <= initHourSlot; i++) {
        slots[i] = 1
      }
    }

    meetingRes.map((reservation, index) => {
      for (let i = reservation.initDateSlot; i < reservation.endDateSlot; i++) {
        slots[i] = 1
      }
    })
    setInitSlots(slots)
  }

  function onInitHourSlotSelected(selectedInitSlot) {
    setInitHourSlot(selectedInitSlot)
    let slots = []

    //Anything less then the initial slot should be blocked
    for (let i = 0; i <= selectedInitSlot; i++) {
      slots[i] = 1
    }

    //Find next blocked slot after initial slot
    let end = 0
    for (let i = selectedInitSlot + 1; i < initSlots.length; i++) {
      if (end === 0 && initSlots[i] === 1) {
        end = i
      }
      slots[i] = end > 0 && i !== end ? 1 : 0
    }

    setEndSlots(slots)
    setSecondSelectBlocked(false)
  }

  //function to get meetingroom reservations by day.
  const reservationsByDay = () => {
    let cleanup = { callback: undefined }
    firebase.getMeetingReservationsByDay(room.id, selectDay, setMeetingRes, meetingRes, cleanup)

    return () => {
      cleanup.callback()
    }
  }

  useEffect(() => {
    reservationsByDay()
  }, [])

  const getUserMeetingReservationByUser = () => {
    firebase.getUserMeetingReservationByUser(authUser.id, date).then(reservations => setUserReservation(reservations))
  }
  useEffect(() => {
    getUserMeetingReservationByUser()
  }, [])

  useEffect(() => {
    createInitSlots()
    setFullDayDisabled(meetingRes.length > 0)
  }, [meetingRes])

  //function to get data from meeting room reservation card and send to firebase.
  const handleReservation = () => {
    const newReservation = {
      roomName: room.name,
      room: room.id,
      area: room.area,
      areaName: areaName,
      //roomNid: Number(room.nid),
      roomNid: room.nid,
      type: room.type,
      initDateSlot: isFullDayChecked
        ? 0 //all day reservation slot
        : initHourSlot,
      endDateSlot: isFullDayChecked
        ? 23 //all day reservation slot
        : endHourSlot,
      date: new Date(year, month, day, 23, 59, 59, 0),
      id: authUser.id,
      status: {
        date: today,
        type: 0,
      },
    }

    setOpenHasReservation(false),
      firebase.createReservation({ ...newReservation, user: authUser.uid }).then(() => setOpen(true))
  }
  let hasMeetingReservation = userReservation.filter(
    reservation => reservation.initDateSlot <= initHourSlot && initHourSlot <= reservation.endDateSlot
  )
  //checking normal room reservation
  const hasReservation = reservations
    ? reservations.some(res => res.table === reservation.table && res.block === reservation.block)
    : false

  //cancel normal room reservations
  const cancelReservation = async () => {
    await firebase
      .deleteReservation(id[0])
      .then(makeAReservation)
      .catch(erro => console.log('ERROR', erro))
  }

  //cancel metting room reservations
  const cancelMeetingReservation = async () => {
    await firebase
      .deleteReservation(id[0])
      .then(handleReservation)
      .catch(erro => console.log('ERROR', erro))
  }

  //create normal room reservation
  const makeAReservation = async () => {
    let hasReservation = await firebase.hasReservation(
      reservation.room,
      reservation.date,
      reservation.table,
      reservation.block
    )
    if (hasReservation) {
      /* setSnackBar(true) */
    } else {
      firebase
        .createReservation({ ...reservation, user: authUser.uid, userName: authUser.displayName })
        .then(() => setOpen(true))
    }
  }

  const handleFetchLotation = () => {
    const newDate = new Date(Number(date))

    let cleanup = { callback: undefined }
    firebase.getRoomLotationReal(room.id, newDate, setLotation, cleanup)
    return () => {
      cleanup.callback()
    }
  }

  useEffect(handleFetchLotation, [])

  const onLotationChanged = () => {
    handleLotationChange(room.id, lotation)
  }

  useEffect(onLotationChanged, [lotation])

  const borderCard = id => {
    if (roomSelected === id) {
      return {
        border: '2px solid #53318a',
      }
    } else {
      return {
        border: 'none',
      }
    }
  }

  const handleOccupationColor = (seatsOccupied, totalSeats) => {
    var percent = ((seatsOccupied * 100) / totalSeats).toFixed(2)
    if (percent <= 30.0) {
      return {
        color: '#25A061',
      }
    } else if (percent <= 60.0) {
      return {
        color: '#FFAD0E',
      }
    } else {
      return {
        color: '#FF0E0E',
      }
    }
  }

  //Get reservation date from URL
  const dateUrl = () => {
    const parametrosUrl = useParams()
    const date = parametrosUrl.date
    const instantiatedDate = new Date(Number(date))
    const convertedDate = format(instantiatedDate, 'dd/MM')
    return convertedDate
  }

  return room.type === 0 ? (
    <Card className={classes.root} elevation={0}>
      <CardActionArea
        style={borderCard(room.id)}
        disabled={!isClickable}
        onClick={() => handleRoomChange(room.id, lotation)}
      >
        <CardMedia className={classes.media} image={room.pictures[0]} title={room.name} />
        <CardContent display='flex'>
          <Box className={classes.box_content}>
            <Typography variant='body1' className={classes.title}>
              {room.name}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              {room.description}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              Gerente: {room.manager}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              Data da reserva: {dateUrl()}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              Bloco: {reservation.block}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              Mesa: {reservation.table}
            </Typography>
          </Box>
          <Typography
            variant='body1'
            className={classes.ocupation}
            style={handleOccupationColor(lotation, getRoomCapacity(room))}
          >
            {lotation}/{getRoomCapacity(room)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions} disableSpacing={true}>
        <Button
          variant='contained'
          color='primary'
          className={classes.btn}
          onClick={step === 2 ? () => handleNext(roomID) : isEdit ? cancelReservation : makeAReservation}
          disabled={isFull ? true : step === 2 ? false : reservation.table ? (hasReservation ? true : false) : true}
        >
          {isFull ? (
            'Sala Lotada'
          ) : step === 3 ? (
            isEdit ? (
              <Typography variant='subtitle1' color='inherit'>
                Trocar reserva
              </Typography>
            ) : (
              <Typography variant='subtitle1' color='inherit'>
                Finalizar
              </Typography>
            )
          ) : (
            <Typography variant='subtitle1' color='inherit'>
              Finalizar
            </Typography>
          )}
        </Button>
        <ShowModal
          isOpen={open}
          image={cardReservation}
          title='Eba! Você reservou uma mesa'
          message='Você realizou uma reserva não se esqueça de fazer o check-in no dia do seu agendamento.'
          handleClose={() => history.push(ROUTES.HOME)}
          secondActionLabel='Fechar'
        />
      </CardActions>
    </Card>
  ) : (
    //Meeting Room
    <Card className={classes.root} elevation={0} style={{ marginBottom: 30 }}>
      <Grid className={classes.meetingArea}>
        <CardMedia className={classes.media} image={room.pictures[0]} title={room.name} />
        <CardContent>
          <Box className={classes.box_content}>
            <Typography variant='body1' className={classes.title}>
              {room.name}
            </Typography>
            <Typography variant='body1' className={classes.description}>
              {room.description}
            </Typography>
          </Box>
        <Grid container spacing={2} className={classes.meetingBox}>
          <Grid item xs={12} sm={12} lg={6} md={12} className={classes.box_content}>
            <InputLabel className={classes.selectTitle}>Horário inicial</InputLabel>
            <FormControl variant='outlined' size='small' className={classes.formControl}>
              <Select
                className={classes.selectHour}
                id='demo-simple-select-outlined'
                onChange={e => {
                  onInitHourSlotSelected(e.target.value)
                  setSelectFieldInitHour(e.target.value)
                }}
                disabled={isFullDayChecked}
                MenuProps={{ className: classes.menu }}
                value={selectFieldInitHour}
              >
                {hours.map((hour, index) => (
                  <MenuItem key={hour} value={index} disabled={initSlots[index] === 1 ? true : false}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={12} className={classes.box_content}>
            <InputLabel className={classes.selectTitle}>Horário final</InputLabel>
            <FormControl variant='outlined' size='small' className={classes.formControl}>
              <Select
                className={classes.selectHour}
                id='demo-simple-select-outlined'
                onChange={e => {
                  setEndHourSlot(e.target.value)
                  setSelectFieldEndHour(e.target.value)
                }}
                disabled={initHourSlot === -1 || isFullDayChecked || secondSelectBlocked}
                MenuProps={{ className: classes.menu }}
                value={selectFieldEndHour}
              >
                {hours.map((hour, index) => (
                  <MenuItem key={hour} value={index} disabled={endSlots[index] === 1 ? true : false}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Box>
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  checked={isFullDayChecked}
                  onChange={() => setFullDayChecked(!isFullDayChecked)}
                  name='checkedB'
                  color='primary'
                  disabled={isFullDayDisabled}
                />
              }
              label='Dia inteiro'
            />
          </Box>
        </Grid>
        </CardContent>
      </Grid>
      <CardActions className={classes.cardActions} disableSpacing={true} spacing={0}>
        <Button
          variant='contained'
          color='primary'
          className={classes.btn}
          disabled={endHourSlot === -1 ? !isFullDayChecked : false}
          onClick={() =>
            !isEdit
              ? hasMeetingReservation.length > 0
                ? setOpenHasReservation(true)
                : handleReservation()
              : cancelMeetingReservation()
          }
        >
          <Typography variant='subtitle1' color='inherit'>
            {!isEdit ? 'Finalizar' : 'Trocar Reserva'}
          </Typography>
        </Button>
        <ShowModal
          isOpen={openHasReservation}
          image={cardReservation}
          title='Você já tem uma reunião agendada para esse horário.'
          message='Tem certeza que gostaria de fazer outra reserva?'
          mainAction={handleReservation}
          mainActionLabel='Sim, tenho certeza'
          handleClose={() => {
            setOpenHasReservation(false)
          }}
          secondActionLabel='Não, sair'
        />

        <ShowModal
          isOpen={open}
          image={cardReservation}
          title='Eba! Você reservou uma sala de reunião'
          message='Você realizou uma reserva não se esqueça de fazer o check-in no dia do seu agendamento.'
          handleClose={() => history.push(ROUTES.HOME)}
          secondActionLabel='Fechar'
        />
      </CardActions>
    </Card>
  )
}
