import React from 'react'
import { Image, Group } from 'react-konva'

class URLImage extends React.Component {
  state = {
    image: null,
  }
  componentDidMount() {
    this.loadImage()
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage()
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad)
  }
  loadImage() {
    this.image = new window.Image()
    this.image.src = this.props.src
    this.image.addEventListener('load', this.handleLoad)
  }
  handleLoad = () => {
    this.setState({
      image: this.image,
    })
  }

  render() {
    return (
      <Group
        id={this.props.id}
        rotation={this.props.rotation ? this.props.rotation : 0}
        rotateEnabled={true}
        x={this.props.x}
        y={this.props.y}
        element={true}
      >
        <Image
          image={this.state.image}
          width={this.props.width}
          height={this.props.height}
          ref={node => {
            this.imageNode = node
          }}
        />
      </Group>
    )
  }
}

export default URLImage
