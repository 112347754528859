import { makeStyles } from '@material-ui/core'

const mobileSize = 'sm'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  boxStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"center",
    alignItems:"center",
  },
  textFieldStyle: {
    width: '70%',
    paddingBottom: 90,
  },
  image: {
    width: '25%',
    marginBottom:36,
    [theme.breakpoints.down(mobileSize)]: {
      width:"65%",
    },
  },
  subtitle:{
    marginTop:8,
    marginBottom: 30,
    width:350,
    textAlign:"center"
  },
  btn:{
    width:"37%",
    maxHeight:'53px',
    fontSize: '15px',
    height:'10%',
    borderRadius: '4px',
    textTransform:"none",    
    [theme.breakpoints.down(mobileSize)]: {
      width:"80%",
    },
  },
}))

export default useStyles
