import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ptBrLocale from 'date-fns/locale/pt'
import { CssBaseline } from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider, Typography, Box } from '@material-ui/core'
import * as microsoftTeams from '@microsoft/teams-js'

import * as ROUTES from '../../config/routes'
import SessionProvider from '../../contexts/session'
import Content from '../../routes/content'
import Login from '../../routes/login'
import Offline from '../../pages/offline'
import { theme } from '../../theme/index'
import { DisplayMenuProvider } from '../../contexts/displayMenuMobile'

import Privacy from '../privacy'
import { PrivateRoute, LoginRoute } from '../../utils/routes-type'
import TermsOfUse from '../termsOfUse'
import WebViewRoom from '../webview'

export default () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine)

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('offline', updateNetwork)
    window.addEventListener('online', updateNetwork)
    return () => {
      window.removeEventListener('offline', updateNetwork)
      window.removeEventListener('online', updateNetwork)
    }
  })

  if (microsoftTeams) {
    if (window.parent === window.self) {
      if (isOnline) {
        return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
              <SessionProvider>
                <DisplayMenuProvider>
                <Router>
                  <Switch>
                    <LoginRoute exact path={ROUTES.LOGIN} component={Login} />
                    <Route exact path={ROUTES.PRIVACY} component={Privacy} />
                    <Route exact path={ROUTES.TERMSOFUSE} component={TermsOfUse} />
                    <Route exact path={ROUTES.TAB} component={TeamsHostError} />
                    <Route exact path={ROUTES.ROOM_WEBVIEW} component={WebViewRoom} />
                    <PrivateRoute component={Content} />
                  </Switch>
                </Router>
                </DisplayMenuProvider>
              </SessionProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        )
      } else {
        return <Offline />
      }
    }

    microsoftTeams.initialize()

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Route exact path='/tab' component={Login} />
        </Router>
      </ThemeProvider>
    )
  }

  return <Typography variant='h3'>Microsoft Teams SDK not found.</Typography>
}

class TeamsHostError extends React.Component {
  render() {
    return (
      <Box>
        <Typography variant='h3' className='Error'>
          Debug your app within the Teams client.
        </Typography>
      </Box>
    )
  }
}
