import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  containerStyle: {
    width: '100%',
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5vh',
    marginBottom: '20px',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    alignSelf: 'center',
    marginBottom: 10,
  },
  disabled: {
    color: '#595959',
    textOverflow: 'ellipsis',
    size: '100%',
  },
  label: {
    marginBottom: 6,
    marginTop: 18,
    color: '#595959',
  },
  spacerMobile: {
    [theme.breakpoints.down('md')]: {
      height: '69px',
    },
  },

  logoutButton: {
    marginTop: 18,
    justifyContent: 'flex-start',
    cursor: 'pointer',
    width: '35%',
    alignItems: 'center',
    display: 'flex'
  },
  icon: {
    marginRight: 5,
  },
  labelLogout: {
    color: '#53318A',
  }
}));

export default useStyles;
