import React from 'react'
import useStyles from './styles'
import ptBrLocale from 'date-fns/locale/pt'
import fromUnixTime from 'date-fns/fromUnixTime'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { capitalize } from '../../../../utils/string-manipulation'
import { Paper, Grid } from '@material-ui/core'
import { TextField, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useState } from 'react'
import format from 'date-fns/format'

const FilterComponent = ({ arraysToFilter, setFilterLabel, setFilterMounth }) => {
  let filteredList = []
  const classes = useStyles()
  const [roomValue, setRoomValue] = useState(null)
  const [mounthValue, setMounthValue] = useState(null)

  var stringMounths = []
  var noRepeatList = arraysToFilter.slice(0)
  var currentItemList

  for (var index in arraysToFilter) {
    stringMounths.push(capitalize(format(fromUnixTime(arraysToFilter[index].date), 'LLLL', { locale: ptBrLocale })))
    stringMounths = [...new Set(stringMounths)]
  }
  ;[...new Set(stringMounths)]

  const filterList = () => {
    for (var index in arraysToFilter) {
      var num = 0

      currentItemList = noRepeatList.pop()
      if (noRepeatList.length === 1) {
        filteredList.push(noRepeatList[0])
      }
      while (num < noRepeatList.length) {
        if (currentItemList.roomName === noRepeatList[num].roomName) {
          break
        } else if (num + 1 === noRepeatList.length) {
          if (currentItemList.roomName === noRepeatList[num].roomName) {
            break
          } else {
            filteredList.push(currentItemList)
          }
        }
        num++
      }
    }
  }

  useEffect(() => {
    filterList()
  }, [arraysToFilter, filteredList])

  return (
    <Paper variant='outlined' className={classes.paper}>
      <Typography variant='subtitle2' className={classes.divtitle}>
        Filtrar
      </Typography>
      <Grid container spacing={1}>
        <Grid xs item>
          <Autocomplete
            value={mounthValue}
            onChange={(event, newValue) => {
              setMounthValue(newValue)
              setFilterMounth(newValue)
            }}
            options={stringMounths}
            renderInput={params => <TextField {...params} placeholder='Mês' variant='outlined' />}
          />
        </Grid>
        <Grid xs item>
          <Autocomplete
            value={roomValue}
            onChange={(event, newValue) => {
              setRoomValue(newValue)
              setFilterLabel(newValue)
            }}
            options={filteredList}
            getOptionLabel={option => option.roomName}
            renderInput={params => <TextField {...params} placeholder='Sala' variant='outlined' />}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FilterComponent
