import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    overrides: {
        MuiBottomNavigationAction: {
            root: {
                color: 'white',
                opacity: "0.62",
                '&$selected': {
                    color: 'white',
                    opacity: '1'
                },
            },
        },
    },

});
