import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import FilterComponent from './filter'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core'
import Row from './row'
import { capitalize } from '../../../utils/string-manipulation'
import fromUnixTime from 'date-fns/fromUnixTime'
import { ThemeProvider } from '@material-ui/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import ptBrLocale from 'date-fns/locale/pt'

import { FirebaseContext } from '../../../contexts/firebase'
import { materialTheme } from '../../../theme/table'
import { useStyles } from './styles'

import { SessionContext } from '../../../contexts/session'
import { format } from 'date-fns'
import { useResizeDetector } from 'react-resize-detector'

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
  { id: 'area', numeric: false, disablePadding: false, label: 'Área' },
  { id: 'room', numeric: false, disablePadding: false, label: 'Sala' },
  { id: 'tableName', numeric: false, disablePadding: false, label: 'Bloco' },
  { id: 'seat', numeric: false, disablePadding: false, label: 'Mesa' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
]

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection='desc'
            style={{ paddingLeft: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF' }}
          >
            <TableSortLabel hideSortIcon={false}>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function EnhancedTable() {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [reservations, setRservation] = useState([])
  const [filterLabel, setFilterLabel] = useState(null)
  const [filterMounth, setFilterMounth] = useState(null)
  const [page, setPage] = useState(0)
  const [lastHeight, setLastHeight] = useState(0)
  const [arraysToFilter, setArraysTofilter] = useState([])
  const firebase = useContext(FirebaseContext)
  const { authUser } = useContext(SessionContext)
  const _isMounted = useRef(true)
  const { height, ref } = useResizeDetector()
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 30]

  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable <= rowsPerHeight) {
          setRowsPerPage(amountDisplayInTable)
          setLastHeight(height)
        } else {
          return
        }
      }
    }
    if (lastHeight !== height && !loading) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions, loading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const heightPerRow = () => {
    return Math.round(reservations)
  }

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  const filterArr = async aux => {
    var todayDate = new Date()
    var mounthFilter = []
    for (let index = 0; index < aux.length; index++) {
      if (todayDate > new Date(aux[index].date.seconds * 1000)) {
        aux.splice(0, index)

        mounthFilter.dates = aux
        await aux.forEach((item, index, aux) => {
          var event = format(new Date(aux[index].date.seconds * 1000), 'dd/MM')
          mounthFilter.push(event)
        })
        setArraysTofilter(aux)
        break
      }
    }
  }

  const fetchData = async () => {
    const users = firebase.getUsers()
    const myReservations = await firebase.getMyAllReservations(authUser.uid)

    if (_isMounted.current) {
      myReservations.sort(function (a, b) {
        return a.date > b.date ? -1 : a.date < b.date ? 1 : 0
      })

      await filterArr(myReservations)
      setRservation(myReservations)
      setLoading(false)
      if (users.length !== 0) {
        setNoData(1)
      } else {
        setNoData(0)
      }
    }
  }

  const handleFetchData = () => {
    fetchData()
  }

  const filterTable = element => {
    if (filterLabel && filterMounth)
      return (
        capitalize(format(fromUnixTime(element.date), 'LLLL', { locale: ptBrLocale })) === filterMounth &&
        element.roomName === filterLabel.roomName
      )
    if (filterMounth)
      return capitalize(format(fromUnixTime(element.date), 'LLLL', { locale: ptBrLocale })) === filterMounth
    if (filterLabel) {
      return element.roomName === filterLabel.roomName
    } else {
      return element
    }
  }

  useEffect(handleFetchData, [])

  return (
    <Grid container direction='row' justify='flex-start' className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant='h5' id='tableTitle' component='div'>
          Histórico
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FilterComponent
          arraysToFilter={arraysToFilter}
          setFilterLabel={setFilterLabel}
          setFilterMounth={setFilterMounth}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridTable}>
        <Paper className={classes.paper}>
          <ThemeProvider theme={materialTheme}>
            <TableContainer className={classes.container}>
              {loading ? (
                <Box>
                  <LinearProgress />
                </Box>
              ) : (
                <>
                  {noData === 0 ? (
                    <Typography>Reservas não encontradas</Typography>
                  ) : (
                    <>
                      <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        aria-label='enhanced table'
                        stickyHeader
                      >
                        <EnhancedTableHead rowCount={reservations.length} classes={classes} />
                        <TableBody>
                          <ThemeProvider theme={materialTheme}>
                            {reservations
                              .filter(reservation => filterTable(reservation))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <Row key={index + '-' + new Date().getTime()} row={row} index={index} />
                              ))}
                          </ThemeProvider>
                        </TableBody>
                      </Table>
                    </>
                  )}
                </>
              )}
            </TableContainer>
            <Box className={classes.box_lastRow}>
              <Box className={classes.box_half}></Box>
              <Box className={classes.box_half}>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={reservations.length}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  page={page}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={'Linhas por página:'}
                />
              </Box>
            </Box>
          </ThemeProvider>
        </Paper>
      </Grid>
    </Grid>
  )
}
