import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    position: 'absolute',
    top: 170,
    left: 23,
    maxWidth: 410,
  },
  menuItem: {
    fontSize: 12,
    padding: 5,
  },
  selectMenuContainer: {
    zIndex: 10,
  },
  selectMenu: {
    padding: 5,
  },
  toolsContainer: props => ({
    marginTop: (props.selectedCategory - 1) * 50,
    zIndex: props.selectedCategory > 0 ? 10 : 0,
  }),
  button: {
    maxWidth: '36px',
    minWidth: '36px',
    margin: "3px 3px 12px",
    color: '#474747',
    borderRadius: 0,
    padding: 5,
  },
  item: {
    padding: 7,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  itemMenu: {
    width: 110,
    height: 80,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  furnitures: {
    maxHeight: "370px",
    overflowY: 'auto',
  },
  scaleContainer: {
    zIndex: 10,
  }
}));

export default useStyles;
