import React, { useEffect } from 'react'

import { Checkbox, TableCell, TableRow } from '@material-ui/core'

import useStyles from './styles'

const RowUserEdit = ({ room, accessRoom, isItemSelected, handleClick }) => {
  const classes = useStyles()

  const verifyRoomStatus = (event, roomId) => {
    if(!room.public){
      return handleClick(event, roomId)
    }
  }

  return (
    <>
      <TableRow 
        hover
        onClick={event => verifyRoomStatus(event, room.id)}
        aria-checked={isItemSelected}
        role='checkbox'
        selected={isItemSelected}
        tabIndex={-1}
        key={room.id}
      >
        <TableCell padding='checkbox'>
          {!room.public && <Checkbox color='primary' className={classes.allChecks} checked={isItemSelected} />}
        </TableCell>
        <TableCell align='left' className={classes.typoTable}>
          {room.nid}
        </TableCell>
        <TableCell component='th' scope='user' padding='none' className={classes.typoTable}>
          {room.name}
        </TableCell>
        <TableCell component='th' scope='user' padding='none' className={classes.typoTable}>
          {room.areaName}
        </TableCell>
        <TableCell align='left' className={classes.typoTable}>
          {room.places}
        </TableCell>
        <TableCell align='left' className={classes.typoTable}>
          {room.manager}
        </TableCell>
        {room.public ? (
          <TableCell align='left' className={classes.typoTable}>
            Livre
          </TableCell>
        ) : (
          <TableCell align='left' className={classes.typoTable}>
            Restrita
          </TableCell>
        )}
        {room.public ? (
          <TableCell align='left' className={classes.public}>
            Publica
          </TableCell>
        ) : accessRoom.find(a => {
            return a.id === room.id
          }) ? (
          <TableCell align='left' className={classes.public}>
            Permitido
          </TableCell>
        ) : (
          <TableCell align='left' className={classes.noPublic}>
            Não permitido
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

export default RowUserEdit
