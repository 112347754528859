import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '1280px',
    display: 'contents',
    height: '30%',
    marginLeft: '0px',
  },
  header:{
    paddingLeft:'40px',
  },
  box_title: {
    marginBottom: 20,
  },

  gridTable: {
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    width: '100%',
  },
  errorMessage: {
    padding: '20px',
    textAlign: 'center',
  },
  message: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  button: {
    '&:hover': {
      backgroundColor: '#835cbb',
      borderColor: '#835cbb',
      boxShadow: 'none',
    },
    backgroundColor: '#53318a',
    textTransform: 'none',
    marginLeft: 22,
  },
  paper: {
    marginTop: theme.spacing(2),
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
  },
  table: {
    minWidth: 750,
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    height: '85%',
  },
  title: {
    marginTop: '20px',
    width: '134px',
    height: '24px',
    left: '364px',
    top: '197px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '29px',
    lineHeight: '24px',

    display: 'flex',
    alignItems: 'center',
    letterSpacing: '1px',
    fontFeatureSettings: 'pnum on lnum on',
    color: '#34313F',
    flex: '1 1 100%',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  box_lastRow: {
    display: 'flex',
    width: '100%',
    height: '13%',
    '@media (min-height:800px)': {
      alignItems: 'flex-end',
    },
  },
  box_half: {
    width: '50%',
  },
  box_button: {
    paddingTop: '8px',
  },
}))

const cell = makeStyles(theme => ({
  row: {
    paddingLeft: 40,
    fontWeight: 'normal',
    cursor:'pointer',
  },
}))

export { useStyles, cell }
