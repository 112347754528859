import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
  },

  link: {
    fontSize: '12px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#34313F',
  },
  inputInvalid: {

    color: '#CDC4DB',

    width: '100%',

  },
  
  input: {
    '&.MuiInput-underline:before': {
      borderBottomColor: '#53318A',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    width: '100%',
    color: '#53318A',
  },
  textColor: {
    color: '#53318A',
  },
  header: {
    marginBottom: '30px',
  },
  helperText:{
    marginLeft: '14px',
    marginRight: '14px',
    color:'red',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },

  color: {
    color: '#707070',
  },

  button: {
    height: '42px',
    width: '156px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },

  imageInput: {
    marginTop: '12px',
  },

  snackbar: {
    background: ' #EB5757',
    width: '50%',
    padding: '5px 20px',
    bottom: 10,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  modalContent: {
    marginTop: 20,
  },

  numberContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    border: '1px solid blue',
    borderRadius: '50%',
  },
  
}))
export default useStyles
