import { Box, Button, IconButton, Paper, Typography, Modal } from '@material-ui/core'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import useStyles from './styles'

const ShowModal = ({
  isOpen,
  image,
  title,
  message,
  children,
  handleClose,
  mainActionLabel,
  secondActionLabel,
  mainAction,
}) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen}>
      <Box className={classes.boxStyle}>
        <Paper className={classes.paperStyle}>
          <IconButton className={classes.close} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
          {image && <img src={image} alt='error' className={classes.sucessoStyle} />}
          <Typography variant='h6' className={classes.titleModal}>
            {title}
          </Typography>
          <Typography variant='subtitle1' color='textSecondary' className={classes.infoModal}>
            {message}
          </Typography>
          {children}
          <Box className={classes.boxButton}>
            {mainAction && (
              <Button
                fullWidth
                variant='contained'
                color='primary'
                className={classes.buttonStyle}
                onClick={() => mainAction()}
              >
                {mainActionLabel}
              </Button>
            )}
            {secondActionLabel && (
              <Button
                fullWidth
                color='primary'
                variant={!mainAction ? 'contained' : 'text'}
                className={classes.buttonStyle}
                onClick={() => handleClose()}
              >
                {secondActionLabel}
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}

export default ShowModal
