import { Line, Group } from 'react-konva'

const Grid = ({ width, height, blockSize, setSelectedElement }) => {
  const setLines = () => {
    let ret = []
    for (let i = 0; i <= height / blockSize; i++) {
      ret.push({ points: [0, i * blockSize, width, i * blockSize] })
    }
    for (let i = 0; i <= width / blockSize; i++) {
      ret.push({ points: [i * blockSize, 0, i * blockSize, height] })
    }
    return ret
  }

  const lines = setLines()

  return (
    <Group onClick={() => setSelectedElement('')}>
      {lines.map((line, index) => (
        <Line key={index} stroke='#D2D2D2' strokeWidth={1} points={line.points} />
      ))}
    </Group>
  )
}

export default Grid
