import { makeStyles, withStyles } from '@material-ui/core';
import { Badge } from '@material-ui/core';
import {margin_root, font_size_subtitle1, button_height} from "../../../utils/variaveis.js"

const colorPurple = '#53318a';
const colorRed = '#FF0E0E';
const colorOrange = '#FFAD0E';
const colorGreen = '#25A061';
const colorWhite = '#ffffff';

const useStyles = makeStyles(theme => ({
  hello: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '95%',
      marginBottom: '10px',
    },
  },
  username: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-2),
      margin: 'auto',
      width: '95%',
    },
  },
  boxTitle: {
    marginBottom: margin_root,
  },
  boxHome:{
    height:'100%', 
    display:'flex', 
    flexDirection:'column',
  },
  container:{
    flexGrow:'1',
  },
  calendarGrid:{
    display:'flex', 
    flexDirection:'column',
  },
  grid: {
    maxHeight: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginBottom:'60px',
    },
  },
  paper: {
    padding: theme.spacing(1),
    width: '100%',
    textTransform: 'capitalize',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '95%',
    },
  },
  paper_reservation: {
    padding: theme.spacing(1),
  },
  message: {
    height: 'auto',
    padding: '0 24px',
    marginTop: theme.spacing(2),
  },
  btnPaper: {
    width: "100%",
    background: '#ffffff',
    flexGrow: '1',
    display:'flex',
    alignItems:'flex-end'
  },
  button: {
    fontSize: font_size_subtitle1,
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    width: '80%',
    height: button_height,
    textTransform: 'none',
    borderColor: '#835cbb',
    margin: "0px auto 24px auto",
    display: "block"
  },
  snackbar: {
    backgroundColor: '#11994B',
    width: '45%',
    padding: '7px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      margin: 'auto',
      width: '90%',
    },
  },
}));

export const StyledPurpleBadge = withStyles(theme => ({
  badge: {
    right: '50%',
    top: '70%',
    height: '8px',
    minWidth: '8px',
    borderRadius: '4px',
    border: `1px solid ${colorPurple}`,
    padding: '0 1px',
    backgroundColor: `${colorPurple}`,
    [theme.breakpoints.down('md')]: {
      right: '50%',
      top: '85%',
      height: '8px',
      minWidth: '8px',
      borderRadius: '4px',
      border: `1px solid ${colorPurple}`,
      padding: '0 1px',
      backgroundColor: `${colorPurple}`,
    },
  },
}))(Badge);

export const StyledOrangeBadge = withStyles(theme => ({
  badge: {
    right: '50%',
    top: '75%',
    height: '8px',
    minWidth: '8px',
    borderRadius: '4px',
    border: `1px solid #FF6C6C`,
    padding: '0 1px',
    backgroundColor: `#FF6C6C`,
    [theme.breakpoints.down('md')]: {
      right: '50%',
      top: '85%',
      height: '8px',
      minWidth: '8px',
      borderRadius: '4px',
      border: `1px solid #FF6C6C`,
      padding: '0 1px',
      backgroundColor: `#FF6C6C`,
    },
  },
}))(Badge);

export const StyledGreenBadge = withStyles(theme => ({
  badge: {
    right: '50%',
    top: '75%',
    height: '8px',
    minWidth: '8px',
    borderRadius: '4px',
    border: `1px solid ${colorGreen}`,
    padding: '0 1px',
    backgroundColor: `${colorGreen}`,
    [theme.breakpoints.down('md')]: {
      right: '50%',
      top: '85%',
      height: '8px',
      minWidth: '8px',
      borderRadius: '4px',
      border: `1px solid ${colorGreen}`,
      padding: '0 1px',
      backgroundColor: `${colorGreen}`,
    },
  },
}))(Badge);

export const StyledWhiteBadge = withStyles(theme => ({
  badge: {
    right: '50%',
    top: '75%',
    height: '8px',
    minWidth: '8px',
    borderRadius: '4px',
    border: `1px solid ${colorWhite}`,
    padding: '0 1px',
    backgroundColor: `${colorWhite}`,
    [theme.breakpoints.down('md')]: {
      right: '50%',
      top: '85%',
      height: '8px',
      minWidth: '8px',
      borderRadius: '4px',
      border: `1px solid ${colorWhite}`,
      padding: '0 1px',
      backgroundColor: `${colorWhite}`,
    },
  },
}))(Badge);

export const StyledRedBadge = withStyles(theme => ({
  badge: {
    right: '50%',
    top: '75%',
    height: '8px',
    minWidth: '8px',
    borderRadius: '4px',
    border: `1px solid ${colorRed}`,
    padding: '0 1px',
    backgroundColor: `${colorRed}`,
    [theme.breakpoints.down('md')]: {
      right: '50%',
      top: '85%',
      height: '8px',
      minWidth: '8px',
      borderRadius: '4px',
      border: `1px solid${colorRed}`,
      padding: '0 1px',
      backgroundColor: `${colorRed}`,
    },
  },
}))(Badge);

export default useStyles;
