import { makeStyles, withStyles, Tooltip } from '@material-ui/core';
import {item_height, header_height, margin_and_padding_covid, width_side_menu} from '../../utils/variaveis.js'

const drawerWidth = width_side_menu;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      // whiteSpace: 'nowrap',
    },
  },
  drawer_paper: {
    width: drawerWidth,
  },
  card_menu: {
    width: '100%',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  root: {
    '& svg': {
      color: '#969696',
    },
  },
  isSelected: {
    alignItems: 'center',
    color: '#EB2368 !important',
    textDecoration: 'none',
    opacity: 'none',
    '& svg': {
      color: '#EB2368',
    },
    '&:hover': {
      color: 'white !important',
    },
  },
  isIcon: {
    '& svg': {
      color: '#EB2368',
    },
  },
  listItem: {
    height: item_height,
    display: 'flex',
    color: '#969696',
    flexDirection: 'row',

    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: '#EB2368',
      '& svg': {
        color: theme.palette.common.white,
      },
    },
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerOpen: {
    width: drawerWidth,
    overflow: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  icon_button: {
    width: 'auto',
  },
  box_icon: {
    display: 'flex',
    width: '100%',
  },
  boxSpace: {
    height: header_height,
  },
  covidBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: margin_and_padding_covid,
    alignItems: 'center',
    margin: margin_and_padding_covid,
    backgroundColor: '#F4F4F4',
    cursor: 'pointer',
  },

  covidText: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  warningCovid: {
    margin: '10px 0 10px 0',
    width: '90%',
  },
}));

export { useStyles, LightTooltip };
