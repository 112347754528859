import { Box, Grid, Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { Star } from '@material-ui/icons'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import React, { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import { getRoomCapacity } from '../../../utils/checkin'
import ShowModal from '../../../components/modal'
import error from '../../../assets/error.svg'
import useStyles from './styles'

export default function MediaCard({
  room,
  date,
  handleRoomChange,
  handleLotationChange,
  favoriteRooms,
  setFavoriteRooms,
  executeScroll,
  disableWorkstationCards,
}) {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(SessionContext)
  const [lotation, setLotation] = useState()
  const [showLockedRoomMessage, setShowLockedRoomMessage] = useState(false)

  const handleFetchLotation = () => {
    const newDate = new Date(Number(date))
    let cleanup = { callback: undefined }
    firebase.getRoomLotationReal(room.id, newDate, setLotation, cleanup)
    getRoomCapacity(room)
    return () => cleanup.callback()
  }

  useEffect(handleFetchLotation, [room])

  const onLotationChanged = () => {
    handleLotationChange(room.id, lotation)
  }

  useEffect(onLotationChanged, [lotation])

  const handleActionFavorite = async id => {
    if (room.isFavorite) {
      firebase
        .removeFavoriteRoom(id)
        .then(() => {
          setAuthUser(oldUser => ({ ...oldUser, favoriteRooms: favoriteRooms.filter(item => item !== id) }))
          setFavoriteRooms(favoriteRooms.filter(item => item !== id))
        })
        .catch(erro => console.log(erro))
    } else {
      firebase
        .addFavoriteRoom(id)
        .then(() => {
          executeScroll()
          setAuthUser(oldUser => ({ ...oldUser, favoriteRooms: [...favoriteRooms, id] }))
          setFavoriteRooms(favoriteRooms => [...favoriteRooms, id])
        })
        .catch(erro => console.log(erro))
    }
  }

  const handleOccupationColor = (seatsOccupied, totalSeats) => {
    var percent = ((seatsOccupied * 100) / totalSeats).toFixed(2)
    if (percent <= 50.0) {
      return {
        color: '#25A061',
      }
    } else if (percent <= 80.0) {
      return {
        color: '#FFAD0E',
      }
    } else {
      return {
        color: '#FF0E0E',
      }
    }
  }

  return (
    <>
      <ShowModal
        title='Você já tem uma mesa de trabalho reservada para hoje!'
        message='Apenas agendamentos em sala de reunião estão disponíveis!'
        isOpen={showLockedRoomMessage}
        handleClose={setShowLockedRoomMessage}
        image={error}
        secondActionLabel='Ok, Entendi!'
      />

      <Box className={classes.card} style={{ opacity: disableWorkstationCards && room.type === 0 ? 0.5 : 1 }}>
        <Card
          onClick={() =>
            disableWorkstationCards && room.type === 0
              ? setShowLockedRoomMessage(true)
              : handleRoomChange(room, lotation)
          }
          elevation={0}
        >
          <CardMedia image={room.pictures[0]} title={room.name} className={classes.media}></CardMedia>
          <CardContent style={{ paddingBottom: '16px' }}>
            <Typography variant='subtitle2' className={classes.title}>
              <Box className='titleBox' className={classes.roomName}>
                {room.name}
              </Box>
              <Box>
                {room.isFavorite ? (
                  <Grid
                    className={classes.hoverStar}
                    style={{ color: '#FFAD0E' }}
                    onClick={event => {
                      event.stopPropagation()
                      handleActionFavorite(room.id)
                    }}
                  >
                    <Star size='small' />
                  </Grid>
                ) : (
                  <Grid
                    className={classes.hoverStar}
                    style={{ color: 'black' }}
                    onClick={event => {
                      event.stopPropagation()
                      handleActionFavorite(room.id)
                    }}
                  >
                    <StarBorderIcon size='small' />
                  </Grid>
                )}
              </Box>
            </Typography>
            <Typography variant='subtitle1' className={classes.description}>
              {room.description}
            </Typography>
            {room.type !== 1 ? (
              <Typography
                variant='subtitle1'
                className={classes.ocupation}
                style={handleOccupationColor(lotation, getRoomCapacity(room))}
              >
                {lotation}/{getRoomCapacity(room)}
              </Typography>
            ) : (
              <Box className={classes.meeting}>
                <GroupIcon />
                <Typography variant='subtitle1'>{room.places}</Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
