export const LOGIN = '/'
export const ABOUT = '/about'
export const PROFILE = '/profile'
export const HOME = '/home'
export const HOME_AREAS = `${HOME}/:date/areas`
export const HOME_ROOMS = `${HOME_AREAS}/:areaID`
export const HOME_ROOM = `${HOME}/:date/:roomID`
export const HOME_REUNION_ROOM = `${HOME}/:date/meeting/:roomID`
export const RESERVATION = '/reservation'
export const ROOMS = '/rooms'
export const ROOM = '/rooms/:id/:date'
export const AREAS = '/areas'
export const NEWAREA = '/newarea'
export const AREAS_ROOMS = `${AREAS}/:areaID`
export const AREAS_NEWROOM = `${AREAS_ROOMS}/newroom`
export const AREAS_MAP = `${AREAS_ROOMS}/map`
export const AREAS_NEWROOM_FORM = '/newroom/form'
export const USER_HISTORY = '/userHistory'
export const AREAS_EDIT = `/editarea/:areaID`
export const ROOMEDITOR = '/roomeditor'
export const SAVEROOM = '/saveroom'
export const USERACESS = '/useracess'
export const DIRECT_CHECKIN = '/checkin/:tableId'
export const CHECKIN = '/checkin'
export const PRIVACY = '/privacy'
export const TERMSOFUSE = '/termsofuse'
export const TAB = '/tab'
export const USERS = '/users'
export const PRINT = '/print/:id'
export const REPORT = '/report'
export const REPORT_ROOMS_LIST = `/report/:month`
export const REPORT_ROOMS_OCUPATION = `/report/ocupation/:month`
export const REPORT_ROOMS_NOSHOW = `/report/noshow/:month`
export const REPORT_ROOMS_NOSHOW_DETAILS = `/report/noshow/:month/:roomID`
export const EDIT_ROOM = `/rooms/:areaID/editroom/:roomID`
export const AREA_EDIT_ROOM = `/rooms/:areaID/editroom/:roomID/map`
export const ROOM_EDITOR = `/rooms/:areaID/editroom/:roomID/editor`
export const ROOM_WEBVIEW = '/mobile-web-view'
export const SCRIPTS = '/scripts'
export const REPORT_SINGLE_ROOM_OCUPPATION = `/report/ocupation/:month/:roomID`
