import { makeStyles } from "@material-ui/core";


export const modalStyle = makeStyles(theme => ({
    boxmodal: {
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
        },
    },
    paper_modal: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5),
            width: '32%',
        },
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5%',
    },
    close: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(-3),
            marginRight: theme.spacing(-3),
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '0px',
        alignSelf: 'flex-end',
    },
    cancelimage: {
        height: '133px',
        width: '177px',
        marginBottom: theme.spacing(2),
    },
    box_cancel: {
        padding: theme.spacing(2),
    },
    box_cancelDay: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    box_bottom: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-around',
        },
    },
    radioBox: {
        padding: "0 10px",
        display: "flex",
        alignSelf: "flex-start",
    },
    observations: {
        textAlign: "start",
        marginBottom: 3
    },
    formControl: {
        display: "flex",
        alignItems: "center",
        textAlign: "start",
        fontSize: 13,
        marginBottom: 22
    },
    field: {
        marginTop: 30,
    },
    reasonTitle: {
        marginBottom: 10,
        fontSize: '1rem',
        color: 'black'
    },
    button_confirm: {
        height: '43px',
        marginTop: '10px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: '#53318a',
        textTransform: 'none',
        width: '100%',
        color: 'white',
        '&:hover': {
            backgroundColor: '#3A1A70',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    button_cancel: {
        height: '43px',
        marginTop: '10px',
        width: '100%',
        textTransform: 'none',
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'white',
            color: '#A593C1',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
}));
