import React from 'react'
import { Group, Image } from 'react-konva'
import useImage from 'use-image'
import { getElementImage } from '../roomEditor/menuBar/model'

const RoomElement = ({ element }) => {
  const [img] = useImage(getElementImage(element.icon))

  return (
    <Group
      id={element.id}
      key={element.id}
      x={element.x}
      y={element.y}
      offset={{ x: element.width / 2, y: element.height / 2 }}
      rotation={element.rotation}
    >
      <Image image={img} width={element.width} height={element.height} id={element.id} />
    </Group>
  )
}

export default RoomElement
