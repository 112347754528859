import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ListMenu } from './rowComponents'


const Row = ({
  row,
  handleDeleteArea,
  isItemSelected,
  labelId,
  selected,
  onDataChanged,
  handleOpenModal,
}) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleEditArea = async area => {
    history.push({ pathname: `/editarea/${row.id}`, state: { area: row } })
  }

 

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        <TableCell id={labelId} component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='left'>
          {row.description}
        </TableCell>
        <TableCell align='right'>
          <ListMenu
            anchorEl={anchorEl}
            row={row}
            onDataChanged={onDataChanged}
            selected={selected}
            handleClickMenu={handleClickMenu}
            handleCloseMenu={handleCloseMenu}
            handleEditArea={handleEditArea}
            handleOpenModal={handleOpenModal}
            handleDeleteArea={handleDeleteArea}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
