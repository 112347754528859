import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
    heigth: '100%',
  },
  header: {
    marginBottom: '30px',
    alignItems: 'flex-start',
  },
  titleGrid:{
    marginTop:'10px',
    marginBottom:'25px',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    height: '42px',
    width: '156px',
    marginTop:'4px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },
}))

export default useStyles
