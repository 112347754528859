import { makeStyles } from '@material-ui/core'
import {header_height} from '../../utils/variaveis.js'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    background: '#FAFAFA',

    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      maxWidth: '100%',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawer_paper: {
    width: drawerWidth,
  },
  main: {
    width: '100%',
  },
  content: {
    padding: theme.spacing(3),
    height: '100vh',
    width: '82vw',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  contentAER: {
    height: '0',
    overflow: '0',
    margin: '0',
    padding: '0',
  },
  toolbar: {
    height: header_height,
  }
}))

export { useStyles }
