import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import { useTheme, Box, SvgIcon, ThemeProvider } from '@material-ui/core'

import HomeIcon from '@material-ui/icons/Home'
import Person from '@material-ui/icons/Person'
import EventSeatIcon from '@material-ui/icons/EventSeat'
import MapIcon from '@material-ui/icons/Map'
import FilterCenterFocus from '@material-ui/icons/FilterCenterFocus'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import SubjectIcon from '@material-ui/icons/Subject'

//Area
import Areas from '../../pages/Area/areaTable'
import NewArea from '../../pages/Area/newArea'
import AreasRooms from '../../pages/Area/areasRooms'
import AreasMap from '../../pages/Area/areasMap'
import AreasNewRoom from '../../pages/Area/areasNewRoom'
import EditArea from '../../pages/Area/editArea'
import newRoomForm from '../../pages/Area/areasNewRoom/newRoomForm'
import AreaEditRoom from '../../pages/Area/areaEditRoom'

//Reservation
import ContentHome from '../../pages/home'

//Room
import RoomEditor from '../../pages/Rooms/roomEditor/index.jsx'
import EditRoom from '../../pages/Rooms/editRoom'
import Rooms from '../../pages/Rooms/roomOldTable'
import Room from '../../pages/Rooms/room/roomCard'
import SaveRoom from '../../pages/Rooms/editRoom/duplicateRoom'

//User
import Users from '../../pages/user/userTable'
import UserHistory from '../../pages/user/userHistory'
import EditUserAcess from '../../pages/user/editUserAcess'
import Profile from '../../pages/user/profile'

//General
import CheckIn from '../../pages/checkin'
import Print from '../../pages/print'

//Report
import Report from '../../pages/report'
import ReportRoomsList from '../../pages/report/roomsReportList'
import Ocupation from '../../pages/report/ocupation'
import NoShowReportList from '../../pages/report/noShowReportList'
import RoomOcuppation from '../../pages/report/RoomOcuppation'
import NoShowReportListDetails from '../../pages/report/noShowReportList/noShowDetails'

//Scripts
import Scripts from '../../pages/scripts'

//Components
import Header from '../../components/header'
import Menu from '../../components/menu'

import * as ROUTES from '../../config/routes'
import { AdminRoute } from '../../utils/routes-type'
import { compareRoutes } from '../../utils/routes-compare'
import { useStyles } from './styles'
import pathsConfig from '../../config/paths-config'
import WebViewRoom from '../../pages/webview'

import MenuMobile from '../../components/menuMobile'
import { DisplayMenuMobileContext } from '../../contexts/displayMenuMobile'

const Home = props => {
  const { window } = props
  const theme = useTheme()
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  const location = useLocation()
  const { isFocused } = useContext(DisplayMenuMobileContext)

  const container = window !== undefined ? window().document.body : undefined

  const handleListItemClick = () => {
    if (mobileOpen) {
      handleDrawerToggle()
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const renderIcons = {
    0: <HomeIcon />,
    1: <FilterCenterFocus />,
    2: <EventSeatIcon />,
    3: <MapIcon />,
    4: <Person />,
    5: <EqualizerIcon />,
    6: <SubjectIcon />,
  }

  const hasNoMenu = pathsConfig.filter(item => item.noMenu).some(item => compareRoutes(location.pathname, item.route))

  return (
    <Box className={classes.root}>
      <ThemeProvider theme={theme}>
        <Header handleDrawerToggle={handleDrawerToggle} />
        {hasNoMenu ? (
          <></>
        ) : (
          <Menu
            container={container}
            theme={theme}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            handleListItemClick={handleListItemClick}
            renderIcons={renderIcons}
          />
        )}

        <main className={classes.main}>
          <Box className={classes.toolbar} />
          <ScrollToTop>
            <Switch>
              <Route exact path={ROUTES.ROOM} component={Room} className={classes.content} />
              <Route path={ROUTES.HOME} component={ContentHome} className={classes.content} />
              <Route path={ROUTES.PROFILE} component={Profile} className={classes.content} />
              <Route exact path={ROUTES.CHECKIN} component={CheckIn} className={classes.content} />
              <Route exact path={ROUTES.DIRECT_CHECKIN} component={CheckIn} className={classes.content} />
              <Route exact path={ROUTES.USER_HISTORY} component={UserHistory} classesName={classes.content} />
              <AdminRoute exact path={ROUTES.SAVEROOM} component={SaveRoom} className={classes.content} />
              <AdminRoute exact path={ROUTES.ROOMS} component={Rooms} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREAS} component={Areas} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREAS_ROOMS} component={AreasRooms} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREAS_MAP} component={AreasMap} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREAS_NEWROOM} component={AreasNewRoom} className={classes.content} />
              <AdminRoute path={ROUTES.AREAS_NEWROOM_FORM} component={newRoomForm} className={classes.content} />
              <AdminRoute exact path={ROUTES.NEWAREA} component={NewArea} className={classes.content} />
              <AdminRoute exact path={ROUTES.USERS} component={Users} className={classes.content} />
              <AdminRoute exact path={ROUTES.USERACESS} component={EditUserAcess} className={classes.content} />
              <AdminRoute exact path={ROUTES.PRINT} component={Print} className={classes.content} />
              <AdminRoute exact path={ROUTES.REPORT} component={Report} className={classes.content} />
              <AdminRoute exact path={ROUTES.SCRIPTS} component={Scripts} className={classes.content} />
              <AdminRoute
                exact
                path={ROUTES.REPORT_ROOMS_LIST}
                component={ReportRoomsList}
                className={classes.content}
              />
              <AdminRoute
                exact
                path={ROUTES.REPORT_ROOMS_OCUPATION}
                component={Ocupation}
                className={classes.content}
              />
              <AdminRoute
                exact
                path={ROUTES.REPORT_SINGLE_ROOM_OCUPPATION}
                component={RoomOcuppation}
                className={classes.content}
              />
              <AdminRoute
                exact
                path={ROUTES.REPORT_ROOMS_NOSHOW}
                component={NoShowReportList}
                className={classes.content}
              />
              <AdminRoute
                exact
                path={ROUTES.REPORT_ROOMS_NOSHOW_DETAILS}
                component={NoShowReportListDetails}
                className={classes.content}
              />
              <AdminRoute exact path={ROUTES.EDIT_ROOM} component={EditRoom} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREA_EDIT_ROOM} component={AreaEditRoom} className={classes.content} />
              <AdminRoute exact path={ROUTES.ROOM_EDITOR} component={RoomEditor} className={classes.content} />
              <AdminRoute exact path={ROUTES.AREAS_EDIT} component={EditArea} className={classes.content} />
              <Route exact path={ROUTES.ROOM_WEBVIEW} component={WebViewRoom} />
              <Redirect from='*' to={ROUTES.LOGIN} className={classes.content} />
            </Switch>
          </ScrollToTop>
        </main>
      </ThemeProvider>
      {!isFocused && <MenuMobile />}
    </Box>
  )
}

Home.propTypes = {
  window: PropTypes.func,
}

export default Home
