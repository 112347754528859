import React, { useContext, useState } from 'react'

import { Button, Grid, Box, FormHelperText, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Microsoft from '../../assets/microsoft.svg'
import logoPrincipal from '../../assets/login/logolockdesk.svg'
import { FirebaseContext } from '../../contexts/firebase'
import { useStyles } from './styles'

import Icon from '@material-ui/core/Icon'

export default () => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const [message, setMessage] = useState('')
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const svgIcon = (
    <Icon>
      <img alt='microsoft' src={Microsoft} />
    </Icon>
  )
  const login = () =>
    firebase
      .signIn()
      .then(token => window.localStorage.setItem('graphToken', token))
      .catch(() => setMessage('Infelizmente houve um erro ao tentar logar'))

  return (
    <Grid container className={classes.root}>
      {matches ? (
        <Grid item container xs={false} sm={false} md={false} className={classes.grid_backgroudimage_mobile}>             
          <img src={logoPrincipal } alt='sala de reuniao' className={classes.image_mobile} />
        
        </Grid>
      ) : (
        <Grid item container xs={false} sm={false} md={8}className={classes.grid_backgroundimagem}>
           <img src={logoPrincipal } alt='Logo' className={classes.grid_image} />
        </Grid>
      )}
      {matches ? (
        <Grid item xs={12} sm={12} md={12}>
          <Box display='flex' flexDirection='column' className={classes.paper_button}>
            <Typography variant='h6'align='center' className={classes.message_welcome_mobile}>
              SEJAM BEM-VINDOS
            </Typography>
            <Typography variant='h3' align='center' className={classes.message_portal}>
              Ao portal LockDesk   
            </Typography>
            <form className={classes.form} noValidate autoComplete='off' color='secondary'>
              <Button id='btn_login' fullWidth className={classes.button_mobile} onClick={login} startIcon={svgIcon}>
                Entrar com Microsoft
              </Button>
              {message && <FormHelperText className={classes.error}>{message}</FormHelperText>}
            </form>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={4}>
          <Box display='flex' flexDirection='column' mt={8} className={classes.paper}>
            <Typography variant='h6' align='center' className={classes.message_welcome}>
              SEJAM BEM-VINDOS 
            </Typography>
            <Typography variant='h3'align='center' className={classes.message_portal}>
              Ao portal LockDesk
            </Typography>
            <form className={classes.form} noValidate autoComplete='off' color='secondary'>
              <Button id='btn_login' fullWidth className={classes.button} onClick={login} startIcon={svgIcon}>
                Entrar com Microsoft
              </Button>
              {message && <FormHelperText className={classes.error}>{message}</FormHelperText>}
            </form>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
