import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { format } from 'date-fns'
import { cell } from './styles'

export const Row = ({ row }) => {
  const classes = cell()

  /*   function handleReservationStatus(status) {
      function 
  } */

  return (
    <TableRow role='checkbox' key={row[0]} onClick={() => handleRoom(row[0])}>
      <TableCell align='left' padding='default' className={classes.row}>
        {format(new Date(row.date.seconds * 1000), 'dd/MM/yyyy')}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row.user}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.tableInfo}>
        {row.table}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.tableInfo}>
        {row.block}
      </TableCell>
    </TableRow>
  )
}
export default Row
