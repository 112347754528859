import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, ThemeProvider } from '@material-ui/core'
import { theme } from '../../theme/menuMobile'

import useStyles from './styles.js'
import PersonIcon from '@material-ui/icons/Person'
import CheckIcon from '@material-ui/icons/Check'
import HomeIcon from '@material-ui/icons/Home'

import { makeStyles } from '@material-ui/styles'

export default () => {
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const history = useHistory()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleRedirect = path => {
    return history.replace(`/${path}`)
  }

  return (
   
    <ThemeProvider theme={theme}>
      <BottomNavigation value={value} onChange={handleChange} className={classes.bottom_menu} showLabels>
        <BottomNavigationAction label='Home' icon={<HomeIcon />} onClick={() => handleRedirect('home')} />
        <BottomNavigationAction
          label='Checkin'
          value='checkin'
          icon={<CheckIcon />}
          onClick={() => handleRedirect('checkin')}
        />
        <BottomNavigationAction
          label='Perfil'
          value='perfil'
          icon={<PersonIcon />}
          onClick={() => handleRedirect('profile')}
        />
      </BottomNavigation>
    </ThemeProvider>
  )
}

