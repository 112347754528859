import { makeStyles } from '@material-ui/core'
import imagemroom from '../../assets/login/backgroundLogin.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(8, 4),
    height: '86vh',
  },
  paper_button: {
    margin: '33px',
    height: '100%',
    justifyContent:'center',
    
  },
  button: {
    padding: theme.spacing(2),
    margin: theme.spacing(8, 0, 2),
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#fcfcfc',
    border: '1px solid #E4E4E4',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: '#fcfcfc',
    },
  },
  button_mobile: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(2, 0),
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#fcfcfc',
    border: '1px solid #E4E4E4',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: '#fcfcfc',
    },
  },

grid_backgroundimagem: {
    background:`url(${imagemroom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'

},

grid_backgroudimage_mobile:{
    background:`url(${imagemroom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
},

  grid_image: {
    width: '88%',
    height:'auto',
    padding: theme.spacing(10)
    
   
  },

  image_mobile: {
    margin: 'auto',
    width: '60%',
  },

  form_mobile: {
    width: '100%',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  message_welcome: {
    marginTop: '50%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  message_welcome_mobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
     
    },
  },
  message_portal: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
       
    },
  },
}))

export { useStyles }
