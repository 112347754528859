import { Box, CircularProgress, Container, Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layer, Line, Stage } from 'react-konva'
import { useLocation, useParams } from 'react-router-dom'
import STEPS from '../../../../config/home_steps.json'
import { FirebaseContext } from '../../../../contexts/firebase'
import { getRoomCapacity } from '../../../../utils/checkin'
import { convertPointsToLine } from '../../../../utils/points-to-line.js'
import CardKonvas from '../../../cards/cardKonvas'
import CardRoom from '../../../cards/rooms'
import RoomElements from '../../roomElements'
import TableGroup from '../../tableGroup'
import useStyles from './styles'
import ZoomControl from './../../../../components/zoomControl'

export default ({ handleStep, handleDate, step, setIsEdit }) => {
  const { state } = useLocation()
  const { reservationProps, isEdit, areaName } = state ? state : propsState
  const [reservation, setReservation] = useState(reservationProps)
  const classes = useStyles({ isEdit })
  const container = useRef(null)
  const { date, roomID } = useParams()
  const firebase = useContext(FirebaseContext)
  const instantiatedDate = new Date(Number(date))
  const [room, setRoom] = useState()
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(true)
  const [isFull, setFull] = useState(false)
  const theme = useTheme()
  const [shouldShowText, setShouldShowText] = useState(false)
  const [avaiable, setAvaiable] = useState(true)
  const [reservationCard, setReservationCard] = useState()
  const [reservationCardPosition, setReservationCardPosition] = useState()
  const [stageResized, setStageResized] = useState(false)
  const [cursor, setCursor] = useState(false)
  const [line, setLine] = useState([])

  const [selected, setSelected] = useState({ block: null, table: null })
  const stage = useRef(null)

  const [width, setWidth] = useState(500)
  const [height, setHeight] = useState(500)

  if (!state) {
    var propsState = {
      roomData: room,
      isEdit: false,
      reservationProps: {},
    }
  }

  let all = useRef(true)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const fetchData = async () => {
    const getRoom = await firebase.getRoom(roomID)
    if (all.current) {
      setRoom(getRoom)
      convertPointsToLine(getRoom.walls || [], setLine)
    }
  }

  const executeScroll = () => {
    container.current.scrollTo({
      top: 0,
      left: 0,
    })
  }
  useEffect(() => {
    if (!all.current) return
    if (container.current) {
      executeScroll()
    }
  }, [container])

  useEffect(() => {
    if (room && room.elements && width && height) {
      setLoading(false)
    }
  }, [room && room.elements, width, height])

  const handleReservations = reservation => {
    if (all.current) {
      setReservations(reservation)
    }
  }

  const handleFecthData = () => {
    fetchData()
    handleStep(STEPS.ROOM)
    handleDate(new Date(Number(date)))
    setIsEdit(isEdit)
    let cleanup = { callback: undefined }
    firebase.getRoomReservationsByDay(roomID, instantiatedDate, handleReservations, reservations, cleanup)

    return () => {
      all.current = true
      cleanup.callback()
    }
  }

  useEffect(() => {
    if (!all.current) return
    handleFecthData()
  }, [date])

  const handleSelectSeat = data => {
    data.type = 0
    data.room = room.id
    data.roomNid = room.nid
    data.roomName = room.name
    data.date = instantiatedDate
    data.area = areaName
      ? areaName
      : firebase.getAreaByRoomID(roomID).then(result => {
          data.area = result.name
        })
    setReservation(data)
  }

  const handleSeat = (seat, reservation, show, avaiable) => {
    if (show && seat && reservation) {
      setReservationCard(reservation)
    }
    setReservationCardPosition(getRelativePointerPosition(stage.current))
    setAvaiable(avaiable)
    setShouldShowText(show)
  }

  const getRelativePointerPosition = node => {
    let transform = node.getAbsoluteTransform().copy()

    transform.invert()

    let pos = node.getStage().getPointerPosition()

    return transform.point(pos)
  }

  const handleClose = () => {
    setShouldShowText(false)
  }

  const circularProgress = (
    <Box className={classes.circularStyle}>
      <CircularProgress />
    </Box>
  )

  const handleLotationChange = (roomid, lotation) => {
    if (roomid && lotation !== undefined) {
      setFull(lotation >= getRoomCapacity(room))
    }
  }
  const [zoom, setZoom] = useState(
    room?.height >= 800 || room?.width >= 1050 ? 0.6 : room?.height >= 600 || room?.width >= 800 ? 0.8 : 1
  )
  useEffect(() => {
    if (room != undefined && loading)
      setZoom(room?.height >= 800 || room?.width >= 1050 ? 0.6 : room?.height >= 600 || room?.width >= 800 ? 0.8 : 1)
  })

  const zoomIn = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    setZoom(0.1 + zoom)
  }

  const zoomOut = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    setZoom(zoom - 0.1)
  }

  const centralize = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    setZoom(room?.height >= 800 || room?.width >= 1050 ? 0.6 : room?.height >= 600 || room?.width >= 800 ? 0.8 : 1)
    s.position({
      x:
        c.clientWidth / 2 -
        (room.width *
          (room?.height >= 800 || room?.width >= 1050 ? 0.6 : room?.height >= 600 || room?.width >= 800 ? 0.8 : 1)) /
          2,
      y:
        c.clientHeight / 2 -
        (room.height *
          (room?.height >= 800 || room?.width >= 1050 ? 0.6 : room?.height >= 600 || room?.width >= 800 ? 0.8 : 1)) /
          2,
    })
    s.batchDraw()
  }

  const [walls, setWalls] = useState([])
  const [minX, setMinX] = useState()
  const [minY, setMinY] = useState()
  const [maxX, setMaxX] = useState()
  const [maxY, setMaxY] = useState()

  useEffect(() => {
    if (!all.current) return

    setWidth(maxX - minX)
    setHeight(maxY - minY)
  }, [minX, minY, maxX, maxY])

  useEffect(() => {
    if (!room || !room.polygon || !all.current) return
    const mx = Math.min(...room.polygon.map(point => point.x))
    const my = Math.min(...room.polygon.map(point => point.y))
    const mX = Math.max(...room.polygon.map(point => point.x))
    const mY = Math.max(...room.polygon.map(point => point.y))
    setMinX(mx)
    setMinY(my)
    setMaxX(mX)
    setMaxY(mY)
  }, [room])

  useEffect(() => {
    if (!room || !room.polygon || !all.current) return
    let aux = []
    room.polygon.forEach(point => {
      aux.push((point.x - minX) * room.scale + (room.width - (maxX - minX) * room.scale) / 2),
        aux.push((point.y - minY) * room.scale + (room.height - (maxY - minY) * room.scale) / 2)
    })
    setWalls(aux)
  }, [room, minX, minY, maxX, maxY, zoom])

  useEffect(() => {
    if (!container || !container.current || stageResized || !all.current) return
    stage.current.width(container.current.clientWidth)
    stage.current.height(container.current.clientHeight)
    stage.current.position({
      x: container.current.clientWidth / 2 - (room.width * zoom) / 2,
      y: container.current.clientHeight / 2 - (room.height * zoom) / 2,
    })
    setStageResized(true)
    stage.current.batchDraw()
  })

  return (
    <Box className={classes.root}>
      <main className={classes.content}>
        <Container style={{ cursor: cursor ? 'grab' : 'default' }} className={classes.container} maxWidth={false}>
          {loading ? (
            circularProgress
          ) : (
            <Grid container spacing={3} style={{ height: '100%' }}>
              <Grid item xs={12} sm={12} lg={8} md={8}>
                <Box className={classes.mapPaper}>
                  <Paper className={classes.card_paper} ref={container} elevation={0}>
                    <Stage
                      width={0}
                      height={0}
                      ref={stage}
                      draggable
                      style={{ alignSelf: 'center', margin: 'auto' }}
                      scale={{ x: zoom, y: zoom }}
                      onDragMove={() => {
                        setCursor(true)
                      }}
                      onDragEnd={() => {
                        setCursor(false)
                      }}
                    >
                      <Layer>
                        {walls.length && <Line closed stroke='black' strokeWidth={3} points={walls} />}
                        {line &&
                          line.map((ln, index) => {
                            return (
                              <Line
                                stroke='black'
                                strokeWidth={5}
                                draggable={false}
                                points={ln.points}
                                closed={false}
                                key={index + new Date()}
                              />
                            )
                          })}
                        {room.elements &&
                          room.elements.map((ele, index) =>
                            ele.workstation ? (
                              <TableGroup
                                element={ele}
                                reservations={reservations}
                                handleSeat={handleSeat}
                                handleSelectSeat={handleSelectSeat}
                                selected={selected}
                                setSelected={setSelected}
                                reserved={reservations.filter(res => res.block === ele.index)}
                                key={index}
                              />
                            ) : (
                              <RoomElements element={ele} key={index} />
                            )
                          )}
                        {shouldShowText && (
                          <CardKonvas
                            avaiable={avaiable}
                            position={reservationCardPosition}
                            reservation={reservationCard}
                            handleClose={handleClose}
                          />
                        )}
                      </Layer>
                    </Stage>
                  </Paper>
                  <ZoomControl handleZoomIn={zoomIn} handleZoomOut={zoomOut} handleCentralize={centralize} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                {loading ? (
                  <Skeleton variant='rect' width={'100%'} height={'100%'} className={classes.skeleton}></Skeleton>
                ) : (
                  <CardRoom
                    handleLotationChange={handleLotationChange}
                    room={room}
                    isEdit={isEdit}
                    isFull={isFull}
                    step={step}
                    areaName={areaName}
                    isClickable={false}
                    date={date}
                    reservation={reservation}
                    reservations={reservations}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    </Box>
  )
}
