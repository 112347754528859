import React, { useRef, useState, useEffect, useContext } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import * as ROUTES from '../../../../config/routes'
import {
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  Snackbar,
  LinearProgress,
} from '@material-ui/core'
import useStyles from './styles'
import ImageInfo from '../../../../components/imageInfo'
import { FirebaseContext } from '../../../../contexts/firebase'
import DropzoneDrag from '../../../../components/dropzone'
import ShowModal from '../../../../components/modal'
import NewRoomModalIcon from '../../../../assets/newRoomHint.svg'

export default ({}) => {
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [progress, setProgress] = useState(0)
  const [managers, setManagers] = useState([])
  const [allAreas, setAllAreas] = useState([])
  const [newRoom, setNewRoom] = useState({
    polygon: state['polygon'],
    name: null,
    nid: null,
    area: state['area'],
    areaName: state['areaName'],
    account: null,
    description: null,
    manager: 'Não definido',
    public: true,
    type: 0,
    places: 0,
    pictures: [],
    elements: [],
  })
  const firebase = useContext(FirebaseContext)
  const [invalidInput, setInvalidInput] = useState()
  const all = useRef(true)
  const nidError = useRef('')

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const handleSubmit = () => {
    if (!all.current) return
    validateNid()
    if (
      !newRoom.name ||
      nidError.current !== '' ||
      !newRoom.description ||
      !newRoom.account ||
      newRoom.type === -1 ||
      !newRoom.manager ||
      !newRoom.areaName ||
      !image
    ) {
      setInvalidInput({
        name: !newRoom.name,
        nid: nidError.current !== '',
        description: !newRoom.description,
        account: !newRoom.account,
        type: newRoom.type === -1,
        manager: !newRoom.manager,
        areaName: !newRoom.areaName,
        places: newRoom.type === 1 && newRoom.places <= 0 ? true : false,
      })

      if (!image) setImageError(true)
      return
    }

    history.push({
      pathname: `../areas/${newRoom.area}/newroom`,
      state: { room: newRoom, isEdit: false, image: image },
    })
  }

  const validateNid = () => {
    nidError.current = ''
    if (!newRoom.nid) {
      nidError.current = 'Este campo não pode ficar em branco'
      return
    }

    if (state.rooms.some(id => newRoom.nid === id.nid)) {
      nidError.current = 'Este ID já está sendo utilizado em outra sala.'
    }
  }

  const handleImage = image => {
    setImageError(false)

    setImage(image)
  }

  useEffect(() => {
    if (!all.current) return

    if (imageError) {
      setShowToast(true)
      const msgShow = setTimeout(() => {
        clearInterval(msgShow)
        setShowToast(false)
        setImageError(false)
      }, 5000)
    }
  }, [imageError])

  const handleDelete = () => {
    if (!all.current) return

    setProgress(0)
    setImage(null)
  }

  const snackBar = (
    <Box>
      <Snackbar open={showToast} autoHideDuration={6000} className={classes.snackbar}>
        <>
          <Typography variant='body1' style={{ color: 'white' }}>
            Você precisa inserir uma imagem.
          </Typography>
          <Button variant='text' onClick={() => setShowToast(false)}>
            <Typography variant='body1' style={{ color: 'white', border: 'none' }}>
              FECHAR
            </Typography>
          </Button>
        </>
      </Snackbar>
    </Box>
  )
  useEffect(() => {
    firebase.getManagers().then(resp => {
      setManagers(resp)
    })
  }, [])

  useEffect(() => {
    firebase.getAllAreas().then(resp => {
      setAllAreas(resp)
    })
  }, [])

  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <Grid container item justify='space-between' alignItems='center' className={classes.header}>
        <Grid item xs={3}>
          <Typography variant='h5'>Salas</Typography>
          <Typography variant='body1' className={classes.color}>
            <Link to={ROUTES.ROOMS} className={classes.link}>
              Salas
            </Link>{' '}
            &gt; Nova sala
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button variant='contained' color='primary' onClick={handleSubmit} className={classes.button}>
            <Typography variant='subtitle1' color='inherit'>
              Avançar
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <ShowModal
        title='Eba! Você vai criar uma nova sala'
        message='Você vai começar a criar uma sala nova, selecionamos aqui 2 dicas importante.'
        isOpen={open}
        handleClose={() => setOpen(false)}
        image={NewRoomModalIcon}
        secondActionLabel='Começar'
      >
        <Grid container alignItems='center' className={classes.modalContent}>
          <Grid item xs={2} className={classes.numberContainer}>
            <Typography color='primary' component='span' className={classes.modalNumber}>
              1
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='subtitle1' color='textSecondary' style={{ textAlign: 'start' }}>
              Seja claro e objetivos nos dados da sala assim fica fácil de identifica-lás.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems='center' className={classes.modalContent}>
          <Grid item xs={2} className={classes.numberContainer}>
            <Typography color='primary' component='span' className={classes.modalNumber}>
              2
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='subtitle1' color='textSecondary' style={{ textAlign: 'start' }}>
              Selecione os 4 cantos das salas, caso precise utilize o zoom da plataforma.
            </Typography>
          </Grid>
        </Grid>
      </ShowModal>
      <Grid container spacing={3} item justify='space-between' alignItems='center' className={classes.nextButton}>
        {loading ? (
          <>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Dados da sala</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Nome da sala
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            error={invalidInput && invalidInput.name}
            helperText={invalidInput && invalidInput.name && 'Este campo não pode ficar em branco'}
            name='name'
            onChange={e => {
              setNewRoom({ ...newRoom, name: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={4} className={classes.nextButton}>
          <Grid item xs={12}>
            <Typography variant='body2' color='textSecondary'>
              ID da sala
            </Typography>
            <TextField
              inputProps={{ className: classes.textColor }}
              className={classes.input}
              variant='outlined'
              type='number'
              error={invalidInput && invalidInput.nid}
              helperText={invalidInput && invalidInput.nid && nidError.current}
              role='none'
              name='nid'
              onChange={e => {
                setNewRoom({ ...newRoom, nid: e.target.valueAsNumber })
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Projeto
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            error={invalidInput && invalidInput.description}
            helperText={invalidInput && invalidInput.account && 'Este campo não pode ficar em branco'}
            name='account'
            onChange={e => {
              setNewRoom({ ...newRoom, account: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Gerente
          </Typography>
          <Select
            className={classes[`input${newRoom.manager === '' ? 'Invalid' : ''}`]}
            variant='outlined'
            defaultValue='Não definido'
            name='manager'
            onChange={e => {
              setNewRoom({ ...newRoom, manager: e.target.value })
            }}
          >
            <MenuItem key={0} value={'Não definido'}>
              Não definido
            </MenuItem>
            {managers.map(manager => (
              <MenuItem key={manager.id} value={manager.displayName}>
                {manager.displayName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Tipo da sala
          </Typography>
          <Select
            className={classes[`input${newRoom.type === -1 ? 'Invalid' : ''}`]}
            variant='outlined'
            error={invalidInput && invalidInput.type}
            style={{ fontSize: 12 }}
            defaultValue={0}
            name='type'
            onChange={e => {
              setNewRoom({ ...newRoom, type: e.target.value, places: 0 })
            }}
          >
            <MenuItem key={0} value={0}>
              Normal
            </MenuItem>
            <MenuItem key={1} value={1}>
              Reunião
            </MenuItem>
          </Select>
        </Grid>
        <Grid className={classes.gridStats} item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Acesso
          </Typography>
          <Select
            className={classes[`input${newRoom.public === true ? 'Invalid' : ''}`]}
            variant='outlined'
            error={invalidInput && invalidInput.public}
            style={{ fontSize: 12 }}
            helpertext={invalidInput && invalidInput.public && 'Este campo não pode ficar em branco'}
            defaultValue={true}
            name='public'
            onChange={e => {
              setNewRoom({ ...newRoom, public: e.target.value })
            }}
          >
            <MenuItem key={true} value={true}>
              Publica
            </MenuItem>
            <MenuItem key={false} value={false}>
              Privada
            </MenuItem>
          </Select>
        </Grid>
        {invalidInput && invalidInput.public && (
          <FormHelperText style={{ color: 'red' }}>Este campo não pode ficar em branco</FormHelperText>
        )}
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Área
          </Typography>
          <Select
            className={classes.input}
            variant='outlined'
            error={invalidInput && invalidInput.areaName}
            style={{ fontSize: 12 }}
            defaultValue={''}
            onChange={e => {
              setNewRoom({ ...newRoom, areaName: e.target.value.name, area: e.target.value.id })
            }}
          >
            {allAreas.map(area => (
              <MenuItem key={area.id} value={area}>
                {area.name}
              </MenuItem>
            ))}
          </Select>
          {invalidInput && invalidInput.areaName && (
            <FormHelperText className={classes.helperText}>Este campo não pode ficar em branco</FormHelperText>
          )}
        </Grid>
        {newRoom.type === 1 && (
          <Grid item xs={2}>
            <Typography variant='body2' color='textSecondary'>
              Numero de lugares
            </Typography>
            <TextField
              inputProps={{ className: classes.textColor }}
              type='number'
              className={classes.input}
              variant='outlined'
              error={invalidInput && invalidInput.places}
              helperText={invalidInput && invalidInput.places && 'Este campo não pode ficar em branco'}
              name='places'
              disabled={newRoom.type === 0}
              onChange={e => {
                setNewRoom({ ...newRoom, places: Number(e.target.value) })
              }}
            />
          </Grid>
        )}
        <Grid item xs={newRoom.type === 1 ? 6 : 8}>
          <Typography variant='body2' color='textSecondary'>
            Descrição
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            error={invalidInput && invalidInput.description}
            helperText={invalidInput && invalidInput.description && 'Este campo não pode ficar em branco'}
            name='description'
            onChange={e => {
              setNewRoom({ ...newRoom, description: e.target.value })
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.imageInput}>
        {!image ? (
          <DropzoneDrag handleChange={handleImage} />
        ) : (
          <ImageInfo handleDelete={handleDelete} progress={progress} image={image} />
        )}
      </Grid>
      {snackBar}
    </Grid>
  )
}
