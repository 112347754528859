import React, { useContext } from 'react'

import {
  Button,
  Box,
  Typography,
  IconButton,
  ListItemIcon,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person'
import DeleteIcon from '@material-ui/icons/Delete'

import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import userPermission from '../../../utils/permission.js'

import { getModalPosition, modalStyle, StyledMenu, StyledMenuItem } from './styles'

export const ListMenu = ({
  anchorEl,
  handleClickMenu,
  handleCloseMenu,
  handleEditAccess,
  handleOpenModal,
  handleOpenUserModal,
  row,
  selected,
}) => {
  const { authUser } = useContext(SessionContext)

  return (
    <Box>
      <IconButton onClick={e => handleClickMenu(e)}>
        <MoreVert />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledMenuItem onClick={() => handleEditAccess(row)} disabled={selected.length > 1}>
          <ListItemIcon style={{ minWidth: '36px' }}>
            <MeetingRoomIcon fontSize='small' />
          </ListItemIcon>
          <span>Salas</span>
        </StyledMenuItem>
        {authUser.uid !== row.uid && (
          <StyledMenuItem onClick={() => handleOpenModal(row)} disabled={selected.length > 1}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <PersonIcon fontSize='small' />
            </ListItemIcon>
            <span>Permissão</span>
          </StyledMenuItem>
        )}
          {authUser.uid !== row.uid && (
        <StyledMenuItem onClick={() => handleOpenUserModal(row)} disabled={selected.length > 1}>
          <ListItemIcon style={{ minWidth: '36px' }}>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <span>Excluir</span>
        </StyledMenuItem>
        )}
      </StyledMenu>
    </Box>
  )
}

export const ContentModal = ({ handleCloseModal, row, onDataChanged }) => {
  const classesModal = modalStyle()
  const permission = userPermission[row.permission]
  const firebase = useContext(FirebaseContext)
  const { authUser } = useContext(SessionContext)
  const [value, setValue] = React.useState(permission)
  const [modalPosition] = React.useState(getModalPosition)

  const handleChangeModal = event => {
    setValue(event.target.value)
  }

  const handleConfirmChange = async () => {
    let newPermission = 0
    if (value === 'Usuário') {
      newPermission = 0
    } else if (value === 'Admin Sala') {
      newPermission = 1
    } else if (value === 'Admin Usuário') {
      newPermission = 2
    } else {
      newPermission = 3
    }
    await firebase.changePermission(authUser.permission, row.uid, newPermission)
    onDataChanged()
    handleCloseModal(false)
  }

  return (
    <Box style={modalPosition} className={classesModal.paper_modal} boxShadow={3}>
      <Box className={classesModal.box_top}>
        <Typography className={classesModal.title_text}>Permissão do usuário</Typography>
        <Button onClick={handleCloseModal} className={classesModal.button_close}>
          <CloseIcon className={classesModal.icon} />
        </Button>
      </Box>
      <Typography className={classesModal.user_text}>Usuário: {row.displayName}</Typography>
      <FormControl className={classesModal.form_control}>
        <RadioGroup value={value} onChange={handleChangeModal} className={classesModal.radio_group}>
          <FormControlLabel
            value='Admin Usuário'
            control={
              <Radio
                classes={{
                  root: classesModal.radio,
                }}
              />
            }
            label='Admin Usuários'
          />
          <FormControlLabel
            value='Admin Sala'
            control={
              <Radio
                classes={{
                  root: classesModal.radio,
                }}
              />
            }
            label='Admin Salas'
          />
          <FormControlLabel
            value='Master Admin'
            control={
              <Radio
                classes={{
                  root: classesModal.radio,
                }}
              />
            }
            label='Master Admin'
          />
          <FormControlLabel
            value='Usuário'
            control={
              <Radio
                classes={{
                  root: classesModal.radio,
                }}
              />
            }
            label='Usuário'
          />
        </RadioGroup>
      </FormControl>
      <Box className={classesModal.box_bottom}>
        <Button
          className={classesModal.button_confirm}
          color='primary'
          variant='contained'
          onClick={handleConfirmChange}
        >
          Confirmar
        </Button>
        <Button onClick={handleCloseModal} className={classesModal.button_cancel}>
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}
