export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const codeSeparator = tableId => {
  let codes = { room: '', block: '', table: '' }
  const bI = tableId.indexOf('B')
  const mI = tableId.indexOf('M')
  codes.room = Number.parseInt(tableId.substring(1, bI))
  codes.block = Number.parseInt(tableId.substring(bI + 1, mI))
  codes.table = Number.parseInt(tableId.substring(mI + 1, tableId.length))
  return codes
}
