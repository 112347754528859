const discriminant = (p1, p2, p3) => {
  //If discriminant is positive the points position are in counter clockwise direction
  //If discriminant is negative the points position are in clockwise direction
  //If discriminant equals zero the points lies in the same line
  return p1.x * p2.y + p1.y * p3.x + p2.x * p3.y - (p2.y * p3.x + p1.x * p3.y + p1.y * p2.x)
}

const lineEquationByPoints = (p1, p2) => {
  return { a: p1.y - p2.y, b: p2.x - p1.x, c: p1.x * p2.y - p1.y * p2.x }
}

const distance = (p1, p2) => {
  //Euclidean distance between points
  return Math.hypot(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y))
}

export const insertPoint = (e, points, scale, setShowPoints, setPoints, newPos, x, y) => {
  const stage = e.target.getStage()
  const clickedPos = {
    x: stage.getPointerPosition().x / scale - stage.attrs.x / scale,
    y: stage.getPointerPosition().y / scale - stage.attrs.y / scale,
  }

  // insertIndex is the index in polygon points where the new point will be insert
  let insertIndex = -1
  const pointsAux = points.map(point => {
    return { x: point.x + newPos.x, y: point.y + newPos.y }
  })

  for (let i = 0; i < pointsAux.length; i++) {
    // Get the line equation of all pair of points in the polygon
    let { a, b, c } = lineEquationByPoints(pointsAux[i], pointsAux[(i + 1) % pointsAux.length])

    // q is the closest point from clicked position that belongs to the line
    let q = { x: 0, y: 0 }
    q.x = (b * (b * clickedPos.x - a * clickedPos.y) - a * c) / (a ** 2 + b ** 2)
    q.y = (a * (-b * clickedPos.x + a * clickedPos.y) - b * c) / (a ** 2 + b ** 2)

    // if q lies between the two points, contains === true
    let contains =
      distance(pointsAux[i], pointsAux[(i + 1) % pointsAux.length]) > distance(pointsAux[i], q) &&
      distance(pointsAux[i], pointsAux[(i + 1) % pointsAux.length]) > distance(q, pointsAux[(i + 1) % pointsAux.length])

    if (distance(clickedPos, q) <= 5 && contains) {
      insertIndex = i
      break
    }
  }

  // if insertIndex === -1, there's no valid index in polygon to insert the new point
  if (insertIndex >= 0) {
    let pointsAux2 = []
    for (let i = 0; i < points.length; i++) {
      pointsAux2.push({ x: points[i].x + x, y: points[i].y + y })
      if (i === insertIndex) {
        pointsAux2.push({ x: clickedPos.x - newPos.x + x, y: clickedPos.y - newPos.y + y })
      }
    }
    setPoints(pointsAux2)
    setShowPoints(true)
  }
}

export const inside = (points1, points2) => {
  const outX = Math.max(...points2.map(point => point.x)) + 1
  const outY = Math.max(...points2.map(point => point.y)) + 1
  //pOut is a point outside the polygon
  const pOut = { x: outX, y: outY }

  let ret = false
  for (let i = 0; i < points1.length; i++) {
    let intersections = 0
    for (let j = 0; j < points2.length; j++) {
      const p1 = points1[i]
      const p3 = points2[j]
      const p4 = points2[(j + 1) % points2.length]

      const d1 = discriminant(p1, pOut, p3)
      const d2 = discriminant(p1, pOut, p4)
      const d3 = discriminant(p3, p4, p1)
      const d4 = discriminant(p3, p4, pOut)

      if (d1 * d2 < 0 && d3 * d4 < 0) intersections++
    }
    // if the number of intersections between a point in points1 and pOut is pair
    // then the point is outside point is outside points2
    // else the point is inside points2
    ret = ret || !!(intersections % 2)
  }
  return ret
}

export const testOverlap = (points1, points2) => {
  let overlap = false
  for (let i = 0; i < points1.length; i++) {
    for (let j = 0; j < points2.length; j++) {
      const p1 = points1[i]
      const p2 = points1[(i + 1) % points1.length]
      const p3 = points2[j]
      const p4 = points2[(j + 1) % points2.length]

      const d1 = discriminant(p1, p2, p3)
      const d2 = discriminant(p1, p2, p4)
      const d3 = discriminant(p3, p4, p1)
      const d4 = discriminant(p3, p4, p2)

      //if two lines in points1 and points2 intersect, overlap === true
      overlap = overlap || (d1 * d2 < 0 && d3 * d4 < 0)
    }
  }
  return overlap
}

const shoelace = points => {
  // shoelace formula to get polygon area
  let sum = 0
  for (let i = 0; i < points.length; i++) {
    sum += points[i].x * points[(i + 1) % points.length].y - points[i].y * points[(i + 1) % points.length].x
  }
  return Math.abs(sum) / 2
}

export const getCentroid = points => {
  let cx = 0,
    cy = 0
  const polygonArea = shoelace(points)
  for (let i = 0; i < points.length; i++) {
    let p1 = points[i]
    let p2 = points[(i + 1) % points.length]
    cx += (p1.x + p2.x) * (p1.x * p2.y - p2.x * p1.y)
    cy += (p1.y + p2.y) * (p1.x * p2.y - p2.x * p1.y)
  }
  return { x: Math.abs(cx) / (6 * polygonArea), y: Math.abs(cy) / (6 * polygonArea) }
}

export const alignPolygon = (pointsAux, setPoints, x, y) => {
  for (let i = 1; i < pointsAux.length; i++) {
    if (Math.abs(pointsAux[i].x - pointsAux[(i - 1) % pointsAux.length].x) < 10) {
      pointsAux[i].x = pointsAux[(i - 1) % pointsAux.length].x
    }
    if (Math.abs(pointsAux[i].y - pointsAux[(i - 1) % pointsAux.length].y) < 10) {
      pointsAux[i].y = pointsAux[(i - 1) % pointsAux.length].y
    }
  }
  if (Math.abs(pointsAux[pointsAux.length - 1].x - pointsAux[0].x) < 10) {
    pointsAux[pointsAux.length - 1].x = pointsAux[0].x
  }
  if (Math.abs(pointsAux[pointsAux.length - 1].y - pointsAux[0].y) < 10) {
    pointsAux[pointsAux.length - 1].y = pointsAux[0].y
  }
  setPoints(
    pointsAux.map(point => {
      return { x: point.x + x, y: point.y + y }
    })
  )
}

export const createPoint = (stageRef, scale, setPos, setNewPos, setClosed, pos, points, setPoints) => {
  const pointSize = 5
  let newpoint = {
    x: stageRef.current.getPointerPosition().x / scale,
    y: stageRef.current.getPointerPosition().y / scale,
  }
  if (!pos) {
    setPos(newpoint)
    setNewPos(newpoint)
  }
  let writePointB = true
  let willClose = false
  for (let i = 0; i < points.length; i++) {
    let distance = Math.sqrt(Math.abs(newpoint.x - points[i].x) ** 2 + Math.abs(newpoint.y - points[i].y) ** 2)
    if (distance < 2 * pointSize) {
      writePointB = false
      willClose = i === 0
    }
  }
  willClose = willClose && points.length >= 3
  if (writePointB) {
    setPoints([...points, newpoint])
  }
  setClosed(willClose)
}
