import { Box, Button, Grid, LinearProgress, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import { useStyles } from './styles'
import DropzoneDrag from '../../../components/dropzone'
import Resizer from 'react-image-file-resizer'

const defaultData = {
  name: '',
  description: '',
  imageURL: '',
  imageResizedURL: '',
}

export default () => {
  const classes = useStyles()
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  const [area, setArea] = useState(defaultData)
  const [nameError, setNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [loading, isLoading] = useState(false)
  const { state } = useLocation()
  const { areaID } = useParams()
  const [fileWay, setFileWay] = useState(null)
  const all = useRef(true)

  useEffect(() => {
    if (state && state.area) {
      setArea(state.area)
      setFileWay(state.area.imageResizedURL)
    }
    else
      firebase.getAreaByID(areaID).then(resp => {
        setArea(resp)
        setFileWay(resp.imageResizedURL)
      })
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    if (area.name === '') {
      setNameError(true)

      return
    }

    if (area.description === '') {
      setDescriptionError(true)

      return
    }

    isLoading(true)
    firebase.updateArea(areaID, area).then(() => {
      history.push(ROUTES.AREAS)
    })
  }

  const handleChange = e => {
    const { name, value } = e.target

    if (name === 'name') {
      setNameError(false)
    }

    if (name === 'description') {
      setDescriptionError(false)
    }

    setArea({
      ...area,
      [name]: value,
    })
  }

  const handleImage = async image => {
    
    const imageFile = image
    setFileWay(URL.createObjectURL(imageFile))

    const imageResized = await new Promise(resolve => {
      Resizer.imageFileResizer(
        image, // Is the file of the image which will resized.
        400, // Is the maxWidth of the resized new image.
        400, // Is the maxHeight of the resized new image.
        'JPEG', // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        uri => {
          resolve(uri)
        }, // Is the callBack function of the resized new image URI.
        'blob', // Is the output type of the resized new image.
        350, // Is the minWidth of the resized new image.
        350 // Is the minHeight of the resized new image.
      )
    })

    const pathImage = await firebase.saveAreaImage(area.id, imageFile)
    const ResizedPathImage = await firebase.saveAreaImage(area.id, imageResized)

    setArea({
      ...area,
      ['imageURL']: pathImage,
      ['imageResizedURL']: ResizedPathImage
    })
  }

  const handleDelete = () => {
    if (!all.current) return
    setFileWay(null)
  }

  return (
    <Box className={classes.root}>
      <Grid container item justify='space-between' alignItems='center' className={classes.header}>
        <Grid item xs={3}>
          <Typography variant='h5'>Editar Área</Typography>
          <Typography variant='body1' className={classes.color}>
            <Link to={ROUTES.AREAS} className={classes.link}>
              Áreas
            </Link>{' '}
            &gt; Editar
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button className={classes.button} disableRipple variant='contained' color='primary' onClick={handleSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </>
      ) : null}
      <Grid container spacing={3} item justify='space-between' alignItems='center' className={classes.nextButton}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Dados da Área</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body1' color='textSecondary'>
            Nome da área
          </Typography>
          <TextField
            InputProps={{ className: classes.textColor }}
            className={classes.autocomplete}
            id='area-name'
            variant='outlined'
            placeholder='Nome'
            error={nameError}
            helperText={nameError ? 'O nome não pode ficar em branco' : null}
            name='name'
            placeholder={area.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} className={classes.nextButton}>
          <Grid item xs={12}>
            <Typography variant='body1' color='textSecondary'>
              Descrição
            </Typography>
            <TextField
              InputProps={{ className: classes.textColor }}
              className={classes.autocomplete}
              id='area-description'
              variant='outlined'
              placeholder='Descrição'
              error={descriptionError}
              helperText={descriptionError ? 'A descrição não pode ficar em branco' : null}
              name='description'
              placeholder={area.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.imageInput}>
        {!fileWay ? (
          <DropzoneDrag handleChange={handleImage} />
        ) : (
          <Grid container className={classes.imageGrid} alignItems='center' justify='space-between' spacing={1}>
            <Grid item xs={2}>
              <img src={fileWay} className={classes.image} />
            </Grid>
            <Grid item xs={2} className={classes.deleteButton}>
              <Button onClick={handleDelete}>X</Button>
            </Grid>
          </Grid>
        )}
      </Grid>

    </Box>
  )
}
