import React, { useContext } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { SessionContext } from '../contexts/session'
import * as ROUTES from '../config/routes'
import routesConfig from '../config/paths-config'
import { compareRoutes } from './routes-compare'
import { useMediaQuery, useTheme } from '@material-ui/core'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoading, authUser } = useContext(SessionContext)

  return (
    <Route
      {...rest}
      render={props =>
        isLoading ? (
          <></>
        ) : authUser ? (
          <Component {...props} />
        ) : (
              <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
            )
      }
    />
  )
}

export const AdminRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const { isLoading, authUser } = useContext(SessionContext)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) return <></>
        else {
          const { permission } = routesConfig.find(route => compareRoutes(location.pathname, route.route))
          if (authUser && authUser.permission >= permission && !isMobile) return <Component {...props} />
          else return <Redirect to={{ pathname: ROUTES.HOME, state: { from: props.location } }} />
        }
      }}
    />
  )
}

export const LoginRoute = ({ component: Component, ...rest }) => {
  const { isLoading, authUser } = useContext(SessionContext)

  return (
    <Route
      {...rest}
      render={props =>
        isLoading ? (
          <></>
        ) : authUser ? (
          <Redirect to={{ pathname: ROUTES.HOME, state: { from: props.location } }} />
        ) : (
              <Component {...props} />
            )
      }
    />
  )
}
