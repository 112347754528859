import React from 'react'
import { Container } from '@material-ui/core'

import useStyles from './checkin_web/styles'
import Check from './checkin_web/index'

const Checkin = () => {
  const classes = useStyles()
  
  return (
    <Container className={classes.root}>
      <Check />
    </Container>
  )
}

export default Checkin
