import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    card:{
        width:"calc(33% - 28px)",
        height: "435px",
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
    },
    cardTitle:{
      fontSize:18
    },
    cardSubTitle:{
        color:"#B9B9B9",
    },
    align:{
        display:"flex",
        marginBottom:10,
        alignItems:"center",
        "&:not(:first-child)": {
            marginLeft: 0
        }
    },
    balls:{
        height:14,
        width:14,
        marginRight: 5,
        borderRadius:"50%"
    },
    link:{
        textDecoration:'none',
        '&:hover': {
          textDecoration:'underline',
        },
        size:'11px',
        color:"#53318A"
    },
    circularStyle: {
        align: 'center',
        marginLeft: '43%',
        marginTop: '50%'
      },
}));
  
export default useStyles  