import { CardContent, CardMedia, Grid, Box, Typography } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import React, { useContext } from 'react'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import useStyles from './styles'

export default function MediaCard({ area, areaSelected, favoriteAreas, setFavoriteAreas, executeScroll }) {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(SessionContext)

  const handleActionFavorite = async id => {
    if (area.isFavorite) {
      firebase
        .removeFavoriteArea(id)
        .then(() => {
          setAuthUser(oldUser => ({ ...oldUser, favoriteAreas: favoriteAreas.filter(item => item !== id) }))
          setFavoriteAreas(favoriteAreas.filter(item => item !== id))
        })
        .catch(erro => console.log(erro))
    } else {
      firebase
        .addFavoriteArea(id)
        .then(() => {
          // executeScroll()
          setAuthUser(oldUser => ({ ...oldUser, favoriteAreas: [...favoriteAreas, id] }))
          setFavoriteAreas(favoriteAreas => [...favoriteAreas, id])
        })
        .catch(erro => console.log(erro))
    }
  }
  return (
    <>
      <CardMedia image={area.imageResizedURL} title={area.name} className={classes.media} />
      <CardContent>
        <Typography variant='subtitle2' className={classes.title}>
          <Box className={classes.areaName}>{area.name}</Box>
          {area.isFavorite ? (
            <Grid
              className={classes.hoverStar}
              style={{color: '#FFAD0E'}}
              onClick={event => {
                event.stopPropagation()
                handleActionFavorite(area.id)
              }}
            >
              <Star />
            </Grid>
          ) : (
            <Grid
              className={classes.hoverStar}
              style={{color: 'black'}}
              onClick={event => {
                event.stopPropagation()
                handleActionFavorite(area.id)
              }}
            >
              <StarBorderIcon />
            </Grid>
          )}
        </Typography>
        <Typography variant='subtitle1' className={classes.description}>
          {area.description}
        </Typography>
      </CardContent>
    </>
  )
}
