import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
  },
  header: {
    marginBottom: '30px',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  color: {
    color: '#707070',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  button: (props) => ({
    height: '42px',
    width: '156px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  }),
  inputInvalid: {
    color: '#CDC4DB',
    width: '100%',
  },
  input: {
    '&.MuiInput-underline:before': {
      borderBottomColor: '#53318A',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    width: '100%',
    color: '#53318A',
  },
  textColor: {
    color: '#53318A',
  },

  image: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  imageGrid: {
    marginTop: '20px',
  },
  imageInput: {
    marginTop: '12px',
  },
  errorText: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#ff3333',
  },
}));
export default useStyles;

export const helperTextStyles = makeStyles(theme => ({
  root: {
    margin: 4,
    color: '#ff3333'
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white
    }
  }
}));
