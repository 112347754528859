import { useContext, useState } from 'react'

import { Avatar, Box, Container, TextField, Typography, Grid } from '@material-ui/core'
import useStyles from './styles'

import { SessionContext } from '../../../contexts/session'
import { ReactComponent as LogoutIconProfile } from '../../../assets/logoutIconProfile.svg'

import ShowModal from '../../../components/modal'
import ExitModal from '../../../assets/ExitModal.svg'
import { FirebaseContext } from '../../../contexts/firebase'

const Profile = () => {
  const classes = useStyles()
  const { authUser } = useContext(SessionContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const firebase = useContext(FirebaseContext)

  const handleDialogClose = () => {
    setDialogOpen(false)
  }
  return (
    <Box className={classes.root}>
      <Container className={classes.containerStyle} maxWidth='xs'>
        <Avatar src={authUser.photoURL} className={classes.avatar} />
        <Typography variant='body1' className={classes.label}>
          Nome
        </Typography>
        <TextField
          value={authUser.displayName}
          variant='outlined'
          InputProps={{ classes: { disabled: classes.disabled } }}
          disabled={true}
          fullWidth
        />
        <Typography variant='body1' className={classes.label}>
          E-mail
        </Typography>
        <TextField
          value={authUser.email}
          variant='outlined'
          InputProps={{ classes: { disabled: classes.disabled } }}
          disabled={true}
          fullWidth
        />
        <Typography variant='body1' className={classes.label}>
          Cargo
        </Typography>
        <TextField
          value={authUser.jobTitle}
          variant='outlined'
          InputProps={{ classes: { disabled: classes.disabled } }}
          disabled={true}
          fullWidth
        />
        <Typography variant='body1' className={classes.label}>
          Gerente
        </Typography>
        <TextField
          value={authUser.manager}
          variant='outlined'
          InputProps={{ classes: { disabled: classes.disabled } }}
          disabled={true}
          fullWidth
        />

        <Grid
          className={classes.logoutButton}
          onClick={e => {
            setDialogOpen(true)
          }}
        >
          <LogoutIconProfile className={classes.icon} />
          <Typography variant='body1' paragraph={false} className={classes.labelLogout}>
            {' '}
            Sair da conta{' '}
          </Typography>
        </Grid>
      </Container>
      <ShowModal
        image={ExitModal}
        isOpen={dialogOpen}
        title='Você deseja sair?'
        message='Tem certeza que gostaria de sair?'
        mainAction={firebase.signOut}
        mainActionLabel='Sim, sair'
        handleClose={handleDialogClose}
        secondActionLabel='Não sair'
      />
      <Box className={classes.spacerMobile} />
    </Box>
  )
}

export default Profile
