import { Box, IconButton, ListItemIcon } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { StyledMenu, StyledMenuItem, useStyles } from './styles'


export const ListMenu = ({
  anchorEl,
  handleClickMenu,
  handleCloseMenu,
  handleEditArea,
  handleOpenModal,
  row,
  selected,
}) => {
  const classes = useStyles()
  return (
    <Box>
      <IconButton onClick={e => handleClickMenu(e)} className={classes.MenuButton}>
        <MoreVert />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledMenuItem onClick={() => handleEditArea(row)} disabled={selected.length > 1}>
          <ListItemIcon style={{ minWidth: '36px' }}>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <span>Editar</span>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            handleOpenModal(row)
          }}
          disabled={selected.length > 1}
        >
          <ListItemIcon style={{ minWidth: '36px' }}>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <span>Excluir</span>
        </StyledMenuItem>
      </StyledMenu>
    </Box>
  )
}
