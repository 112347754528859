//Position of identifier text of block
const setTextLocation = element => {
  if (!element.workstation) return { textX: null, textY: null, textRotation: null }
  let x, y, rotation, upsideDown
  switch (element.textLocation) {
    case 0:
      upsideDown = element.rotation > 90 && element.rotation < 270 ? true : false
      x = upsideDown ? element.width : 0
      y = element.height + (upsideDown ? 12 : 2)
      rotation = upsideDown ? 180 : 0
      break
    case 1:
      upsideDown = (element.rotation + 180) % 360 > 90 && (element.rotation + 180) % 360 < 270 ? true : false
      x = upsideDown
        ? -element.width / (((element.places + 1) / 2) >> 0)
        : (1 - 1 / (((element.places + 1) / 2) >> 0)) * element.width
      y = upsideDown ? -element.height / 2 - 12 : -element.height / 2 - 2
      rotation = upsideDown ? 0 : 180
      break
    case 2:
      upsideDown = (element.rotation + 270) % 360 > 90 && (element.rotation + 270) % 360 < 270 ? true : false
      x = element.width + (upsideDown ? -element.height / 2 + 12 : 0)
      y = upsideDown ? 0 : element.height / 2
      rotation = upsideDown ? 90 : 270
      break
    case 3:
      upsideDown = (element.rotation + 90) % 360 > 90 && (element.rotation + 90) % 360 < 270 ? true : false
      x = upsideDown ? -18 : -element.height / 2 - 6
      y = upsideDown ? element.height / 2 - 3 : 3
      rotation = upsideDown ? 270 : 90
      break
  }
  return { textX: x, textY: y, textRotation: rotation, upsideDown: upsideDown }
}

export default setTextLocation
