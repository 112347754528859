import { Box, Button, Grid, Paper, Typography, Tooltip, IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import useStyles from './styles'
import { getElementImage } from '../menuBar/model'
import LineStyleIcon from '@material-ui/icons/LineStyle'
import brickIcon from '../../../../assets/editRoom/tijolos.svg'
const Tools = ({
  selectedCategory,
  menuIndex,
  setMenuIndex,
  tools,
  setElements,
  elements,
  setPlaces,
  places,
  setBlocks,
  blocks,
  nextAction,
  handleClose,
  onToolChanged,
  dragImageRef,
  setSelectedElement,
  roomType,
  setLine,
  setPoints,
}) => {
  const buttonWidth = 80
  const ButtonHeight = 60
  const iconSize = 20

  const [tool, setTool] = useState([])
  const [itens, setItens] = useState([])
  const [selectedTool, setSelectedTool] = useState('')
  const classes = useStyles()

  const [showInput, setShowInput] = useState(false)

  

  useEffect(() => {
    const tool = tools[selectedCategory - 1]
    setTool(tool)
  }, [selectedCategory])

  useEffect(() => {
    const items = menuIndex ? tools[selectedCategory - 1].listItens[menuIndex - 1] : []
    setItens(items)
  }, [selectedCategory, menuIndex])
  const onToolSelected = tool => {
    setSelectedTool(tool.parameter)
    if (tool.workstation) setBlocks(blocks + 1)
    const newElement = {
      ...tool,
      x: 20,
      y: 20,
      rotation: 0,
      textLocation: 0,
      id: Math.floor(Math.random() * 10000),
      ...(tool.workstation ? { index: blocks } : { index: 0 }),
    }
    nextAction([...elements, newElement])
    setElements([...elements, newElement])
    setSelectedElement(newElement)
    tool.workstation && setPlaces(places + ((tool.places / 2) >> 0))
    onToolChanged(tool)
  }
  const createWall = () => {
    setLine(line => [...line, { points: [50, 100, 50, 60] }])
    setPoints(points => [
      ...points,
      {
        pts: [
          { x: 50, y: 100 },
          { x: 50, y: 60 },
        ],
      },
    ])
  }
  return (
    <>
      {selectedCategory && !menuIndex && (
        <Paper className={classes.menuItem}>
          <Grid container spacing={1}>
            {tool &&
              tool.listItens &&
              tool.listItens.map((tl, index) => (
                <Grid key={tl.name + index} item lg={12} md={12}>
                  <Typography
                    className={classes.item}
                    style={{ width: '100%' }}
                    key={tl.name}
                    variant='body2'
                    onClick={() => setMenuIndex(index + 1)}
                  >
                    <img src={getElementImage(tl.icon)} width={iconSize} height={iconSize} />
                    {tl.name}
                  </Typography>
                </Grid>
              ))}
            {selectedCategory == 1 && (
              <Grid item lg={12} md={12} onClick={createWall}>
                <Typography className={classes.item} style={{ width: '100%' }} variant='body2'>
                  <img src={brickIcon} width={iconSize} height={iconSize} />
                  Parede
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
      {selectedCategory && menuIndex && (
        <Paper className={classes.menuItem}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Button onClick={handleClose} style={{ width: '30px' }}>
              {<ArrowBack />}
            </Button>
            <Typography variant='subtitle2'>{itens?.name}</Typography>
          </Box>
          <Grid container className={classes.furnitures} spacing={2}>
            {itens &&
              itens.itens &&
              itens.itens.map((tl, index) => (
                <Grid key={tl.name + index} item lg={6} md={6}>
                  <Button
                    className={classes.itemMenu}
                    key={tl.name}
                    variant='outlined'
                    onClick={() => {
                      roomType && itens.name === 'Trabalho' ? '' : (onToolSelected(tl), handleClose())
                    }}
                    color={selectedTool === tl.parameter ? 'secondary' : 'primary'}
                    style={{ opacity: roomType && itens.name === 'Trabalho' ? 0.5 : 1 }}
                  >
                    <img
                      src={getElementImage(tl.icon)}
                      alt={tl.name}
                      width={buttonWidth}
                      height={ButtonHeight}
                      draggable='true'
                      onDragStart={e => {
                        dragImageRef.current = tl
                      }}
                      onDragEnd={() => handleClose()}
                    />
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Paper>
      )}
    </>
  )
}

export default Tools
