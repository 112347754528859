import { Button, Grid, Link, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ImageNotFound from '../../../assets/not_found.png'
import DropzoneDrag from '../../../components/dropzone'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import useStyles, { helperTextStyles } from './styles'

export default () => {
  const history = useHistory()
  const { state } = useLocation()
  const firebase = useContext(FirebaseContext)
  const { isDuplicate, roomReceived } = state
  const [room, setRoom] = React.useState(
    roomReceived || {
      nid: '',
      name: '',
      manager: '',
      public: true,
      account: '',
      places: 0,
      description: '',
      width: 60,
      height: 40,
      pictures: null,
      tables: [],
      elements: [],
    }
  )
  const [newRoom, setNewRoom] = useState({
    nid: roomReceived?.nid || '',
    name: roomReceived?.name + ' - Cópia' || '',
    manager: roomReceived?.manager || 'Não Definido',
    public: roomReceived?.public || true,
    account: roomReceived?.account || '',
    places: roomReceived?.places || 0,
    description: roomReceived?.description || '',
    type: roomReceived?.type || 0,
  })
  const [managers, setManagers] = useState([])
  const [areas, setAreas] = useState([])
  const [errors, setErrors] = useState({})

  const [fileWay, setFileWay] = useState(null)
  const [image, setImage] = useState(null)
  const [imageNotFound, setImageNotFound] = useState('')

  const classes = useStyles()
  const helperTextStyle = helperTextStyles()

  const CreateMapRoom = () => {
    room.id = newRoom.nid
    room.nid = Number(newRoom.nid)
    room.name = newRoom.name
    room.manager = newRoom.manager
    room.public = newRoom.public
    room.account = newRoom.account
    room.description = newRoom.description
    room.places = Number(newRoom.places)
    room.pictures = []
    setRoom(room)

    history.push({
      pathname: `./rooms/${room.area}/editroom/${room.nid}/map`,
      state: {
        room: room,
        isDuplicate: isDuplicate,
        pictures: image ? image : imageNotFound,
      },
    })
  }

  const validateNid = () => {
    let error = {}
    if (!newRoom.nid) {
      error.nid = 'Este campo não pode ficar em branco'
    }
    if (state.rooms.some(id => Number(newRoom.nid) === id.nid)) {
      error.nid = 'Este ID já está sendo utilizado em outra sala.'
    }
    return error
  }

  function formValidation() {
    let formErrors = {}
    Object.entries(newRoom).map(roomProps => {
      if (roomProps[0] === 'nid') {
        const roomNid = validateNid()
        if (roomNid.nid === undefined) return
        formErrors[roomProps[0]] = roomNid.nid
      } else if (roomProps[1] === '') {
        formErrors[roomProps[0]] = true
      }
    })
    setErrors(formErrors)
    return formErrors
  }

  async function fetchManagers() {
    const allManagers = await firebase.getManagers()
    setManagers(allManagers)
  }

  useEffect(() => {
    fetchManagers()
  }, [])

  const handleFields = e => {
    const { name, value } = e.target
    setNewRoom({
      ...newRoom,
      [name]: value,
    })
  }

  useEffect(() => {
    srcToFile(ImageNotFound, `not_found_${new Date().getTime()}.png`, 'image/png').then(file => {
      setImageNotFound(file)
    })
  }, [room])

  function handleSubmit() {
    const getErrors = formValidation()
    if (Object.keys(getErrors).length === 0) {
      CreateMapRoom()
    }
  }
  const srcToFile = async (src, fileName, mimeType) => {
    const res = await fetch(src, { mode: 'cors' })
    const buf = await res.arrayBuffer()
    return new File([buf], fileName, { type: mimeType })
  }

  const all = useRef(true)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const handleDelete = () => {
    if (!all.current) return
    setFileWay(null)
    setImage(null)
  }
  const handleImage = image => {
    const imageFile = image
    setFileWay(URL.createObjectURL(imageFile))
    setImage(imageFile)
  }

  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <Grid container item justify='space-between' alignItems='center' className={classes.header}>
        <Grid item xs={3}>
          <Typography variant='h5'>Salas</Typography>
          <Typography variant='body1' className={classes.color}>
            <Link href={`${ROUTES.ROOMS}`} className={classes.link}>
              Salas
            </Link>{' '}
            &gt; Duplicar sala
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button variant='contained' color='primary' className={classes.button} onClick={() => handleSubmit()}>
            Avançar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} item justify='space-between' alignItems='center' className={classes.nextButton}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Dados da sala</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Nome da sala
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            name='name'
            onChange={handleFields}
            value={newRoom.name}
            FormHelperTextProps={{ classes: helperTextStyle }}
            helperText={errors?.name && 'Este campo não pode ficar em branco'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Gerente
          </Typography>
          <Select
            className={classes[`input${newRoom.type === -1 ? 'Invalid' : ''}`]}
            variant='outlined'
            style={{ fontSize: 12 }}
            select
            name='manager'
            onChange={handleFields}
            value={managers.length > 0 ? newRoom.manager : 'Não definido'}
          >
            <MenuItem key={'Normal'} value={'Não definido'}>
              Não Definido
            </MenuItem>
            {managers?.map(manager => (
              <MenuItem key={manager.displayName} value={manager.displayName}>
                {manager.displayName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Projeto
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            name='account'
            onChange={handleFields}
            value={newRoom.account}
            FormHelperTextProps={{ classes: helperTextStyle }}
            helperText={errors?.account && 'Este campo não pode ficar em branco'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            ID da sala
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            type='number'
            role='none'
            name='nid'
            onChange={handleFields}
            value={newRoom.nid}
            FormHelperTextProps={{ classes: helperTextStyle }}
            helperText={errors?.nid}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Tipo da sala
          </Typography>
          <Select
            className={classes[`input${newRoom.type === -1 ? 'Invalid' : ''}`]}
            variant='outlined'
            style={{ fontSize: 12 }}
            select
            defaultValue={newRoom.type}
            name='type'
            onChange={e => setNewRoom({ ...newRoom, type: e.target.value, places: 0 })}
            value={newRoom.type}
          >
            <MenuItem key={'Normal'} value={0}>
              Normal
            </MenuItem>
            <MenuItem key={'Reunião'} value={1}>
              Reunião
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2' color='textSecondary'>
            Acesso
          </Typography>
          <Select
            className={classes[`input${newRoom.public === -1 ? 'Invalid' : ''}`]}
            variant='outlined'
            style={{ fontSize: 12 }}
            select
            defaultValue={newRoom.public}
            name='public'
            onChange={handleFields}
          >
            <MenuItem key={true} value={true}>
              Publica
            </MenuItem>
            <MenuItem key={false} value={false}>
              Privada
            </MenuItem>
          </Select>
        </Grid>
        {newRoom.type === 1 && (
          <Grid item xs={2}>
            <Typography variant='body2' color='textSecondary'>
              Numero de lugares
            </Typography>
            <TextField
              inputProps={{ className: classes.textColor }}
              className={classes.input}
              variant='outlined'
              type='number'
              name='places'
              onChange={handleFields}
              value={newRoom.places}
              FormHelperTextProps={{ classes: helperTextStyle }}
              helperText={errors?.places && 'Este campo não pode ficar em branco'}
            />
          </Grid>
        )}
        <Grid item xs={newRoom.type === 1 ? 10 : 12}>
          <Typography variant='body2' color='textSecondary'>
            Descrição
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.input}
            variant='outlined'
            name='description'
            onChange={handleFields}
            value={newRoom.description}
            FormHelperTextProps={{ classes: helperTextStyle }}
            helperText={errors?.description && 'Este campo não pode ficar em branco'}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.imageInput}>
        {!fileWay ? (
          <DropzoneDrag handleChange={handleImage} />
        ) : (
          <Grid container className={classes.imageGrid} alignItems='center' justify='space-between' spacing={1}>
            <Grid item xs={2}>
              <img src={fileWay} className={classes.image} />
            </Grid>
            <Grid item xs={2} className={classes.deleteButton}>
              <Button onClick={handleDelete}>X</Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
