import React, { useContext } from 'react'
import { Box, Typography, Container, TextField, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import checkInSVG from '../../../../assets/check-in.svg'
import useStyles from './styles'
import { DisplayMenuMobileContext } from '../../../../contexts/displayMenuMobile'

const FieldCheckIn = ({ setScreenState, screenState, setCode, code, doCheckIn, matches }) => {
  const { isFocused, setFocus } = useContext(DisplayMenuMobileContext)
  const classes = useStyles()
  const theme = useTheme()
  const displayImage = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChange = event => {
    setCode(event.target.value.toUpperCase())
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.boxStyle}>
        <Typography className={classes.typography}>Insira o código localizado em sua mesa (Ex: S00B00M00) </Typography>
        <TextField
          id='outlined-basic'
          variant='outlined'
          placeholder='Código'
          className={classes.textFieldStyle}
          value={code}
          onChange={e => handleChange(e)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          inputProps={{
            maxLength: 16,
            style: {
              color: '#3A1A70',
              weight: 400,
              fontSize: '15px',
            },
          }}
        />
        <Grid container spacing={1}>
          {matches ? (
            <Grid item xs={6}>
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonStyle}
                onClick={() => setScreenState(!screenState)}
              >
                <Typography variant='subtitle1' color='primary'>
                  Escanear
                </Typography>
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='primary'
              disabled={code.length ? false : true}
              onClick={doCheckIn}
              className={classes.buttonStyle}
            >
              <Typography variant='subtitle1' color='inherit'>
                Confirmar
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {((!isFocused && matches) || displayImage) && (
          <img src={checkInSVG} alt='imagem pessoa scan QR-code' className={classes.image} />
        )}
      </Box>
    </Container>
  )
}
export default FieldCheckIn
