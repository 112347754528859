import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bottom_menu: {
    backgroundColor: '#53318A',
    height: '61px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    zIndex: '10',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '1',
    "&:active": {
      textDecoration: 'none',
    },
  },
  button: {
    height: '100%',
    width: '100%',
  },
  button_img: {
    marginRight: '9px',
    fill: 'white',
  },
  button_text: {
    color: 'white',
    fontSize: '11px',
    fontWeight: 'normal',
  },
}));

export default useStyles;