import useStyles from './styles'
import { ZoomInput } from './styles'

import { Paper, IconButton, FormControl, MenuItem, Select, Divider } from '@material-ui/core'

import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'

const SettingsMenu = ({ undo, redo, zoomValue, setZoomValue }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div className={classes.grid}>
        <FormControl className={classes.margin}>
          <Select
            labelId='demo-customized-select-label'
            id='demo-customized-select'
            value={zoomValue}
            onChange={e => setZoomValue(e.target.value)}
            input={<ZoomInput />}
          >
            <MenuItem value='' />
            <MenuItem value={100}>100%</MenuItem>
            <MenuItem value={125}>125%</MenuItem>
            <MenuItem value={150}>150%</MenuItem>
            <MenuItem value={175}>175%</MenuItem>
            <MenuItem value={200}>200%</MenuItem>
          </Select>
        </FormControl>
        <Divider orientation='vertical' flexItem />
        <IconButton onClick={undo} className={classes.button}>
          <UndoIcon />
        </IconButton>
        <IconButton onClick={redo} className={classes.button}>
          <RedoIcon />
        </IconButton>
      </div>
    </Paper>
  )
}

export default SettingsMenu
