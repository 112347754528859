import React from 'react'

import { Box, Typography } from '@material-ui/core'

class TermsOfUse extends React.Component {
  render() {
    return (
      <Box>
        <Typography variant='h1'>Terms of Use</Typography>
      </Box>
    )
  }
}

export default TermsOfUse
