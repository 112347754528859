import { Box, Grid, Typography } from "@material-ui/core";
import Dropzone from 'react-dropzone'
import useStyles from './styles'

function DropzoneDrag({handleChange }) {
  const classes = useStyles()

  return (
    <Dropzone accept='image/*' onDrop={acceptedFiles => handleChange(acceptedFiles[0])}>
      {({ getRootProps, getInputProps }) => (
        <section className={classes.inputImage}>
          <Box className={classes.inputImage} {...getRootProps()}>
            <input className={classes.inputImage} {...getInputProps()} />
            <Grid
              className={classes.imageContainer}
              container
              direction='column'
              justify='center'
              alignItems='center'
              item
              xs={12}
            >
              <Grid item>
                <Typography variant='body1' color='primary'>
                  <u>Clique aqui</u> ou arraste para selecionar documentos
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary'>
                  (tamanho máximo: 5mb)
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </section>
      )}
    </Dropzone>
  )
}

export default DropzoneDrag;
