import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontWeight:'700',
  },
    overrides: {
      MuiSvgIcon:{
        root:{
          width:'1.3em',
          height:'1.3em',
        },
      },
      MuiStep:{
        alternativeLabel:{
          flex: 0,
        },
      },
      MuiStepper:{
        horizontal:{
          justifyContent:'space-between'
        }
      },
      MuiStepButton:{
        root:{
          paddingTop:'0',
          paddingBottom:'0',
          backgroundColor:'rgb(250, 250, 250)',
          zIndex:1,
        },
      },
      MuiStepConnector:{
        alternativeLabel:{
          top: '-0.5em',
          left: '-100vw',
          right:'-10vw',
          zIndex:0,
        },
      },
      MuiStepLabel:{
        alternativeLabel:{
          marginTop:'0px !important',
        },
        labelContainer:{
          [breakpoints.down('sm')]: {
            marginTop:'10px'
          },
        },
      },
      MuiStepIcon:{
        completed:{
          color:'#53318A !important',
        },
        active:{
          color:'#53318A !important',
        },
      },
    }
})

export { materialTheme };




