import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  menu:{
    marginTop:'20px',
  },
  icon: {
    marginRight: 16,
  },
  link: {
    textDecoration: 'none',
  },
  menuItem: {
    paddingRight: 29,
  },
}))

export default useStyles
