import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
    heigth: '100%',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  label: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    color: '#707070',
  },
  table: {
    minWidth: 750,
  },
  filter: {
    margin: ' 24px  0px 16px',
  },
  button: {
    height: '42px',
    width: '156px',
    marginRight: '11px',
  },
  button2: {
    height: '42px',
    width: '156px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  public: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#27A163',
  },
  noPublic: {
    width: '150px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#FF6C6C',
  },
  typoTable: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
  },
  paperTable: {
    border: '1px solid #EEEEEE',
    borderRadius: '5px',
  },
}));

export default useStyles;
