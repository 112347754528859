import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    height: '100%',
    border: '1px  #EEEEEE',
    background: '#FFFFFF',
    justifyContent: 'space-between',
  },
  media: {
    minHeight: '180px',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    top: 0,
    padding: 0,
    position: 'absolute',
  },
  title: {
    height: 'auto',
    fontSize: '15px',
    lineHeight:'22.5px',
    fontWeight: 700,

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      fontSize: '15px',
      lineHeight:'22.5px',
      fontWeight: 700,
    },
  },
  description: {
    height: 'auto',
    fontSize: '15px',
    lineHeight:'22.5px',
    fontWeight: '400',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight:'22.5px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  ocupation: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '15px',
    marginLeft: 'auto',
    fontWeight: 400,
    right: 0,
    bottom: 0,
    marginTop: '20px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: '12px',
      display: 'flex',
      right: 0,
      bottom: 0,
      marginRight: theme.spacing(2),
    },
  },
  box_content: {
    display: 'flex',
    flexDirection: 'column',

  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'space-between',
    padding: 16,
    width: '100%',
    '&.MuiCardActions(:first-child)': {
      marginLeft: 0,
    },
  },
  selectTitle: {
    display: 'flex',
    paddingBottom: '7px',
  },
  btn: {
    display: 'flex',
    width: '100%',
    height: '54px',
    textTransform: 'none',
  },
  menu: {
    height: 300,
  },
  meetingArea:{
    width:'100%',
  },
  meetingBox:{
    marginTop:'20px',
  },
  selectHour: {
    '&.Mui-disabled': {
      background: '#e8e8e8',
    },
  },
  switch: {
    paddingLeft: '10px',
  },
}))

export default useStyles
