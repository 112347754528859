import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import DropzoneDrag from '../../../components/dropzone'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import useStyles from './styles'

export default ({}) => {
  const { areaID, roomID } = useParams()
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [newRoom, setNewRoom] = useState(null)
  const firebase = useContext(FirebaseContext)
  const [invalidInput, setInvalidInput] = useState()
  const [loading, setLoading] = useState(true)
  const [managers, setManagers] = useState([])
  const all = useRef(true)
  const [image, setImage] = useState(null)
  const [fileWay, setFileWay] = useState(null)
  const nidError = useRef('')
  const [allRooms, setAllRooms] = useState(null)

  const handleDelete = () => {
    if (!all.current) return

    setImage(null)
    setFileWay(null)
  }
  const handleChange = event => {
    const imageFile = event
    setFileWay(URL.createObjectURL(imageFile))
    setImage(imageFile)
  }

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (!all) return
    const requisicao = async () => {
      const resp = await firebase.getRoomInfoByID(areaID, roomID)
      setNewRoom({ ...resp, areaName: state.areaName })
      setLoading(false)
      setImage(resp.pictures)
      setFileWay(resp.pictures)
    }
    requisicao()
  }, [])

  const handleSubmit = () => {
    validateNid()
    if (
      !newRoom.name ||
      nidError.current !== '' ||
      !newRoom.description ||
      !newRoom.account ||
      newRoom.type === -1 ||
      !newRoom.manager ||
      !newRoom.polygon
    ) {
      setInvalidInput({
        name: !newRoom.name,
        nid: nidError.current !== '',
        description: !newRoom.description,
        account: !newRoom.account,
        type: newRoom.type === -1,
        manager: !newRoom.manager,
      })
      return
    }
    firebase.updateRoom(newRoom.id, newRoom, image.length == 1 ? null : image).then(resp => {
      history.push('/rooms')
    })
  }

  useEffect(() => {
    firebase.getManagers().then(resp => {
      setManagers(resp)
    })
  }, [])

  useEffect(() => {
    firebase.getAllRooms().then(resp => {
      setAllRooms(resp)
    })
  }, [])

  const validateNid = () => {
    nidError.current = ''
    if (!newRoom.nid) {
      nidError.current = 'Este campo não pode ficar em branco'
      return
    }

    if (allRooms.some(id => newRoom.nid === id.nid && newRoom.nid !== Number(roomID))) {
      nidError.current = 'Este ID já está sendo utilizado em outra sala.'
    }
  }

  return (
    <Box>
      {newRoom && !loading ? (
        <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
          <Grid container item justify='space-between' alignItems='center' className={classes.header}>
            <Grid item xs={3}>
              <Typography variant='h5'>Salas</Typography>
              <Typography variant='body1' className={classes.color}>
                <Link to={`${ROUTES.ROOMS}`} className={classes.link}>
                  Salas
                </Link>{' '}
                &gt; Editar
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.nextButton}>
              <Button variant='contained' color='primary' onClick={handleSubmit} className={classes.button}>
                Avançar
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} item justify='space-between' alignItems='center' className={classes.nextButton}>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>Dados da sala</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body2' color='textSecondary'>
                Nome da Sala
              </Typography>
              <TextField
                placeholder={newRoom.name}
                InputProps={{ className: classes.textColor }}
                className={classes.input}
                variant='outlined'
                error={invalidInput && invalidInput.name}
                helperText={invalidInput && invalidInput.name && 'Este campo não pode ficar em branco'}
                name='name'
                onChange={e => {
                  setNewRoom({ ...newRoom, name: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.nextButton}>
              <Grid item xs={12}>
                <Typography variant='body2' color='textSecondary'>
                  ID da sala
                </Typography>
                <TextField
                  InputProps={{ className: classes.textColor }}
                  className={classes.input}
                  variant='outlined'
                  type='number'
                  placeholder={roomID}
                  error={invalidInput && invalidInput.nid}
                  helperText={invalidInput && invalidInput.nid && nidError.current}
                  role='none'
                  name='nid'
                  onChange={e => {
                    setNewRoom({ ...newRoom, nid: e.target.valueAsNumber })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Typography variant='body2' color='textSecondary'>
                  Projeto
                </Typography>
                <TextField
                  InputProps={{ className: classes.textColor }}
                  className={classes.input}
                  variant='outlined'
                  placeholder={newRoom.account}
                  error={invalidInput && invalidInput.account}
                  helperText={invalidInput && invalidInput.account && 'Este campo não pode ficar em branco'}
                  name='account'
                  onChange={e => {
                    setNewRoom({ ...newRoom, account: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body2' color='textSecondary'>
                Acesso
              </Typography>
              <Select
                className={classes[`input${newRoom.public === true ? 'Invalid' : ''}`]}
                variant='outlined'
                style={{ fontSize: 12 }}
                value={newRoom.public}
                name='type'
                onChange={e => {
                  setNewRoom({ ...newRoom, public: e.target.value })
                }}
              >
                <MenuItem key={true} value={true}>
                  Pública
                </MenuItem>
                <MenuItem key={false} value={false}>
                  Privada
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Typography variant='body2' color='textSecondary'>
                  Gerente
                </Typography>
                <Select
                  className={classes[`input${newRoom.manager === newRoom.manager ? 'Invalid' : ''}`]}
                  variant='outlined'
                  value={managers.length > 0 ? newRoom.manager : 'Não definido'}
                  name='manager'
                  onChange={e => {
                    setNewRoom({ ...newRoom, manager: e.target.value })
                  }}
                >
                  <MenuItem key={0} value={'Não definido'}>
                    Não definido
                  </MenuItem>
                  {managers?.map(manager => (
                    <MenuItem key={manager.id} value={manager.displayName}>
                      {manager.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body2' color='textSecondary'>
                Descrição
              </Typography>
              <TextField
                InputProps={{ className: classes.textColor }}
                className={classes.input}
                variant='outlined'
                placeholder={newRoom.description}
                error={invalidInput && invalidInput.description}
                helperText={invalidInput && invalidInput.description && 'Este campo não pode ficar em branco'}
                name='description'
                onChange={e => {
                  setNewRoom({ ...newRoom, description: e.target.value })
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.imageInput}>
            {!fileWay ? (
              <DropzoneDrag handleChange={handleChange} />
            ) : (
              <Grid container className={classes.imageGrid} alignItems='center' justify='space-between' spacing={1}>
                <Grid item xs={2}>
                  <img src={fileWay} className={classes.image} />
                </Grid>
                <Grid item xs={2} className={classes.deleteButton}>
                  <Button onClick={handleDelete}>X</Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <CircularProgress style={{ marginTop: '25%' }} />
      )}
    </Box>
  )
}
