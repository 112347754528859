import React from 'react'
import { Group, Image, Text } from 'react-konva'
import useImage from 'use-image'
import Available from '../../../assets/availableTable.svg'
import Table from '../../../assets/editRoom/elements/workTable/table1seat.svg'
import Reserved from '../../../assets/reservedTable.svg'
import Selected from '../../../assets/selectedTable.svg'
import SideTable from '../../../assets/sideTable.svg'
import SideTableDiagonal from '../../../assets/sideTableDiagonal.svg'
import Unavailable from '../../../assets/unavailableTable.svg'
import BLOCKS_LAYOUT from '../../../config/blocks-layout.json'
import { verifySeatBlock } from '../../../utils/checkin'
import { setPos } from './tables-position'
import setTextLocation from './text-location'

const TableGroup = ({ element, handleSeat, handleSelectSeat, selected, setSelected, reserved }) => {
  const [singleTableImage] = useImage(Table)
  const [availableIconImage] = useImage(Available)
  const [reservedIconImage] = useImage(Reserved)
  const [selectedIconImage] = useImage(Selected)
  const [unavailableIconImage] = useImage(Unavailable)
  const [sideTableImage] = useImage(element.layout === BLOCKS_LAYOUT.NORMAL ? SideTable : SideTableDiagonal)

  const singleTableWidth = 49.15 * 1.4
  const singleTableHeight = 43.81 * 1.4

  const defaultOffset = { x: singleTableWidth / 2, y: singleTableHeight / 2 }

  const handleClick = (event, table) => {
    if (!setSelected) return
    if (handleSelectSeat && table.available) {
      setSelected({ block: element.index, table: table.id })
      handleSeat(null, null, false, false)
      handleSelectSeat({ block: element.index, table: table.id })
    } else if (handleSeat) {
      if (table.reserved) {
        handleSeat(table, reserved.filter(res => res.table === table.id)[0], true, true)
      } else {
        handleSeat(null, null, true, false)
      }
    }
  }

  const pos = setPos(element, reserved, selected, singleTableImage, sideTableImage)
  const { textX, textY, textRotation, upsideDown } = setTextLocation(element)
  return (
    <Group
      x={element.x}
      y={element.y}
      offset={{ x: (element.width - singleTableWidth) / 2, y: (element.height - singleTableHeight) / 2 }}
      rotation={element.rotation}
    >
      {pos &&
        pos.map((table, index) => {
          return (
            <Group
              x={table.x}
              y={table.y}
              onClick={e => handleClick(e, table)}
              onTap={e => handleClick(e, table)}
              key={index}
            >
              <Image
                image={table.image}
                width={table.width}
                height={table.height}
                rotation={table.rotation}
                offset={defaultOffset}
                opacity={table.reserved ? 1 : table.available ? 1 : 0.5}
              />
              <Image
                image={
                  table.reserved
                    ? reservedIconImage
                    : table.available
                    ? selected && selected.block === element.index && selected.table === table.id
                      ? selectedIconImage
                      : availableIconImage
                    : unavailableIconImage
                }
                opacity={
                  table.reserved
                    ? 1
                    : table.available
                    ? selected && selected.block === element.index && selected.table === table.id
                      ? 1
                      : 1
                    : 0.3
                }
                width={14}
                height={14}
                offset={table.iconOffset}
                rotation={-element.rotation}
              />
              <Text
                fontSize={11}
                offset={table.textOffset}
                fontFamily='Poppins'
                fontStyle='bold'
                text={verifySeatBlock(table.numPlaces, index, table.tablesLayout, table)}
                rotation={-element.rotation}
              />
            </Group>
          )
        })}
      <Text
        x={textX}
        y={textY}
        width={element.textLocation <= 1 ? element.width : element.height + 5}
        fontSize={12}
        text={`BLOCO - ${element.index}`}
        align='center'
        rotation={textRotation}
        fontWeight='bold'
        offset={
          element.layout !== BLOCKS_LAYOUT.UNILATERAL
            ? upsideDown && element.textLocation <= 1
              ? { x: -singleTableWidth / 2, y: -singleTableHeight / 2 }
              : { x: singleTableWidth / 2, y: singleTableHeight / 2 }
            : element.textLocation > 1
            ? element.textLocation == 2
              ? {
                  x: (singleTableWidth / 2) * (upsideDown ? 1 : 0),
                  y: singleTableHeight / 2,
                }
              : {
                  x: (singleTableWidth / 2) * (upsideDown ? 0 : 1),
                  y: (-singleTableHeight / 2) * (upsideDown ? -1 : 0),
                }
            : element.textLocation === 1
            ? { x: (element.width / 2) * (upsideDown ? -1 : 1), y: 0 }
            : { x: (singleTableWidth / 2) * (upsideDown ? -1 : 1), y: (singleTableHeight / 2) * (upsideDown ? -1 : 1) }
        }
      />
    </Group>
  )
}

export default TableGroup
