import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ThemeProvider } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import DeleteModalIcon from '../../../../assets/deleteImage.svg'
import ShowModal from '../../../../components/modal'
import { FirebaseContext } from '../../../../contexts/firebase'
import { materialTheme } from '../../../../theme/table'
import Row from './row'
import { useStyles } from './styles'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Área' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Descrição' },

  { id: 'more', disablePadding: false, numeric: true },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ paddingLeft: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visually_hidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function TableComponent({ stableSort, filterValues, valueFiltered, setAreas, areas }) {
  const firebase = useContext(FirebaseContext)
  const classes = useStyles()
  const { height, ref } = useResizeDetector()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('displayName')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 20, 30]
  const [lastHeight, setLastHeight] = useState(0)

  const _isMounted = useRef(true)

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable <= rowsPerHeight) {
          setRowsPerPage(amountDisplayInTable)
          setLastHeight(height)
        } else {
          return
        }
      }
    }
    if (lastHeight !== height && !loading) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions, loading])

  const heightPerRow = () => {
    return Math.round(rows)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const fetchData = async () => {
    firebase.getAllAreas().then(rowAreas => setAreas(rowAreas))
    const users = await firebase.getUsers()
    if (_isMounted.current) {
      setRows(users)
      setLastHeight(height)
      setLoading(false)
      if (users.length !== 0) {
        setNoData(1)
      } else {
        setNoData(0)
      }
    }
  }

  const onDataChanged = async () => {
    await fetchData()
    setSnackBar(true)
  }

  const handleFetchData = () => {
    fetchData()
  }

  useEffect(handleFetchData, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.displayName)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleOpenModal = row => {
    setSelectedRow(row)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleDeleteArea = async area => {
    await firebase.removeArea(area.id).then(async () => {
      await fetchData()
      setOpenModal(false)
    })
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <Paper className={classes.paper} ref={ref} elevation={0}>
      <ThemeProvider theme={materialTheme}>
        <TableContainer className={classes.container}>
          {loading ? (
            <Box>
              <LinearProgress />
            </Box>
          ) : (
            <>
              {noData === 0 ? (
                <Typography variant='h5'>Ainda não há áreas cadastradas</Typography>
              ) : (
                <>
                  <Table
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    aria-label='enhanced table'
                    stickyHeader
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      <ThemeProvider theme={materialTheme}>
                        {stableSort(areas, getComparator(order, orderBy))
                          .filter(area => (valueFiltered ? filterValues(area) : area))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((area, index) => {
                            const isItemSelected = isSelected(area.name)
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                              <Row
                                row={area}
                                isItemSelected={isItemSelected}
                                selected={selected}
                                key={index + '-' + new Date().getTime()}
                                labelId={labelId}
                                handleClick={handleClick}
                                handleOpenModal={handleOpenModal}
                                onDataChanged={onDataChanged}
                              />
                            )
                          })}
                      </ThemeProvider>
                    </TableBody>
                  </Table>
                </>
              )}
            </>
          )}
        </TableContainer>
        <Box className={classes.box_lastRow}>
          <Box className={classes.box_half}>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={areas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={'Linhas por página:'}
            />
          </Box>
        </Box>
      </ThemeProvider>
      <ShowModal
        isOpen={openModal}
        image={DeleteModalIcon}
        title='Tem certeza que deseja excluir essa área?'
        message='Ao confirmar todos os dados serão excluídos permanentemente.'
        mainAction={() => handleDeleteArea(selectedRow)}
        mainActionLabel='Confirmar'
        secondActionLabel='Cancelar'
        handleClose={handleCloseModal}
      />
    </Paper>
  )
}
