import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router'
import { cell } from './styles'

export const Row = ({ row, monthYear }) => {
  const classes = cell()
  const history = useHistory()
  return (
    <TableRow role='checkbox' key={row[0]} onClick={() => history.push(`/report/ocupation/${monthYear}/${row[0]}`)}>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].roomName}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].totalReservations}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {!isNaN(row[1].tablesUsedByMonth) ? row[1].tablesUsedByMonth + '%' : '0%'}
      </TableCell>
    </TableRow>
  )
}
export default Row
