import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    maxWidth: 400,
    zIndex: 10,
  },
  button: {
    maxWidth: '36px',
    minWidth: '36px',
    margin: 3,
    color: '#474747',
  },
}))

export default useStyles
