import React from 'react'
import useStyles from './styles'
import { Paper, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Typography } from '@material-ui/core'
import { addYears } from 'date-fns/esm'

const FilterComponent = ({ listAutoComplete }) => {
  const classes = useStyles()

  const comparator = (option, autoComplete) => {
    let valueOfComparatedField = Object.entries(option).filter(key => {
      return key.includes(autoComplete.comparator)
    })

    if (valueOfComparatedField[0] !== undefined && typeof valueOfComparatedField[0][1] === 'object') {
      const returnField = Object.entries(valueOfComparatedField[0][1]).filter(key => {
        return key.includes(autoComplete.returnField)
      })
      valueOfComparatedField = returnField
    }
    return valueOfComparatedField === undefined || valueOfComparatedField[0] === undefined
      ? ''
      : valueOfComparatedField[0][1]
  }

  const removeDuplicatedItems = options => {
    return [...new Map(options.map(item => [item['areaName'], item])).values()]
  }

  const checkSelected = (option, value, autoComplete) => {
    let optionOfComparatedField = Object.entries(option).filter(key => {
      return key.includes(autoComplete.comparator)
    })
    let valueOfComparatedField = Object.entries(value).filter(key => {
      return key.includes(autoComplete.comparator)
    })
    if (
      optionOfComparatedField[0] !== undefined &&
      valueOfComparatedField[0] !== undefined &&
      typeof valueOfComparatedField[0][1] === 'object'
    ) {
      const returnValueField = Object.entries(optionOfComparatedField[0][1]).filter(key => {
        return key.includes(autoComplete.returnField)
      })
      const returnOptionField = Object.entries(valueOfComparatedField[0][1]).filter(key => {
        return key.includes(autoComplete.returnField)
      })

      valueOfComparatedField = returnValueField
      optionOfComparatedField = returnOptionField
    }
    return (
      valueOfComparatedField[0] !== undefined &&
      optionOfComparatedField[0] !== undefined &&
      optionOfComparatedField[0][1] === valueOfComparatedField[0][1]
    )
  }

  return (
    <Paper variant='outlined' className={classes.paper}>
      <Typography variant='subtitle2' className={classes.divtitle}>
        Filtrar
      </Typography>
      <Grid container spacing={1} className={classes.grid}>
        {listAutoComplete.map((autoComplete, index) => {
          return (
            <Grid xs item className={classes.grid} key={index}>
              <Autocomplete
                disableClearable={autoComplete.disableClear}
                value={autoComplete.value || null}
                onChange={autoComplete.onChange}
                options={
                  autoComplete.comparator == 'areaName'
                    ? removeDuplicatedItems(autoComplete.options) || []
                    : autoComplete.options || []
                }
                getOptionLabel={option => String(comparator(option, autoComplete))}
                getOptionSelected={(option, value) => checkSelected(option, value, autoComplete)}
                renderInput={params => <TextField {...params} placeholder={autoComplete.title} variant='outlined' />}
              />
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default FilterComponent
