import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto',
      paddingTop: theme.spacing(10)
     },
  },
  areaName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    minHeight: '150px',
    width: 'auto',
  },
  title:{
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      margin: '0px',
    },   
  },
  
  description:{
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  hoverStar: {   
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth:'fit-content',
    cursor: 'pointer',
    opacity: 0.75,
    padding: 0,
    display:'flex'
  },
  likeStyle: {
    width: '100%',
    justifContent: 'flex-end',
    marginBottom: theme.spacing(-6),
  },
}))

export default useStyles
