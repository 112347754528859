import React from 'react'

import { Box, IconButton, Paper } from '@material-ui/core'
import { ZoomIn, ZoomOut, CenterFocusStrong } from '@material-ui/icons'

import useStyles from './styles.js'

export default ({ handleZoomIn, handleZoomOut, handleCentralize }) => {
  const classes = useStyles();
  
  return (
    <>
      <Box className={classes.box_zoom}>
        <Paper className={classes.box_paper}>
          <IconButton
            className={classes.icon_button}
            color='primary'
            aria-label='upload picture'
            component='span'
            onClick={handleCentralize}
          >
            <CenterFocusStrong />
          </IconButton>
        </Paper>

        <Paper className={classes.box_paper}
        >
          <IconButton
            className={classes.icon_button}
            color='primary'
            aria-label='upload picture'
            component='span'
            onClick={handleZoomIn}
          >
            <ZoomIn />
          </IconButton>
          <IconButton
            className={classes.icon_button}
            color='primary'
            aria-label='upload picture'
            component='span'
            onClick={handleZoomOut}
          >
            <ZoomOut />
          </IconButton>
        </Paper>
      </Box>
    </>
  )
}
