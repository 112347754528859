import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
    height: '100%',
    display:'flex',
    flexDirection:'column',
    flexWrap:'nowrap'
  },
  titleGrid: {
    marginTop: '10px',
    marginBottom: '25px',
  },
  button: {
    width: '156px',
    height: '42px',
    marginTop:'4px',
    '&:hover': {
      backgroundColor: '#835cbb',
      borderColor: '#835cbb',
      boxShadow: 'none',
    },
    backgroundColor: '#53318a',
    textTransform: 'none',
    marginLeft: 22,
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  areaName: {
    fontWeight: 'bold',
    margin: '0 0 5px',
  },
  link: {
    fontSize: 14,
    color: '#707070',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.7,
      textDecoration: 'underline',
    },
  },
  titleGrid: {
    marginTop: '10px',
  },
  color: {
    color: '#707070',
  },
}));

export default useStyles;
