import React from 'react'
import { Text, Group, Rect, Image } from 'react-konva'
import URLImage from '../../../components/urlImage'
import CloseIcon from '../../../assets/close.png'
import useImage from 'use-image'
import avatar from '../../../assets/avatar.svg'
import clearIcon from '../../../assets/Group 1219.svg'

export default ({ position, reservation, handleClose, avaiable }) => {
  const tx = position ? position.x : 0
  const ty = position ? position.y : 0
  const y = ty
  const x = tx
  const [imageAvatar] = useImage(avatar)

  const cardNotAvaiable = (
    <>
      <Group y={y > 100 ? y - 100 : y} x={x > 320 ? x - 320 : x}>
        <Rect width={320} height={80} fill={'#fff'} stroke='#afbdd0' strokeWidth={1} />
        <Group x={300} y={10} onTap={handleClose} onClick={handleClose}>
          <URLImage src={CloseIcon} height={10} width={10} />
        </Group>
        <URLImage x={15} y={25} src={clearIcon} height={30} width={30} />
        <Group x={60} y={10}>
          <Text width={280} y={5} text={`Atenção`} fontStyle={'bold'} fontSize={14} />
          <Text y={23} width={229} text={'Mesa bloqueada devido as diretrizes contra covid-19!'} fontSize={14} />
        </Group>
      </Group>
    </>
  )

  const cardAvaible = (
    <>
      <Group y={y > 150 ? y - 150 : y} x={x > 320 ? x - 320 : x}>
        <Rect width={320} height={100} fill={'#fff'} stroke='#afbdd0' strokeWidth={1} cornerRadius={4} />
        <Group x={15} y={25}>
          {avaiable && reservation.user.photoURL ? (
            <Group
              clipFunc={function (ctx) {
                ctx.arc(25, 25, 25, 0, Math.PI * 2, false)
              }}
            >
              <URLImage src={reservation && reservation.user.photoURL} width={50} height={50} />
            </Group>
          ) : (
            <Group>
              <Image image={imageAvatar} width={50} height={50} />
            </Group>
          )}
        </Group>

        <Group x={295} y={10} onTap={handleClose} onClick={handleClose}>
          <URLImage src={CloseIcon} height={15} width={15} />
        </Group>
        <Group x={80} y={15}>
          <Text
            y={15}
            width={230}
            text={reservation && `${reservation.user.displayName}`}
            fontSize={16}
            ellipsis={true}
          />
          <Text
            y={45}
            width={230}
            text={reservation && `${reservation.user.jobTitle}`}
            fontSize={10}
            wrap='none'
            ellipsis={true}
          />
        </Group>
      </Group>
    </>
  )

  return <>{avaiable ? cardAvaible : cardNotAvaiable}</>
}
