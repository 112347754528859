import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Box, CircularProgress } from '@material-ui/core'
import useStyles from './styles'
import AreaBoard from '../../areaBoard'
import { FirebaseContext } from '../../../contexts/firebase'
import useImage from 'use-image'
import ShowModal from '../../../components/modal'
import NewRoomModalIcon from '../../../assets/newRoomHint.svg'
import success from '../../../assets/addRoom.svg'

export default ({}) => {
  const { areaID } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [area, setArea] = useState()
  const [scale, setScale] = useState(1)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const firebase = useContext(FirebaseContext)
  const divRef = useRef()
  const all = useRef(true)
  const [img] = useImage(area?.imageResizedURL)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (!all.current) return

    if (!areaID) {
      history.push('/areas')
      return
    }

    firebase.getAreaByID(areaID).then(area => {
      setArea(area), setLoading(false)
      setScale(area.scale)
    })
  }, [])

  const calcScale = points => {
    if (!all.current || !points || !points.length) return
    setScale(50 / Math.hypot(points[0].x - points[1].x, points[0].y - points[1].y))
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    history.push({
      pathname: '/areas',
    })
  }
  const handleSubmit = () => {
    setDialogOpen(true)

    firebase.updateArea(areaID, { ...area, scale })
  }
  return (
    <>
      <Box className={classes.btn}>
        <Button className={classes.btnAv} variant='contained' color='primary' onClick={handleSubmit}>
          Avançar
        </Button>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {!loading && img ? (
          <Box>
            <ShowModal
              title='Agora você vai selecionar uma escala para a sua área'
              message='Arraste a linha para representar qual o tamanho de 1 metro na sua planta'
              isOpen={open}
              handleClose={() => setOpen(false)}
              image={NewRoomModalIcon}
              secondActionLabel='Começar'
            />
            <ShowModal
              image={success}
              isOpen={dialogOpen}
              title='Área adicionada com sucesso!'
              message='Agora você pode adicionar salas dentro dessa área cadastrada.'
              mainAction={handleDialogClose}
              mainActionLabel='Fechar'
            />

            <Box ref={divRef} className={classes.areaBoard}>
              {img ? (
                <AreaBoard
                  width={img.width}
                  height={img.height}
                  image={img}
                  savePolygon={calcScale}
                  divRef={divRef}
                  isScale={true}
                />
              ) : null}
            </Box>
          </Box>
        ) : (
          <CircularProgress style={{ marginTop: '20%' }} />
        )}
      </Box>
    </>
  )
}
