import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router'
import { cell } from './styles'

export const Row = ({ index, row, monthYear }) => {
  const year = monthYear.split('-')[0]
  const month = monthYear.split('-')[1].length == 2 ? monthYear.split('-')[1] : monthYear.split('-')[1].substr(1)
  const history = useHistory()
  const classes = cell()
  return (
    <TableRow
      role='checkbox'
      onClick={() => {
        history.push(`/report/noshow/${monthYear}/${row[0]}`)
      }}
      key={row[0]}
    >
      <TableCell align='left' padding='default' className={classes.row}>
        {`${month}/${year}`}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].roomName}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].totalCheckins}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].totalNoShows}
      </TableCell>
    </TableRow>
  )
}
export default Row
