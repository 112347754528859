import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    height: '90%',
    width: '100%',
  },
  boxQRCode: {
    marginTop: 55,
    width: '60%',
    maxHeight: 450,
  },
  qrReader: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
  boxStyle: {
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    paddingLeft: '20%',
  },
  textFieldStyle: {
    width: '70%',
    paddingBottom: 90,
  },
  image: {
    width: '32%',
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '53%',
      bottom: 46,
    },
  },
  typography: {
    paddingBottom: 10,
  },
  typographyChecked: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  typographyConfimation: {
    marginTop: theme.spacing(3),
  },
  paperStyle: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '60%',
  },
  sucessoStyle: {
    alignSelf: 'center',
  },
  circularStyle: {
    alignSelf: 'center',
    marginLeft: '50%',
    marginTop: '15%',
  },
  buttonStyle: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    textTransform: 'none',
    width: '100%',
  },
}))

export default useStyles
