import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    boxContainer: ({ selectedWall }) =>
    ({
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        zIndex: selectedWall > 0 ? 10 : 0,
        maxWidth: 410,
    }),
    button: {
        maxWidth: '43px',
        minWidth: '43px',
        maxHeight: '43px',
        minHeight: '43px',
        margin: 4,
        color: '#474747',
    },
}));

export default useStyles;
