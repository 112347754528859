import { makeStyles } from '@material-ui/core'

const mobileSize = 'sm'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 150,
    width: '100%',
    [theme.breakpoints.down(mobileSize)]: {
      height: '75%',
    },
  },
  boxStyle: {
    alignSelf: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldStyle: {
    width: '60%',
    marginBottom: 17,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down(mobileSize)]: {
      width: '100%',
    },
  },
  image: {
    width: '36vw',
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down(mobileSize)]: {
      position: 'fixed',
      right: 3,
      bottom: '65px',
    },
  },
  typography: {
    paddingBottom: 16,
    fontWeight: 'normal',
    fontSize: 15,
    [theme.breakpoints.down(mobileSize)]: {
      textAlign: 'center',
    },
  },
  buttonStyle: {
    width: '225px',
    height: '50px',
    fontSize: '13px',
    marginLeft: 0,
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down(mobileSize)]: {
      width: '100%',
      padding: theme.spacing(1),
      textTransform: 'none',
      height: '40px',
    },
  },
}))

export default useStyles
