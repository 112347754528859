import React from 'react'
import { Stage, Layer, Arc, Text } from 'react-konva'

const ReservationsPie = ({ rooms, dim, colors }) => {
  const radius = 100
  const center = [dim[0] / 2, dim[1] / 2]
  const pi = Math.PI
  const distance = 10

  let totalOfReservations = rooms.reduce((accumulator, current) => accumulator + current.totalReservations, 0)
  let totalOfCanceledReservation = rooms.reduce((accumulator, current) => accumulator + current.totalCanceled, 0)
  let total = totalOfCanceledReservation + totalOfReservations
  let percentage = total ? Math.round((totalOfReservations / total) * 100) : 0

  const a = Math.sin((percentage * pi) / 100)
  const b = Math.cos((percentage * pi) / 100)
  const xDif = percentage && percentage < 100 ? 20 + Math.abs(Math.max(percentage, 100 - percentage) - 90) / 5 : -25
  const yDif = percentage && percentage < 100 ? 10 : 10

  return (
    <Stage width={dim[0]} height={dim[1]}>
      <Layer>
        <Arc
          x={center[0] + (b * distance) / 2}
          y={center[1] + (a * distance) / 2}
          outerRadius={radius}
          angle={(360 * percentage) / 100}
          innerRadius={0}
          fill={colors[0]}
        />
        <Arc
          x={center[0] - (b * distance) / 2}
          y={center[1] - (a * distance) / 2}
          rotation={(360 * percentage) / 100}
          outerRadius={radius}
          angle={360 * (1 - percentage / 100)}
          innerRadius={0}
          fill={colors[1]}
        />
        {percentage >= 10 && (
          <Text
            text={`${percentage}%`}
            x={center[0] + b * (90 + distance) * 0.6 - xDif}
            y={center[1] + a * (90 + distance) * 0.6 - yDif}
            width={60}
            height={30}
            fontSize={23}
            fontStyle={'bold'}
            align='center'
            verticalAlign='center'
            fill='white'
          />
        )}
        {percentage <= 90 && (
          <Text
            text={`${100 - percentage}%`}
            x={center[0] - b * (90 + distance) * 0.6 - xDif}
            y={center[1] - a * (90 + distance) * 0.6 - yDif}
            width={60}
            height={30}
            fontSize={23}
            fontStyle={'bold'}
            align='center'
            verticalAlign='center'
            fill='white'
          />
        )}
      </Layer>
    </Stage>
  )
}
export default ReservationsPie
