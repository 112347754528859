import { Button, Grid } from '@material-ui/core'
import React, { useState, useEffect, useContext, useRef } from 'react'
import useStyles from './styles'
import { FirebaseContext } from '../../contexts/firebase'

const Scripts = () => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const echo = useRef(null)

  const getRoomID = roomName => {
    switch (roomName) {
      case 'CTD/FTI':
        return 'SIFRsWh0hIou2wByDutq'
      case 'Orion':
        return '3N7XiRfw0pgJbQYeP128'
      case 'Boreal':
        return '3nFIlOKbLbov6I284Vqa'
      case 'Lua':
        return '9APyvtZ8eJS9TubcjV8P'
      case 'Hercules':
        return 'A1widoiIN1aEE5PNllZW'
      case 'Fênix':
        return 'OGJ91aazWyn5lWcoCO2P'
      case 'Cassiopeia':
        return 'P1uNODfdkYjUDO8pGiR5'
      case 'Centauro':
        return 'P3BnykcQiE5We7FPMOEp'
      case 'Andromeda':
        return 'Puk8xN59NpyHukVbWyDa'
      case 'TI + Devs':
        return 'THYq6vLnNfD598UjWDPR'
      case 'Perseu':
        return 'WcX4LgSxT6wAaKEyrQL2'
      case 'Lira':
        return 'ch65cZiCppNK1KDpLPaA'
      case 'Sol':
        return 'dbslyxV0CJekKYXikZvs'
      case 'Pégaso':
        return 'xXyeTRQz5hxoNt4VnwBs'
      case 'Sala Principal':
        return '2LZzTiJAH7rv5H3d1Jx1'
      case 'S2':
        return 'oSKwlANG6wVkOfhkFZ8v'
    }
  }

  const changeIconPath = () => {
    echo.current.append('Call changeIconPath\n\r')
    firebase
      .getAllRooms()
      .then(rooms => {
        rooms.map(room => {
          let update = false
          room.elements.map(ele => {
            if (ele && ele.icon && ele.icon.includes('.svg')) {
              let extracted = extractIcon(ele.icon)
              echo.current.append(`UDPATING: room ${room.name} - icon from ${ele.icon} to ${extracted}\n\r`)
              ele.icon = getIconName(extracted)
              update = true
            } else {
              echo.current.append(`OK: room: ${room.name} - icon: ${ele.icon}\n\r`)
            }
          })
          if (update) {
            firebase
              .updateRoom(room.id, room)
              .then(res => {
                echo.current.append(`ROOM UPDATED ON FIREBASE: ${room.name} \n\r`)
              })
              .catch(error => {
                echo.current.append(`ERROR UPDATING ROOM: ${room.name} msg: ${error}`)
              })
          }
        })
      })
      .catch(error => {
        echo.current.append(`ERROR: ${error}`)
      })
  }

  const extractIcon = icon => {
    if (!icon) {
      console.log('Null icon')
      return null
    }
    let rx = /\/\w+[-]*\w+\./g
    let res = rx.exec(icon)
    if (!res || !res[0]) {
      console.log('Null res', res)
      return null
    }

    return res[0].slice(1, -1)
  }

  const getIconName = icon => {
    switch (icon) {
      case 'tijolos':
        return 'brickIcon'
      case 'coluna':
        return 'columnIcon'
      case 'openDoor':
        return 'openDoorIcon'
      case 'desk':
        return 'deskIcon'
      case 'businessMeeting':
        return 'businessMeetingIcon'

      //Mesas de trabalho
      case 'table1seat':
        return 'tableWith1'
      case 'table2seat':
        return 'tableWith2'
      case 'table2seatUnilateral':
        return 'tableWith2Uni'
      case 'table3seat':
        return 'tableWith3'
      case 'table3seatUnilateral':
        return 'tableWith3Uni'
      case 'table4seat':
        return 'tableWith4'
      case 'table4seatDiagonal':
        return 'tableWith4Dia'
      case 'table4seatUnilateral':
        return 'tableWith4Uni'
      case 'table5seat':
        return 'tableWith5'
      case 'table5seatDiagonal':
        return 'tableWith5Dia'
      case 'table7seatDiagonal':
        return 'tableWith7Dia'
      case 'table9seatDiagonal':
        return 'tableWith9Dia'
      case 'table5seatUnilateral':
        return 'tableWith5Uni'
      case 'table6seat':
        return 'tableWith6'
      case 'table6seatUnilateral':
        return 'tableWith6Uni'
      case 'table7seat':
        return 'tableWith7'
      case 'table8seat':
        return 'tableWith8'
      case 'table9seat':
        return 'tableWith9'
      case 'table10seat':
        return 'tableWith10'

      //Mesas de Reunião
      case 'meet2seat':
        return 'meetTableWith2'
      case 'meet4seat':
        return 'meetTableWith4'
      case 'meet6seat':
        return 'meetTableWith6'
      case 'meet7seat':
        return 'meetTableWith7'
      case 'meet8seat':
        return 'meetTableWith8'
      case 'meet12seat':
        return 'meetTableWith12'
      case 'groupPlants':
        return 'groupPlants'
      case 'squarePlants':
        return 'squarePlants'
      case 'vaso-torto':
        return 'vasoTorto'
      case 'vaso-reto':
        return 'vasoReto'
      case 'bigLocker':
        return 'bigLocker'
      case 'smallLocker':
        return 'smallLocker'
      case 'columnGrey':
        return 'columnGrey'
      case 'sofa':
        return 'sofa'
      case 'table':
        return 'tableIcon'
      case 'locker':
        return 'locker'
      case 'column':
        return 'column'
      case 'leftDoor':
      case 'door':
        return 'leftDoor'
      case 'rightDoor':
        return 'rightDoor'
      default:
        return null
    }
  }

  async function changeReservationsRoomID() {
    const allReservations = await firebase.getAllReservations()
    echo.current.append('Call changeReservationsRoomID\n\r')
    Promise.all(
      allReservations.map(reservation => {
        const roomID = getRoomID(reservation.roomName)
        if (reservation.roomName && roomID) {
          echo.current.append('RoomName:', reservation.roomName, '\n\r')
          firebase.updateReservations(reservation, roomID)
        }
      })
    )
    echo.current.append('Finish \n\r')
  }

  const addFavoriteAreaProp = () => {
    echo.current.append('Call addFavoriteArea Property\n\r')
    firebase.getUsers().then(users => {
      users.map(user => {
        firebase
          .createFavoriteAreaField(user.id)
          .then(res => {
            echo.current.append(`UDPATING: room ${user.displayName}\n\r`)
          })
          .catch(err => console.log(err))
      })
    })
  }

  const addCancelTypeToReservations = () => {
    echo.current.append('Call addCancelTypeToReservations \n\r')
    firebase.getAllCancelReservations()
  }

  const addUserNameToReservation = async () => {
    echo.current.append('Call addUserNameToReservation \n\r')
    const allReservations = await firebase.getAllReservations()
    await Promise.all(
      allReservations.map(async reservation => {
        if (!reservation.userName) {
          const user = await firebase.getUserByUid(reservation.user)
          await firebase.updateDisplayNameReservation(reservation, user[0]?.displayName ?? 'Excluído')
          echo.current.append(`UDPATING: userName ${user[0]?.displayName ?? 'Excluído'}\n\r`)
        }
      })
    )
    echo.current.append('FINISHED \n\r')
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        <Button onClick={changeIconPath}>Update images</Button>
        <Button onClick={addFavoriteAreaProp}>Create Favorite property</Button>
        <Button onClick={changeReservationsRoomID}>Change rooms ID</Button>
        <Button onClick={addCancelTypeToReservations}>Add cancel type</Button>
        <Button onClick={addUserNameToReservation}>Add username to reservations</Button>
        <pre ref={echo}></pre>
      </Grid>
    </div>
  )
}

export default Scripts
