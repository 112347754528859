import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FilterComponent from '../../../components/filterComponent/filter'
import DeleteUser from '../../../assets/CancelReservation.svg'
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  TablePagination,
  Snackbar,
  Grid,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiAlert from '@material-ui/lab/Alert'
import { ContentModal } from './rowComponents'
import { useStyles } from './styles'

import { FirebaseContext } from '../../../contexts/firebase'
import { materialTheme } from '../../../theme/table'
import { useResizeDetector } from 'react-resize-detector'
import Row from './row'
import DeleteUserModal from '../../../components/modal'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  { id: 'displayName', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'manager', numeric: false, disablePadding: true, label: 'Gerente' },
  {
    id: 'permission',
    numeric: false,
    disablePadding: true,
    label: 'Permissões',
  },
  { id: 'more', disablePadding: false, numeric: true },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ paddingLeft: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visually_hidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function EnhancedTable() {
  const classes = useStyles()
  const { height, ref } = useResizeDetector()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('displayName')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState(1)
  const [openSnackBar, setSnackBar] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [valueFiltered, setValueFiltered] = useState()
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 20, 30]
  const [lastHeight, setLastHeight] = useState(0)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)

  const firebase = useContext(FirebaseContext)

  const fetchData = async () => {
    const users = await firebase.getUsers()
    setRows(users)
    setLastHeight(height)
    setLoading(false)
  }

  const handleFetchData = () => {
    fetchData()
  }

  useEffect(handleFetchData, [])

  const handleDeleteUser = async user => {
    await firebase.removeUser(user.id).then(async () => {
      await fetchData()
      setValueFiltered()
    })
  }

  const onDataChanged = async () => {
    await fetchData()
    setSnackBar(true)
  }

  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable <= rowsPerHeight) {
          setRowsPerPage(amountDisplayInTable)
          setLastHeight(height)
        } else {
          return
        }
      }
    }
    if (lastHeight !== height && !loading) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions, loading])

  const heightPerRow = () => {
    return Math.round(rows)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.displayName)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleOpenModal = row => {
    setSelectedRow(row)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenUserModal = (row) =>{
    setSelectedRow(row)
    setOpenDeleteUserModal(true)
  }

  const handleCloseUserModal = () =>{
    setOpenDeleteUserModal(false)
  }

  const handleUserRow = () => {
    handleDeleteUser(selectedRow)
    setOpenDeleteUserModal(false)
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleCloseSnackBar = reason => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar(false)
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const userFilter = (e, newSearch) => {
    setPage(0)
    setValueFiltered(newSearch)
  }

  const filterValues = row => {
    if (row.displayName && row.email && row.manager) {
      return (
        row.displayName === valueFiltered.displayName &&
        row.email === valueFiltered.email &&
        row.manager === valueFiltered.manager
      )
    }
  }

  const modal = (
    <>
      <ContentModal handleCloseModal={handleCloseModal} row={selectedRow} onDataChanged={onDataChanged} />
    </>
  )

  const listAutoComplete = [
    {
      title: 'Nome',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(rows, getComparator('asc', orderBy)),
      comparator: 'displayName',
    },
    {
      title: 'Email',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(rows, getComparator('asc', orderBy)),
      comparator: 'email',
    },
  ]

  return (
    <Grid container direction='row' justify='flex-start' className={classes.root}>
      <Grid className={classes.titleGrid} item xs={12}>
        <Typography className={classes.title} variant='h5' id='tableTitle' component='div'>
          Usuários
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FilterComponent listAutoComplete={listAutoComplete} />
      </Grid>
      <Grid item xs={12} className={classes.gridTable}>
        <Paper className={classes.paper} ref={ref}>
          <ThemeProvider theme={materialTheme}>
            <TableContainer className={classes.container}>
              {loading ? (
                <Box>
                  <LinearProgress />
                </Box>
              ) : (
                <>
                  {noData === 0 ? (
                    <Typography>Ainda não há usuários cadastrados</Typography>
                  ) : (
                    <>
                      <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        aria-label='enhanced table'
                        stickyHeader
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                          <ThemeProvider theme={materialTheme}>
                            {stableSort(rows, getComparator(order, orderBy))
                              .filter(row => (valueFiltered ? filterValues(row) : row))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.displayName)
                                const labelId = `enhanced-table-checkbox-${index}`

                                return (
                                  <Row
                                    row={row}
                                    isItemSelected={isItemSelected}
                                    selected={selected}
                                    key={index + '-' + new Date().getTime()}
                                    labelId={labelId}
                                    handleClick={handleClick}
                                    handleOpenModal={handleOpenModal}
                                    onDataChanged={onDataChanged}
                                    handleOpenUserModal={handleOpenUserModal}
                                  />
                                )
                              })}
                          </ThemeProvider>
                        </TableBody>
                      </Table>
                    </>
                  )}
                </>
              )}
            </TableContainer>
            <Box className={classes.box_lastRow}>
              <Box className={classes.box_half}></Box>
              <Box className={classes.box_half}>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={'Linhas por página:'}
                />
              </Box>
            </Box>
          </ThemeProvider>
        </Paper>
      </Grid>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity='success'>
          Alterações confirmadas!
        </Alert>
      </Snackbar>
      <Modal open={openModal} onClose={handleCloseModal}>
        {modal}
      </Modal>
      <DeleteUserModal 
        isOpen={openDeleteUserModal}
        image={DeleteUser}
        title={'Tem certeza que deseja excluir o usuário?'}
        message={'Atenção! O usuário será excluido permanentemente!'}
        handleClose={handleCloseUserModal}
        mainAction={handleUserRow}
        mainActionLabel={'Confirmar exclusão'}
        secondActionLabel={'Cancelar'}
      />                       
    </Grid>
  )
}
