import React, { useState, useEffect, useContext } from 'react'
import useStyles from './styles'
import { Grid, Typography, Button } from '@material-ui/core'
import RoomsTableList from './roomsTable'
import { format } from 'date-fns'
import { FirebaseContext } from '../../../../contexts/firebase'
import * as XLSX from 'xlsx'
import { useParams, Link } from 'react-router-dom'
import * as ROUTES from '../../../../config/routes'

import CustomFilter from '../../../../components/customFilter'

const NoShowReportListDetails = () => {
  const classes = useStyles()
  const params = useParams()
  const firebase = useContext(FirebaseContext)
  const [noShowRooms, setNoShowRooms] = useState()
  const [filteredRoom, setFilteredRoom] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({
    date: null,
    user: null,
  })
  const [year, month] = params.month.split('-').map(a => Number(a))

  const monthYear = year + '-' + month
  const [roomInfo, setRoomInfo] = useState([])

  const handleFilter = (_, value, name) => {
    setSelectedFilter({ ...selectedFilter, [name]: value })
  }

  const valid = month > 0 && month <= 12
  //Limit of months to show in filter (current + past months)

  const availableUsers = noShowRooms?.map(room => room.userName)
  let uniqueAvailableUsers = [...new Set(availableUsers)]

  let dates = noShowRooms?.map(room => {
    return new Date(room.date.seconds * 1000).toLocaleDateString('pt-BR')
  })
  dates = [...new Set(dates)]

  let users = filteredRoom?.map(reservation => reservation.user)
  users = [...new Set(users)]

  useEffect(() => {
    if (valid) {
      firebase.getRoom(params.roomID).then(ret => {
        setRoomInfo(ret)
      })
      firebase.getMonthNoShowRoomReservationReport(monthYear, params.roomID).then(ret => {
        setNoShowRooms(ret)
        setFilteredRoom(ret)
      })
    }
  }, [params.month, firebase, valid])

  const listAutoComplete = [
    {
      name: 'user',
      label: 'Usuario',
      value: selectedFilter.user,
      onChange: handleFilter,
      options: uniqueAvailableUsers,
    },
    {
      name: 'date',
      label: 'Data',
      value: selectedFilter.date,
      onChange: handleFilter,
      options: dates,
    },
  ]

  let auxRoom = filteredRoom ? filteredRoom : noShowRooms
  const downloadExcelFile = () => {
    const wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test')
    let ws_data = [
      ['Data', 'Usuário', 'Status'],
      ...auxRoom.map(aux => [
        format(new Date(aux.date.seconds * 1000), 'dd/MM/yyyy'),
        aux.userName,
        aux.status[aux.status.length - 1].type === 3 && 'No show',
      ]),
    ]
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test'] = ws
    XLSX.utils.book_append_sheet(wb, ws, 'No show')
    XLSX.writeFile(wb, 'No show Detalhes.xlsx')
  }

  useEffect(() => {
    let aux = noShowRooms
    if (selectedFilter.date) {
      aux = aux.filter(reservation => {
        const [day] = selectedFilter.date.split('/')
        return new Date(reservation.date.seconds * 1000).getDate() == day
      })
    }
    if (selectedFilter.user) {
      aux = aux.filter(reservation => {
        return reservation.userName === selectedFilter.user
      })
    }

    setFilteredRoom(aux)
  }, [selectedFilter])

  return (
    <Grid container direction='row' justify='flex-start' className={classes.root}>
      <Grid item xs={12} className={classes.divtitle}>
        <Typography variant='h5'>No show</Typography>
        <Typography variant='body1' className={classes.color}>
          <Link to={ROUTES.REPORT} className={classes.link}>
            {' Relatórios '}
          </Link>
          &gt;
          <Link to={`${ROUTES.REPORT}/noshow/${year}-${String(month).padStart(2, '0')}`} className={classes.link}>
            {' No show '}
          </Link>
          &gt; Detalhes {roomInfo.name}{' '}
        </Typography>
      </Grid>
      {auxRoom && (
        <Grid item xs={12} className={classes.gridButton}>
          <Button className={classes.button} variant='contained' color='primary' onClick={() => downloadExcelFile()}>
            Exportar
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomFilter fields={listAutoComplete} />
      </Grid>
      <Grid item xs={12}>
        <RoomsTableList rooms={filteredRoom ? filteredRoom : noShowRooms} monthYear={params.month} />
      </Grid>
    </Grid>
  )
}

export default NoShowReportListDetails
