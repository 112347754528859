import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    maxWidth: '1280px',
    padding: '24px',
  },
  header: {
    marginBottom: '30px',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    height: '42px',
    width: '156px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },
  input: {
    width: '100%',
    maxWidth: '1280px',
  },
  autocomplete: {
    width: '100%',
  },
  gridAuto: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  color: {
    color: '#707070',
  },
  input: {
    '&.MuiInput-underline:before': {
      borderBottomColor: '#53318A',
    },
    width: '98%',
    color: '#53318A',
  },
  textColor: {
    color: '#53318A',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  imageInput: {
    marginTop: '12px',
  },
  imageGrid: {
    marginTop: '20px',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

export { useStyles }
