import React, { useEffect, useRef, useState } from 'react'
import { Layer, Stage, Line } from 'react-konva'

import { Box, CircularProgress, Container, Grid, Paper } from '@material-ui/core'

import useStyles from './styles.js'
import CardKonvas from '../cards/cardKonvas'
import { getRoomCapacity } from '../../utils/checkin'

import TableGroup from '../Rooms/tableGroup'

import RoomElements from '../Rooms/roomElements'

const RoomWebView = () => {
  const classes = useStyles()
  const container = useRef(null)
  const [isFull, setFull] = useState(false)
  const [reservation, setReservation] = useState({ id: '', setFull })
  const [reservations, setReservations] = useState([])
  const [reservationCard, setReservationCard] = useState()
  const [reservationCardPosition, setReservationCardPosition] = useState()
  const [avaiable, setAvaiable] = useState()
  const [shouldShowText, setShouldShowText] = useState(false)
  const instantiatedDate = new Date()
  const [room, setRoom] = useState({})
  const [loading, setLoading] = useState(true)
  const [stageResized, setStageResized] = useState(false)
  const [cursor, setCursor] = useState(false)

  const [selected, setSelected] = useState({ block: null, table: null })
  const stage = useRef(null)

  const [width, setWidth] = useState(500)
  const [height, setHeight] = useState(500)
  let all = useRef(true)

  window.webviewZoomIn = () => zoomIn()
  window.webviewZoomOut = () => zoomOut()
  window.webviewCentralize = () => centralize()

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (window.JSBridge !== undefined) {
      const roomFromWebView = window.JSBridge.getRoomFromWebView()
      const reservationsFromWebView = window.JSBridge.getReservationsFromWebView()

      setRoom(JSON.parse(roomFromWebView))
      setReservations(JSON.parse(reservationsFromWebView))
    }
  }, [])

  const executeScroll = () => {
    container.current.scrollTo({
      top: 0,
      left: 0,
    })
  }
  useEffect(() => {
    if (!all.current) return
    if (container.current) {
      executeScroll()
    }
  }, [container])

  useEffect(() => {
    if (!all.current) return

    if (room && room.elements && width && height) {
      setLoading(false)
    }
  }, [room && room.elements, width, height])

  const handleSelectSeat = data => {
    data.room = room.id
    data.roomNid = room.nid
    data.roomName = room.name
    data.date = instantiatedDate
    if (window.JSBridge !== null && window.JSBridge !== undefined) window.JSBridge.setTableBlock(JSON.stringify(data))
    /*  data.area = areaName */
    setReservation(data)
  }

  const handleSeat = (seat, reservation, show, avaiable) => {
    if (show && seat && reservation) {
      setReservationCard(reservation)
    }
    setReservationCardPosition(getRelativePointerPosition(stage.current))
    setAvaiable(avaiable)
    setShouldShowText(show)
  }

  const handleClose = () => {
    setShouldShowText(false)
  }

  function showAndroidToast(toast) {
    Android.showToast(toast)
  }

  const getRelativePointerPosition = node => {
    let transform = node.getAbsoluteTransform().copy()

    transform.invert()

    let pos = node.getStage().getPointerPosition()

    return transform.point(pos)
  }

  const circularProgress = (
    <Box className={classes.circularStyle}>
      <CircularProgress />
    </Box>
  )

  const handleLotationChange = (roomid, lotation) => {
    if (roomid && lotation !== undefined) {
      setFull(lotation >= getRoomCapacity(room))
    }
  }

  const [zoom, setZoom] = useState(1)

  const zoomIn = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    setZoom(0.1 + zoom)
  }

  const zoomOut = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    setZoom(zoom - 0.1)
  }

  const centralize = () => {
    let s = stage.current
    let c = container.current
    s.width(c.clientWidth)
    s.height(c.clientHeight)
    s.position({
      x: c.clientWidth / 2 - (room.width * zoom) / 2,
      y: c.clientHeight / 2 - (room.height * zoom) / 2,
    })
    s.batchDraw()
  }

  const [walls, setWalls] = useState([])
  const [minX, setMinX] = useState()
  const [minY, setMinY] = useState()
  const [maxX, setMaxX] = useState()
  const [maxY, setMaxY] = useState()

  useEffect(() => {
    if (!all.current) return

    setWidth(maxX - minX)
    setHeight(maxY - minY)
  }, [minX, minY, maxX, maxY])

  useEffect(() => {
    if (!room || !room.polygon || !all.current) return
    const mx = Math.min(...room.polygon.map(point => point.x))
    const my = Math.min(...room.polygon.map(point => point.y))
    const mX = Math.max(...room.polygon.map(point => point.x))
    const mY = Math.max(...room.polygon.map(point => point.y))
    setMinX(mx)
    setMinY(my)
    setMaxX(mX)
    setMaxY(mY)
  }, [room])

  useEffect(() => {
    if (!room || !room.polygon || !all.current) return
    let aux = []
    room.polygon.forEach(point => {
      aux.push((point.x - minX) * room.scale + (room.width - (maxX - minX) * room.scale) / 2),
        aux.push((point.y - minY) * room.scale + (room.height - (maxY - minY) * room.scale) / 2)
    })
    setWalls(aux)
  }, [room, minX, minY, maxX, maxY, zoom])

  useEffect(() => {
    if (!container || !container.current || stageResized || !all.current) return
    stage.current.width(container.current.clientWidth)
    stage.current.height(container.current.clientHeight)
    stage.current.position({
      x: container.current.clientWidth / 2 - Math.min(room.width, container.current.clientWidth) / 2,
      y: container.current.clientHeight / 2 - Math.min(room.height, container.current.clientHeight) / 2,
    })
    setStageResized(true)
    stage.current.batchDraw()
  })

  return (
    <Box className={classes.root}>
      <main className={classes.content}>
        <Container style={{ cursor: cursor ? 'grab' : 'default' }} className={classes.container}>
          {loading ? (
            circularProgress
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Box className={classes.mapPaper}>
                  <Paper className={classes.card_paper} ref={container} elevation={0}>
                    <Stage
                      width={0}
                      height={0}
                      ref={stage}
                      draggable
                      style={{ alignSelf: 'center', margin: 'auto' }}
                      scale={{ x: zoom, y: zoom }}
                      onDragMove={() => {
                        setCursor(true)
                      }}
                      onDragEnd={() => {
                        setCursor(false)
                      }}
                    >
                      <Layer>
                        {walls.length && <Line closed stroke='black' strokeWidth={3} points={walls} />}
                        {room.elements &&
                          room.elements.map((ele, index) =>
                            ele.workstation ? (
                              <TableGroup
                                element={ele}
                                reservations={reservations}
                                handleSeat={handleSeat}
                                handleSelectSeat={handleSelectSeat}
                                selected={selected}
                                setSelected={setSelected}
                                reserved={reservations.filter(res => res.block === ele.index)}
                                key={index}
                              />
                            ) : (
                              <RoomElements element={ele} key={index} />
                            )
                          )}
                        {shouldShowText && (
                          <CardKonvas
                            avaiable={avaiable}
                            position={reservationCardPosition}
                            reservation={reservationCard}
                            handleClose={handleClose}
                          />
                        )}
                      </Layer>
                    </Stage>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    </Box>
  )
}

export default RoomWebView
