import { makeStyles } from '@material-ui/core'
import {margin_root, header_height} from '../../utils/variaveis.js'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: margin_root,
    display:'flex',
    flexDirection:'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  heightBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  stepperName: {
    textAlign: 'center',
  },
  spacerMobile: {
    [theme.breakpoints.down('md')]: {
      height: '69px',
    },
  },
}))

export default useStyles
