import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    image: {
        width: "100%",
        height: "100%"
    },
    progressContainer: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "flex-end"
    }
}));

export default useStyles;
