import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Grid, Button, Typography, Snackbar, CircularProgress } from '@material-ui/core'
import useStyles from './styles'
import AreaBoard from '../../areaBoard'
import { FirebaseContext } from '../../../contexts/firebase'
import useImage from 'use-image'
import ZoomControl from './../../../components/zoomControl'
import MuiAlert from '@material-ui/lab/Alert'

export default ({}) => {
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [newRoom, setNewRoom] = useState(state.room)
  const [area, setArea] = useState()
  const [rooms, setRooms] = useState([])
  const [polygon, setPolygon] = useState(null)
  const firebase = useContext(FirebaseContext)
  const divRef = useRef()
  const [img] = useImage(area?.imageResizedURL)
  const all = useRef(true)
  const [zoom, setZoom] = useState(1)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertInvalidPosition, setShowAlertInvalidPosition] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isDisabled, setDisabled] = useState(false)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (!all.current) return

    if (!state) {
      history.push('/areas')
      return
    }

    firebase.getAreaByID(newRoom.area).then(area => {
      setArea(area)
      setLoading(false)
    })
    firebase.getRoomsByID(newRoom.area).then(rooms => setRooms(rooms))
  }, [])

  const savePolygon = polygon => {
    if (!all.current) return
    setPolygon(polygon)
    setNewRoom({ ...newRoom, polygon: polygon })
  }

  const handleCloseSnackBar = reason => {
    if (reason === 'clickaway') {
      return
    }
    setShowAlert(false)
    setShowAlertInvalidPosition(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const handleSubmit = () => {
    let validPolygon = true
    if (!all.current) return

    if (polygon) {
      polygon.forEach(point => {
        if (point.x > img.width || point.x < 0) {
          validPolygon = false
          return
        } else if (point.y > img.height || point.y < 0) {
          validPolygon = false
          return
        }
      })
      if (!validPolygon) {
        setShowAlertInvalidPosition(true)
        return
      }
    }
    if (!polygon) {
      setShowAlert(true)
      return
    }

    setDisabled(true)
    firebase
      .AddNewRoom(newRoom, state.image)
      .then(resp => {
        const roomComplete = { ...newRoom, id: resp }
        history.push({
          pathname: `/rooms/${newRoom.area}/editroom/${newRoom.nid}/editor`,
          state: { room: roomComplete, isEdit: false },
        })
      })
      .catch(() => setDisabled(false))
  }

  const handleZoomIn = () => {
    if (!all.current) return
    setZoom(Math.min(zoom / 0.9, 3))
  }

  const handleZoomOut = () => {
    if (!all.current) return
    setZoom(Math.max(zoom * 0.9, 0.5))
  }

  const handleCentralize = () => {
    if (!all.current) return
    setZoom(1)
  }
  return (
    <>
      <Grid container direction='row' justify='flex-start' className={classes.root}>
        <Grid container item xs={12} justify='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={isDisabled}
              className={classes.button}
            >
              <Typography variant='subtitle1' color='inherit'>
                Avançar
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {!loading && img ? (
          <Grid item className={classes.divMap}>
            <Grid item className={classes.divarea}>
              <div ref={divRef} className={classes.areaBoard}>
                {img ? (
                  <AreaBoard
                    width={img.width}
                    height={img.height}
                    image={img}
                    rooms={rooms.filter(room => room.polygon)}
                    savePolygon={savePolygon}
                    divRef={divRef}
                    isEdit={false}
                    isScale={false}
                    zoom={zoom}
                  />
                ) : null}
              </div>
            </Grid>
            <ZoomControl
              handleCentralize={() => handleCentralize()}
              handleZoomIn={() => handleZoomIn()}
              handleZoomOut={() => handleZoomOut()}
            />
          </Grid>
        ) : (
          <CircularProgress style={{ margin: '0 auto' }} />
        )}
        <Snackbar open={showAlert} autoHideDuration={3000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity='error'>
            Desenhe um polígono válido para continuar!
          </Alert>
        </Snackbar>
        <Snackbar open={showAlertInvalidPosition} autoHideDuration={3000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity='error'>
            Desenhe um polígono em uma posição válida para continuar!
          </Alert>
        </Snackbar>
      </Grid>
    </>
  )
}
