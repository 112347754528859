import { createContext, useState } from "react";

export const DisplayMenuMobileContext = createContext();

export const DisplayMenuProvider = ({ children }) => {
    const [isFocused, setFocus] = useState(false)

    return (
        <DisplayMenuMobileContext.Provider value={{isFocused, setFocus}}>
            {children}
        </DisplayMenuMobileContext.Provider>
    );
};