import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
    padding: '24px',
    ght: 'calc(100vh - 200px)',
    position: 'relative',
  },
  input: {
    width: '100%',
  },
  autocomplete: {
    width: '100%',
  },
  imageInput: {
    margin: '20px 0',
  },
  inputContainer: {
    marginBottom: 20,
  },
  image: {
    maxWidth: '1280px',
    maxHeight: '525px',
    overflow: 'auto',
  },
  button: {
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    height: '42px',
    width: '156px',
    left: '0px',
    top: '0px',
    borderRadius: '4px',
    textTransform: 'none',
    color: '#FFF',
  },
  nextButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    height: '85%',
  },

  inputText: {
    color: '#53318A',
  },
  loadingText: {
    marginTop: '34px',
    fontStyle: 'normal',
    fontSize: '15px',
    letterSpacing: '1px',
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
  header: {
    marginBottom: '30px',
    alignItems: 'flex-start',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  color: {
    color: '#707070',
  },
  snackbar: {
    background: ' #EB5757',
    width: '50%',
    bottom: -100,
    padding: '5px 20px',
    transform: 'translateX(-50%)',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      margin: 'auto',
      width: '90%',
    },
  },
  textColor: {
    color: '#53318A',
  },
  imageContainer: {
    height: '153px',
    background: '#FAFAFA',
    border: '2px dashed #53318A',
    boxSizing: 'border-box',
    borderRadius: '7px',
    cursor: 'pointer',
  },
  imageTextUnderline: {
    fontFamily: 'Raleaway',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#53318A',
  },
  imageText: {
    fontFamily: 'Raleaway',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#53318A',
  },
}))

export { useStyles }

