import React, { useContext, useEffect, useState, useRef } from 'react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useParams } from 'react-router-dom'

import useStyles from './styles'

import CardSelectRoom from '../cards'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import STEPS from '../../../config/home_steps.json'

const SelectRoom = ({
  handleRoomChange,
  handleLotationChange,
  handleStep,
  roomId,
  handleDate,
  disableWorkstationCards,
  setDisableWorkstationCards,
}) => {
  const classes = useStyles()
  const container = useRef(null)
  const { authUser } = useContext(SessionContext)
  const firebase = useContext(FirebaseContext)
  const { areaID, date } = useParams()
  const [rooms, setRooms] = useState([])
  const [roomsAux, setRoomsAux] = useState([])
  const [selectedRoomType, setSelectedRoomType] = useState(null)
  const [loading, setLoading] = useState(true)
  const [noRooms, setNoRooms] = useState(1)
  const [favoriteRooms, setFavoriteRooms] = useState(authUser.favoriteRooms)
  const [roomsWithFavorite, setRoomsWithFavorite] = useState(
    rooms.map(room => {
      room.isFavorite = favoriteRooms.some(favoriteRoom => favoriteRoom === room.id)
      return room
    })
  )

  //Refactor this function later - getMyAllReservations gets all reservations, we need only to get selected day reservations
  useEffect(() => {
    if (disableWorkstationCards) return
    const fetchMyReservation = async () => {
      let reservations = await firebase.getMyAllReservations(authUser.uid)
      const dayReservation = reservations.filter(reserv => {
        const selectedDay = new Date(reserv.date.seconds * 1000).getDate()
        const selectedMonth = new Date(reserv.date.seconds * 1000).getMonth()
        const currentDay = new Date(Number(date)).getDate()
        const currentMonth = new Date(Number(date)).getMonth()
        return selectedDay == currentDay && selectedMonth == currentMonth
      })

      let filterByType = dayReservation.find(reservation => reservation.type === 0)
      setDisableWorkstationCards(filterByType ? true : false)
    }
    fetchMyReservation()
  }, [])

  const getRooms = async () => {
    let roomsList = await firebase.getUserRoomsByAreaId(authUser.uid, areaID)
    setRooms(roomsList)
    setRoomsAux(roomsList)
    setLoading(false)
    if (roomsList.length === 0) {
      setNoRooms(0)
    } else {
      setNoRooms(1)
    }
  }
  const handleGetRooms = () => {
    getRooms()
    handleStep(STEPS.DATE)
    handleDate(new Date(Number(date)))
  }
  useEffect(handleGetRooms, [authUser.account, date])

  const executeScroll = () => {
    container.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleFilterRooms = type => {
    if (type === selectedRoomType) {
      setRooms(roomsAux)
      setSelectedRoomType(null)
      return
    }
    !selectedRoomType && setRooms(roomsAux)
    const roomsCopy = [...roomsAux]
    setSelectedRoomType(type)
    const filteredRooms = roomsCopy.filter(room => room.type === type)
    setRooms(filteredRooms)
  }

  return (
    <Grid container spacing={2} className={classes.root} ref={container}>
      {noRooms === 1 && (
        <Grid item xs={12}>
          <Typography variant='subtitle2' className={classes.title}>
            Selecione a sala para agendamento
          </Typography>

          <Box className={classes.filterButtons}>
            <span variant='subtitle2'>Ordenar por: </span>
            <Button
              variant={selectedRoomType === 0 ? 'contained' : 'outlined'}
              color='primary'
              className={classes.filterBtn}
              onClick={() => handleFilterRooms(0)}
            >
              Estação de Trabalho
            </Button>

            <Button
              variant={selectedRoomType === 1 ? 'contained' : 'outlined'}
              color='primary'
              className={classes.filterBtn}
              onClick={() => handleFilterRooms(1)}
            >
              Sala de Reunião
            </Button>
          </Box>
        </Grid>
      )}
      {noRooms === 0 ? (
        <Typography variant='h4'>Nenhuma sala encontrada!</Typography>
      ) : loading ? (
        Array.from(new Array(3))
      ) : (
        rooms
          .sort((a, b) => b.isFavorite - a.isFavorite)
          .sort((a, b) => (disableWorkstationCards ? b.type - a.type : null))
          .map((room, index) => (
            <Grid key={index} item xs={6} sm={6} lg={3} md={3}>
              {noRooms === 0 ? (
                <Typography variant='h4'>Nenhuma sala encontrada! </Typography>
              ) : room ? (
                <CardSelectRoom
                  room={room}
                  date={date}
                  handleRoomChange={handleRoomChange}
                  handleLotationChange={handleLotationChange}
                  roomSelected={roomId}
                  favoriteRooms={favoriteRooms}
                  setFavoriteRooms={setFavoriteRooms}
                  roomsWithFavorite={roomsWithFavorite}
                  setRoomsWithFavorite={setRoomsWithFavorite}
                  executeScroll={executeScroll}
                  disableWorkstationCards={disableWorkstationCards}
                />
              ) : (
                <Skeleton variant='rect' width={'100%'} height={'80%'} style={{ borderRadius: '4px' }} />
              )}
            </Grid>
          ))
      )}
    </Grid>
  )
}

export default SelectRoom
