import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { InputBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: 11,
  },
  paper: {
    width: 'fit-content',
    alignItems: 'center',
    position: 'absolute',
    top: 110,
    left: 22,
    zIndex: 13,
  },
  button: {
    maxWidth: '36px',
    minWidth: '36px',
    color: '#474747',
    borderRadius: 0,

    "&:first-child": {
      marginRight: 10
    }
  }
}));

export const ZoomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#Fff',
    minWidth: 20,
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(InputBase);

export default useStyles;
