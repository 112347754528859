import { Image, Group, Text } from 'react-konva'
import useImage from 'use-image'

const URLImage = ({ image, position, element, selectedElement, setSelectedElement, handleDragEnd, width, height }) => {
  const [img] = useImage(image)
  const setTextLocation = () => {
    if (!element.workstation) return { textX: null, textY: null, textRotation: null }
    let x, y, rotation, upsideDown
    switch (element.textLocation) {
      case 0:
        upsideDown = element.rotation > 90 && element.rotation < 270 ? true : false
        x = upsideDown ? element.width : 0
        y = element.height + (upsideDown ? 12 : 2)
        rotation = upsideDown ? 180 : 0
        break
      case 1:
        upsideDown = (element.rotation + 180) % 360 > 90 && (element.rotation + 180) % 360 < 270 ? true : false
        x = upsideDown ? 0 : element.width
        y = upsideDown ? -12 : -2
        rotation = upsideDown ? 0 : 180
        break
      case 2:
        upsideDown = (element.rotation + 90) % 360 > 90 && (element.rotation + 90) % 360 < 270 ? true : false
        x = upsideDown ? element.width + 5 : element.width + 12
        y = upsideDown ? element.height : 0
        rotation = upsideDown ? 270 : 90
        break
      case 3:
        upsideDown = (element.rotation + 270) % 360 > 90 && (element.rotation + 270) % 360 < 270 ? true : false
        x = upsideDown ? -5 : -12
        y = upsideDown ? 0 : element.height
        rotation = upsideDown ? 90 : 270
        break
    }
    return { textX: x, textY: y, textRotation: rotation }
  }

  const { textX, textY, textRotation } = setTextLocation()

  return (
    <Group
      onClick={() => (selectedElement.id === element.id ? setSelectedElement('') : setSelectedElement(element))}
      width={width}
      height={height}
      x={position.x}
      y={position.y}
      onDragStart={() => setSelectedElement('')}
      onDragEnd={e => handleDragEnd(e, element.id)}
      draggable
      offset={{ x: width / 2, y: height / 2 }}
      rotation={element.rotation}
    >
      <Image
        image={img}
        stroke={selectedElement.id === element.id ? '#FF6666' : 'black'}
        strokeWidth={selectedElement.id === element.id ? 2 : 0}
        width={width}
        height={height}
      />
      {element.workstation && typeof element.textLocation === 'number' && (
        <Text
          text={`BLOCO ${element.index}`}
          width={element.textLocation <= 1 ? width : height}
          y={textY}
          x={textX}
          rotation={textRotation}
          align='center'
          fontSize={12}
          fontWeight='bold'
        />
      )}
    </Group>
  )
}

const Element = ({
  elementImage,
  position,
  handleElement,
  selectedElement,
  setSelectedElement,
  element,
  allElements,
  elementIndex,
  handleDragEnd,
  width,
  height,
}) => {
  return (
    <>
      <URLImage
        image={elementImage}
        position={position}
        handlElementClick={handleElement}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        element={element}
        allElements={allElements}
        elementIndex={elementIndex}
        handleDragEnd={handleDragEnd}
        width={width}
        height={height}
      />
    </>
  )
}
export default Element
