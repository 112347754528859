import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  
  image: {
    marginTop: theme.spacing(5),
    alignSelf: 'center',
    height: '32vh',
    
    display: 'inline',
    
  },
  boxStyle:{
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    textAlign:'center',
    justifyContent:'center',
    p: 1,
    m: 1,
  },
  typographyBold: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  typographyMessage: {
    marginTop: theme.spacing(3),
  },
  
}))

export default useStyles
