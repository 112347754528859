import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import config from '../../config/firebase.json'
import PERMISSION from '../../config/permission.json'
import RESERVATIONSTATUS from '../../config/reservations-status.json'
import { getMonthRange, getDateRange, monthYearToDateName } from '../../utils/date-manipulation'
import { verifyCheckin } from '../../utils/checkin'
import { getDataFromDocuments } from '../../utils/firestore-utils'
import { getRoomCapacity } from '../../utils/checkin'
import { codeSeparator } from '../../utils/string-manipulation'
import Resizer from 'react-image-file-resizer'
class Firebase {
  constructor() {
    this.dev = process.env.NODE_ENV === 'development'
    firebase.initializeApp(config)

    this.provider = new firebase.auth.OAuthProvider('microsoft.com')
    this.provider.addScope('email')
    this.provider.setCustomParameters({
      hd: 'enforcegroup.com.br',
    })
    this.auth = firebase.auth()
    this.firestore = firebase.firestore()
    this.storage = firebase.storage()
    this.functions = firebase.functions()
    this.timestamp = firebase.firestore.Timestamp
    this.rooms = firebase.firestore().collection(this.dev ? 'dev_rooms' : 'rooms')
    this.users = firebase.firestore().collection(this.dev ? 'dev_users' : 'users')
    this.reservations = firebase.firestore().collection(this.dev ? 'dev_reservations' : 'reservations')
    this.roomsAccess = firebase.firestore().collection(this.dev ? 'dev_roomsAccess' : 'roomsAccess')
    this.statistics = firebase.firestore().collection(this.dev ? 'dev_statistics' : 'statistics')
    this.areas = firebase.firestore().collection(this.dev ? 'dev_areas' : 'areas')
    this.folders = this.dev
      ? { users: 'dev_users', rooms: 'dev_rooms', areas: 'dev_areas' }
      : { users: 'users', rooms: 'rooms', areas: 'areas' }
  }

  resizeImage = async image => {
    let imageResized = await new Promise(resolve => {
      Resizer.imageFileResizer(
        image, // Is the file of the image which will resized.
        400, // Is the maxWidth of the resized new image.
        400, // Is the maxHeight of the resized new image.
        'JPEG', // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        uri => {
          resolve(uri)
        }, // Is the callBack function of the resized new image URI.
        'blob', // Is the output type of the resized new image.
        350, // Is the minWidth of the resized new image.
        350 // Is the minHeight of the resized new image.
      )
    })
    return imageResized
  }

  saveImage = async (uid, blob) =>
    (await this.storage.ref(`${this.folders.users}/${uid}/profile`).put(blob)).ref.getDownloadURL()

  saveRoomImage = async (roomNid, blob) => {
    const resized = await this.resizeImage(blob)
    return (await this.storage.ref(`${this.folders.rooms}/${roomNid}`).put(resized)).ref.getDownloadURL()
  }

  saveAreaImage = async (image, imageType) => {
    //const imageName = await image.name + new Date().getTime()
    ;(
      await this.storage.ref(`${this.folders.areas}/${image.name + new Date().getTime() + imageType}`).put(image)
    ).ref.getDownloadURL()
  }

  addUserData = (id, data) => this.user(id).set(data)

  updateUserProfile = (id, data) => this.user(id).update(data)

  getManagers = async () => {
    const managersSnap = await this.users.where('permission', '>=', PERMISSION.ROOMADMIN).get()
    return getDataFromDocuments(managersSnap.docs)
  }

  hasReservation = async (room, date, table, block) => {
    const range = getDateRange(date)
    let res
    const { docs } = await this.reservations
      .where('room', '==', room)
      //.where('table', '==', table)
      //.where('block', '==', block)
      .where('date', '>=', range.start)
      .where('date', '<=', range.end)
      .get()

    let reservations = getDataFromDocuments(docs)
    let removeCanceledReservation = reservations.filter(reservation => !reservation.cancelReason)
    res = removeCanceledReservation.filter(res => res.block && res.block === block)
    res = res.filter(res => res.table && res.table === table)
    return res.length > 0
  }

  createReservation = async data => {
    let newDocRef = this.reservations.doc()
    newDocRef.set({
      ...data,
      id: newDocRef.id,
      status: [{ type: RESERVATIONSTATUS.PENDING, date: new Date() }],
    })
  }

  getReservations = uid => this.reservations.where('user', '==', uid)

  getAllReservations = async () => {
    const { docs } = await this.reservations.get()
    return getDataFromDocuments(docs)
  }

  updateDisplayNameReservation = async (reservation, displayName) =>
    this.reservations.doc(reservation.id).update({
      ...reservation,
      userName: displayName,
    })

  updateReservations = async (reservation, newID) => {
    this.reservations.doc(reservation.id).update({
      ...reservation,
      room: newID,
    })
  }

  roomValidation = async code => {
    let valid
    const { docs } = await this.rooms.where('nid', '==', code.room).get()
    let room = await getDataFromDocuments(docs)
    if (room) {
      valid = room[0].elements.some(item => item.index == code.block && code.table > 0 && code.table <= item.places)
    }
    return valid
  }

  getTableReservationToday = async tableId => {
    const today = new Date()
    const { start, end } = getDateRange(today)
    let tableInfos = codeSeparator(tableId)

    const { docs } = await this.reservations.where('date', '>=', start).where('date', '<=', end).get()
    let reservations = getDataFromDocuments(docs)

    const isValid = await this.roomValidation(tableInfos)
    if (!isValid) return false

    reservations = reservations.filter(element => element.roomNid == tableInfos.room)
    reservations = reservations.filter(element => element.block == tableInfos.block)
    reservations = reservations.filter(element => element.table == tableInfos.table)
    reservations = reservations.filter(element => element.cancelReason == undefined)

    return reservations
  }

  getMyAllReservations = async uid => {
    const { docs } = await this.getReservations(uid).get()
    const reservations = getDataFromDocuments(docs)
    return reservations.filter(reservation => !reservation.cancelReason)
  }

  getReservationsByDate = async (userUid, range) => {
    const { docs } = await this.getReservations(userUid)
      .where('date', '>=', range.start)
      .where('date', '<=', range.end)
      .get()

    return getDataFromDocuments(docs)
  }

  getUserReservationByDate = async (userId, range) => {
    const reservations = await this.getReservationsByDate(userId, range)
    return reservations.find(reservation => reservation.type === 0)
  }

  getUserMeetingReservationByUser = async (userId, date) => {
    const range = getDateRange(new Date(parseInt(date)))
    const reservations = await this.getReservationsByDate(userId, range)

    return reservations.filter(reservation => reservation.type === 1 && !reservation.cancelReason)
  }

  getReservationsByMonth = async (userUid, date) => {
    const receivedDate = new Date(date)
    receivedDate.setDate(1)
    const range = getMonthRange(receivedDate)
    let reservations = await this.getReservationsByDate(userUid, range)
    return reservations.filter(reservation => !reservation.cancelReason)
  }

  getRoomReservationsByDay = async (room, date, setReservations, reservations, cleanup) => {
    const { start, end } = getDateRange(date)
    return (cleanup.callback = this.reservations
      .where('room', '==', room)
      .where('date', '>=', start)
      .where('date', '<=', end)
      .onSnapshot(
        async snap => {
          const snapReservations = snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
          const reservationsFilter = await this.getUsersByReservations(
            snapReservations.filter(item => !reservations.some(snapItem => snapItem.id === item.id))
          )
          const removeCanceledReservation = reservationsFilter.filter(reservation => !reservation.cancelReason)
          setReservations([...reservations, ...removeCanceledReservation])
        },
        error => {
          console.log('No reservation listner:', error)
        }
      ))
  }

  getMeetingReservationsByDay = async (room, date, setReservations, reservations, cleanup) => {
    const { start, end } = getDateRange(date)
    return (cleanup.callback = this.reservations
      .where('room', '==', room)
      .where('date', '>=', start)
      .where('date', '<=', end)
      .where('type', '==', 1)
      .onSnapshot(
        async snap => {
          const snapReservations = snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
          const reservationsFilter = await this.getUsersByReservations(
            snapReservations.filter(item => !reservations.some(snapItem => snapItem.id === item.id))
          )
          const activeReservation = reservationsFilter.filter(reservation => !reservation.cancelReason)
          setReservations([...reservations, ...activeReservation])
        },
        error => {
          console.log('No reservation listner:', error)
        }
      ))
  }
  registerCheckin = reservationId => {
    const date = new Date()

    const newStatus = [
      {
        type: RESERVATIONSTATUS.ATTENDED,
        date,
      },
    ]

    return this.reservations.doc(reservationId).update({
      status: firebase.firestore.FieldValue.arrayUnion(...newStatus),
    })
  }

  reservationNoShow = id => {
    const date = new Date()

    const newStatus = [
      {
        type: RESERVATIONSTATUS.NOSHOW,
        date,
      },
    ]
    return this.reservations.doc(id).update({
      status: firebase.firestore.FieldValue.arrayUnion(...newStatus),
    })
  }

  getMyTodayReservation = async uid => {
    const range = getDateRange(new Date())
    return await this.getReservationsByDate(uid, range)
  }

  checkin = async (reservation, checkinCode) => {
    const reserv = verifyCheckin(reservation, checkinCode)
    if (reserv) {
      await this.registerCheckin(reserv.id)
      return true
    }
    return false
  }

  verifyIfNoShow = async reservation => {
    let isFinished = false
    if (reservation.status) {
      isFinished = reservation.status.some(item => item.type === RESERVATIONSTATUS.FINISHED)
    }
    const today = new Date()
    const { date } = reservation

    if (date.getTime() < today.setHours(0, 0, 0, 0) && isFinished === false)
      await this.reservationNoShow(reservation.id)
  }

  deleteReservation = async id => this.reservations.doc(id).delete()

  cancelReservation = async (data, reason, comments) => {
    const date = new Date()
    const updatedData = {
      ...data,
      user: data.user.id ?? data.user,
      cancelReason: {
        reason,
        comments,
      },
    }

    delete updatedData.actualStatus

    await this.reservations.doc(data.id).update(updatedData)

    const newStatus = [
      {
        type: RESERVATIONSTATUS.CANCELED,
        date: date,
      },
    ]
    await this.reservations.doc(data.id).update({
      status: firebase.firestore.FieldValue.arrayUnion(...newStatus),
    })
  }

  getRooms = async userId => {
    const publicRooms = (await this.rooms.where('public', '==', true).get()).docs
    const grantedRooms = (await this.rooms.where('users', 'array-contains', userId).get()).docs
    const roomsDocs = [...publicRooms, ...grantedRooms]

    return getDataFromDocuments(roomsDocs)
  }

  getUserRoomsByAreaId = async (userId, areaID) => {
    const allRoomsByArea = getDataFromDocuments((await this.rooms.where('area', '==', areaID).get()).docs)

    const filteredRooms = allRoomsByArea.filter(room => {
      if (room.public) return true
      return room.users.includes(userId)
    })

    return filteredRooms
  }

  getRoom = async roomId =>
    this.rooms
      .doc(roomId)
      .get()
      .then(doc => ({ ...doc.data(), id: doc.id }))

  createRoom = async data => this.rooms.add(data)

  updateRoom = async (roomId, room, image) => {
    if (image) {
      const imageName = room.name + '_resized'
      const imageResized = await this.resizeImage(image)

      await this.storage
        .ref(`${this.folders.rooms}/${imageName}`)
        .put(imageResized)
        .catch(error => console.log('Error: ', error))

      const imageURL = await this.storage.ref(`${this.folders.rooms}/${imageName}`).getDownloadURL()

      room.pictures = [imageURL]
    }

    this.rooms.doc(roomId).update(room)
  }

  updateArea = async (areaId, data) => this.areas.doc(areaId).update(data)

  removeRoom = async roomId => {
    const doc = await this.rooms.doc(roomId).get()

    const image = this.storage.refFromURL(doc.data().pictures[0])

    image.delete()

    Promise.all([this.deleteRoomFutureReservations(roomId), this.rooms.doc(roomId).delete()])
  }

  deleteRoomFutureReservations = roomId => {
    return this.reservations
      .where('date', '>', new Date())
      .where('room', '==', roomId)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          doc.ref.delete()
        })
      })
  }

  getMonthNoShowRoomReservationReport = async (monthDate, roomID) => {
    const [year, month] = monthDate.split('-')

    const firstDay = new Date(year, month - 1, 1)

    const lastDay = new Date(year, month, 0)

    const { docs } = await this.reservations
      .where('room', '==', roomID)
      .where('date', '>=', firstDay)
      .where('date', '<=', lastDay)
      .get()
    let roomReservations = getDataFromDocuments(docs)
    let noShowRoomReservations = roomReservations.filter(room => room.status[room.status.length - 1].type === 3)
    noShowRoomReservations = noShowRoomReservations.filter(room => room.cancelReason === undefined)
    return noShowRoomReservations
  }

  getRoomLotationReal = async (roomId, date, setLotation, cleanup) => {
    const { start, end } = getDateRange(date)
    return (cleanup.callback = this.reservations
      .where('room', '==', roomId)
      .where('date', '>=', start)
      .where('date', '<=', end)
      .onSnapshot(snap => {
        const reservations = getDataFromDocuments(snap.docs)
        const removeCanceledReservation = reservations.filter(reservation => !reservation.cancelReason)
        setLotation(removeCanceledReservation.length)
      }))
  }

  getTotalCapacity = async () => {
    let rooms = await this.rooms.where('public', '==', true).get()
    let ret = 0
    rooms.forEach(room => {
      let aux = getRoomCapacity(room.data())
      ret += aux
    })
    return ret
  }

  //Funções de relatório
  getReportByMonth = async monthYear => {
    let ret = {}
    let complete = false

    await this.statistics
      .doc(monthYear)
      .collection('rooms')
      .get()
      .then(() => {
        ret = this.createReportMonth(monthYear)
      })
    return ret
  }

  getMonthReservationsReport = async monthYear => {
    let ret = {}
    await this.statistics
      .doc(monthYear)
      .get()
      .then(currentMonth => (ret = currentMonth.data()))
    return ret
  }

  createReportMonth = async monthYear => {
    let [year, month] = monthYear.split('-')
    let date = Date.parse(`${month}/01/${year}`)
    let reservations = []
    await this.getAllreservationsByMonth(date).then(reservationsDoc =>
      reservationsDoc.forEach(reservation => reservations.push({ ...reservation.data(), id: reservation.id }))
    )
    let totalDays = new Set()

    // adiciona todos os dias úteis que tem reserva no set totalDays
    reservations.forEach(x => {
      let date = new Date(x.date.seconds * 1000)
      let weekDay = date.getDay()
      if (weekDay !== 0 && weekDay !== 6) totalDays.add(date.getDate())
    })
    let content = await this.setContent(reservations, monthYear)

    let curMonth = new Date().getMonth() + 1
    let curYear = new Date().getFullYear()
    let monthData = {
      id: monthYear,
      totalCapacity: 0,
      totalReservations: 0,
      totalCheckins: 0,
      totalCanceled: 0,
      totalNoShows: 0,
      days: [],
      complete: false,
    }
    if (curYear > Number(year)) {
      monthData.complete = true
    } else if (curYear === Number(year) && curMonth > Number(month)) {
      monthData.complete = true
    }

    let daysAux = []
    let monthSize = new Date(year, month, 0).getDate()
    for (let i = 0; i < monthSize; i++) {
      daysAux.push({ reservations: 0, checkins: 0, noShows: 0, timestamp: new Date(year, month - 1, i + 1).getTime() })
    }
    for (let roomId in content) {
      monthData.totalReservations += content[roomId].totalReservations
      monthData.totalCheckins += content[roomId].totalCheckins
      monthData.totalCanceled += content[roomId].totalCanceled
      monthData.totalNoShows += content[roomId].totalNoShows
      let room = []
      await this.statistics
        .doc(monthYear)
        .collection('rooms')
        .doc(roomId)
        .get()
        .then(x => room.push(x.data()))
      for (let all in room) {
        for (let day = 0; day < room[all].days.length; day++) {
          daysAux[day].reservations += room[all].days[day].reservationsNumber
          daysAux[day].checkins += room[all].days[day].checkins
          daysAux[day].noShows += room[all].days[day].noShows
        }
      }
    }
    monthData.days = daysAux
    monthData.totalCapacity = await this.getTotalCapacity()
    let workDays = totalDays.size
    monthData.totalCapacity *= workDays
    await this.statistics.doc(monthYear).set(monthData)
    return content
  }

  getSpecificRoomReservation = async roomId => {
    const { docs } = await this.reservations.where('room', '==', roomId).get()

    return getDataFromDocuments(docs)
  }

  getMonthRoomReservationReport = async (monthDate, roomID) => {
    const [year, month] = monthDate.split('-')

    const firstDay = new Date(year, month - 1, 1)

    const lastDay = new Date(year, month, 0)

    const { docs } = await this.reservations
      .where('room', '==', roomID)
      .where('date', '>=', firstDay)
      .where('date', '<=', lastDay)
      .get()

    let roomReservations = getDataFromDocuments(docs)

    roomReservations = await Promise.all(
      roomReservations.map(async reservation => {
        const userData = await this.getUserFromReservation(reservation)

        return {
          ...reservation,

          user: userData.displayName,
        }
      })
    )

    return roomReservations
  }
  getAllMonthsReport = async (limit, monthInUrl) => {
    const monthsObject = []
    var month, year
    await this.statistics
      .where('id', '<=', monthInUrl)
      .orderBy('id', 'desc')
      .limit(limit)
      .get()
      .then(doc => {
        doc.forEach(monthWithYear => {
          ;[year, month] = monthWithYear.id.split('-').map(a => Number(a))
          monthsObject.push({
            title: monthYearToDateName(year, month),
            url:
              monthWithYear.id.length === 6
                ? monthWithYear.id.split('-').map(a => Number(a))[0] +
                  '-0' +
                  monthWithYear.id.split('-').map(a => Number(a))[1]
                : monthWithYear.id,
            monthInNumber: monthWithYear.id.split('-').map(a => Number(a))[1],
          })
        })
      })
    return monthsObject
  }

  setContent = async (reservations, monthYear) => {
    let content = {}
    let [year, month] = monthYear.split('-').map(x => Number(x))
    let firstMonthDay = monthYear + '-01'
    let date = new Date(firstMonthDay)
    let weekDay = date.getDay()

    for (let reservation of reservations) {
      let dateReservation = parseInt(new Date(reservation.date.seconds * 1000).getDate()) - 1
      let monthSize = new Date(year, month, 0).getDate()
      let today = new Date()

      let room = reservation.room

      let currentStatus = reservation.status[reservation.status.length - 1].type
      if (reservation.date.seconds * 1000 < today.setHours(0, 0, 0, 0) && currentStatus === 0) {
        await this.reservationNoShow(reservation.id)
      }
      if (!content[room]) {
        content[room] = {}
        content[room]['roomName'] = reservation.roomName
        let roomTmp = await this.getRoom(room)
        content[room]['capacity'] = getRoomCapacity(roomTmp)
        content[room].days = new Array(monthSize)
        for (let day = 0; day < monthSize; day++)
          content[room].days[day] = { reservationsNumber: 0, checkins: 0, canceled: 0, noShows: 0 }
        for (let day = 0; day < monthSize; day++) {
          if (day === dateReservation) content[room].days[day].reservationsNumber++
        }
      } else {
        content[room].days[dateReservation].reservationsNumber++
      }

      if (currentStatus === 1) content[room].days[dateReservation].checkins++
      if (currentStatus === 2) content[room].days[dateReservation].canceled++
      if (currentStatus === 3) content[room].days[dateReservation].noShows++
    }
    for (let roomId in content) {
      let totalReservations = 0
      let totalNoShows = 0
      let totalCanceled = 0
      let totalCheckins = 0
      let maxMonthCapacity = 0

      for (let day = 0; day < content[roomId].days.length; day++) {
        totalReservations += content[roomId].days[day].reservationsNumber
        totalNoShows += content[roomId].days[day].noShows
        totalCanceled += content[roomId].days[day].canceled
        totalCheckins += content[roomId].days[day].checkins
        weekDay++
        // weekDay 0 = sunday 6 = saturday
        if (weekDay !== 0 && weekDay !== 6) {
          maxMonthCapacity += content[roomId]['capacity']
        }
        if (weekDay == 6) weekDay = 0
      }
      content[roomId]['totalReservations'] = totalReservations - totalCanceled
      content[roomId]['totalNoShows'] = totalNoShows
      content[roomId]['totalCanceled'] = totalCanceled
      content[roomId]['totalCheckins'] = totalCheckins
      content[roomId]['tablesUsedByMonth'] = Math.round((content[roomId]['totalReservations'] / maxMonthCapacity) * 100)
      await this.statistics.doc(monthYear).collection('rooms').doc(roomId).set(content[roomId])
    }
    return content
  }

  getAllreservationsByMonth = async date => {
    const { start, end } = getMonthRange(date)
    let ret = []
    ret = await this.reservations.where('date', '>=', start).where('date', '<=', end).get()
    return ret
  }
  //Fim das funções de relatório

  addFavoriteRoom = roomId =>
    this.user(this.auth.currentUser.uid).update({
      favoriteRooms: firebase.firestore.FieldValue.arrayUnion(roomId),
    })
  removeFavoriteRoom = roomId =>
    this.user(this.auth.currentUser.uid).update({
      favoriteRooms: firebase.firestore.FieldValue.arrayRemove(roomId),
    })

  addFavoriteArea = areaId =>
    this.user(this.auth.currentUser.uid).update({
      favoriteAreas: firebase.firestore.FieldValue.arrayUnion(areaId),
    })
  removeFavoriteArea = areaId =>
    this.user(this.auth.currentUser.uid).update({
      favoriteAreas: firebase.firestore.FieldValue.arrayRemove(areaId),
    })

  createFavoriteAreaField = async userId => {
    this.user(userId).update({
      favoriteAreas: [],
    })
  }

  addUserToRoom = (userId, roomId, userPermission) => {
    if (this.isAdmin(userPermission)) {
      const addToRoom = this.rooms.doc(roomId).update({ users: firebase.firestore.FieldValue.arrayUnion(userId) })
      const roomToUser = this.user(userId).update({
        rooms: firebase.firestore.FieldValue.arrayUnion(roomId),
      })

      return Promise.all([addToRoom, roomToUser])
    } else return Promise.reject('No permission !')
  }

  addNewArea = async (area, image) => {
    const imageName = area.name
    const imageResized = await this.resizeImage(image)
    const imageResizedName = area.name + '_resized'

    await this.storage
      .ref(`${this.folders.areas}/${imageName}`)
      .put(image)
      .catch(error => console.log('Error: ', error))

    await this.storage
      .ref(`${this.folders.areas}/${imageResizedName}`)
      .put(imageResized)
      .catch(error => console.log('Error: ', error))

    const imageURL = await this.storage.ref(`${this.folders.areas}/${imageName}`).getDownloadURL()
    const imageResizedURL = await this.storage.ref(`${this.folders.areas}/${imageResizedName}`).getDownloadURL()

    area.imageURL = imageURL
    area.imageResizedURL = imageResizedURL

    const finished = await this.areas.add(area).catch(error => console.log('Error: ', error))

    return finished
  }

  removeArea = async areaId => {
    const doc = await this.areas.doc(areaId).get()

    const image = this.storage.refFromURL(doc.data().imageURL)
    const imageResized = this.storage.refFromURL(doc.data().imageResizedURL)
    // delete favorite area in users
    let favoriteUsers = await this.users.where('favoriteAreas', 'array-contains', areaId).get()
    favoriteUsers = getDataFromDocuments(favoriteUsers.docs)
    favoriteUsers.map(user => {
      this.user(user.id).update({ favoriteAreas: firebase.firestore.FieldValue.arrayRemove(areaId) })
    })
    //delete future reservations
    const range = getDateRange(new Date())
    const { docs } = await this.reservations.where('area', '==', doc.data().name).where('date', '>=', range.start).get()
    let reservationsInArea = getDataFromDocuments(docs)
    reservationsInArea.map(element => {
      this.reservations.doc(element.id).delete()
    })
    //delete area image
    image.delete()
    imageResized.delete()
    //delete rooms in area
    const areaRooms = await this.getAllRoomsFromArea(areaId)
    areaRooms.map(room => {
      this.removeRoom(room.id)
    })
    this.areas.doc(areaId).delete()
  }

  getAllRoomsFromArea = async areaID => {
    const rooms = (await this.rooms.where('public', '==', true).where('area', '==', areaID).get()).docs

    return getDataFromDocuments(rooms)
  }

  getAllRooms = async () => {
    const rooms = (await this.rooms.get()).docs

    return getDataFromDocuments(rooms)
  }

  AddNewRoom = async (room, image) => {
    const imageName = room.name + '_resized'
    const imageResized = await this.resizeImage(image)

    await this.storage
      .ref(`${this.folders.rooms}/${imageName}`)
      .put(imageResized)
      .catch(error => console.log('Error: ', error))

    const imageURL = await this.storage.ref(`${this.folders.rooms}/${imageName}`).getDownloadURL()

    room.pictures.push(imageURL)

    const getRoom = await this.rooms.add(room).catch(error => console.log('Error: ', error))

    return getRoom.id
  }

  removeUserFromRoom = (userId, roomId, userPermission) => {
    if (this.isAdmin(userPermission)) {
      const removeUserFromRoom = this.rooms
        .doc(roomId)
        .update({ users: firebase.firestore.FieldValue.arrayRemove(userId) })
      const removeRoomFromUser = this.user(userId).update({
        rooms: firebase.firestore.FieldValue.arrayRemove(roomId),
      })

      return Promise.all([removeUserFromRoom, removeRoomFromUser])
    } else return Promise.reject('No permission !')
  }

  callFunction = (name, data) =>
    this.functions
      .httpsCallable(name)(data)
      .then(res => res.data)

  getUsers = async () => {
    const { docs } = await this.users.get()
    return await getDataFromDocuments(docs)
  }

  getUserByEmail = async email => {
    const { docs } = await this.users.where('email', '==', email).get()
    return await getDataFromDocuments(docs)
  }

  getUserByUid = async uid => {
    const { docs } = await this.users.where('uid', '==', uid).get()
    return await getDataFromDocuments(docs)
  }

  getIncompletedUsers = async () => {
    const { docs } = await this.users.where('isCompleted', '==', false).get()
    return await getDataFromDocuments(docs)
  }

  //pass the reservations by date
  getUserFromReservation = async reservation => {
    const user = await this.users
      .doc(reservation.user)
      .get()
      .then(x => x.data())
    return user
  }

  getUsersByReservations = async reservations => {
    const results = reservations.map(async reservation => {
      const user = await this.getUserFromReservation(reservation)
      return { ...reservation, user: { ...user, uid: reservation.user } }
    })
    return Promise.all(results)
  }

  changePermission = (authPermission, uid, permission) =>
    this.isAdmin(authPermission) ? this.user(uid).update({ permission }) : Promise.reject()

  isAdmin = userPermission => userPermission >= PERMISSION.ROOMADMIN

  getUsersByManager = async name => (await this.users.where('manager', '==', name).get()).docs.map(i => i.id)

  getAllAreas = async () => {
    const { docs } = await this.areas.get()
    return getDataFromDocuments(docs)
  }

  getRoomsByID = async id => {
    const { docs } = await this.rooms.where('area', '==', id).get()
    return getDataFromDocuments(docs)
  }

  getRoomByID = async nid => {
    const { docs } = await this.rooms.where('nid', '==', nid).get()
    return getDataFromDocuments(docs)[0]
  }

  getAreaScale = async id => {
    let data
    await this.areas
      .doc(id)
      .get()
      .then(resp => (data = resp.data()))
    return data.scale ? data.scale : 1
  }

  getAreaByRoomID = async id => {
    let areaID
    await this.rooms
      .doc(id)
      .get()
      .then(resp => resp.data() && (areaID = resp.data().area))
    if (!areaID) return
    let ret
    await this.areas
      .doc(areaID)
      .get()
      .then(resp => (ret = resp.data()))
    return ret
  }

  getRoomInfoByID = async (areaID, roomID) => {
    const rooms = await this.getRoomsByID(areaID)
    const filteredRoom = rooms.filter(room => room.nid === Number(roomID))
    return filteredRoom[0]
  }

  getRoomsAreaByID = async id => await this.rooms.where('areas', '==', id).get()

  getAreaByID = async id =>
    await this.areas
      .doc(id)
      .get()
      .then(x => x.data())

  addRoomInArea = async (id, data) => await this.areas.doc(id).collection('rooms').add(data)
  getAreaRoomsByID = async id => await (await this.areas.doc(id).collection('rooms').get()).docs.map(x => x.data())

  getPolygonFromRoom = async (areaID, roomID) => {
    const areasRoomID = await this.getAreaRoomsByID(areaID)
    if (!areasRoomID) return null
    const filterRoom = areasRoomID.filter(room => roomID === room.id)
    if (!filterRoom.length) return null
    return filterRoom[0].polygon
  }

  getAllCancelReservations = async () => {
    const { docs } = await this.reservations.get()
    const date = new Date()
    let reservations = getDataFromDocuments(docs)
    reservations = reservations.filter(
      reservation => reservation.cancelReason && reservation.status[reservation.status.length - 1].type !== 2
    )
    Promise.all(
      reservations.map(async reservation => {
        const firstStatus = reservation.status[0]
        const newStatus = [
          firstStatus,
          {
            type: RESERVATIONSTATUS.CANCELED,
            date: date,
          },
        ]
        await this.reservations.doc(reservation.id).update({
          status: newStatus,
        })
      })
    )
  }

  signIn = () => this.auth.signInWithPopup(this.provider).then(r => r.credential.accessToken)

  signInWithCustomToken = token => this.auth.signInWithCustomToken(token)

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        authUser = { ...authUser.providerData[0], uid: authUser.uid }
        const dbUser = await this.user(authUser.uid).get()
        if (dbUser.exists)
          if (dbUser.data().isCompleted !== undefined) next({ ...authUser, ...dbUser.data() })
          else next({ ...authUser, ...dbUser.data(), isCompleted: false })
        else next({ ...authUser, completed: false })
      } else fallback()
    })

  user = id => this.users.doc(id)

  signOut = () => {
    console.log('SIGN OUT')
    this.auth.signOut()
  }

  copyFirebase = async col => {
    if (!this.env) return
    const { docs } = await firebase.firestore().collection(col).get()
    const areas = getDataFromDocuments(docs)
    areas.map(area => this[col].doc(area.id).set(area))
  }

  removeUser = async uid => {
    //delete rooms permissons
    const userRooms = getDataFromDocuments((await this.rooms.where('users', 'array-contains', uid).get()).docs)
    userRooms.map(itemRoom => {
      const userId = itemRoom.users.filter(user => user != uid)
      this.updateRoom(itemRoom.id, { ...itemRoom, users: userId })
    })

    //delete future reservations
    const range = getDateRange(new Date())
    const { docs } = await this.reservations.where('user', '==', uid).where('date', '>=', range.start).get()
    let reservationsInArea = getDataFromDocuments(docs)
    reservationsInArea.map(element => {
      this.deleteReservation(element.id)
    })

    this.users.doc(uid).delete()
  }
}

export default Firebase
