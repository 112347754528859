import { Box, Button, Grid, LinearProgress, Snackbar, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Link, useHistory } from 'react-router-dom'
import ImageInfo from '../../../components/imageInfo'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import { useStyles } from './styles'

const defaultData = {
  name: '',
  description: '',
  imageURL: '',
  imageResizedURL: '',
}

export default () => {
  const classes = useStyles()
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  const [area, setArea] = useState(defaultData)
  const [image, setImage] = useState(null)
  const [nameError, setNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [loading, isLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    if (image === null) {
      setImageError(true)
      return
    }

    if (area.name === '') {
      setNameError(true)
      return
    }

    if (area.description === '') {
      setDescriptionError(true)
      return
    }

    isLoading(true)

    firebase.addNewArea(area, image).then(function (a) {
      isLoading(false)
      history.push({ pathname: `areas/${a.id}/map` })
    })
  }

  const handleChange = e => {
    const { name, value } = e.target

    if (name === 'name') {
      setNameError(false)
    }

    if (name === 'description') {
      setDescriptionError(false)
    }

    setArea({
      ...area,
      [name]: value,
    })
  }

  const handleImage = image => {
    setImageError(false)
    setImage(image)
  }

  const handleDelete = () => {
    setImage(null)
  }

  const snackBar = (
    <Box>
      <Snackbar open={showToast} autoHideDuration={6000} className={classes.snackbar}>
        <>
          <Typography variant='body1' style={{ color: 'white' }}>
            Você precisa inserir uma imagem.
          </Typography>
          <Button variant='text' onClick={() => setShowToast(false)}>
            FECHAR
          </Button>
        </>
      </Snackbar>
    </Box>
  )

  useEffect(() => {
    if (imageError) {
      setShowToast(true)
      const msgShow = setTimeout(() => {
        clearInterval(msgShow)
        setShowToast(false)
        setImageError(false)
      }, 5000)
    }
  }, [imageError])

  return (
    <Box className={classes.root}>
      <Grid container item justify='space-between' alignItems='center' className={classes.header}>
        <Grid item xs={3}>
          <Typography variant='h5'>Áreas</Typography>
          <Typography variant='body1' className={classes.color}>
            <Link to={ROUTES.AREAS} className={classes.link}>
              Áreas
            </Link>{' '}
            &gt; Nova área
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button className={classes.button} disableRipple variant='contained' color='primary' onClick={handleSubmit}>
            Avançar
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <>
          <Grid item xs={12}>
            <Typography className={classes.loadingText}>Carregando</Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </>
      ) : null}

      <Grid container spacing={3} item justify='space-between' alignItems='center' className={classes.nextButton}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Dados da área</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' color='textSecondary'>
            Nome
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.autocomplete}
            id='area-name'
            variant='outlined'
            error={nameError}
            helperText={nameError ? 'O nome não pode ficar em branco' : null}
            name='name'
            value={area.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='bod23' color='textSecondary'>
            Descrição
          </Typography>
          <TextField
            inputProps={{ className: classes.textColor }}
            className={classes.autocomplete}
            id='area-description'
            variant='outlined'
            error={descriptionError}
            helperText={descriptionError ? 'A descrição não pode ficar em branco' : null}
            name='description'
            value={area.description}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.imageInput}>
        {!image ? (
          <Grid item xs={12}>
            <Dropzone accept='image/*' onDrop={acceptedFiles => handleImage(acceptedFiles[0])}>
              {({ getRootProps, getInputProps }) => (
                <section className={classes.input}>
                  <div className={classes.input} {...getRootProps()}>
                    <input className={classes.input} {...getInputProps()} />
                    <Grid
                      className={classes.imageContainer}
                      container
                      direction='column'
                      justify='center'
                      alignItems='center'
                      item
                      xs={12}
                    >
                      <Grid item>
                        <Typography className={classes.inputText} variant='body1'>
                          Clique aqui ou arraste para selecionar documentos
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.inputText} variant='body1'>
                          (tamanho máximo: 5mb)
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        ) : (
          <ImageInfo image={image} handleDelete={handleDelete} />
        )}
      </Grid>
      {snackBar}
    </Box>
  )
}
