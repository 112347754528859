import React from 'react'
import QRCode from 'qrcode.react'
import { Typography } from '@material-ui/core'

const QrCode = ({ codigo, bloco, mesa }) => {
  const novoCodigo = codigo.split('/')

  return (
    <>
      <QRCode
        id={codigo}
        value={codigo}
        size={170}
        renderAs={'svg'}
        includeMargin={false}
        style={{ marginTop: '40px', marginBottom: 'auto'}}
      />
      <Typography>{bloco}</Typography>
      <Typography>{mesa}</Typography>
      <Typography>COD: {novoCodigo[4]}</Typography>
    </>
  )
}

export default QrCode
