import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  card_paper: {
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  mapPaper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}))

export default useStyles
