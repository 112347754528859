import { Box, Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import CardEmpty from '../../../assets/CardReservationEmpty.svg'
import useStyles from './styles'

export default ({ handleNext, date }) => {
  const classes = useStyles()

  const renderReservation = date => {  
    var now = new Date()
    var ndate = new Date(date)

    now.setHours(0, 0, 0, 0)
    ndate.setHours(0, 0, 0, 0)
    if (ndate.getTime() < now.getTime()) return -1
    else if (ndate.getTime() === now.getTime()) return 1
    else return 0
  }

  const ndate = renderReservation(date)

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.cardContent}>
        <img src={CardEmpty} alt='Não existe reservas' className={classes.image} />
        <Box>
          {ndate <= 0 ? (
            ndate < 0 ? (
              <Typography variant='h6'>Você não fez nenhuma reserva nesta data!</Typography>
            ) : (
              <Typography variant='h6'>Você ainda não tem nenhuma reserva nesta data!</Typography>
            )
          ) : (
            <Typography variant='h6'>Você ainda não tem nenhuma reserva para hoje!</Typography>
          )}
          <Typography variant='subtitle1' color='textSecondary' style={{ padding: '10px' }}>
            Não encontramos nenhuma reserva para a data selecionada, para reservar escolha uma data.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
