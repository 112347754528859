import { Box, Container, Step, StepButton, Stepper, ThemeProvider, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import * as ROUTES from '../../config/routes'
import { getRoomCapacity } from '../../utils/checkin'
import Room from '../Rooms/room/roomCard'
import CalendarView from './calendar'
import CardArea from './selectAreas/index'
import SelectRoom from './selectRoom'
import useStyles from './styles'
import { SessionContext } from '../../contexts/session'
import { FirebaseContext } from '../../contexts/firebase'
import { materialTheme } from '../../theme/stepper'

const ContentHome = () => {
  const classes = useStyles()
  const history = useHistory()
  let currentDate = new Date()
  let currentMonth = currentDate.getMonth() + 1
  const [date, changeDate] = useState(new Date())
  const [month, setMonth] = useState(currentMonth)
  const [room, setRoom] = useState([])
  const [area, setArea] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [roomId, setRoomId] = useState()
  const [isFull, setFull] = useState(false)
  const [roomName, setRoomName] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [prevParams, setPrevParams] = useState('')
  const [disableWorkstationCards, setDisableWorkstationCards] = useState(false)
  const [reservationsToday, setReservationsToday] = useState([])
  const steps = getSteps()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const all = useRef(true)
  const { authUser } = useContext(SessionContext)
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const handleMonth = month => {
    let date = new Date(month)
    setMonth(date.getMonth() + 1)
    changeDate(date)
  }

  const handleDate = date => {
    changeDate(date)
  }   

  const handleNext = params => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setIsEdit(false)
    switch (activeStep) {
      case 0:
        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/areas`,
        })
      case 1:
        params && setPrevParams(params)
        setArea(params.name)
        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/areas/${params.id}`,
        })
      case 2:
        const url = params && params.type ? (params.type === 1 ? `meeting/${params.id}` : `${params.id}`) : params.id

        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/${url}`,
          state: {
            roomData: room,
            areaName: area,
            isEdit: false,
            reservationProps: {},
          },
        })
      default:
        return 'Unknown step'
    }
  }

  const handleStep = step => () => {
    setActiveStep(step)
    setIsEdit(false)
    switch (step) {
      case 0:
        setRoomId(null)
        return history.push({
          pathname: ROUTES.HOME,
        })
      case 1:
        setRoomId(null)
        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/areas`,
        })
      case 2:
        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/areas/${prevParams.id}`,
        })
      case 3:
        return history.push({
          pathname: `${ROUTES.HOME}/${date.getTime()}/${prevParams.id}`,
          state: {
            roomData: room,
            isEdit: false,
            reservationProps: {},
          },
        })
      default:
        return 'Unknown step'
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleRoomChange = (room, lotation) => {
    setRoomId(room.id)
    setRoomName(room.name)
    setRoom(room)
    setFull(lotation >= getRoomCapacity(room))
    handleNext(room)
  }

  const handleLotationChange = (roomid, lotation) => {
    if (roomId && roomid && lotation !== undefined) {
      if (roomId.toString() === roomid.toString()) {
        setFull(lotation >= getRoomCapacity(room))
      }
    }
  }

  useEffect(() => {
    let reservationVerification = reservationsToday.find(element => element.type === 0)
    reservationVerification === undefined ? setDisableWorkstationCards(false) : setDisableWorkstationCards(true)
  }, [reservationsToday])

  const handleStepperVisibility = activeStep => {
    if (activeStep !== 0 && !isEdit) {
      if (!matches) {
        return {
          visibility: 'visible',
          backgroundColor: '#fafafa',
          height: '12%',
          padding: 0,
          marginBottom: 30,
          marginTop:10,
        }
      } else {
        return {
          visibility: 'visible',
          backgroundColor: '#fafafa',
          height: '12%',
          padding: 10,
          position: 'fixed',
          width: '100%',
          zIndex: 1,
        }
      }
    } else {
      if (matches) {
        return {
          display: 'none',
        }
      } else {
        return {
          display: 'none',
        }
      }
    }
  }

  function getSteps() {
    return ['Data', 'Área', 'Sala', 'Mesa']
  }

  const showReservationButton = activeStep => {
    if (activeStep !== 0 && !isEdit) {
      return {
        visibility: 'visible',
        backgroundColor: 'transparent',
        height: '12%',
        padding: 0,
        marginBottom: 24,
      }
    } else {
      return {
        visibility: 'hidden',
        backgroundColor: 'transparent',
      }
    }
  }

  return (
    <Container className={classes.root} maxWidth={false}>
      <Box className={classes.heightBox}>
        {activeStep === 0 ? (
          <Box></Box>
          ) : (
            <ThemeProvider theme={materialTheme}>
              <Stepper activeStep={activeStep} style={handleStepperVisibility(activeStep)}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                if (matches) {
                  return (
                    <Step key={label} {...stepProps} alternativeLabel>
                      <StepButton onClick={handleStep(index)} {...labelProps} className={classes.stepperName}>
                        {label}
                      </StepButton>
                    </Step>
                  )
                } else {
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton onClick={handleStep(index)} {...labelProps}>
                        {label}
                      </StepButton>
                    </Step>
                  )
                }
              })}
            </Stepper>
          </ThemeProvider>
        )}
      </Box>
      <Switch>
        <Route exact path={ROUTES.HOME}>
          <CalendarView
            handleNext={handleNext}
            handleDate={handleDate}
            handleMonth={handleMonth}
            month={month}
            date={date}
            handleStep={setActiveStep}
            setDisableWorkstationCards={setDisableWorkstationCards}
            reservationsToday={reservationsToday}
            setReservationsToday={setReservationsToday}
          />
        </Route>
        <Route exact path={ROUTES.HOME_AREAS}>
          <CardArea handleClick={handleNext} handleStep={setActiveStep} handleRoomID={setRoomId} />
        </Route>
        <Route exact path={ROUTES.HOME_ROOMS}>
          <Box>
            <SelectRoom
              handleRoomChange={handleRoomChange}
              handleLotationChange={handleLotationChange}
              handleStep={setActiveStep}
              roomId={roomId}
              handleNext={handleNext}
              showReservationButton={showReservationButton}
              roomName={roomName}
              handleDate={handleDate}
              isFull={isFull}
              disableWorkstationCards={disableWorkstationCards}
              setDisableWorkstationCards={setDisableWorkstationCards}
            />
          </Box>
        </Route>
        <Route exact path={ROUTES.HOME_REUNION_ROOM}>
          <Room
            handleBack={handleBack}
            handleStep={setActiveStep}
            handleLotationChange={handleLotationChange}
            handleDate={handleDate}
            step={activeStep}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
          />
        </Route>
        <Route exact path={ROUTES.HOME_ROOM}>
          <Room
            handleBack={handleBack}
            handleStep={setActiveStep}
            handleLotationChange={handleLotationChange}
            handleDate={handleDate}
            step={activeStep}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
          />
        </Route>
      </Switch>
      <Box className={classes.spacerMobile} />
    </Container>
  )
}

export default ContentHome
