import { makeStyles } from '@material-ui/core'
import {header_height} from '../../utils/variaveis.js'

/* Estilização de alguns componentes de material-ui */
const useStyles = makeStyles(theme => ({
  app_bar: {
    backgroundColor: '#333333',
    height: header_height, 
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menu_button: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    paddingRight: 0,
    height: header_height, 
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      alignSelf: 'center'
    },
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  user_section: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    marginRight: 10,
  },
  grid_image: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 55,
    },
    maxHeight: 35,
  },
  lockdesk: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      marginRight: 10,
    },
    maxLines: 1,
    fontSize: '14px',
    color: '#fff',
    marginRight: 8,
  },
  typography: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      marginRight: 30,
      color: '#fff',
    },
    fontSize: '14px',
    color: '#fff',
    marginRight: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

export default useStyles
