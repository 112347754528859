import axios from 'axios';

export default token => {
  axios.defaults.headers.common['Authorization'] = token;
  axios.defaults.baseURL = 'https://graph.microsoft.com/v1.0/me';

  const getJobTitle = axios.get().then(res => res.data.jobTitle);

  const noManager = {
    displayName: "Não definido"
  };

  const getManager = axios.get(`/manager`).then(res => res.data).catch(err => noManager);

  const managerValidation = getManager ? getManager : noManager;

  const getProfileImage = axios
    .get(`/photo/$value`, { responseType: 'blob' })
    .then(res => res.data)
    .catch(e => null);

  return Promise.all([getJobTitle, managerValidation, getProfileImage]);
};
