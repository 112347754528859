import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  roomName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hoverStar: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth:'fit-content',
    cursor: 'pointer',
    opacity: 0.75,
    padding: 0,
    display:'flex'
  },
  likeStyle: {
    width: '100%',
    justifContent: 'flex-end',
    marginBottom: theme.spacing(-6),
  },
  media: {
    height: '174px',
    width: '100%',
  },

  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      margin: '0px',
    },
  },

  description: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '240px',
    marginTop: '8px',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  ocupation: {
    width: '100%',
    bottom: 0,
    textAlign: 'right',
  },

  meeting: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: 'green',
    justifyContent: "flex-end",
    width: '100%',
    bottom: 0,
    textAlign: 'right',
  },
}));

export default useStyles;
