import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  paperMenu: {
    position: 'relative',
    height: '0',
    overflow: '0',
    margin: '0',
    padding: '0',
  },
  button: {
    position: 'absolute', 
    right: 50, 
    top: 110, 
    width: '120px', 
    height: '40px', 
    zIndex: 10, 
    fontSize: 14, 
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },
  zoomControl_container:{
    position: 'absolute',
    bottom: 65,
    right: 50,
    zIndex: 10,
  }
}))

export default useStyles
