import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
  },
  areaBoard: {
    marginTop: '105px',
    justifyContent: 'center',
    display: 'flex',
    width: '125%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#58318A',
      transition: 'background .3s',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#AE8CC2',
    },
  },
  btn: {
    margin: '26px',
    float: 'right',
  },
  btnAv: {
    width: '156px',
    height: '42px',
  },
}))
export default useStyles
