import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { cell } from './styles'

export const Row = ({ row, monthYear }) => {
  const year = monthYear.split('-')[0]
  const month = monthYear.split('-')[1].length == 2 ? monthYear.split('-')[1] : monthYear.split('-')[1].substr(1)

  const classes = cell()
  return (
    <TableRow role='checkbox' key={row[0]}>
      <TableCell align='left' padding='default' className={classes.row}>
        {`${month}/${year}`}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].roomName}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].totalReservations}
      </TableCell>
      <TableCell align='left' padding='default' className={classes.row}>
        {row[1].totalCanceled}
      </TableCell>
    </TableRow>
  )
}
export default Row
