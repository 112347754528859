import { makeStyles, MenuItem, Menu, withStyles } from '@material-ui/core';

const modalStyle = makeStyles(theme => ({
  paper_modal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid white',
    borderRadius: '5px',
    minWidth: '450px',
    minHeight: '433px',
  },
  box_top: {
    display: 'flex',
    padding: '6px',
  },
  title_text: {
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
    marginLeft: '54px',
    fontWeight: 'normal',
    fontSize: '17px',
  },
  button_close: {
    marginBottom: '24px',
    '&:hover': {
      background: 'none',
    },
  },
  icon: {
    marginLeft: 'auto',
    color: '#53318a',
    '&:hover': {
      marginLeft: 'none',
      border: '1px solid',
      borderRadius: '20px',
      background: '#A593C1',
      color: 'white',
    },
  },
  form_control: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  radio_group: {
    height: '100%',
    marginLeft: '-150px',
  },
  user_text: {
    color: '#969696',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'normal',
    marginBottom: '30px',
    marginTop: '-10px',
  },
  box_bottom: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button_cancel: {
    marginTop: '10px',
    color: '#53318A',
    width: '303px',
    height: '50px',
    borderRadius: '4px',
    textTransform: 'Capitalize',
  },
  button_confirm: {
    width: '303px',
    height: '50px',
    borderRadius: '4px',
    textTransform: 'Capitalize',
    '&:hover': {
      backgroundColor: '#3A1A70',
    },
  },
  radio: {
    '&:checked': {
      color: '#f50057',
    },
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
    heigth: '100%',
  },
  table_cell: {
    paddingLeft: '16px',
  },
  button: {
    '&:hover': {
      backgroundColor: '#835cbb',
      borderColor: '#835cbb',
      boxShadow: 'none',
    },
    backgroundColor: '#53318a',
    textTransform: 'none',
    marginLeft: 22,
  },
  gridTable: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    background: '#FFFFFF',
    border: '1px solid #EEEEEE',
  },
  table: {
    minWidth: 750,
  },
  container: {
    height: '85%',
  },
  title: {
    color: '#34313F',

    flex: '1 1 100%',
    margin: '0 0 20px',
  },
  titleGrid: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  box_lastRow: {
    display: 'flex',
    height: '15%',
    width: '100%',
    alignItems: 'flex-start',
  },
  box_half: {
    width: '50%',
  },
  box_button: {
    paddingTop: '8px',
  },
}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: '#707070',
    '&:focus': {
      backgroundColor: '#fff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      background: '#53318a',
      color: '#fff',
      '& svg': {
        color: '#fff',
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles(() => ({
  paper: {
    border: '1px solid #d3d4d5',
  },
}))(Menu);

function getModalPosition() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export { useStyles, modalStyle, getModalPosition, StyledMenu, StyledMenuItem };
