import { Box, Grid, IconButton, Paper, Tooltip } from '@material-ui/core'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import { useEffect, useState } from 'react'
import { ReactComponent as DoorIcon } from '../../../../assets/dooricon.svg'
import { ReactComponent as TableIcon } from '../../../../assets/editRoom/table.svg'
import { ReactComponent as FlowerIcon } from '../../../../assets/flower.svg'
import furnitures from './model'
import useStyles from './styles'
import Tools from './tools'

const MenuBar = ({
  selectedCategory,
  setSelectedCategory,
  menuIndex,
  setMenuIndex,
  onToolChanged,
  setElements,
  elements,
  dragImageRef,
  scale,
  setScale,
  nextAction,
  setSelectedElement,
  setBlocks,
  setPlaces,
  places,
  blocks,
  setLine,
  setPoints,
  roomType,
}) => {
  const classes = useStyles({ selectedCategory })
  const [showInput, setShowInput] = useState(false)
  const [newScale, setNewScale] = useState(0)

  useEffect(() => {
    setNewScale(scale)
  }, [scale])

  const getSelectedCategory = category => {
    if (showInput) setShowInput(false)
    if (selectedCategory === category) {
      setSelectedCategory(null)
      setMenuIndex(null)
      return
    }
    switch (category) {
      case 1:
        setSelectedCategory(1)
        setMenuIndex(null)
        break
      case 2:
        setSelectedCategory(2)
        setMenuIndex(null)
        break
      case 3:
        setSelectedCategory(3)
        setMenuIndex(null)
        break
    }
  }
  const onConfirmScale = () => {
    if (newScale < 0) return setScale(1)
    return newScale > 15 ? setScale(15) : setScale(newScale)
  }
  const handleShowInputScale = () => {
    setSelectedCategory(null)
    setMenuIndex(null)
    setShowInput(!showInput)
  }

  const handleClose = () => {
    setSelectedCategory('')
    setMenuIndex(null)
  }

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={2} className={classes.selectMenuContainer}>
        <Paper className={classes.selectMenu}>
          <Tooltip title='Alvenaria'>
            <IconButton onClick={() => getSelectedCategory(1)} className={classes.button} size='small'>
              <DoorIcon fill={selectedCategory - 1 === 0 ? '#53318A' : '#474747'} transform='scale(1.2)' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Mesas'>
            <IconButton onClick={() => getSelectedCategory(2)} className={classes.button} size='small'>
              <TableIcon fill={selectedCategory - 1 === 1 ? '#53318A' : '#474747'} transform='scale(1.2)' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Decoração'>
            <IconButton onClick={() => getSelectedCategory(3)} className={classes.button} size='small'>
              <FlowerIcon fill={selectedCategory - 1 === 2 ? '#53318A' : '#474747'} transform='scale(1.2)' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Escala'>
            <IconButton onClick={handleShowInputScale} className={classes.button} size='small'>
              <ShowChartIcon fill={showInput ? '#53318A' : '#474747'} transform='scale(1.2)' />
            </IconButton>
          </Tooltip>         
        </Paper>
      </Grid>
      <Grid item xs={menuIndex ? 8 : 5} className={classes.toolsContainer}>
        <Tools
          selectedCategory={selectedCategory}
          tools={furnitures}
          handleClose={handleClose}
          menuIndex={menuIndex}
          setMenuIndex={setMenuIndex}
          onToolChanged={onToolChanged}
          dragImageRef={dragImageRef}
          elements={elements}
          setElements={setElements}
          nextAction={nextAction}
          setBlocks={setBlocks}
          blocks={blocks}
          setPlaces={setPlaces}
          places={places}
          setSelectedElement={setSelectedElement}
          roomType={roomType}
          setLine={setLine}
          setPoints={setPoints}
        />
      </Grid>
      {showInput && (
        <Box className={classes.scaleContainer}>
          <input type='number' value={newScale} onChange={e => setNewScale(e.target.value)} step='0.5' max={15} />
          <button onClick={onConfirmScale}>confirmar</button>
        </Box>
      )}
    </Grid>
  )
}

export default MenuBar
