import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  Grid,
  Drawer,
  Hidden,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'

import { SessionContext } from '../../contexts/session'
import pathsConfig from '../../config/paths-config'
import { useStyles, LightTooltip } from './styles'

const defaultProps = {
  border: 1,
}

export default ({ container, theme, mobileOpen, handleDrawerToggle, renderIcons, handleListItemClick }) => {
  const classes = useStyles()
  const { authUser } = useContext(SessionContext)
  const [open, setOpen] = useState(true)

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))

  const handleDrawerClose = () => {
    setOpen(!open)
  }

  const handleToolTip = (menu, index) => {
    if (open) {
      return <ListItemIcon>{renderIcons[index]}</ListItemIcon>
    } else {
      return (
        <LightTooltip title={menu.name}>
          <ListItemIcon>{renderIcons[index]}</ListItemIcon>
        </LightTooltip>
      )
    }
  }

  const itemsMenu = pathsConfig
    .filter(item => item.permission <= authUser.permission && item.onMenu && !(!item.onMobile && isMobile))
    .map((menu, index) => (
      <ListItem
        key={menu.name}
        classes={{ root: classes.listItem }}
        button
        component={NavLink}
        to={menu.route}
        activeClassName={`${classes.isSelected} ${classes.isIcon}`}
        onClick={() => handleListItemClick()}
      >
        <Box component='span' className='menuItem'>
          {handleToolTip(menu, index)}
          <Typography variant='subtitle1' color='inherit' className={classes.typo}>
            {menu.name}
          </Typography>
        </Box>
      </ListItem>
    ))

  const appDrawer = (
    <Box className={classes.menuContainer}>
      <Box>
        <List disablePadding={true} componente='nav' classes={{ root: classes.root }}>
          {itemsMenu}
        </List>
      </Box>
    </Box>
  )

  return (
    <Box className={classes.root}>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          container={container}
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawer_paper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {appDrawer}
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Grid container direction='column' justify='space-between' style={{ flex: 1 }}>
            <Box className={classes.boxSpace} />
            {appDrawer}
            <Grid item xs={3} style={{ flex: 1 }}>
              <Box className={classes.box_icon} />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Box>
  )
}
