import { Button, Grid, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import useStyles from './styles'
import RoomsTable from './table'

export default ({}) => {
  const [area, setArea] = useState()
  const { areaID } = useParams()
  const location = useLocation()
  const firebase = useContext(FirebaseContext)
  const classes = useStyles()
  const [roomIds, setRoomIds] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (location.state) {
      setArea(location.state.area)
    } else {
      firebase.getAreaByID(areaID).then(res => {
        setArea(res)
      })
    }
  }, [])

  const handleRooms = () => {
    history.push({
      pathname: `${areaID}/newroom`,
      state: {
        roomIds: roomIds,
        areaID: areaID,
        isEdit: false,
        isDuplicate: false,
      },
    })
  }
  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <Grid container item justify='space-between' alignItems='center' className={classes.header}>
        <Grid item xs={3} className={classes.titleGrid}>
          <Typography variant='h5'>{area && area.name}</Typography>
          <Typography variant='body1' className={classes.color}>
            <Link to={ROUTES.AREAS} className={classes.link}>
              Áreas
            </Link>{' '}
            &gt; Salas
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button className={classes.button} variant='contained' color='primary' onClick={handleRooms}>
              {' '}
              Nova Sala
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RoomsTable areaID={areaID} setRoomIds={setRoomIds} roomIds={roomIds} areaName={area}/>
      </Grid>
    </Grid>
  )
}
