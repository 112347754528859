import { makeStyles } from '@material-ui/core';

const mobileSize = 'sm';

const useStyles = makeStyles(theme => ({
  boxStyle: {
    alignSelf: 'center',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
  },
  paperStyle: {
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '460px',
    [theme.breakpoints.down(mobileSize)]: {
      padding: theme.spacing(2),
      width: '95%',
    },
  },
  sucessoStyle: {
    alignSelf: 'center',
    marginBottom: '15px',
  },
  close: {
    marginTop: '-24px',
    marginRight: '-24px',
    padding: '5px',
    alignSelf: 'flex-end',
    [theme.breakpoints.down(mobileSize)]: {
      marginTop: '0',
      marginRight: '0',
    },
  },
  boxButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    width: '100%',
  },
  infoModal: {
    marginTop: '15px',
    [theme.breakpoints.down(mobileSize)]: {
      fontSize: '0.8rem',
    },
  },
  titleModal: {
    [theme.breakpoints.down(mobileSize)]: {
      fontSize: '1rem',
    },
  },
  buttonStyle: {
    marginTop: '10px',
    marginBottom: '0px',
    width: '100%',
    fontSize: '13px',
    height: '43px',
    padding: '10px',
    textTransform: 'none',
  },
}));

export default useStyles;
