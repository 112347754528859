import { makeStyles, MenuItem, Menu, withStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
    heigth: '100%',
  },
  table_cell: {
    paddingLeft: '16px',
  },
  button: {
    '&:hover': {
      backgroundColor: '#835cbb',
      borderColor: '#835cbb',
      boxShadow: 'none',
    },
    backgroundColor: '#53318a',
    textTransform: 'none',
    marginLeft: 22,
  },
  gridTable: {
    width: '100%',
  },
  checkIn: {
    width: '150px',
    height: '23px',
    color: '#27A163',
  },
  noShow: {
    width: '150px',
    height: '23px',
    color: '#FF6C6C',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    height: '85%',
  },
  title: {
    flex: '1 1 100%',
    margin: '0 0 20px',
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  box_lastRow: {
    display: 'flex',
    height: '15%',
    width: '100%',
    alignItems: 'flex-start',
  },
  box_half: {
    width: '50%',
  },
  box_button: {
    paddingTop: '8px',
  },
}));

export { useStyles };
