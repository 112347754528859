import PERMISSION from './permission.json';
import * as ROUTES from './routes';
export default [
  {
    name: 'Home',
    route: ROUTES.HOME,
    permission: PERMISSION.USER,
    onMenu: true,
    noMenu: false,
    onMobile: true,
  },
  {
    name: 'Check-In',
    route: ROUTES.CHECKIN,
    permission: PERMISSION.USER,
    onMenu: true,
    noMenu: false,
    onMobile: true,
  },
  {
    name: 'Check-In',
    route: ROUTES.DIRECT_CHECKIN,
    permission: PERMISSION.USER,
    onMenu: false,
    noMenu: false,
    onMobile: true,
  },
  {
    name: 'Profile',
    route: ROUTES.PROFILE,
    permission: PERMISSION.USER,
    onMenu: false,
    noMenu: false,
    onMobile: true,
  },
  {
    name: 'Sala',
    route: ROUTES.ROOM,
    permission: PERMISSION.USER,
    onMenu: false,
    noMenu: true,
    onMobile: true,
  },
  {
    name: 'Edição de sala formulário',
    route: ROUTES.EDIT_ROOM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Edição de sala no mapa',
    route: ROUTES.AREA_EDIT_ROOM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: true,
    onMobile: false,
  },
  {
    name: 'Edição de sala no editor',
    route: ROUTES.ROOM_EDITOR,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: true,
    onMobile: false,
  },
  {
    name: 'Salas',
    route: ROUTES.ROOMS,
    permission: PERMISSION.ROOMADMIN,
    onMenu: true,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Áreas',
    route: ROUTES.AREAS,
    permission: PERMISSION.ROOMADMIN,
    onMenu: true,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Mapa da Área',
    route: ROUTES.AREAS_MAP,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: true,
    onMobile: false,
  },
  {
    name: 'Nova Áreas',
    route: ROUTES.NEWAREA,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Área Interna',
    route: ROUTES.AREAS_ROOMS,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Nova Sala',
    route: ROUTES.AREAS_NEWROOM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: true,
    onMobile: false,
  },
  {
    name: 'Nova Sala Formulário',
    route: ROUTES.AREAS_NEWROOM_FORM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Editar salas',
    route: ROUTES.ROOMEDITOR,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Salvar sala',
    route: ROUTES.SAVEROOM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
  },
  {
    name: 'Salvar sala',
    route: ROUTES.SAVEROOM,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
  },
  {
    name: 'Usuários',
    route: ROUTES.USERS,
    permission: PERMISSION.USERADMIN,
    onMenu: true,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Relatórios',
    route: ROUTES.REPORT,
    permission: PERMISSION.ROOMADMIN,
    onMenu: true,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Histórico',
    route: ROUTES.USER_HISTORY,
    permission: PERMISSION.USER,
    onMenu: true,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Acesso de usuários',
    route: ROUTES.USERACESS,
    permission: PERMISSION.USERADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Imprimir sala',
    route: ROUTES.PRINT,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: true,
    onMobile: false,
  },
  {
    name: 'Relatório Mensal por sala',
    route: ROUTES.REPORT_ROOMS_LIST,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Relatório Mensal por sala de Ocupação',
    route: ROUTES.REPORT_ROOMS_OCUPATION,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Relatório Mensal por sala de No-show',
    route: ROUTES.REPORT_ROOMS_NOSHOW,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Relatório Detalhado por sala de No-show',
    route: ROUTES.REPORT_ROOMS_NOSHOW_DETAILS,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Edição de Área',
    route: ROUTES.AREAS_EDIT,
    permission: PERMISSION.ROOMADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },
  {
    name: 'Scripts',
    route: ROUTES.SCRIPTS,
    permission: PERMISSION.USERADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  }, {
    name: 'Room Ocuppation',
    route: ROUTES.REPORT_SINGLE_ROOM_OCUPPATION,
    permission: PERMISSION.USERADMIN,
    onMenu: false,
    noMenu: false,
    onMobile: false,
  },

];
