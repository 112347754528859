import React, { useState, useEffect, useContext, useCallback } from 'react'
import useStyles from './styles'
import { Grid, Typography, Button } from '@material-ui/core'
import RoomsTableList from './roomsTable'
import * as XLSX from 'xlsx'
import { FirebaseContext } from '../../../contexts/firebase'
import { getCurrentMonth } from '../../../utils/date-manipulation'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import CustomFilter from '../../../components/customFilter'

const ReportRoomsList = () => {
  const classes = useStyles()
  const params = useParams()
  const firebase = useContext(FirebaseContext)
  const [rooms, setRooms] = useState([])
  const [roomsAux, setRoomAux] = useState([])
  const [filteredRoom, setFilteredRoom] = useState([])
  const [, month] = params.month.split('-').map(a => Number(a))
  const [allMonths, setAllMonths] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({
    date: '',
    room: '',
  })
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const valid = month > 0 && month <= 12
  //Limit of months to show in filter (current + past months)
  const amountShowMonths = 12

  useEffect(() => {
    firebase.getAllMonthsReport(amountShowMonths, getCurrentMonth()).then(ret => {
      setAllMonths(ret.sort((a, b) => b.monthInNumber - a.monthInNumber))
      ret.forEach(listMonths => {
        if (listMonths.monthInNumber === month) {
          setSelectedFilter({ ...selectedFilter, date: listMonths.title })
        }
      })
    })
  }, [firebase, month])

  useEffect(() => {
    if (valid) {
      firebase.getReportByMonth(params.month).then(ret => {
        firebase.getReportByMonth(params.month).then(ret => {
          setRoomAux(Object.entries(ret))
          setRooms(Object.entries(ret))
          setLoading(false)
        })
      })
    }
  }, [params.month, firebase, valid])

  const handleFilter = (_, value, name) => {
    setSelectedFilter({ ...selectedFilter, [name]: value })
  }

  const handleMonths = () => {
    const monthData = allMonths.find(month => month.title === selectedFilter.date)
    return monthData
  }

  const allRooms = useCallback(() => {
    const room = rooms?.map(room => room[1].roomName)
    return room
  }, [rooms])

  const months = useCallback(() => {
    const month = allMonths?.map(month => month.title)
    return month
  }, [roomsAux])

  useEffect(() => {
    let roomCopy = roomsAux
    if (selectedFilter.date) {
      const newMonth = handleMonths()
      if (newMonth.monthInNumber !== month) {
        setRooms([])
        setRoomAux([])
        setLoading(true)
        history.push({
          pathname: newMonth.url,
        })
      }
    }
    if (selectedFilter.room) {
      roomCopy = roomCopy.filter(room => {
        return room[1].roomName === selectedFilter.room
      })
    }

    setRooms(roomCopy)
  }, [selectedFilter])

  const fields = [
    {
      name: 'date',
      label: 'Mês',
      options: months(),
      value: selectedFilter.date,
      onChange: handleFilter,
    },
    {
      name: 'room',
      label: 'Salas',
      options: allRooms(),
      value: selectedFilter.room,
      onChange: handleFilter,
    },
  ]

  const downloadExcelFile = () => {
    const wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test')
    let ws_data = [
      ['Data', 'Sala', 'Reservas', 'Cancelados'],
      ...rooms.map(aux => [params.month, aux[1].roomName, aux[1].totalReservations, aux[1].totalCanceled]),
    ]
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test'] = ws
    XLSX.utils.book_append_sheet(wb, ws, 'Reservas e Cancelamentos')
    XLSX.writeFile(wb, 'Reservas e Cancelamentos.xlsx')
  }

  return (
    <Grid container direction='row' justify='flex-start' className={classes.root}>
      <Grid item xs={12} className={classes.divtitle}>
        <Typography variant='h5'>Reservas e Cancelamentos</Typography>
        <Typography variant='body1' className={classes.color}>
          <Link to={ROUTES.REPORT} className={classes.link}>
            {' '}
            Relatórios
          </Link>{' '}
          &gt; Reservas e Cancelamentos{' '}
        </Typography>
      </Grid>
      {rooms && (
        <Grid item xs={12} className={classes.gridButton}>
          <Button className={classes.button} variant='contained' color='primary' onClick={() => downloadExcelFile()}>
            Exportar
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomFilter fields={fields} />
      </Grid>

      <Grid item xs={12}>
        <RoomsTableList rooms={rooms} monthYear={params.month} loading={loading} />
      </Grid>
    </Grid>
  )
}

export default ReportRoomsList
