import React from 'react'
import { useHistory } from 'react-router-dom'

import { TableCell, TableRow } from '@material-ui/core'

import userPermission from '../../../utils/permission.js'

import { useStyles } from './styles'
import { ListMenu } from './rowComponents'

const Row = ({ row, isItemSelected, labelId, selected, onDataChanged, handleOpenModal, handleOpenUserModal }) => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleEditAccess = user => {
    history.push({ pathname: '/useracess', state: { user: user } })
  }

  const handleModal = row => {
    handleOpenModal(row)
    handleCloseMenu()
  }

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.displayName}
        selected={isItemSelected}
      >
        <TableCell id={labelId} component='th' scope='row' className={classes.table_cell}>
          {row.displayName}
        </TableCell>
        <TableCell align='left' className={classes.table_cell}>
          {row.email}
        </TableCell>
        <TableCell align='left' className={classes.table_cell} spacing='2'>
          {row.manager}
        </TableCell>
        <TableCell align='left' className={classes.table_cell}>
          {userPermission[row.permission]}
        </TableCell>
        <TableCell align='right'>
          <ListMenu
            anchorEl={anchorEl}
            row={row}
            onDataChanged={onDataChanged}
            selected={selected}
            handleClickMenu={handleClickMenu}
            handleCloseMenu={handleCloseMenu}
            handleEditAccess={handleEditAccess}
            handleOpenModal={handleModal}
            handleOpenUserModal={handleOpenUserModal}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
