import React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import ArrowUpIcon from '@material-ui/icons/TrendingUp'
import ArrowDownIcon from '@material-ui/icons/TrendingDown'
import LoadingCard from './Skeletons'
import useStyles from './style'

export default ({ title, value, previousMonth,isLoading }) => {
  const classes = useStyles()
  
  function percentage() {
    if(previousMonth !== 0){
      let percent = 0;
      let aux = value / previousMonth
      percent = Math.round((aux -1) *100)
      return Math.abs(percent)  
    }
  }

  return (
    isLoading ? (
      <LoadingCard isLoading={isLoading}/>
    ):(
    <Card  variant="outlined" className={classes.cardMetrics} style={{ height: '100%'}}>
      <CardContent>
        <Grid container>
          <Grid item style={{ justifyContent: 'space-between' }}>
            <Typography className={classes.cardTitle}>{title}</Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Box
          style={{
            display: 'flex',
            justifyContent:"space-between",
            alignItems:"center"
          }}
        >
          <Typography className={classes.numberData}>{value}</Typography>
          <Box
            style={{
              display:"flex",
              alignItems:"center"
            }}
          >
          {previousMonth < value && previousMonth !==0 ? (          
            <>
              <Typography
                className={classes.percentage}
                style={{
                  color: '#4caf50'
                }}
              >
                {percentage()}%
              </Typography>
              <ArrowUpIcon style={{ color: '#4caf50' }} />
            </>
          ) : previousMonth > value ? (
            <>
              <Typography
                className={classes.percentage}
                style={{
                  color: '#DD425A',
                }}
              >
                {percentage()}%
              </Typography>
              <ArrowDownIcon style={{ color: '#DD425A' }} />{' '}
            </>
          ) : (
            <></>
          )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  ))
}
