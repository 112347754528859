import { Button, Grid, Typography, CircularProgress } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import { FirebaseContext } from '../../../contexts/firebase'
import useStyles from './styles'
import RoomsTable from './table'
import { SessionContext } from '../../../contexts/session'
import { materialTheme } from '../../../theme/table'
import { ThemeProvider } from '@material-ui/styles'

export default ({}) => {
  const [rooms, setRooms] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const firebase = useContext(FirebaseContext)
  const { authUser } = useContext(SessionContext)
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      firebase
        .getAllRooms(authUser.uid)
        .then(res => {
          setRooms(res)
          setAllRooms(res)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
    fetchData()
  }, [firebase])

  const handleRooms = () => {
    history.push({
      pathname: `/newroom/form`,
      state: {
        isEdit: false,
        isDuplicate: false,
        rooms: rooms,
      },
    })
  }
  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Grid container item justify='space-between' alignItems='center'>
          <Grid item xs={3} className={classes.titleGrid}>
            <Typography variant='h5'>Salas</Typography>
          </Grid>
          <Grid item xs={3} className={classes.nextButton}>
            <Button className={classes.button} color='primary' variant='contained' onClick={handleRooms}>
              {' '}
              Nova sala
            </Button>
          </Grid>
          <Grid item xs={12} container >
              <RoomsTable rooms={rooms} allRooms={allRooms} setRooms={setRooms} loading={loading}/>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  )
}
