import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import * as ROUTES from '../../config/routes'
import { AppBar, Toolbar, IconButton, Typography, Box } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import Account from '../../pages/account/Account'
import { SessionContext } from '../../contexts/session'
import useStyles from './styles'

import {ReactComponent as LockdeskLogo} from './../../assets/lockdesk_logo.svg'

export default ({ handleDrawerToggle }) => {
  const classes = useStyles()
  const { authUser } = useContext(SessionContext)

  return (
    <AppBar position='fixed' color='inherit' className={classes.app_bar} elevation={0}>
      <Toolbar className={classes.toolbar}>
          <Link to={ROUTES.HOME} style={{ color: '#FFF', textDecoration: 'none', height: 22 }}>
            <LockdeskLogo/>
          </Link>
        <Box className={classes.grow} />
        <Box className={classes.user_section}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
