import { Divider, IconButton, ListItemIcon, TableCell, TableRow } from '@material-ui/core'
import { Create, Delete, FileCopyOutlined, MoreVert, Print, GpsFixed, TableChart } from '@material-ui/icons'
import React from 'react'
import { getRoomCapacity } from '../../../../utils/checkin'
import { useHistory } from 'react-router-dom'
import { StyledMenu, StyledMenuItem } from './styles'

const Row = ({ row, rooms, isItemSelected, labelId, setRoom, setOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = room => {
    setOpen(true)
    setRoom(room)
    handleClose()
  }

  const handleEditRoomMap = room => {
    history.push({
      pathname: window.location.pathname + `/${room.area}/editroom/${room.nid}/editor`,
      state: { isEdit: true, areaName: room.areaName },
    })
  }

  const handleEditRoom = room => {
    history.push({
      pathname: window.location.pathname + `/${room.area}/editroom/${room.nid}`,
      state: { isEdit: true, areaName: room.areaName },
    })
  }

  const handleDuplicateRoom = room => {
    history.push({
      pathname: `/saveroom`,
      state: {
        rooms: rooms,
        roomReceived: room,
        isEdit: false,
        isDuplicate: true,
      },
    })
  }

  const handlePrintRoom = room => {
    let url = `/print/${room.id}`
    history.replace({
      pathname: url,
      state: { room: room },
    })
  }

  const handleMapEdit = room => {
    history.push({
      pathname: window.location.pathname + `/${room.area}/editroom/${room.nid}/map`,
      state: { room: room },
    })
  }

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        <TableCell component='th' id={labelId} scope='row' padding='default'>
          {row.name}
        </TableCell>
        <TableCell align='left' padding='default'>
          {row.nid}
        </TableCell>
        <TableCell align='left' padding='default'>
          {row.areaName}
        </TableCell>
        <TableCell align='left' padding='default'>
          {getRoomCapacity(row)}
        </TableCell>
        <TableCell padding='default'>{row.account}</TableCell>
        <TableCell padding='default'>{row.manager}</TableCell>
        <TableCell align='left' padding='default'>
          {row.public ? 'Livre' : 'Restrita'}
        </TableCell>
        <TableCell align='right' padding='default'>
          <IconButton aria-label='expand row' size='small' onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <StyledMenu
            id='customized-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={() => handleEditRoom(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <Create fontSize='small' />
              </ListItemIcon>
              <span>Editar dados</span>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => handleMapEdit(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <GpsFixed fontSize='small' />
              </ListItemIcon>
              <span>Editar Mapa</span>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => handleEditRoomMap(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <TableChart fontSize='small' />
              </ListItemIcon>
              <span>Editar Sala</span>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => handleDuplicateRoom(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <FileCopyOutlined fontSize='small' />
              </ListItemIcon>
              <span>Duplicar Sala</span>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => handlePrintRoom(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <Print fontSize='small' />
              </ListItemIcon>
              <span>QrCode</span>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => handleOpen(row)}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <Delete fontSize='small' />
              </ListItemIcon>
              <span>Remover Sala</span>
            </StyledMenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  )
}
export default Row
