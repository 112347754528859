import React, { useState, useRef, useContext } from 'react'
import ExitModal from '../../assets/ExitModal.svg'

import { Avatar, Menu, MenuItem, IconButton, SvgIcon } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoutIcon } from '../../assets/logoutIcon.svg'
import { ReactComponent as ProfileIcon } from '../../assets/profileIcon.svg'

import { SessionContext } from '../../contexts/session'
import { FirebaseContext } from '../../contexts/firebase'
import * as ROUTES from '../../config/routes'
import useStyles from './styles'
import ShowModal from '../../components/modal'

const Account = () => {
  const ref = useRef()
  const classes = useStyles()

  const { authUser } = useContext(SessionContext)
  const firebase = useContext(FirebaseContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <>
      {authUser.photoURL ? (
        <Avatar src={authUser.photoURL} ref={ref} onClick={handleOpen} alt='Avatar' />
      ) : (
        <AccountCircleIcon ref={ref} alt='Avatar' style={{ color: 'fff' }} />
      )}
      <IconButton onClick={handleOpen}>
        <KeyboardArrowDown style={{ color: '#FAFAFA' }} />
      </IconButton>

      <Menu
        elevation={0}
        className={classes.menu}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {/* <MenuItem>Perfil</MenuItem> */}
        <Link to={ROUTES.PROFILE} onClick={handleClose} className={classes.link}>
          <MenuItem alignItems='center' className={classes.menuItem}>
            <SvgIcon component={ProfileIcon} fontSize='small' viewBox='0 0 14 14' className={classes.icon} />
            Perfil
          </MenuItem>
        </Link>
        <MenuItem
          alignItems='center'
          className={classes.menuItem}
          onClick={e => {
            setDialogOpen(true)
          }}
        >
          <SvgIcon component={LogoutIcon} fontSize='small' viewBox='-1.5 0 14 9' className={classes.icon} />
          Sair
        </MenuItem>
      </Menu>
      <ShowModal
        image={ExitModal}
        isOpen={dialogOpen}
        title='Você deseja sair?'
        message='Tem certeza que gostaria de sair?'
        mainAction={firebase.signOut}
        mainActionLabel='Sim, sair'
        handleClose={handleDialogClose}
        secondActionLabel='Não sair'
      />
    </>
  )
}

export default Account
