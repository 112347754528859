import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import DeleteModalIcon from '../../../../assets/deleteImage.svg'
import FilterComponent from '../../../../components/filterComponent/filter'
import ShowModal from '../../../../components/modal'
import { useHistory } from 'react-router-dom'
import { FirebaseContext } from '../../../../contexts/firebase'
import { materialTheme } from '../../../../theme/table'
import Row from './row'
import { useStyles } from './styles'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Sala' },
  { id: 'nid', numeric: true, disablePadding: false, label: 'ID Sala' },
  { id: 'areaName', numeric: false, disablePadding: false, label: 'Área' },
  { id: 'places', numeric: true, label: 'Lugares' },
  { id: 'account', numeric: false, label: 'Projeto' },
  { id: 'manager', numeric: false, label: 'Gerente' },
  { id: 'public', numeric: false, label: 'Tipo de sala' },
  { id: 'more', disablePadding: false, numeric: true },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visually_hidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function EnhancedTable({ rooms, allRooms, setRooms, loading }) {
  const _isMounted = useRef(true)
  const { height, ref } = useResizeDetector()
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('nid')
  const [selected, setSelected] = useState([])
  // const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [open, setOpen] = useState(false)
  const [room, setRoom] = useState({})
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 20, 30]
  const [valueFiltered, setValueFiltered] = useState()
  const [areaValueFiltered, setAreaValueFiltered] = useState()
  const [lastHeight, setLastHeight] = useState(height)

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable <= rowsPerHeight) {
          setRowsPerPage(amountDisplayInTable)
          setLastHeight(height)
        } else {
          return
        }
      }
    }

    if (lastHeight !== height && !loading) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions])

  const heightPerRow = () => {
    return Math.round(rooms)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rooms.map(n => n.sala)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const isSelected = sala => selected.indexOf(sala) !== -1

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleList = room => {
    handleClose()
    setRooms(rooms.filter(item => item.id !== room.id))
  }

  const handleRowPerMonth = e => {}

  const handleRemoveRoom = async room => {
    firebase
      .removeRoom(room.id)
      .then(handleList(room))
      .catch(error => console.log(error))
  }

  const roomFilter = (e, newSearch) => {
    setPage(0)
    setValueFiltered(newSearch)
  }

  const areaFilter = (e, newSearch) => {
    if (!newSearch) {
      setPage(0)
      setAreaValueFiltered(newSearch)
      setRooms(allRooms)
      return
    }
    const aux = rooms.filter(item => item.areaName == newSearch.areaName)
    setRooms(aux)
    setPage(0)
    setAreaValueFiltered(newSearch)
  }

  const filterValues = row => {
    if (row.name && row.account && row.manager) {
      return (
        row.name === valueFiltered.name && row.email === valueFiltered.email && row.manager === valueFiltered.manager
      )
    }
  }

  const listAutoComplete = [
    {
      title: 'Área',
      disableClear: false,
      value: areaValueFiltered ? areaValueFiltered : '',
      onChange: areaFilter,
      options: stableSort(rooms, getComparator('asc', orderBy)),
      comparator: 'areaName',
    },
    {
      title: 'Sala',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: roomFilter,
      options: stableSort(rooms, getComparator('asc', orderBy)),
      comparator: 'name',
    },
    {
      title: 'Projeto',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: roomFilter,
      options: stableSort(rooms, getComparator('asc', orderBy)),
      comparator: 'account',
    },
  ]

  return (
    <>
      <Grid container direction='row' justify='flex-start'>
        <Grid item xs={12}>
          <FilterComponent listAutoComplete={listAutoComplete} />
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          {rooms.length === 0 && !loading && (
            <>
              <Typography variant='h6' className={classes.emptyMessage}>
                Nenhuma sala encontrada{' '}
              </Typography>
            </>
          )}
          <Paper className={classes.paper} ref={ref}>
            <ThemeProvider theme={materialTheme}>
              <TableContainer className={classes.container}>
                {loading ? (
                  <Box>
                    <LinearProgress />
                  </Box>
                ) : (
                  <>
                    {rooms.length !== 0 && (
                      <>
                        <Table
                          className={classes.table}
                          aria-labelledby='tableTitle'
                          aria-label='enhanced table'
                          stickyHeader
                        >
                          <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rooms.length}
                          />

                          <TableBody>
                            {stableSort(rooms, getComparator(order, orderBy))
                              .filter(row => (valueFiltered ? filterValues(row) : row))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.name)
                                const labelId = `enhanced-table-checkbox-${index}`

                                return (
                                  <Row
                                    key={index}
                                    row={row}
                                    isItemSelected={isItemSelected}
                                    labelId={labelId}
                                    setRoom={setRoom}
                                    setOpen={setOpen}
                                    areaID={row.area}
                                    areaName={row.areaName}
                                    rooms={rooms}
                                  />
                                )
                              })}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </>
                )}
              </TableContainer>
              {rooms.length !== 0 && (
                <Box className={classes.box_lastRow}>
                  <Box className={classes.box_half}></Box>
                  <Box className={classes.box_half}>
                    <TablePagination
                      onClick={e => handleRowPerMonth(e)}
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={valueFiltered ? rooms.filter(row => filterValues(row)).length : rooms.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      labelRowsPerPage={'Linhas por página:'}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                </Box>
              )}
            </ThemeProvider>
          </Paper>
          <ShowModal
            isOpen={open}
            message='Tem certeza que deseja excluir?'
            title='Tem certeza que deseja excluir a sala cadastrada?'
            image={DeleteModalIcon}
            mainAction={() => handleRemoveRoom(room)}
            mainActionLabel='Sim, Excluir'
            secondActionLabel='Não'
            handleClose={handleClose}
          />
        </Grid>
      </Grid>
    </>
  )
}
