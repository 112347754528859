import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    overflowX: 'auto',
    marginBottom: '16px',
    paddingBottom: '10px',
    paddingTop: '10px',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#58318A',
      transition: 'background .3s',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#AE8CC2',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto',
      paddingTop: theme.spacing(10)
    },
  },
  title: {
    marginBottom: 20
  },
  filterButtons: {
    display: "flex",
    gap: "10px",
    justifyContent:'start',
    alignItems:'center'
  },
  filterBtn: {
    width: "100%",
    maxWidth: "180px",
    padding: 15,
    height: '34px',
    fontSize: 14
    
  },
  container_button: {
    width: '100%',
    display: 'flex',
    height: '15%',
    alignItems: 'flex-end'
  },
  grid_main: {
    paddingRight: '32',
  },
}));

export default useStyles;
