import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../firebase';
import getUserData from '../../utils/get-graph-data';
import PERMISSION from '../../config/permission.json';
export const SessionContext = React.createContext();
export default ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [zoomValue, setZoomValue] = useState(100);
  const firebase = useContext(FirebaseContext);

  const changeUser = data => {
    setAuthUser(data);
    setIsLoading(false);
  };

  const updateProfile = async (user, isntCompleted = false) => {
    const token = window.localStorage.getItem('graphToken');


    const [jobTitle, manager, blob] = await getUserData(token).catch(e => firebase.signOut());
    let photoURL = null;

    if (blob) photoURL = await firebase.saveImage(user.uid, blob);
    let additionalData = {};

    const { displayName } = manager;

    if (jobTitle && jobTitle.includes('GERENTE')) {
      additionalData.permission = PERMISSION.USERADMIN;
    }

    const userData = {
      jobTitle,
      manager: displayName,
      photoURL,
      email: user.email,
      uid: user.uid,
      id: user.uid,
      isCompleted: true,
      ...additionalData,
    };
    if (isntCompleted) {
      additionalData = {
        ...{
          displayName: user.displayName,
          permission: PERMISSION.USER,
          rooms: [],
          favoriteRooms: [],
          favoriteAreas: []
        },
        ...additionalData,
      };
      await firebase.addUserData(user.uid, { ...userData, ...additionalData });
    } else await firebase.updateUserProfile(user.uid, userData);
    changeUser({ ...user, ...userData });
    window.location.reload();
  };

  const hanleFirebase = () => {
    firebase.onAuthUserListener(
      async user => {
        if (!user.isCompleted) updateProfile(user, true);
        else changeUser(user);
      },
      () => changeUser(null)
    );
  };

  useEffect(hanleFirebase, []);

  return (
    <SessionContext.Provider value={{ authUser, setAuthUser, isLoading, updateProfile }}>
      {children}
    </SessionContext.Provider>
  );
};
