import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '20px',
        widows: '100%'
      },
    divtitle: {   
        marginBottom:10    
    },
   
}))

export default useStyles