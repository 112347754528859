import { makeStyles, MenuItem, Menu, withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    maxWidth: '1280px',
    padding: '24px',
  },
  gridTable: {
    width: '100%',
  },
  message: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  emptyMessage: {
    marginTop: theme.spacing(2),
  },
  emptyPaper: {
    display: 'none',
  },
  table: {
    minWidth: 750,
  },
  tableCell:{
    fontWeight: 'bold', 
    textAlign:"left",
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    height: '85%',
  },
  box_lastRow: {
    display: 'flex',
    width: '100%',
    height: '13%',
    '@media (min-height:800px)': {
      alignItems: 'flex-end',
    },
  },
  box_half: {
    width: '50%',
  },
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: '#707070',
    '&:focus': {
      backgroundColor: '#fff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      background: '#53318a',
      color: '#fff',
      '& svg': {
        color: '#fff',
      },
    },
  },
}))(MenuItem)

const StyledMenu = withStyles(() => ({
  paper: {
    border: '1px solid #d3d4d5',
  },
}))(Menu)

function getModalPosition() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export { StyledMenu, StyledMenuItem, useStyles, getModalPosition }
