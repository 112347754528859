// Offset of numbers displayed in table
export const setTextOffset = () => {
  let aux = {
    x: [
      36,
      4,
      42,
      0,
      35,
      6,
      18,
      22,
      5,
      35,
      0,
      40,
      7,
      34,
      22,
      18,
      4,
      18,
      30,
      40,
      35,
      21,
      8,
      2,
      5,
      -8,
      -2,
      16,
      35,
      46,
      42,
      23,
    ],
    y: [
      5,
      33,
      22,
      18,
      35,
      4,
      40,
      -3,
      33,
      5,
      17,
      20,
      2,
      34,
      -2,
      40,
      7,
      -3,
      3,
      17,
      30,
      39,
      35,
      23,
      40,
      22,
      3,
      -8,
      -2,
      13,
      33,
      47,
    ],
  }
  return { x: aux.x.map(a => a * 1.4), y: aux.y.map(a => a * 1.4) }
}

// Offset of icons displayed in table
export const setIconOffset = () => {
  let aux = {
    x: [
      35,
      4,
      32,
      8,
      22,
      18,
      10,
      30,
      3,
      35,
      8,
      32,
      18,
      22,
      30,
      10,
      16,
      25,
      30,
      32,
      23,
      15,
      8,
      8,
      20,
      2,
      -2,
      5,
      20,
      36,
      42,
      35,
    ],
    y: [
      15,
      20,
      27,
      7,
      33,
      2,
      30,
      5,
      18,
      15,
      7,
      28,
      0,
      32,
      5,
      28,
      5,
      5,
      13,
      24,
      28,
      28,
      22,
      11,
      38,
      30,
      15,
      0,
      -5,
      0,
      18,
      34,
    ],
  }
  return { x: aux.x.map(a => a * 1.4), y: aux.y.map(a => a * 1.4) }
}
