import { Box, Button, Grid, Paper, ThemeProvider, Typography, useMediaQuery, useTheme, Snackbar } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import STEPS from '../../../config/home_steps.json'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import { materialTheme } from '../../../theme/calendar'
import { getReservationStatus } from '../../../utils/checkin'
import CardReservation from '../reservation'
import CardReservationEmpty from '../reservationEmpty'
import useStyles, { StyledGreenBadge, StyledPurpleBadge, StyledRedBadge, StyledWhiteBadge } from './styles'
import {margin_root} from '../../../utils/variaveis.js'

const Calendar = ({
  handleNext,
  handleDate,
  handleMonth,
  month,
  date,
  handleStep,
  setDisableWorkstationCards,
  reservationsToday,
  setReservationsToday,
}) => {
  const _isMounted = useRef(true)
  const theme = useTheme()
  const classes = useStyles()
  const { authUser } = useContext(SessionContext)
  const firebase = useContext(FirebaseContext)
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [reservations, setReservations] = useState([])
  const [reservationIndex, setReservationIndex] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const fetchReservations = async () => {
      const res = await firebase.getReservationsByMonth(authUser.uid, date)

      if (_isMounted.current) {
        setReservations(
          res.map(item => {
            item.date = new Date(item.date.toMillis() - 15 * 3600000)
            item.actualStatus = getReservationStatus(item)
            item.user = authUser
            return item
          })
        )
      }
    }
    fetchReservations()
    handleStep(STEPS.CALENDAR)
  }, [month, firebase, authUser, handleStep, date])

  const hasReservation = date =>
    reservations.find(res => {
      const reservationDate = new Date(res.date).toLocaleDateString('pt-br')
      const today = new Date(date).toLocaleDateString('pt-br')
      return reservationDate === today
    })

  const handleHighlightDate = (date, dayComponent, isSelected, selectedDate) => {
    var now = new Date()
    now.setHours(0, 0, 0, 0)
    date.setHours(0, 0, 0, 0)
    selectedDate.setHours(0, 0, 0, 0)
    const hasAreservation = hasReservation(date)

    if (date.toLocaleDateString('pt-br') === now.toLocaleDateString('pt-br')) {
      if (hasAreservation) {
        if (hasAreservation.actualStatus === 1) {
          return <StyledGreenBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledGreenBadge>
        } else {
          return date.getTime() === selectedDate.getTime() ? (
            <StyledWhiteBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledWhiteBadge>
          ) : (
            <StyledPurpleBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledPurpleBadge>
          )
        }
      } else {
        return <StyledWhiteBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledWhiteBadge>
      }
    } else if (date.getTime() > now.getTime()) {
      if (date.getTime() === selectedDate.getTime()) {
        return <StyledWhiteBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledWhiteBadge>
      } else {
        return <StyledPurpleBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledPurpleBadge>
      }
    } else {
      if (hasAreservation) {
        if (hasAreservation.actualStatus === 1) {
          return <StyledGreenBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledGreenBadge>
        } else {
          return <StyledRedBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledRedBadge>
        }
      } else {
        return <StyledPurpleBadge badgeContent={isSelected ? '' : undefined}>{dayComponent}</StyledPurpleBadge>
      }
    }
  }

  const handleMaxDate = () => {
    var currentDate = new Date()
    var currentYear = currentDate.getFullYear()
    var currentMonth = currentDate.getMonth() + 1
    var maxDate = new Date(currentYear, currentMonth + 1, 0)
    return maxDate
  }

  const handleMinDate = () => {
    var minDate = new Date()
    minDate.setDate(1)
    minDate.setMonth(minDate.getMonth() - 1)
    return minDate
  }

  const handleCloseSnackBar = reason => {
    if (reason?.type === 'click') {
      return
    }
    setOpenSnackbar(false)
  }

  useEffect(() => {
    const filterReservationsToday = reservations.filter(res => {
      const reservationDate = new Date(res.date).toLocaleDateString('pt-br')
      const today = new Date(date).toLocaleDateString('pt-br')
      return reservationDate === today
    })
    setReservationsToday(filterReservationsToday)
  }, [reservations])

  const showReservation = action => {
    if (action === 'next') {
      setReservationIndex(reservationIndex + 1)
    } else {
      setReservationIndex(reservationIndex - 1)
    }
  }

  const hasAReservation = hasReservation(date)

  return (
    <Box className={classes.boxHome}> 
      <Grid container>
        <Grid item xs={12} className={classes.boxTitle}>
          <Typography className={classes.hello} variant='h2'>
            {' '}
            Olá,{' '}
            {authUser.displayName.split(' ')[0]}{' '}
            {authUser.displayName.split(' ')[authUser.displayName.split(' ').length - 1]}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} lg={7} md={12} className={classes.calendarGrid} style={{padding:`calc(${margin_root}/2)`}}>
          <Paper elevation={0} className={classes.paper}>
            <ThemeProvider theme={materialTheme}>
              <DatePicker
                autoOk
                disableToolbar
                orientation={matches ? 'portrait' : 'landscape'}
                variant='static'
                openTo='date'
                value={date}
                maxDate={handleMaxDate()}
                minDate={handleMinDate()}
                onChange={date => handleDate(date)}
                onMonthChange={date => handleMonth(date)}
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                  let isSelected = false
                  for (const reservation of reservations) {
                    reservation.date.setHours(0, 0, 0, 0)
                    day.setHours(0, 0, 0, 0)
                    if (isInCurrentMonth && reservation.date.getTime() === day.getTime()) isSelected = true
                  }

                  return handleHighlightDate(day, dayComponent, isSelected, selectedDate)
                }}
              />
            </ThemeProvider>
          </Paper>
          <Paper elevation={0} className={classes.btnPaper}>
            <Button className={classes.button} variant='contained' color='primary' onClick={handleNext}>
              Novo agendamento
            </Button>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} lg={5} md={12} item className={classes.grid} style={{padding:`calc(${margin_root}/2)`}}>
          {date ? (
            hasAReservation ? (
              <CardReservation
                reservation={hasAReservation}
                todayReservation={reservationsToday[reservationIndex]}
                reservationOnDay={reservationsToday}
                showReservation={showReservation}
                setReservations={setReservations}
                actualStatus={hasAReservation.actualStatus}
                handleStep={handleStep}
                setDisableWorkstationCards={setDisableWorkstationCards}
                setReservationIndex={setReservationIndex}
                reservationIndex={reservationIndex}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
              />
            ) : (
              <CardReservationEmpty date={date} handleNext={handleNext} handleStep={handleStep} />
            )
          ) : (
            <Paper elevation={0} className={classes.paper_reservation}>
              <Typography variant='subtitle1' className={classes.message}>
                Nenhuma data selecionada!
              </Typography>
            </Paper>
          )}
            <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={handleCloseSnackBar} className={classes.snackbar}>
              <>
                <Typography variant='body1' style={{ color: '#fff' }}>
                  Cancelamento confirmado!
                </Typography>
                <Button
                  variant='outlined'
                  style={{ color: 'white', border: 'transparent' }}
                  onClick={() => setOpenSnackbar(false)}
                >
                  <Typography variant='body1' color='inherit'>
                    Fechar
                  </Typography>
                </Button>
              </>
            </Snackbar>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calendar
