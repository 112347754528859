import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box_paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '6px',    
    [theme.breakpoints.up('md')]: {
      width:'100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginRight: '6px',
      height:'80%'
    },
  },
  box_zoom: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: '6px 0 10px 0',
    },
  },
  icon_button: {
    borderRadius: '50%',
    color: '#474747',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
}))

export default useStyles
