import { Paper, Button, Divider } from '@material-ui/core'
import { useEffect, useState } from 'react'
import useStyles from './styles'

import DeleteIcon from '@material-ui/icons/Delete'

const WallTool = ({ selectedWall, removeWall }) => {
  const classes = useStyles({ selectedWall })

  return (
    <div>
      {selectedWall && (
        <Paper
          className={classes.boxContainer}
          style={{
            position: 'absolute',
            left: `23px`,
            top: `370px`,
          }}
        >
          <Button onClick={removeWall} className={classes.button}>
            {<DeleteIcon />}
          </Button>
        </Paper>
      )}
    </div>
  )
}

export default WallTool
