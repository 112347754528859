import React, { useState, useEffect, useContext, useRef } from 'react'
import useStyles from './styles'
import NoShowPie from './charts/NoShowPie.jsx'
import OccupationPie from './charts/OccupationPie'
import ReservationsPie from './charts/ReservationsPie'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, Card, Typography } from '@material-ui/core'
import * as ROUTES from '../../config/routes'
import { Link } from 'react-router-dom'

import GraphicCard from './graphicCard/index.jsx'
import { FirebaseContext } from '../../../src/contexts/firebase'
import { getCurrentMonth, getPreviousMonth } from '../../../src/utils/date-manipulation'
import MetricsCard from './metricsCards/scheduledReservations'
import BarChart from './BarChart'

const reservationsColor = ['#53318A', '#EB2368']
const ocupationColor = ['#53318A', '#EB2368']
const noShowColor = ['#53318A', '#EB2368']

const reservation = [{ label: 'Reservadas' }, { label: 'Canceladas' }]
const ocupation = [{ label: 'Total' }, { label: 'Ocupado' }]

const noShow = [{ label: 'Check-in' }, { label: 'No show' }]

const variationValues = [
  { title: 'Mês', range: [0, 31] },
  { title: '1° Quinzena', range: [0, 15] },
  { title: '2° Quinzena', range: [15, 31] },
]

const Report = () => {
  const classes = useStyles()
  const _isMounted = useRef(true)
  const firebase = useContext(FirebaseContext)
  const [allMonths, setAllMonths] = useState([])
  const [monthReport, setMonthReport] = useState({})
  const [previousReport, setPreviousReport] = useState({})
  const [variationDay, setVariationDay] = useState([])
  const [range, setRange] = useState([0, 31])
  const paper = useRef(null)
  const graphMargin = 80
  const [rooms, setRooms] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [informationMonthsCardsLoader, setInformationMonthsCardsLoader] = useState(true)

  //Limit of months to show in filter (current + past months)
  const amountShowMonths = 12
  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth())
  const [currentDisplayMonth, setCurrentDisplayMonth] = useState(currentMonth)
  const [lastMonth, setLastMonth] = useState(getPreviousMonth())

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  function getLastMonth(year, month) {
    let pYear = parseInt(year)
    if (month === 1) {
      pYear = pYear - 1
      month = 12
    } else month = month - 1

    if (month < 10) return `${pYear}-0${month}`
    else return `${pYear}-${month}`
  }

  useEffect(() => {
    firebase.getAllMonthsReport(amountShowMonths, getCurrentMonth()).then(ret => {
      if (_isMounted.current) setAllMonths(ret)
    })
  }, [firebase])

  useEffect(() => {
    setCurrentDisplayMonth(allMonths[0])
  }, [allMonths])

  const handleMonths = (e, month) => {
    setCurrentDisplayMonth(month)
    setCurrentMonth(month.url)
    setLastMonth(getLastMonth(month.url, month.monthInNumber))
  }

  const f1 = async () => {
    setIsLoading(true)
    let roomAux = []
    await firebase.getReportByMonth(currentMonth).then(ret => {
      if (_isMounted.current) {
        Object.entries(ret).forEach(room => {
          roomAux.push(room[1])
        })
        roomAux.sort((a, b) => b?.totalReservations - a?.totalReservations)
        setRooms(roomAux)
      }
    })

    await firebase.getMonthReservationsReport(currentMonth).then(ret => {
      if (_isMounted.current) {
        setMonthReport(ret)
        setVariationDay(ret.days)
      }
    })

    await firebase.getMonthReservationsReport(lastMonth).then(ret => {
      if (_isMounted.current) {
        setPreviousReport(ret)
        setInformationMonthsCardsLoader(isLoading)
      }
    })
    if (_isMounted.current) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    f1()
  }, [firebase, currentMonth])

  const checkinsToday = () => {
    let totalCheckinsPrevious = 0
    if (previousReport && previousReport.days) {
      const day = parseInt(new Date().getDate()) - 1
      for (let i = 0; i <= day && i < previousReport.days.length; i++) {
        totalCheckinsPrevious += previousReport.days[i].checkins
      }
    }

    return (
      <MetricsCard
        isLoading={isLoading}
        title='Checkins Realizados'
        value={monthReport.totalCheckins}
        previousMonth={totalCheckinsPrevious}
      />
    )
  }

  const noShowsToday = () => {
    let totalNoShowsPrevious = 0
    if (previousReport && previousReport.days) {
      const day = parseInt(new Date().getDate()) - 1
      for (let i = 0; i <= day && i < previousReport.days.length; i++) {
        totalNoShowsPrevious += previousReport.days[i].noShows
      }
    }

    return (
      <MetricsCard
        isLoading={isLoading}
        title='No Show'
        value={monthReport.totalNoShows}
        previousMonth={totalNoShowsPrevious}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column' justify='center' alignItems='flex-start'>
        <Grid container direction='row' justify='space-between' alignItems='center' className={classes.boxTitle}>
          <Typography variant='h6'>Resumo sobre o mês</Typography>
          <Autocomplete
            autoComplete={false}
            onChange={(e, value) => handleMonths(e, value)}
            value={currentDisplayMonth || null}
            disableClearable
            id='list_of_months'
            size='small'
            options={allMonths}
            getOptionLabel={option => (option.title === undefined ? '' : option.title)}
            getOptionSelected={(option, value) => option.title === value.title}
            style={{ width: 200 }}
            renderInput={params => <TextField {...params} placeholder='Mês' variant='outlined' />}
          />
        </Grid>
        {!(monthReport.totalCapacity == 0 && !isLoading) ? (
          <>
            <Grid container className={classes.gridMetricsCards} spacing={3}>
              <Grid item xs={4}>
                <MetricsCard
                  isLoading={isLoading}
                  title='Reservas agendadas'
                  value={monthReport?.totalReservations || 0}
                  previousMonth={previousReport?.totalReservations || 0}
                />
              </Grid>
              <Grid item xs={4}>
                {monthReport.complete ? (
                  <MetricsCard
                    isLoading={isLoading}
                    title='Checkins realizados'
                    value={monthReport.totalCheckins}
                    previousMonth={previousReport.totalCheckins}
                  />
                ) : (
                  checkinsToday()
                )}
              </Grid>
              <Grid item xs={4}>
                {monthReport.complete ? (
                  <MetricsCard
                    isLoading={isLoading}
                    title='No Show'
                    value={monthReport.totalNoShows}
                    previousMonth={previousReport.totalNoShows}
                  />
                ) : (
                  noShowsToday()
                )}
              </Grid>
            </Grid>
            <br></br>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Typography variant='h6'>Variação de reservas por dia</Typography>
              <Autocomplete
                disableClearable
                id='fortnight'
                size='small'
                onChange={(event, value) => setRange(value.range)}
                defaultValue={variationValues[0]}
                options={variationValues}
                getOptionLabel={option => option.title}
                getOptionSelected={(option, value) => option.title === value.title}
                style={{ width: 200 }}
                renderInput={params => <TextField {...params} placeholder='Período' variant='outlined' />}
              />
            </Grid>
            <Card variant='outlined' className={classes.variationGraphic} ref={paper} elevation={0}>
              {!isLoading && (
                <BarChart
                  data={variationDay}
                  range={range}
                  width={paper.current ? paper.current.offsetWidth - graphMargin : 1000}
                  isLoading={isLoading}
                />
              )}
            </Card>
            <Grid container item xs={12} direction='row' className={classes.gridCards}>
              <GraphicCard
                title='Reservas'
                seeMore
                currentDisplayMonth={currentDisplayMonth}
                subTitle='Reservas e cancelamentos'
                legend={reservation}
                colors={reservationsColor}
                isLoading={isLoading}
                path={ROUTES.REPORT}
                graph={<ReservationsPie rooms={rooms} dim={[300, 300]} colors={reservationsColor} />}
              />
              <GraphicCard
                title='Ocupação'
                seeMore
                currentDisplayMonth={currentDisplayMonth}
                subTitle='Ocupação mensal do escritório'
                legend={ocupation}
                colors={ocupationColor}
                isLoading={isLoading}
                path={`${ROUTES.REPORT}/ocupation`}
                graph={
                  <OccupationPie
                    occupation={
                      monthReport.totalCapacity
                        ? Math.round((monthReport?.totalReservations / monthReport?.totalCapacity) * 100)
                        : 0
                    }
                    dim={[300, 300]}
                    colors={ocupationColor}
                  />
                }
              />
              <GraphicCard
                title='No show'
                seeMore
                subTitle='No Show Mensal'
                legend={noShow}
                colors={noShowColor}
                currentDisplayMonth={currentDisplayMonth}
                isLoading={isLoading}
                path={`${ROUTES.REPORT}/noshow`}
                graph={
                  <NoShowPie
                    perc={
                      monthReport.totalCheckins + monthReport.totalNoShows
                        ? (monthReport.totalCheckins / (monthReport.totalCheckins + monthReport.totalNoShows)) * 100
                        : 0
                    }
                    dim={[300, 300]}
                    colors={noShowColor}
                  />
                }
              />
            </Grid>
          </>
        ) : (
          <Typography variant='h5'>Nenhuma informação sobre este mês foi encontrada.</Typography>
        )}
      </Grid>
    </div>
  )
}

export default Report
