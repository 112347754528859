import { makeStyles } from '@material-ui/core';
import {font_size_h6, image_width, image_botton_margin, button_height} from '../../../utils/variaveis.js'

const useStyles = makeStyles(theme => ({
  image: {
    width: image_width,
    marginBottom: image_botton_margin,
  },
  reservation: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
    width: '100%',
    padding: '0 27px 0',
  },
  date: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 6px',
  },
  dateText:{
    textAlign: 'left', 
    paddingLeft: '15px',
    fontSize: font_size_h6,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  calendarIcon: {
    width: '34px',
    height: '34px',
    borderRadius: '100%',
    background: '#EB2368',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: '19px',
    height: '19px',
  },
  edit: {
    '&:hover ': {
      cursor: 'pointer',
    },
  },
  box:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '29px',
    height: '29px',
    borderRadius: '100%',
  },
  buttons: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    '&:hover': {
      borderColor: '#835cbb',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    width: '100%',
    height: button_height,
    textTransform: 'none',
    padding: '10px',
  },
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '95%',
    },
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 330,
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      padding: 4,
    },
  },
  imgBtn: {
    height: 30,
    width: 30
  },
  GridInfos: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: '10px 10px'
  }
}));

export { useStyles };
