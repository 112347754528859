import { useState, useRef, useEffect } from 'react'
import { IconButton, Paper } from '@material-ui/core'
import { Stage, Image, Layer, Line } from 'react-konva'
import { insertPoint, testOverlap, inside, createPoint, alignPolygon } from '../../utils/geometry'
import { Gavel } from '@material-ui/icons'

import Polygon from './polygon'

export default ({
  width,
  height,
  image,
  rooms,
  savePolygon,
  divRef,
  roomToEdit,
  isScale,
  zoom = 1,
  isDuplicated = false,
}) => {
  let [points, setPoints] = useState([])
  let [closed, setClosed] = useState(false)
  let [valid, setValid] = useState(true)
  let [pos, setPos] = useState(false)
  let [newPos, setNewPos] = useState(false)
  let [scale, setScale] = useState(1)
  const [showAlign, setShowAlign] = useState(false)
  const [duplicated, setDuplicated] = useState(false)
  let stageRef = useRef()
  let layerRef = useRef()
  let all = useRef(true)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (!all.current) return
    if (roomToEdit) {
      setClosed(true)
      setPoints(roomToEdit.polygon)
      setValid(true)
      setPos({ x: 0, y: 0 })
      setDuplicated(isDuplicated) //true
    } else if (isScale) {
      setPoints([
        { x: 50, y: 50 },
        { x: 50, y: 100 },
      ])
    }
  }, [])

  const updatePointsPosition = (x, y, index) => {
    if (!all.current) return
    let pointsAux = []
    for (let i = 0; i < points.length; i++) {
      if (i === index) {
        isScale ? pointsAux.push({ x: x, y: y }) : pointsAux.push({ x: x + pos.x, y: y + pos.y })
      } else pointsAux.push(points[i])
    }
    setPoints(pointsAux)
  }

  const updateAllPointsPosition = e => {
    if (!all.current || isScale) return
    if (e.target.children[0].children.length && e.target.children[0].children[0].className === 'Line') {
      setNewPos({ x: e.target.attrs.x, y: e.target.attrs.y })
    }
  }

  useEffect(() => {
    if (!all.current) return
    if (!closed) return
    let overlap = false
    const pointsAux = points.map(point => {
      return { x: point.x - pos.x + newPos.x, y: point.y - pos.y + newPos.y }
    })
    if (closed) {
      overlap = overlap || testOverlap(points, points)
      for (let room of rooms) {
        overlap = overlap || testOverlap(pointsAux, room.polygon)
        overlap = overlap || inside(pointsAux, room.polygon)
        overlap = overlap || inside(room.polygon, pointsAux)
      }
    }
    setValid(duplicated ? false : !overlap)
    setDuplicated(false)
  }, [closed, points, newPos])

  const getPolygon = () => {
    if (!all.current) return
    if ((!closed || !valid) && !isScale) return
    let shift = newPos ? newPos : { x: 0, y: 0 }
    return points.map(point => {
      return isScale ? { x: point.x, y: point.y } : { x: point.x - pos.x + shift.x, y: point.y - pos.y + shift.y }
    })
  }

  useEffect(() => {
    if (!all.current) return
    savePolygon(getPolygon())
  }, [pos, points, newPos, valid, closed])

  useEffect(() => {
    if (!isScale) {
      setScale(zoom)
    }
  }, [zoom])

  const handleWheel = e => {
    if (!all.current) return
    if (!e.evt.ctrlKey) return
    e.evt.preventDefault()

    const scaleBy = 0.95
    const stage = e.target.getStage()
    if (stage.getPointerPosition().x > stage.attrs.width || stage.getPointerPosition().y > stage.attrs.height) return
    const oldScale = stage.scaleX()
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale,
      y: stage.getPointerPosition().y / oldScale,
    }
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy
    setScale(newScale)

    if (divRef && divRef.current) {
      let scroll = { x: divRef.current.scrollLeft, y: divRef.current.scrollTop }

      let scrollAux = {
        x: -stage.getPointerPosition().x + mousePointTo.x * newScale + scroll.x,
        y: -stage.getPointerPosition().y + mousePointTo.y * newScale + scroll.y,
      }

      divRef.current.scrollTo({
        left: scrollAux.x,
        top: scrollAux.y,
      })
    }
  }

  return (
    <>
      <Stage
        x={0}
        y={0}
        width={width * scale}
        height={height * scale}
        ref={stageRef}
        onClick={e => {
          if (!closed && !isScale) createPoint(stageRef, scale, setPos, setNewPos, setClosed, pos, points, setPoints)
        }}
        scaleX={scale}
        scaleY={scale}
        onWheel={e => handleWheel(e)}
      >
        {!isScale ? (
          <Layer ref={layerRef}>
            <Image image={image} />
            {rooms.map((room, index) => {
              return (
                (!roomToEdit || room.id !== roomToEdit.id) && (
                  <Polygon
                    key={index}
                    points={room.polygon}
                    closed={true}
                    scale={scale}
                    id={room.nid}
                    layerRef={layerRef}
                    lineScale={false}
                  />
                )
              )
            })}
            <Polygon
              x={pos.x}
              y={pos.y}
              setShowAlign={setShowAlign}
              points={points.map(p => {
                return { x: p.x - pos.x, y: p.y - pos.y }
              })}
              closed={closed}
              updatePointsPosition={updatePointsPosition}
              updateAllPointsPosition={updateAllPointsPosition}
              insertPoint={insertPoint}
              setPoints={setPoints}
              newPos={newPos}
              scale={scale}
              valid={valid}
              lineScale={false}
            />
          </Layer>
        ) : (
          <Layer ref={layerRef}>
            <Image image={image} opacity={0.5} />
            <Polygon
              x={pos.x}
              y={pos.y}
              points={points}
              closed={false}
              updatePointsPosition={updatePointsPosition}
              updateAllPointsPosition={updateAllPointsPosition}
              insertPoint={insertPoint}
              setPoints={setPoints}
              newPos={newPos}
              scale={scale}
              valid={true}
              lineScale={true}
            />
          </Layer>
        )}
      </Stage>
      {showAlign && (
        <Paper style={{ position: 'absolute', top: 320, right: '25px' }}>
          <IconButton onClick={() => alignPolygon(points, setPoints, 0, 0)}>
            <Gavel />
          </IconButton>
        </Paper>
      )}
    </>
  )
}
