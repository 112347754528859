import { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Grid, Card, CardMedia, Typography, CardContent, Box, Button } from '@material-ui/core'

import useStyles from './styles'
import { FirebaseContext } from '../../../contexts/firebase'
import STEPS from '../../../config/home_steps.json'
import { Skeleton } from '@material-ui/lab'
import * as ROUTES from '../../../config/routes'
import { SessionContext } from '../../../contexts/session'   
import CardSelectArea from '../cardArea'


const AreaCard = ({ handleClick, handleStep, handleRoomID }) => {
  const [areas, setAreas] = useState([])
  const [loading, setLoading] = useState(true)
  const firebase = useContext(FirebaseContext)
  const classes = useStyles()
  const paramsInUrl = useParams()
  const history = useHistory()
  const { authUser } = useContext(SessionContext)
  const [favoriteAreas, setFavoriteAreas] = useState(authUser.favoriteAreas)

  const [areasWithFavorite, setAreasWithFavorite] = useState(
    areas.map(area => {
      area.isFavorite = favoriteAreas ? (favoriteAreas.some(favoriteArea => favoriteArea === area.id)) : false
      return area
    })
  )
  useEffect(() => {
    setLoading(true)
    firebase
      .getAllAreas()
      .then(resp => setAreas(resp))
      .then(() => setLoading(false))
  }, [firebase])

  //verify router
  useEffect(() => {
    var isValid = new Date(parseInt(paramsInUrl.date))

    var todayDate = new Date()
    todayDate.setSeconds(0)
    todayDate.setMilliseconds(0)
    todayDate.setMinutes(0)
    todayDate.setHours(0)

    if (isValid.getTime() < todayDate.getTime() || isNaN(isValid) || isValid.getFullYear() < todayDate.getFullYear()) {
      history.push({
        pathname: ROUTES.HOME,
      })
    }
  }, [])

  useEffect(() => {
    handleStep(STEPS.AREAS)
    handleRoomID(null)
  }, [])

  const executeScroll = () => {
    container.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Selecione a área para agendamento</Typography>
      </Grid>
      {areas && !loading ? (
         areas
         .sort((a, b) => b.isFavorite - a.isFavorite)
         .map((area, index) => (
           <Grid key={area.name + index} item xs={12} sm={12} lg={3} md={3}>
             <Card onClick={() => handleClick(area)} elevation={0}>
               <CardSelectArea
                 area={area}
                 areaSelected={area.id}
                 favoriteAreas={favoriteAreas}
                 setFavoriteAreas={setFavoriteAreas}
                 areasWithFavorite={areasWithFavorite}
                 setAreasWithFavorite={setAreasWithFavorite}
                 executeScroll={executeScroll}
               />
             </Card>
           </Grid>
         ))

      ) : (
        <Grid container>
          {Array.from(new Array(4)).map((item, index) => (
            <Grid item xs={3} key={index} width={250}>
              <Skeleton variant='rect' width={250} height={150} />
              <Box pt={2}>
                <Skeleton width='60%' />
                <Skeleton width='30%' />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default AreaCard
