import React, { useContext, useState, useEffect, useRef } from 'react'

import { Box, Container, CircularProgress, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import QrReader from 'react-qr-reader'
import { useParams } from 'react-router-dom'

import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import { getReservationStatus } from '../../../utils/checkin'
import sucesso from '../../../assets/sucesso.svg'
import error from '../../../assets/error.svg'
import checkInSVG from '../../../assets/check-in.svg'
import CheckinDisabled from '../../../assets/checkinDisabled.svg'
import alterReservation from '../../../assets/alterReservation.svg'
import newDirectReservation from '../../../assets/newDirectReservation.svg'

import FieldCheckIn from './field_check_In/index'
import CheckInDisabled from './field_check_in_disabled/index'
import { codeSeparator } from '../../../utils/string-manipulation'

import useStyles from './styles'
import ShowModal from '../../../components/modal'
import CancelReasonModal from '../../../components/CancelModals'
import { useHistory } from 'react-router-dom'

const Checkin = () => {
  const classes = useStyles()
  const history = useHistory()
  const _isMounted = useRef(true)
  const { authUser } = useContext(SessionContext)
  const firebase = useContext(FirebaseContext)
  const [reservation, setReservation] = useState()
  const [loading, setLoading] = useState(true)
  const { tableId } = useParams()
  const [code, setCode] = useState(tableId ? tableId : '')
  const [scanCode, setScanCode] = useState(null)
  const [lastError, setLastError] = useState('')
  const [status, setStatus] = useState()
  const [flag, setFlag] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [isOpenCancelReason, setOpenCancelReason] = useState(false)
  const [screenState, setScreenState] = useState(false)
  const tableinfos = codeSeparator(tableId ? tableId : code)

  const STATUS_UKNOWN = 0
  const STATUS_CHECKIN_SUCCESS = 1
  const STATUS_TABLE_NOT_AVAILABLE = 2
  const STATUS_TABLE_AVAILABLE = 3
  const STATUS_CHANGE_RESERVATION = 4
  const STATUS_CHECKIN_ERROR = 5
  const [reservationStatus, setReservationStatus] = useState(STATUS_UKNOWN)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  useEffect(() => {
    if (tableId) {
      tryDoCheckin()
    }

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (scanCode) {
      tryDoCheckin()
    }
  }, [scanCode])

  const tryDoCheckin = async () => {
    const roomInfos = await firebase.getRoomByID(tableinfos.room)
    if (roomInfos == undefined) {
      setReservationStatus(STATUS_CHECKIN_ERROR)
    } else {
      const tableReserved = await firebase.getTableReservationToday(code)
      let reservationInDay = await firebase.getMyTodayReservation(authUser.uid)
      reservationInDay = reservationInDay.filter(element => element.cancelReason == undefined)

      if (_isMounted.current && tableReserved) {
        if (tableReserved.length > 0) {
          if (tableReserved[0].user == authUser.uid) {
            doCheckin(reservationInDay)
          } else {
            setReservationStatus(STATUS_TABLE_NOT_AVAILABLE)
          }
        } else {
          if (reservationInDay.length > 0) {
            setReservationStatus(STATUS_CHANGE_RESERVATION)
          } else {
            setReservationStatus(STATUS_TABLE_AVAILABLE)
          }
        }
      } else {
        setReservationStatus(STATUS_CHECKIN_ERROR)
      }
    }
  }

  const doCheckin = async thisReservation => {
    const res = await firebase.checkin(thisReservation, code)

    if (_isMounted.current) {
      if (res) {
        setIsOpen(true)
        setReservationStatus(STATUS_CHECKIN_SUCCESS)
      } else {
        setLastError('Não foi possível fazer o checkin no momento')
        setReservationStatus(STATUS_CHECKIN_ERROR)
      }
    }
  }

  const reserveAndCheckin = async () => {
    const roomInfos = await firebase.getRoomByID(tableinfos.room)
    const selectDay = new Date()
    const day = selectDay.getDate()
    const year = selectDay.getFullYear()
    const month = selectDay.getMonth()

    const data = {
      area: roomInfos.areaName,
      block: tableinfos.block,
      date: new Date(year, month, day, 23, 59, 59, 0),
      room: roomInfos.id,
      roomNid: tableinfos.room,
      table: tableinfos.table,
      type: 0,
      roomName: roomInfos.name,
    }
    firebase
      .createReservation({ ...data, user: authUser.uid })
      .then(() => {
        tryDoCheckin()
      })
      .catch(error => {
        if (_isMounted.current) {
          setLastError('Error no servidor:' + error)
          setReservationStatus(STATUS_CHECKIN_ERROR)
        }
      })
  }

  useEffect(() => {
    if (flag) {
      const aux = reservation && getReservationStatus(reservation)
      setStatus(aux ? aux : 0)
    }
  }, [reservation, flag])

  useEffect(() => {
    if (typeof status === 'number') setLoading(false)
  }, [status])

  useEffect(() => {
    const verifyReservation = async () => {
      let todayReservation = await firebase.getMyTodayReservation(authUser.uid)
      todayReservation = todayReservation.filter(element => element.cancelReason == undefined)
      if (_isMounted.current) {
        if (todayReservation) {
          setReservation(todayReservation)
          setFlag(true)
        }
      }
    }
    verifyReservation()
  }, [firebase, authUser.uid])

  const handleClose = () => {
    history.push('/home')
  }

  const handleScan = data => {
    if (data) {
      const lastBar = data.lastIndexOf('/') + 1
      const code = data.substring(lastBar, data.length)
      setCode(code)
      setScanCode(code)
    }
  }

  const handleError = err => {
    console.error(err)
  }

  const handleChangeReservation = async () => {
    setIsOpen(false)
    setOpenCancelReason(true)
  }

  const circularProgress = (
    <Box className={classes.circularStyle}>
      <CircularProgress />
    </Box>
  )

  const qrCode = (
    <Box className={classes.boxQRCode}>
      <Box className={classes.qrReader}>
        <QrReader delay={300} onError={handleError} onScan={handleScan} />
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonStyle}
          disableElevation
          onClick={() => setScreenState(!screenState)}
        >
          <Typography variant='body1' color='inherit'>
            Digitar código
          </Typography>
        </Button>
      </Box>
      <Box className={classes.imageBox}>
        <img src={checkInSVG} alt='imagem pessoa scan QR-code' className={classes.image} />
      </Box>
    </Box>
  )

  const renderView = () => {
    if (loading) {
      return circularProgress
    } else {
      switch (reservationStatus) {
        case STATUS_CHECKIN_SUCCESS:
          return (
            <ShowModal
              title='Eba! Check-in concluído!'
              message='Agora você já pode aproveitar a sua mesa reservada.'
              isOpen={isOpen}
              image={sucesso}
              handleClose={handleClose}
              secondActionLabel='Fechar'
            />
          )
        case STATUS_TABLE_AVAILABLE:
          return (
            <ShowModal
              title='Você ainda não tem uma reserva'
              message='Essa mesa está disponível, gostaria de agendar a mesa selecionada? '
              isOpen={isOpen}
              image={newDirectReservation}
              mainAction={() => reserveAndCheckin()}
              mainActionLabel='Sim, reservar'
              secondActionLabel='Cancelar'
              handleClose={handleClose}
            />
          )
        case STATUS_TABLE_NOT_AVAILABLE:
          return (
            <ShowModal
              title='Essa mesa já está reservada!'
              message='Parece que essa mesa já está reservada, escolha outra mesa para continuar.'
              isOpen={isOpen}
              image={error}
              secondActionLabel='Digitar novamente'
              handleClose={() => setReservationStatus(0)}
            />
          )
        case STATUS_CHANGE_RESERVATION:
          if (isOpenCancelReason) {
            return (
              <>
                <CancelReasonModal
                  setOpenModal={setOpenCancelReason}
                  isOpenCancelReason={isOpenCancelReason}
                  openModal={isOpenCancelReason}
                  reservation={reservation[0]}
                  todayReservation={reservation[0]}
                  finishCustomFunction={() => reserveAndCheckin()}
                  isChange={true}
                  setReservationStatus={setReservationStatus}
                />
              </>
            )
          } else {
            return (
              <>
                <ShowModal
                  title='Já encontramos um agendamento'
                  message='Parece que você já tem uma mesa agendada para o dia de hoje, você gostaria de trocar seu agendamento?'
                  isOpen={isOpen}
                  image={alterReservation}
                  mainAction={() => handleChangeReservation()}
                  mainActionLabel='Sim, trocar'
                  secondActionLabel='Cancelar'
                  handleClose={handleClose}
                />
              </>
            )
          }

        case STATUS_CHECKIN_ERROR:
          //TODO: Show error
          return (
            <ShowModal
              title='Ocorreu um erro!'
              message='Atualize a pagina e tente fazer a reserva ou check-in novamente.'
              isOpen={isOpen}
              image={error}
              secondActionLabel='Tentar novamente'
              handleClose={() => setReservationStatus(0)}
            />
          )

        default:
          if (!screenState && matches) {
            return qrCode
          } else {
            return (
              <FieldCheckIn
                setScreenState={setScreenState}
                screenState={screenState}
                setCode={setCode}
                code={code}
                doCheckIn={tryDoCheckin}
                matches={matches}
              />
            )
          }
      }
    }
  }
  return <Container className={classes.root}>{renderView()}</Container>
}
export default Checkin
