import React from 'react'
import { Stage, Layer, Arc, Text } from 'react-konva'

const OccupationPie = ({ occupation, dim, colors }) => {
  const radius = 100
  const angleOccupation = (360 * occupation) / 100
  const angleFree = 360 - angleOccupation
  const message = `${occupation}%`

  return (
    <Stage width={dim[0]} height={dim[1]} align={'center'} verticalAlign={'middle'}>
      <Layer>
        <Arc
          x={dim[0] / 2}
          y={dim[1] / 2}
          fill={colors[1]}
          innerRadius={80}
          outerRadius={radius}
          rotation={270}
          angle={angleOccupation}
        />
        <Arc
          x={dim[0] / 2}
          y={dim[1] / 2}
          angle={angleFree}
          rotation={270 - angleFree}
          outerRadius={radius}
          innerRadius={80}
          fill={colors[0]}
        />
        <Text
          width={dim[0]}
          height={dim[1]}
          verticalAlign={'middle'}
          text={message}
          fontSize={46}
          fontStyle={'bold'}
          align='center'
          fill='#34313F'
        />
        <Text
          y={30}
          width={dim[0]}
          height={dim[1]}
          verticalAlign={'middle'}
          text={'Reservado'}
          fontSize={18}
          fontStyle={'normal'}
          align='center'
          fill='#34313F'
        />
      </Layer>
    </Stage>
  )
}

export default OccupationPie
