import { FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import { useState, useContext, useEffect } from 'react'
import { modalStyle } from './styles'
import { FirebaseContext } from '../../contexts/firebase'
import ShowModal from '../modal'

import CancelReservation from '../../assets/CancelReservation.svg'

const CancelReservationModal = ({
  reservation,
  setOpenSnackbar,
  todayReservation,
  setOpenModal,
  openModal,
  finishCustomFunction,
  isChange,
  setReservationStatus,
}) => {
  const firebase = useContext(FirebaseContext)
  const classesModal = modalStyle()
  const [openReasonModal, setOpenReasonModal] = useState(false)
  const [cancelReason, setCancelReason] = useState('Não Informado')
  const [comments, setComments] = useState('')
  const cancelOptions = [
    {
      value: 'Não comparecerei',
      label: 'Não irei conseguir comparecer a empresa na data reservada.',
    },
    {
      value: 'Preciso ir embora mais cedo',
      label: 'Preciso ir embora mais cedo.',
    },
    {
      value: 'Mesa com problema',
      label: 'Mesa com problema',
    },
  ]

  const changeOptions = [
    {
      value: 'Equipamentos danificados',
      label: 'Monitor, régua ou cadeira quebrada ou com defeitos.',
    },
    {
      value: 'Problemas técnicos',
      label: 'Preciso mudar de posição por algum problema técnico.',
    },
  ]

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleReasonModal = () => {
    if (isChange) {
      setReservationStatus(0)
    }
    setOpenReasonModal(false)
  }

  const handleReasonForm = e => {
    const { value } = e.target
    setCancelReason(value)
  }

  const cancelReservationAction = async () => {
    await firebase
      .cancelReservation(todayReservation, cancelReason, comments)
      .then(setOpenModal(false), setOpenReasonModal(false), finishCustomFunction())
      .catch(erro => (isChange ? setReservationStatus(5) : console.log('ERROR', erro)))
  }

  const handleModal = () => {
    const reservationDate = new Date(reservation?.date).getDate()
    const today = new Date().getDate()

    if (today < reservationDate) {
      setOpenSnackbar && setOpenSnackbar(true)
      return cancelReservationAction()
    } else {
      setOpenReasonModal(true)
      setOpenModal(false)
    }
  }

  useEffect(() => {
    if (isChange) {
      setOpenReasonModal(true)
    }
  }, [])

  return (
    <>
      {!isChange && (
        <ShowModal
          isOpen={openModal}
          image={CancelReservation}
          title='Tem certeza que deseja cancelar?'
          message={`Gostaria de cancelar sua reserva do dia ${format(reservation.date, 'dd/MM')} em sala ${
            reservation.roomName
          }
          ?`}
          mainActionLabel='Sim, cancelar'
          mainAction={handleModal}
          secondActionLabel='Não, fechar'
          handleClose={handleCloseModal}
        />
      )}
      <ShowModal
        isOpen={openReasonModal}
        title={`Qual o motivo ${isChange ? 'da troca' : 'do cancelamento'} ?`}
        message={'Nos ajude a manter o local sempre utilizavél para outros companheiro:'}
        mainActionLabel='Salvar'
        mainAction={cancelReservationAction}
        secondActionLabel='Não, fechar'
        handleClose={handleReasonModal}
      >
        <FormControl component='fieldset' className={classesModal.field}>
          <RadioGroup aria-label='reason' name='reason' value={cancelReason} onChange={handleReasonForm}>
            {(isChange ? changeOptions : cancelOptions).map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  className={classesModal.formControl}
                  control={<Radio className={classesModal.radioBox} />}
                  label={item.label}
                />
              )
            })}
            {/* <FormControlLabel
              value='Não comparecerei'
              className={classesModal.formControl}
              control={<Radio className={classesModal.radioBox} />}
              label='Não irei conseguir comparecer a empresa na data reservada.'
            />
            <FormControlLabel
              value='Preciso ir embora mais cedo '
              className={classesModal.formControl}
              control={<Radio className={classesModal.radioBox} />}
              label='Preciso ir embora mais cedo '
            />
            <FormControlLabel
              value='Mesa com problema'
              className={classesModal.formControl}
              control={<Radio className={classesModal.checkBox} />}
              label='Mesa com problema'
            /> */}
          </RadioGroup>
          <Typography variant='body2' className={classesModal.observations}>
            Observações:
          </Typography>
          <TextField
            multiline
            rows={2}
            variant='outlined'
            type='text'
            name='reason'
            value={comments}
            placeholder='Digite aqui...'
            onChange={e => setComments(e.target.value)}
          />
        </FormControl>
      </ShowModal>
    </>
  )
}

export default CancelReservationModal
