import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import EventNoteIcon from '@material-ui/icons/EventNote'
import MapEdit from '../../../assets/MapEditIcon.png'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import cardReservation from '../../../assets/CardReservation.svg'
import noShow from '../../../assets/error.svg'
import checkinSucess from '../../../assets/sucesso.svg'
import STEPS from '../../../config/home_steps.json'
import * as ROUTES from '../../../config/routes'
import { useStyles } from './styles'
import { capitalize } from '../../../utils/string-manipulation'
import CancelReservationModal from '../../../components/CancelModals'
import { useEffect } from 'react'
import { SignalCellularNullOutlined } from '@material-ui/icons'

export default ({
  reservation,
  todayReservation,
  reservationIndex,
  reservationOnDay,
  showReservation,
  setReservations,
  handleStep,
  setReservationIndex,
  setOpenSnackbar,
}) => {
  const classes = useStyles()
  const rootRef = useRef(null)
  const history = useHistory()
  const [openModal, setOpenModal] = React.useState(false)
  const date = format(reservation.date, 'PPPP', { locale: ptBR })
  const theme = useTheme()
  const [oldBooking, setOldBooking] = useState(false)

  const formatingDate = reservationDate => {
    const formatDate = reservationDate.split(' ')
    formatDate[0] = capitalize(formatDate[0].split('-')[0].split(',')[0])
    formatDate[3] = capitalize(formatDate[3])
    const newFormat = `${formatDate[0]}, ${formatDate[1]} de ${formatDate[3]} de ${formatDate[5]}`

    return newFormat
  }

  function meetingBookedHour(reservationHour) {
    let hours = []
    let hour = 7

    for (let i = 0; i < 24; i += 2) {
      hours[i] = hour + ':00'

      hours[i + 1] = hour + ':30'

      hour++
    }

    return hours[reservationHour]
  }

  const finishCustomFunction = () => {
    setOpenSnackbar(true)
    setReservations(oldReservations => oldReservations.filter(res => res.id !== todayReservation.id))
    setReservationIndex(reservationIndex <= 0 ? 0 : reservationIndex - 1)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    reservation.date.setHours(0, 0, 0, 0)

    if (reservation.date.getTime() >= now.getTime()) {
      setOldBooking(false)
    } else setOldBooking(true)
  }, [reservation])

  const renderReservation = () => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    reservation.date.setHours(0, 0, 0, 0)

    return displayReservation(reservation.date.getTime() === now.getTime())
  }
  const handleCheckin = () => {
    history.push(ROUTES.CHECKIN)
  }

  const showOnMap = () => {
    handleStep(STEPS.ROOM)
    history.push({
      pathname: `${ROUTES.HOME}/${todayReservation.date.getTime()}/${todayReservation.room}`,
      state: {
        roomData: { id: todayReservation.room },
        isEdit: true,
        reservationProps: todayReservation,
        areaName: todayReservation.areaName,
      },
    })
  }

  const reservationContent = subject => {
    if (todayReservation) {
      const status = todayReservation.status[todayReservation.status.length - 1].type
      let roomType = null
      let statusAux = null

      if (status === 0) {
        statusAux = 'pending'
      } else if (status === 1) {
        statusAux = 'checkin'
      } else if (status === 3) {
        statusAux = 'noshow'
      }

      todayReservation.type === 0 ? (roomType = 'normal') : (roomType = 'meeting')

      const content = {
        normal: {
          pending: {
            title: 'Eba! Você agendou uma mesa!',
            description: 'Você realizou uma reserva não se esqueça de fazer o check-in ou cancelar com antecedência.',
            image: cardReservation,
          },
          checkin: {
            title: 'Eba! Você fez check-in!',
            description: 'Você realizou uma reserva e check-in neste dia!',
            image: checkinSucess,
          },
          noshow: {
            title: 'Sentimos sua falta 💔',
            description: 'Você reservou uma mesa neste dia, mas não compareceu.',
            image: noShow,
          },
        },
        meeting: {
          pending: {
            title: 'Eba! Você agendou uma sala de reunião!',
            description: 'Você realizou uma reserva não se esqueça de fazer o check-in ou cancelar com antecedência.',
            image: cardReservation,
          },
          checkin: {
            title: 'Eba! Você fez check-in em uma sala de reunião!',
            description: 'Você realizou uma reserva e check-in neste dia!',
            image: checkinSucess,
          },
          noshow: {
            title: 'Sentimos sua falta 💔',
            description: 'Você reservou uma sala de reunião neste dia, mas não compareceu.',
            image: noShow,
          },
        },
      }

      return content[roomType][statusAux][subject]
    } else {
      return
    }
  }

  const displayReservation = today => (
    <>
      <img src={reservationContent('image')} className={classes.image} alt='' />
      <Box className={classes.text}>
        <Typography variant='h6'>{reservationContent('title')}</Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {reservationContent('description')}
        </Typography>
      </Box>
      <Box className={classes.reservation}>
        <Grid container className={classes.date}>
          <Grid item xs={1}>
            <Box className={classes.calendarIcon}>
              <EventNoteIcon className={classes.icon} />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.dateText}>{formatingDate(date)}</Typography>
          </Grid>
          <Grid item xs={1}>
            {!oldBooking && (
              <Tooltip title='Ver no Mapa'>
                <img
                  src={MapEdit}
                  onClick={showOnMap}
                  style={{
                    color: todayReservation?.status.length > 1 ? null : '#EB2368',
                    width: '34px',
                    height: '34px',
                    display: 'flex',
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Box className={classes.GridInfos}>
          <Box>
            <Typography variant='body1' color='textSecondary'>
              Área
            </Typography>
            <Typography variant='body1'>
              {todayReservation?.type === 0 ? todayReservation?.area : todayReservation?.areaName}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1' color='textSecondary'>
              Sala
            </Typography>
            <Typography variant='body1'>{todayReservation?.roomName}</Typography>
          </Box>
          {todayReservation?.type === 0 && (
            <Box>
              <Typography variant='body1' color='textSecondary'>
                Bloco
              </Typography>
              <Typography variant='body1'>{todayReservation?.block}</Typography>
            </Box>
          )}
          {todayReservation?.type === 0 && (
            <Box>
              <Typography variant='body1' color='textSecondary'>
                Mesa
              </Typography>
              <Typography variant='body1'>{todayReservation?.table}</Typography>
            </Box>
          )}
          {todayReservation?.type === 1 && (
            <Box>
              <Typography variant='body1' color='textSecondary'>
                Período
              </Typography>
              <Typography variant='body1'>
                {meetingBookedHour(todayReservation.initDateSlot)} -{meetingBookedHour(todayReservation.endDateSlot)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Grid container className={classes.date}>
        <Grid item>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '29px',
              height: '29px',
              borderRadius: '100%',
              background: reservationIndex <= 0 ? '#DBDBDB' : '#EB2368',
            }}
          >
            <IconButton
              onClick={() => {
                showReservation('previous')
              }}
              className={classes.edit}
              disabled={reservationIndex <= 0}
            >
              <KeyboardArrowLeftIcon
                style={{
                  color: reservationIndex <= 0 ? null : '#FFF',
                  borderRadius: '100%',
                  width: '29px',
                  height: '29px',
                }}
              />
            </IconButton>
          </Box>
        </Grid>

        <Grid item>
          <Box
            style={{
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '29px',
              height: '29px',
              borderRadius: '100%',
              background: reservationOnDay.length - 1 === reservationIndex ? '#DBDBDB' : '#EB2368',
            }}
          >
            <IconButton
              onClick={() => {
                showReservation('next')
              }}
              className={classes.edit}
              disabled={reservationOnDay.length - 1 === reservationIndex}
            >
              <KeyboardArrowRightIcon
                style={{
                  color: reservationOnDay.length - 1 === reservationIndex ? null : '#FFF',
                  width: '29px',
                  height: '29px',
                }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.buttons} spacing={1}>
        {todayReservation?.status.length === 1 && (
          <Grid item xs={6}>
            {!oldBooking ? (
              <Button
                disableElevation
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={handleCheckin}
                disabled={!today}
              >
                <Typography variant='subtitle1' color='inherit'>
                  Check-in
                </Typography>
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        )}
        <Grid item xs={todayReservation?.status.length === 1 ? 6 : 12}>
          {!oldBooking && (
            <Button
              disableElevation
              variant='outlined'
              color='primary'
              className={classes.button}
              onClick={handleOpenModal}
            >
              Cancelar
            </Button>
          )}
        </Grid>
      </Grid>
      <CancelReservationModal
        reservation={reservation}
        todayReservation={todayReservation}
        setOpenModal={setOpenModal}
        openModal={openModal}
        finishCustomFunction={finishCustomFunction}
        setOpenSnackbar={setOpenSnackbar}
      />
    </>
  )

  return (
    <Card elevation={0} className={classes.root} ref={rootRef}>
      <CardContent className={classes.cardContent}>{renderReservation(reservation.date)}</CardContent>
    </Card>
  )
}
