//Content Home
export const margin_root = 'clamp(0.75rem, 1.1vw + 0.05rem, 1.5rem)' 

//header
export const header_height = 'clamp(4rem, 3.5vw + 1rem, 5rem)' 

//Olá usuário
export const font_size_h2 = 'clamp(1.375rem, 1vw + 0.6rem, 1.875rem)' 
export const font_height_h2 = 'clamp(1.5rem, 1vw + 0.3rem, 2.25rem)' 

//Menu lateral de navegação
export const font_size_subtitle1 = 'clamp(0.8rem, 0.8vw + 0.1rem, 1rem)' 
export const line_height_subtitle1 = 'clamp(1.05rem, 1vw + 0.3rem, 1.833rem)' 
export const item_height = 'clamp(2.4rem, 2.2vw + 0.5rem, 3.125rem)' 
export const width_side_menu = 'clamp(12rem, 11.3vw + 2rem, 15rem)' 

//covid warning
export const margin_and_padding_covid = 'clamp(0.7rem, 0.75vw + 0.1rem, 1rem)' 
export const font_size_subtitle2 = 'clamp(0.75rem, 0.75vw + 0.1rem, 1rem)' 
export const line_height_subtitle2 = 'clamp(0.9rem, 0.9vw + 0.1rem, 1.833rem)' 
export const font_size_body2_index = 'clamp(0.65rem, 0.65vw + 0.1rem, 0.85rem)'
export const line_height_body2_index = 'clamp(0.9rem, 0.75vw + 0.1rem, 1.125rem)'


//Calendario
export const font_size_body1 = 'clamp(1.1rem, 0.75vw + 0.6rem, 1.5rem)'
export const font_size_body2 = 'clamp(0.8rem, 0.8vw + 0.2rem, 1.1rem)'
export const slot_size = 'clamp(2.8rem, 3vw + 0.5rem, 4rem)' 
export const calendar_header_margin_botton = 'clamp(1rem, 0.75vw + 0.3rem, 1.5rem)'
export const calendar_height = 'clamp(16rem, 21vw + 0.5rem, 27rem)'

//Reservation Card
export const font_size_h6 =  'clamp(0.9rem, 0.7vw + 0.45rem, 1.3rem)'
export const line_height_h6 = 'clamp(1.5rem, 1vw + 1rem, 2.5rem)' 
export const image_width = 'clamp(8.4rem, 9vw + 1rem, 15rem)' 
export const image_botton_margin = '0rem' 
export const button_height = 'clamp(2rem, 1.6vw + 1rem, 3.313rem)' 

