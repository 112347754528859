import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {font_size_body1, font_size_body2, slot_size, calendar_height, calendar_header_margin_botton, calendar_days_margin_botton} from '../utils/variaveis.js'

const breakpoints = createBreakpoints({});

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '50px',
      lineHeight: '75px',
      color: '#000000',
      fontStyle: 'normal',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '30px',
      lineHeight: '36px',
      color: '#000000',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '28px',
      lineHeight: '31px',
      color: '#000000',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '30px',
      color: '#000000',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '30px',
      color: '#000000',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: '25px',
      color: '#000000',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '22px',
      color: '#000000',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '22px',
      color: '#000000',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '19px',
      color: '#000000',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#000000',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '15px',
      color: '#000000',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        minWidth: '140px',
        backgroundColor: '#53318a',
      },
      toolbarLandscape: {
        maxWidth: '155px',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbarLandscape: {
        padding: '12px',
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-around',
      },
      transitionContainer: {
        minHeight: calendar_height,
        [breakpoints.down('md')]: {
          minHeight: '255px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop:'0px',
        marginBottom: calendar_header_margin_botton,
      },
      daysHeader: {
        justifyContent: 'space-around',
        marginBottom: '8px',
      },
      transitionContainer: {
        minHeight: '40px',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
        minWidth: '30px',
        minHeight: '30px',
        height: 'auto',
        width: 'auto',
        [breakpoints.up('1420')]: {
          minWidth: '72px',
          minHeight: '72px',
          height: 'auto',
          width: 'auto',
        },
        [breakpoints.down('md')]: {
          minWidth: '50px',
          minHeight: '50px',
          height: 'auto',
          width: 'auto',
        },
        [breakpoints.down('1280')]: {
          minWidth: '42px',
          minHeight: '42px',
          height: 'auto',
          width: 'auto',
        },
        [breakpoints.up('1280')]: {
          minWidth: slot_size, 
          minHeight: slot_size, 
          height: 'auto',
          width: 'auto',
        },
      },
      daySelected: {
        backgroundColor: '#EB2368',
        '&:hover': {
          backgroundColor: '#EB2368',
        },
      },
      current: {
        backgroundColor: 'none',
        border: '1px solid #EB2368',
        color: '#EB2368',
        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#53318a',
      },
    },
    MuiPickersBasePicker: {
      container: {
        height: '100%',
        [breakpoints.down('md')]: {
          minHeight: 'fit-content',
        },
      },
      pickerView: {
        maxWidth: '100%',
        width: '100%',
        justifyContent: 'start',
        overflowX: 'clip',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        height: '100%',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '#F1F3F4',
        },
      },
    },
    MuiTypography: {
      body2: {
        fontSize: font_size_body2,
      },
      caption: {
        fontSize: font_size_body2,
      },
      body1: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: font_size_body1,        
      },
    },
  },
});

export { materialTheme };
