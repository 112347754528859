import { useEffect, useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FirebaseContext } from '../../../contexts/firebase'
import { Box, Grid, Typography, Button } from '@material-ui/core'

import * as ROUTES from '../../../config/routes'

import { format } from 'date-fns'
import * as XLSX from 'xlsx'
import CustomFilter from '../../../components/customFilter'
import ReportReservationTable from './roomsTable'
import useStyles from './styles'

const RoomOcuppation = () => {
  const { month, roomID } = useParams()
  const firebase = useContext(FirebaseContext)
  const classes = useStyles()

  const [reservationData, setReservationData] = useState([])
  const [reservationAux, setReservationAux] = useState(reservationData)
  const [selectedFilter, setSelectedFilter] = useState({
    inicialDate: null,
    finalDate: null,
    user: null,
    table: null,
    block: null,
  })

  function fetchData() {
    firebase.getMonthRoomReservationReport(month, roomID).then(reservation => {
      const filterReservations = reservation.filter(reservation => !reservation.cancelReason)
      setReservationData(filterReservations), setReservationAux(filterReservations)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  let dates = reservationAux?.map(reservation => {
    return new Date(reservation.date.seconds * 1000).toLocaleDateString('pt-BR')
  })
  dates = [...new Set(dates)]

  let users = reservationAux?.map(reservation => reservation.user)
  users = [...new Set(users)]

  let tables = reservationAux?.map(reservation => reservation.table).sort(function(a,b){
      return a - b;
    }).map(table => table.toString())
  tables = [...new Set(tables)]

  let blocks = reservationAux?.map(reservation => reservation.block).sort(function(a,b){
      return a - b;
    }).map(block => block.toString())
  blocks = [...new Set(blocks)]

  const handleFilter = (_, value, name) => {
    setSelectedFilter({ ...selectedFilter, [name]: value })
  }

  useEffect(() => {
    let aux = reservationAux
    if (selectedFilter.inicialDate) {
      aux = aux.filter(reservation => {
        const [day] = selectedFilter.inicialDate.split('/')
        return new Date(reservation.date.seconds * 1000).getDate() >= day
      })
    }
    if (selectedFilter.finalDate) {
      aux = aux.filter(reservation => {
        const [day] = selectedFilter.finalDate.split('/')
        return new Date(reservation.date.seconds * 1000).getDate() <= day
      })
    }
    if (selectedFilter.user) {
      aux = aux.filter(reservation => {
        return reservation.user === selectedFilter.user
      })
    }
    if(selectedFilter.table){
      aux = aux.filter(reservation => {
        return reservation.table.toString() === selectedFilter.table
      })
    }
    if(selectedFilter.block){
      aux = aux.filter(reservation => {
        return reservation.block.toString() === selectedFilter.block
      })
    }

    setReservationData(aux)
  }, [selectedFilter])

  const fields = [
    {
      name: 'inicialDate',
      label: 'Data Inicial',
      options: dates,
      value: selectedFilter.inicialDate,
      onChange: handleFilter,
    },
    {
      name: 'finalDate',
      label: 'Data Final',
      options: dates,
      value: selectedFilter.finalDate,
      onChange: handleFilter,
    },
    {
      name: 'user',
      label: 'Usuário',
      options: users,
      value: selectedFilter.user,
      onChange: handleFilter,
    },
    {
      name: 'table',
      label: 'Mesa',
      options: tables,
      value: selectedFilter.table,
      onChange: handleFilter,
    },
    {
      name: 'block',
      label: 'Bloco',
      options: blocks,
      value: selectedFilter.block,
      onChange: handleFilter,
    },
  ]
  let auxRoom = reservationData
  const downloadExcelFile = () => {
    const wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test')
    let ws_data = [
      ['Data','Usuário', 'Mesa', 'Bloco'],
      ...auxRoom.map(aux => [format(new Date(aux.date.seconds * 1000), 'dd/MM/yyyy'),aux.user, aux.table,aux.block]),
    ]
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test'] = ws
    XLSX.utils.book_append_sheet(wb, ws, 'Detalhes da Ocupação')
    XLSX.writeFile(wb, 'Detalhes da Ocupação.xlsx')
  }
  
  
  return (
    <Grid container direction='row' justify='flex-start' className={classes.root}>
      <Grid item xs={12} className={classes.divtitle} {...console.log(reservationData)} >
        
          <Typography variant='h4'> {reservationData[0]?.roomName}</Typography>
        <Box className={classes.breadcrumbs}>
          <Typography variant='body1' className={classes.color}>
            <Link to={ROUTES.REPORT} className={classes.link}>
              Relatórios
            </Link>
            {'>'}
          </Typography>
          <Typography variant='body1' className={classes.color}>
            <Link to={'./'} className={classes.link}>
              Ocupação
            </Link>
            {'>'}
          </Typography>
          <Typography variant='body1' className={classes.color}>
            {reservationData[0]?.roomName}
          </Typography>
        </Box>
      </Grid>
      {auxRoom &&
              <Grid item xs={12} className={classes.gridButton}>
                <Button className={classes.button} variant='contained' color='primary' onClick={() => downloadExcelFile()}>
                  Exportar
                </Button>
              </Grid>
              }
      <Grid item xs={12}>
        <CustomFilter fields={fields} />
      </Grid>
      <Grid item xs={12}>
        <ReportReservationTable reservations={reservationData} />
      </Grid>
    </Grid>
  )
}

export default RoomOcuppation
