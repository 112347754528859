import React from 'react'

import { Typography, Box } from '@material-ui/core'

class Privacy extends React.Component {
  render() {
    return (
      <Box>
        <Typography variant='h1'>Privacy Statement</Typography>
      </Box>
    )
  }
}

export default Privacy
