import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'

import { Typography, Button, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import FilterComponent from '../../../components/filterComponent/filter'
import TableComponent from './table/table'
import { ThemeProvider } from '@material-ui/styles'
import { materialTheme } from '../../../theme/table'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export default function EnhancedTable() {
  const [areas, setAreas] = useState([])
  const [valueFiltered, setValueFiltered] = useState()
  const classes = useStyles()
  const [orderBy, setOrderBy] = useState('displayName')
  const history = useHistory()
  const _isMounted = useRef(true)

  const handleClick = () => {
    history.push(ROUTES.NEWAREA)
  }

  const userFilter = (e, newSearch) => {
    setValueFiltered(newSearch)
  }

  const filterValues = row => {
    if (row.name && row.description) {
      return row.name === valueFiltered.name && row.description === valueFiltered.description
    }
  }

  const listAutoComplete = [
    {
      title: 'Área',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(areas, getComparator('asc', orderBy)),
      comparator: 'name',
    },
    {
      title: 'Descrição',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(areas, getComparator('asc', orderBy)),
      comparator: 'description',
    },
  ]
  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <ThemeProvider theme={materialTheme}>
        <Grid container item justify='space-between' alignItems='center'>
          <Grid item xs={3} className={classes.titleGrid}>
            <Typography variant='h5'>Áreas</Typography>
          </Grid>
          <Grid item xs={3} className={classes.nextButton}>
            <Button className={classes.button} color='primary' variant='contained' onClick={handleClick}>
              Nova área
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.filter}>
            <FilterComponent listAutoComplete={listAutoComplete} />
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              stableSort={stableSort}
              filterValues={filterValues}
              valueFiltered={valueFiltered}
              setAreas={setAreas}
              areas={areas}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  )
}
