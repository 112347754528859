import { TableRow, TableCell } from '@material-ui/core'
import { useStyles } from './styles'
import { format } from 'date-fns'

const Row = ({ row, index }) => {
  const classes = useStyles()
  return (
    <TableRow hover role='checkbox' tabIndex={-1}>
      <TableCell id={index} component='th' scope='row' className={classes.table_cell}>
        {format(new Date(row.date.seconds * 1000), 'dd/MM/yyyy')}
      </TableCell>
      <TableCell align='left' className={classes.table_cell}>
        {row.area}
      </TableCell>
      <TableCell align='left' className={classes.table_cell} spacing='2'>
        {row.roomName}
      </TableCell>
      <TableCell align='left' className={classes.table_cell}>
        {row.block}
      </TableCell>
      <TableCell align='left' className={classes.table_cell}>
        {row.table}
      </TableCell>
      {row.status[0].type === 1 ? (
        <TableCell align='left' className={(classes.public, classes.checkIn)}>
          Check-in
        </TableCell>
      ) : row.status[0].type === 3 ? (
        <TableCell align='left' className={(classes.public, classes.noShow)}>
          No Show
        </TableCell>
      ) : row.status[1]?.type === 1 ? (
        <TableCell align='left' className={(classes.public, classes.checkIn)}>
          Check-in
        </TableCell>
      ) : (
        <TableCell align='left' className={(classes.noPublic, classes.noShow)}>
          No Show
        </TableCell>
      )}
    </TableRow>
  )
}
export default Row
