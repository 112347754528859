import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    card_paper: {
        overflow: 'hidden',
        height: '97vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    skeleton: {
        borderRadius: '4px',
    },
    mapPaper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    box_zoom: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
    },
    icon_button: {
        borderRadius: '50%',
        color: '#474747',
    },
    circularStyle: {
        alignSelf: 'center',
        marginLeft: '50%',
        marginTop: '25%',
    },
}));

export default useStyles;
