import { Paper, Button, Divider } from '@material-ui/core'
import useStyles from './styles'

import DeleteIcon from '@material-ui/icons/Delete'
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw'
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone'
import TextRotateUpIcon from '@material-ui/icons/TextRotateUp'
import TextRotationDownIcon from '@material-ui/icons/TextRotationDown'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { red } from '@material-ui/core/colors'

const Toolbar = ({
  selectedElement,
  scale,
  element,
  handleDelete,
  handleRotateLeft,
  handleRotateRight,
  handleCopyElement,
  rotateText,
  stage,
}) => {
  const classes = useStyles()
  const headerHeight = 87
  return (
    <div>
      {selectedElement && (
        <Paper
          className={classes.boxContainer}
          style={{
            height: 48,
            width: 'fit-content',
            alignItems: 'center',
            position: 'absolute',
            top: 110,
            left: 179,
            zIndex: 13,
          }}
        >
          <Divider orientation='vertical' flexItem />
          <Button onClick={handleDelete} className={classes.button}>
            {<DeleteIcon />}
          </Button>
          <Divider orientation='vertical' flexItem />
          <Button onClick={handleRotateLeft} className={classes.button}>
            {<Rotate90DegreesCcwIcon />}
          </Button>
          <Button onClick={handleRotateRight} style={{ transform: 'scaleX(-1)' }} className={classes.button}>
            {<Rotate90DegreesCcwIcon />}
          </Button>
          <Divider orientation='vertical' flexItem />
          <Button className={classes.button} onClick={() => rotateText(0)}>
            {<TextRotationNoneIcon />}
          </Button>
          <Button style={{ transform: 'rotate(90deg)' }} onClick={() => rotateText(1)} className={classes.button}>
            {<TextRotationDownIcon />}
          </Button>
          <Button className={classes.button} onClick={() => rotateText(2)}>
            {<TextRotateUpIcon />}
          </Button>
          <Button className={classes.button} onClick={() => rotateText(3)}>
            {<TextRotationDownIcon />}
          </Button>
          <Button className={classes.button} onClick={() => handleCopyElement()}>
            {<FileCopyIcon />}
          </Button>
        </Paper>
      )}
    </div>
  )
}

export default Toolbar
