import { useEffect, useState, useContext, useRef } from 'react'
import { FirebaseContext } from '../../../contexts/firebase'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import Map from './map/index'
import SettingsMenu from './settingsMenu'
import ZoomControl from './../../../components/zoomControl'

import { Grid, Button, Typography } from '@material-ui/core'
import useStyles from './styles'
import MenuBar from './menuBar'
import ShowModal from '../../../components/modal'
import SaveRoomImage from '../../../assets/saveRoom.svg'
import { convertPointsToLine } from '../../../utils/points-to-line'

export const App = () => {
  const classes = useStyles()
  const { areaID, roomID } = useParams()
  const { state } = useLocation()
  const { isEdit, isDuplicate, pictures } = state

  const [selectedElement, setSelectedElement] = useState('')
  const [centralize, setCentralize] = useState(true)
  const [tool, setTool] = useState({})
  const [selectedCategory, setSelectedCategory] = useState('')
  const [menuIndex, setMenuIndex] = useState(null)
  const [blocks, setBlocks] = useState(1)

  // Stores the states and current index to undo and redo functions
  const [history, setHistory] = useState([])
  const [historyStep, setHistoryStep] = useState(-1)

  const [zoom, setZoom] = useState(100)
  const [elements, setElements] = useState([])
  const [places, setPlaces] = useState(0)

  const firebase = useContext(FirebaseContext)
  const [room, setRoom] = useState()
  const [scale, setScale] = useState(0)
  const [cursor, setCursor] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [hasInitialState, setHasInitialState] = useState(false)
  const [points, setPoints] = useState([])
  const [line, setLine] = useState([])
  const [selectedWall, setSelectedWall] = useState(null)

  const dragImageRef = useRef()
  const uHistory = useHistory()
  const all = useRef(true)

  const [area, setArea] = useState({})

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  useEffect(() => {
    if (!all.current) return
    if (state && state.room) {
      setRoom(state.room),
        setElements(state.room.elements),
        setHistory([state.room.elements]),
        setHasInitialState(true),
        setHistoryStep(0)
    } else
      firebase.getRoomInfoByID(areaID, roomID).then(resp => {
        setRoom(resp)
        setPoints(resp.walls || []), convertPointsToLine(resp.walls || [], setLine)
        resp.elements &&
          resp.elements.length &&
          (setElements(resp.elements), setHistory([resp.elements]), setHasInitialState(true), setHistoryStep(0))
      })
  }, [])

  useEffect(() => {
    if (!room || !all.current) return
    if (room.scale) setScale(Number(room.scale))
    else firebase.getAreaScale(areaID).then(resp => setScale(Number(resp)))
  }, [room])

  useEffect(() => {
    if (!room || !all.current) return
    setPlaces(room.places ? room.places : 0)
  }, [room])

  useEffect(() => {
    if (!elements.length || !all.current) return
    setBlocks(Math.max(...elements.map(ele => ele.index)) + 1)
  }, [elements])

  const onPositionChanged = pos => {
    if (!all.current) return
    setPosition(pos)
    history.push(pos)
  }
  //Stores a new state in history and increment the current index
  const nextAction = newElements => {
    if (!all.current) return
    let cnt = 0
    newElements.length &&
      newElements.forEach((ele, index) => {
        if (ele.workstation) {
          ele.index = cnt + 1
          cnt++
        }
      })

    let aux = history.slice(0, historyStep + 1)
    setElements(newElements)
    setHistory([...aux, newElements])
    setHistoryStep(historyStep + 1)
  }

  // Back in history if its possible
  const undo = () => {
    if (historyStep < 0 || (historyStep === 0 && hasInitialState) || !all.current) {
      return
    }
    const previous = historyStep > 0 ? history[historyStep - 1] : []
    let cnt = 0
    previous.length &&
      previous.forEach((ele, index) => {
        if (ele.workstation) {
          ele.index = cnt + 1
          cnt++
        }
      })
    setElements(previous)
    setHistoryStep(historyStep - 1)
    setSelectedElement('')
  }

  // Advance in history if its possible
  const redo = () => {
    if (historyStep === history.length - 1 || !all.current) return
    const next = history[historyStep + 1]
    let cnt = 0
    next.length &&
      next.forEach((ele, index) => {
        if (ele.workstation) {
          ele.index = cnt + 1
          cnt++
        }
      })
    setHistoryStep(historyStep + 1)
    setElements(next)
    setSelectedElement('')
  }

  const saveEditedRoom = () => {
    if (!all.current) return
    /* if (isDuplicate) return */
    const minX = Math.min(...room.polygon.map(point => point.x))
    const minY = Math.min(...room.polygon.map(point => point.y))
    const maxX = Math.max(...room.polygon.map(point => point.x))
    const maxY = Math.max(...room.polygon.map(point => point.y))
    const roomWidth = (maxX - minX) * scale
    const roomHeight = (maxY - minY) * scale

    const width = 50 * (((roomWidth + 99) / 50) >> 0)
    const height = 50 * (((roomHeight + 99) / 50) >> 0)
    const updatedRoom = { ...room, elements, scale, width, height, places, walls: points }
    delete updatedRoom.id
    isDuplicate && pictures
      ? firebase.AddNewRoom(updatedRoom, pictures).then(() => setOpenModal(true))
      : firebase.updateRoom(room.id, updatedRoom).then(() => setOpenModal(true))
  }

  const handleDelete = () => {
    if (!all.current) return
    if (selectedElement.workstation) {
      elements.forEach(ele => ele.workstation && ele.index > selectedElement.index && ele.index--)
      setBlocks(blocks - 1)
      setPlaces(places - ((selectedElement.places / 2) >> 0))
    }
    const filterArray = elements.filter(ele => {
      return ele.id !== selectedElement.id
    })
    const ret = filterArray.map(ele => {
      return { ...ele, x: ele.x, y: ele.y }
    })
    nextAction(ret)
    setElements(ret)
    setSelectedElement('')
  }

  const handleRotateLeft = () => {
    if (!selectedElement || !all.current) return
    const rotateSelectedElement = elements.map(ele => {
      if (ele.id === selectedElement.id) {
        return {
          ...ele,
          rotation: (ele.rotation + 315) % 360,
        }
      } else {
        return ele
      }
    })
    setElements(rotateSelectedElement)
    nextAction(rotateSelectedElement)
  }

  const handleRotateRight = () => {
    if (!selectedElement || !all.current) return
    const rotateSelectedElement = elements.map(ele => {
      if (ele.id === selectedElement.id) {
        return {
          ...ele,
          rotation: (ele.rotation + 45) % 360,
        }
      } else {
        return ele
      }
    })
    setElements(rotateSelectedElement)
    nextAction(rotateSelectedElement)
  }

  const handleZoomIn = () => {
    if (!all.current) return
    setZoom(Math.min(zoom / 0.9, 300))
    setCentralize(false)
  }
  const handleZoomOut = () => {
    if (!all.current) return
    setZoom(Math.max(zoom * 0.9, 50))
    setCentralize(false)
  }

  const handleCentralize = () => {
    if (!all.current) return
    setCentralize(true)
  }

  const handleDescentralize = () => {
    if (!all.current) return
    setCentralize(false)
  }

  const closeMenuBarWithClick = () => {
    if (selectedCategory === '') return
    setSelectedCategory('')
    setMenuIndex(null)
  }

  const rotateText = position => {
    if (!all.current) return
    let rotateElements = elements.map((ele, index) => {
      if (selectedElement.id === ele.id) {
        return { ...ele, textLocation: position }
      }
      return ele
    })

    nextAction(rotateElements)
    setElements(rotateElements)
  }
  function copyElement(e) {
    if (!selectedElement) return
    const newElement = {
      ...selectedElement,
      id: Math.floor(Math.random() * 10000),
      ...(selectedElement.workstation ? { index: blocks } : { index: 0 }),
      x: selectedElement.x + 5,
      y: selectedElement.y + 5,
    }
    setPlaces(places + ((selectedElement.places / 2) >> 0))
    setElements(elements => [...elements, newElement])
    setBlocks(blocks + 1)
    nextAction([...elements, newElement])
  }

  function deleteWall() {
    if (!selectedWall) return

    let pointsCopy = [...points]
    let lineCopy = [...line]

    lineCopy.splice(selectedWall - 1, 1)
    pointsCopy.splice(selectedWall - 1, 1)
    setPoints(pointsCopy)
    setLine(lineCopy)

    setSelectedWall(null)
  }

  document.onkeydown = e => {
    if ((e.ctrlKey && e.key === 'z') || e.key === 'Z') {
      undo()
    }
    if ((e.ctrlKey && e.key === 'y') || e.key === 'Y') {
      redo()
    }
    if ((e.ctrlKey && e.key === 'm') || e.key === 'm') {
      copyElement()
    }
  }

  //Get Room Image
  async function getAreaImage() {
    await firebase.getAreaByID(areaID).then(area => {
      setArea(area)
    })
  }
  useEffect(() => {
    getAreaImage()
  }, [])

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12}>
        <SettingsMenu undo={undo} redo={redo} zoomValue={zoom} setZoomValue={setZoom} className={classes.paperMenu} />
        <Grid className={classes.zoomControl_container}>
          <ZoomControl
            handleCentralize={() => handleCentralize()}
            handleZoomIn={() => handleZoomIn()}
            handleZoomOut={() => handleZoomOut()}
          />
        </Grid>
        <MenuBar
          setLine={setLine}
          setPoints={setPoints}
          onToolChanged={setTool}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          menuIndex={menuIndex}
          setMenuIndex={setMenuIndex}
          dragImageRef={dragImageRef}
          scale={scale}
          setScale={setScale}
          setElements={setElements}
          elements={elements}
          nextAction={nextAction}
          blocks={blocks}
          places={places}
          setBlocks={setBlocks}
          setPlaces={setPlaces}
          setSelectedElement={setSelectedElement}
          roomType={room?.type}
        />
        <Button variant='contained' color='primary' onClick={saveEditedRoom} className={classes.button}>
          <Typography variant='subtitle1' color='inherit'>
            Salvar
          </Typography>
        </Button>
      </Grid>
      <ShowModal
        title={isEdit ? 'Alterações salvas com sucesso!' : 'Sala adicionada com sucesso!'}
        message='Agora você já pode reservar lugares dentro desta sala cadastrada.'
        isOpen={openModal}
        image={SaveRoomImage}
        handleClose={() => uHistory.push('/rooms')}
        secondActionLabel='Fechar'
      />
      <Grid item xs={12} style={{ cursor: cursor ? 'grab' : 'default' }} onClick={() => closeMenuBarWithClick()}>
        {room && scale && blocks && (
          <Map
            onPositionChanged={onPositionChanged}
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            elements={elements}
            places={places}
            setPlaces={setPlaces}
            blocks={blocks}
            setBlocks={setBlocks}
            polygon={room.polygon}
            scale={scale}
            zoom={zoom}
            centralize={centralize}
            handleDescentralize={handleDescentralize}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            setCursor={setCursor}
            handleDelete={handleDelete}
            handleRotateLeft={handleRotateLeft}
            handleRotateRight={handleRotateRight}
            handleCopyElement={copyElement}
            rotateText={rotateText}
            draggedElement={dragImageRef}
            nextAction={nextAction}
            area={area}
            points={points}
            setPoints={setPoints}
            line={line}
            setLine={setLine}
            selectedWall={selectedWall}
            setSelectedWall={setSelectedWall}
            removeWall={deleteWall}
            //Refatorar o componente de toolbar de dentro do mapa
          />
        )}
      </Grid>
    </Grid>
  )
}

export default App
