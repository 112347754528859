import React, { useRef, useState, useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  Container,
  ThemeProvider,
  Box,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { useStyles } from './styles'
import Row from './row'
import { materialTheme } from '../../../../theme/table'
import LinearProgress from '@material-ui/core/LinearProgress'

const headCells = [
  { id: 'date', numeric: false, disable: false, label: 'Data' },
  { id: 'user', disable: false, numeric: false, label: 'Usuário' },
  { id: 'table', disable: false, numeric: false, label: 'Mesa' },
  { id: 'block', disable: false, numeric: false, label: 'Bloco' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={headCell.disable ? order : false}
            padding={'default'}
            className={classes.header}
          >
            {headCell.disable && headCell.label}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {!headCell.disable && headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function RoomsTableList({ reservations }) {
  const rootRef = useRef(null)
  const { height, ref } = useResizeDetector()
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('user')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 20, 30]
  const [valueFiltered, setValueFiltered] = useState()
  const [lastHeight, setLastHeight] = useState(height)

  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable < rowsPerHeight) {
          setLastHeight(height)
        } else {
          return
        }
      }
    }
    if (lastHeight !== height && reservations !== undefined && reservations.length > 0) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions])

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
  const heightPerRow = () => {
    let rows =
      (ref.current.firstChild.offsetHeight - ref.current.firstChild.firstChild.firstChild.offsetHeight) /
      ref.current.firstChild.firstChild.lastChild.firstChild.offsetHeight
    return Math.round(rows)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  return (
    <Container className={classes.root} ref={rootRef}>
      <Paper className={classes.paper} ref={ref} elevation={0}>
        {reservations === undefined ? (
          <LinearProgress />
        ) : reservations.length === 0 ? (
          <Typography className={classes.errorMessage}> Nenhuma sala reservada </Typography>
        ) : (
          <>
            <ThemeProvider theme={materialTheme}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} aria-labelledby='tableTitle' aria-label='enhanced table' stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={reservations.length}
                  />
                  <TableBody>
                    {stableSort(reservations, getComparator(order, orderBy))
                      .filter(row => (valueFiltered ? filterValues(row) : row))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(reservation => {
                        return <Row row={reservation} key={reservation.id} />
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.box_lastRow}>
                <Box className={classes.box_half}></Box>
                <Box className={classes.box_half}>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    count={valueFiltered ? reservations.filter(row => filterValues(row)).length : reservations.length}
                    page={page}
                    onChangePage={handleChangePage}
                    labelRowsPerPage={'Linhas por página:'}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            </ThemeProvider>
          </>
        )}
      </Paper>
    </Container>
  )
}
