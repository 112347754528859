
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    cardMetrics :{
        border:" 1px solid #E2E2E2",
        boxSizing: "border-box",
        borderRadius: "6px",
    },  
    cardTitle :{
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "140%",
        textTransform: "capitalize",
        color: "#979797",
        marginBottom: "10px"
    },  
    numberData:{
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "120%",
        letterSpacing: "-0.4px",
        color: "#181819 "
    },
    percentage:{
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "120%",
        letterSpacing: "-0.4px",
        marginLeft: "200px"
    }
}));
  
export default useStyles