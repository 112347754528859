import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    maxWidth: '1280px',
    padding: '24px',
  },
}))

export default useStyles
