import { Box, Container, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { Layer, Line, Stage } from 'react-konva'
import TableGroup from '../../Rooms/tableGroup'
import RoomElements from '../roomElements'
import useStyles from './styles.js'

export default ({ roomProps, line, zoom }) => {
  const classes = useStyles()
  const container = useRef(null)
  const [room, setRoom] = useState(roomProps)
  const stage = useRef(null)

  useEffect(() => {
    const handleFecthData = () => {
      setRoom(roomProps)
    }

    handleFecthData()
  }, [room, roomProps])

  const [walls, setWalls] = useState([])
  const [minX, setMinX] = useState()
  const [minY, setMinY] = useState()
  const [maxX, setMaxX] = useState()
  const [maxY, setMaxY] = useState()

  useEffect(() => {
    if (!room.polygon) return
    const mx = Math.min(...room.polygon.map(point => point.x))
    const my = Math.min(...room.polygon.map(point => point.y))
    const mX = Math.max(...room.polygon.map(point => point.x))
    const mY = Math.max(...room.polygon.map(point => point.y))
    setMinX(mx)
    setMinY(my)
    setMaxX(mX)
    setMaxY(mY)
  }, [room])

  useEffect(() => {
    if (!room.polygon) return
    let aux = []
    room.polygon.forEach(point => {
      aux.push((point.x - minX) * room.scale + (room.width - (maxX - minX) * room.scale) / 2),
        aux.push((point.y - minY) * room.scale + (room.height - (maxY - minY) * room.scale) / 2)
    })
    setWalls(aux)
  }, [room, minX, minY, maxX, maxY, zoom])

  const AllWallsisNumber = walls?.every(wall => typeof wall === 'number' && !isNaN(wall))

  return (
    <main className={classes.content}>
      <Container className={classes.container}>
        <Box className={classes.mapPaper}>
          <Paper className={classes.card_paper} ref={container} elevation={0}>
            <Stage
              width={room.width}
              height={room.height}
              ref={stage}
              style={{ alignSelf: 'center', margin: 'auto' }}
              scale={{ x: zoom, y: zoom }}
              draggable={true}
            >
              <Layer>
                {walls && walls.length > 0 && AllWallsisNumber && (
                  <Line closed stroke='black' strokeWidth={3} points={walls} />
                )}
                {line &&
                  line.map((ln, index) => {
                    return (
                      <Line
                        stroke='black'
                        strokeWidth={5}
                        draggable={false}
                        points={ln.points}
                        closed={false}
                        key={index + new Date()}
                      />
                    )
                  })}
                {room.elements &&
                  room.elements.map((ele, index) =>
                    ele.workstation ? (
                      <TableGroup element={ele} key={index} />
                    ) : (
                      <RoomElements element={ele} key={index} />
                    )
                  )}
              </Layer>
            </Stage>
          </Paper>
        </Box>
      </Container>
    </main>
  )
}
