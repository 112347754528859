import { Box } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { Circle, Group, Image, Layer, Line, Stage } from 'react-konva'
import useImage from 'use-image'
import Element from '../mapElements'
import Toolbar from '../toolbar'
import WallTool from '../wallTools'
import Grid from './grid'
import { getElementImage } from '../menuBar/model'

const Map = ({
  setSelectedElement,
  selectedElement,
  elements,
  polygon,
  scale,
  zoom,
  centralize,
  handleDescentralize,
  handleZoomIn,
  handleZoomOut,
  setCursor,
  draggedElement,
  handleDelete,
  handleRotateLeft,
  handleRotateRight,
  setBlocks,
  blocks,
  setPlaces,
  places,
  nextAction,
  rotateText,
  handleCopyElement,
  area,
  points,
  setPoints,
  setLine,
  line,
  selectedWall,
  setSelectedWall,
  removeWall,
}) => {
  const blockSize = 50
  const [grid, setGrid] = useState({ width: 0, height: 0 })
  const [walls, setWalls] = useState([])
  const [roomWidth, setRoomWidth] = useState(0)
  const [roomHeight, setRoomHeight] = useState(0)
  const [zoomScreen, setZoomScreen] = useState(1)
  let stageRef = useRef()

  // Width and Height of screen
  const height = window.innerHeight - 64
  const width = window.innerWidth

  const minX = Math.min(...polygon.map(point => point.x))
  const minY = Math.min(...polygon.map(point => point.y))
  const maxX = Math.max(...polygon.map(point => point.x))
  const maxY = Math.max(...polygon.map(point => point.y))
  let all = useRef(true)

  const [img] = useImage(area?.imageResizedURL)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const handleDragEnd = (e, id) => {
    if (!all.current) return
    const currentElements = elements.map(ele => {
      return ele.id === id
        ? {
            ...ele,
            x: e.target.attrs.x,
            y: e.target.attrs.y,
          }
        : ele
    })
    nextAction(currentElements)
  }

  // Apply zoom with ctrl+wheel
  const handleWheel = e => {
    if (!all.current) return
    if (!e.evt.ctrlKey) return
    e.evt.preventDefault()

    const scaleBy = 0.9
    const stage = stageRef.current
    if (stage.getPointerPosition().x > stage.attrs.width || stage.getPointerPosition().y > stage.attrs.height) return
    const oldScale = stage.scaleX()

    const { x: pointerX, y: pointerY } = stage.getPointerPosition()
    const mousePointTo = {
      x: (pointerX - stage.x()) / oldScale,
      y: (pointerY - stage.y()) / oldScale,
    }

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy
    if ((e.evt.deltaY <= 0 && zoom / 0.9 > 300) || (e.evt.deltaY > 0 && zoom * 0.9 < 50)) return
    e.evt.deltaY > 0 ? handleZoomOut() : handleZoomIn()
    stage.scale({ x: newScale, y: newScale })
    const newPos = {
      x: pointerX - mousePointTo.x * newScale,
      y: pointerY - mousePointTo.y * newScale,
    }
    stage.position(newPos)
    stage.batchDraw()
  }

  //Apply background in editor
  const clipFunction = ctx => {
    ctx.beginPath()
    for (let i = 0; i < walls.length; i += 2) {
      ctx.lineTo(walls[i], walls[i + 1])
    }
    ctx.closePath()
  }

  // set square Grid (in background) width and height
  useEffect(() => {
    if (!all.current) return
    setGrid({
      width: blockSize * (Math.ceil(roomWidth / blockSize) + 1), //(((roomWidth + 99) / 50) >> 0),
      height: blockSize * (Math.ceil(roomHeight / blockSize) + 1), //(((roomHeight + 99) / 50) >> 0),
    })
  }, [roomWidth, roomHeight])
  // set points of polygon with scale to draw the walls limits
  useEffect(() => {
    if (!all.current) return
    setRoomWidth((maxX - minX) * scale)
    setRoomHeight((maxY - minY) * scale)
    let aux = []
    polygon.forEach(point => {
      aux.push((point.x - minX) * scale), aux.push((point.y - minY) * scale)
    })
    setWalls(aux)
  }, [scale])
  // apply zoom to fit the entire room in the screen
  useEffect(() => {
    if (!all.current || !stageRef.current || !grid.width || !grid.height) return
    const initialScale = Math.min((width - 200) / grid.width, (height - 80) / grid.height)
    setZoomScreen((zoom / 100) * initialScale)
  }, [stageRef, grid, zoom, scale])

  // centralize room position (only when page loads or scale changes)
  useEffect(() => {
    if (!all.current || !stageRef.current || !grid.width || !grid.height || !centralize) return
    const stage = stageRef.current
    stage.scale({ x: zoomScreen, y: zoomScreen })
    stage.position({
      x: (width - grid.width * zoomScreen) / 2,
      y: (height - grid.height * zoomScreen) / 2,
    })
    stage.batchDraw()
  }, [zoomScreen, centralize])

  const updateLinePosition = e => {
    let arr = []
    line.map((ln, idx) => {
      if (idx === selectedWall - 1) {
        let pts = [
          ...ln.points.map((pointLine, indexLine) => {
            if (Math.floor(indexLine / 2) === e.target.attrs.id) {
              return indexLine % 2 === 1 ? e.target.attrs.y : e.target.attrs.x
            } else return pointLine
          }),
        ]
        return arr.push({ points: pts })
      } else {
        return arr.push(ln)
      }
    })
    setLine(arr)
  }

  const updatePointsPosition = (x, y, index) => {
    if (!all.current) return
    let pointsAux = []

    for (let i = 0; i < points.length; i++) {
      if (i === selectedWall - 1) {
        let aux = { pts: [] }
        for (let j = 0; j < points[i].pts.length; j++) {
          aux.pts.push(j === index ? { x: x, y: y } : { x: points[i].pts[j].x, y: points[i].pts[j].y })
        }
        pointsAux.push(aux)
      } else {
        pointsAux.push(points[i])
      }
    }
    setPoints(pointsAux)
  }
  useEffect(() => {
    if (!all.current) return
  }, [])

  const unselectElement = () => {
    setSelectedWall(null)
    setSelectedElement('')
  }
  return (
    <Box
      onDragOver={e => e.preventDefault()}
      onDrop={e => {
        e.preventDefault()
        stageRef.current.setPointersPositions(e)
        const stage = stageRef.current
        const newElement = {
          ...draggedElement.current,
          ...(draggedElement.current.workstation ? { index: blocks } : { index: 0 }),
          x: (stage.getPointerPosition().x - stage.attrs.x) / zoomScreen,
          y: (stage.getPointerPosition().y - stage.attrs.y) / zoomScreen,
          rotation: 0,
          id: Math.floor(Math.random() * 10000),
          textLocation: 0,
        }
        draggedElement.current.workstation && setPlaces(places + ((draggedElement.current.places / 2) >> 0))
        if (draggedElement.current.workstation) setBlocks(blocks + 1)
        nextAction([...elements, newElement])
      }}
    >
      <Stage
        scale={{ x: zoomScreen, y: zoomScreen }}
        width={width}
        height={height}
        onWheel={e => handleWheel(e)}
        style={{ backgroundColor: `#FAFAFA` }}
        onClick={e => !e.target.attrs.image && unselectElement()}
        draggable
        ref={stageRef}
        onDragStart={() => setSelectedElement('')}
        onDragEnd={() => {
          handleDescentralize(), setCursor(false)
        }}
        onDragMove={() => setCursor(true)}
      >
        <Layer>
          <Group
            clipFunc={clipFunction}
            offset={{ x: -(grid.width - roomWidth) / 2, y: -(grid.height - roomHeight) / 2 }}
          >
            <Image
              image={img}
              opacity={0.5}
              offsetX={polygon[0].x}
              offsetY={polygon[0].y}
              scaleX={scale}
              scaleY={scale}
            />
            <Grid width={grid.width} height={grid.height} blockSize={blockSize} />
            <Line stroke='black' strokeWidth={3} points={walls} closed />
          </Group>

          {elements &&
            elements.map((ele, index) => {
              return (
                <Element
                  elementImage={getElementImage(ele.icon)}
                  key={index}
                  position={{ x: ele.x, y: ele.y }}
                  width={ele.width}
                  height={ele.height}
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  element={ele}
                  allElements={elements}
                  elementIndex={ele.index}
                  handleDragEnd={handleDragEnd}
                />
              )
            })}
          {points &&
            points.map(
              (point, index) =>
                point.pts &&
                point.pts.map((pt, ptIndex) => {
                  return (
                    <Group
                      x={pt.x}
                      y={pt.y}
                      onDragMove={updateLinePosition}
                      onDragEnd={e => updatePointsPosition(e.target.attrs.x, e.target.attrs.y, e.target.attrs.id)}
                      draggable={selectedWall - 1 === index}
                      id={ptIndex}
                      key={ptIndex}
                    >
                      {selectedWall - 1 === index && (
                        <Circle radius={3.24 / scale} fill='gray' opacity={0.5} width={15} height={15} />
                      )}
                    </Group>
                  )
                })
            )}
          {line &&
            line.map((ln, index) => {
              return (
                <Line
                  stroke={selectedWall - 1 === index ? 'red' : 'black'}
                  onClick={() => (selectedWall - 1 === index ? setSelectedWall(null) : setSelectedWall(index + 1))}
                  strokeWidth={5}
                  points={ln.points}
                  closed={false}
                  key={index + new Date()}
                />
              )
            })}
        </Layer>
      </Stage>
      <Toolbar
        selectedElement={selectedElement}
        handleDelete={handleDelete}
        handleRotateLeft={handleRotateLeft}
        handleRotateRight={handleRotateRight}
        handleCopyElement={handleCopyElement}
        stage={stageRef.current}
        scale={zoomScreen}
        rotateText={rotateText}
      />
      <WallTool
        removeWall={removeWall}
        selectedWall={selectedWall}
        points={points}
        scale={scale}
        stage={stageRef.current}
      />
    </Box>
  )
}

export default Map
