import { createMuiTheme, SvgIcon } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import {font_size_h2, font_height_h2, font_size_subtitle1, line_height_subtitle1, font_size_h6, line_height_h6, font_size_subtitle2, line_height_subtitle2, font_size_body2_index, line_height_body2_index} from '../utils/variaveis.js'

export const theme = createMuiTheme({
  palette: {
    initial: {
      main: '#fff',
    },
    primary: {
      main: '#53318A',
      dark: '#3A1A70',
      light: '#EB2368',
    },
    secondary: {
      main: '#27A163',
      dark: '#FF6C6C',
      light: '#FFAD0E',
    },

    //error
    error: {
      main: '#FF0E0E',
    },
    occupationLow: {
      main: '#25A061',
    },
    occupationMedium: {
      main: '#FFAD0E',
    },
    occupationHigh: {
      main: '#FF0E0E',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        boxShadow: 'none !important',
      },
    },
  },
  typography: {
    fontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '50px',
      lineHeight: '75px',
      color: '#000000',
      fontStyle: 'normal',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: font_size_h2,
      lineHeight: font_height_h2,
      color: '#000000',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '28px',
      lineHeight: '31px',
      color: '#000000',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '30px',
      color: '#000000',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '30px',
      color: '#000000',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: font_size_h6,
      lineHeight: line_height_h6,
      color: '#000000',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: font_size_subtitle1,
      lineHeight: line_height_subtitle1,
      color: '#000000',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: font_size_subtitle2,
      lineHeight: line_height_subtitle2,
      color: '#000000',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '19px',
      color: '#000000',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: font_size_body2_index,
      lineHeight: line_height_body2_index,
      color: '#000000',
    },
    body3: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '15px',
      height: '53px',
    },
  },
  props: {
    MuiCheckbox: {
      icon: <CheckBoxOutlineBlankIcon style={{ color: '#53318A' }} />,
    },
  },
})
