//Icones da aba Portas
import brickIcon from '../../../../assets/editRoom/tijolos.svg'
import columnIcon from '../../../../assets/editRoom/coluna.svg'
import openDoorIcon from '../../../../assets/editRoom/openDoor.svg'

//Icones da aba Alvenaria
import deskIcon from '../../../../assets/editRoom/desk.svg'
import businessMeetingIcon from '../../../../assets/editRoom/businessMeeting.svg'

//Mesas de trabalho
import tableWith1 from '../../../../assets/editRoom/elements/workTable/table1seat.svg'
import tableWith2 from '../../../../assets/editRoom/elements/workTable/table2seat.svg'
import tableWith2Uni from '../../../../assets/editRoom/elements/workTable/table2seatUnilateral.svg'
import tableWith3 from '../../../../assets/editRoom/elements/workTable/table3seat.svg'
import tableWith3Uni from '../../../../assets/editRoom/elements/workTable/table3seatUnilateral.svg'
import tableWith4 from '../../../../assets/editRoom/elements/workTable/table4seat.svg'
import tableWith4Dia from '../../../../assets/editRoom/elements/workTable/table4seatDiagonal.svg'
import tableWith4Uni from '../../../../assets/editRoom/elements/workTable/table4seatUnilateral.svg'
import tableWith5 from '../../../../assets/editRoom/elements/workTable/table5seat.svg'
import tableWith5Dia from '../../../../assets/editRoom/elements/workTable/table5seatDiagonal.svg'
import tableWith7Dia from '../../../../assets/editRoom/elements/workTable/table7seatDiagonal.svg'
import tableWith9Dia from '../../../../assets/editRoom/elements/workTable/table9seatDiagonal.svg'
import tableWith5Uni from '../../../../assets/editRoom/elements/workTable/table5seatUnilateral.svg'
import tableWith6 from '../../../../assets/editRoom/elements/workTable/table6seat.svg'
import tableWith6Uni from '../../../../assets/editRoom/elements/workTable/table6seatUnilateral.svg'
import tableWith7 from '../../../../assets/editRoom/elements/workTable/table7seat.svg'
import tableWith8 from '../../../../assets/editRoom/elements/workTable/table8seat.svg'
import tableWith9 from '../../../../assets/editRoom/elements/workTable/table9seat.svg'
import tableWith10 from '../../../../assets/editRoom/elements/workTable/table10seat.svg'

//Mesas de Reunião
import meetTableWith2 from '../../../../assets/editRoom/elements/meeting/meet2seat.svg'
import meetTableWith4 from '../../../../assets/editRoom/elements/meeting/meet4seat.svg'
import meetTableWith6 from '../../../../assets/editRoom/elements/meeting/meet6seat.svg'
import meetTableWith7 from '../../../../assets/editRoom/elements/meeting/meet7seat.svg'
import meetTableWith8 from '../../../../assets/editRoom/elements/meeting/meet8seat.svg'
import meetTableWith12 from '../../../../assets/editRoom/elements/meeting/meet12seat.svg'

//Decoração
import groupPlants from '../../../../assets/editRoom/elements/decoration/groupPlants.svg'
import squarePlants from '../../../../assets/editRoom/elements/decoration/squarePlants.svg'
import vasoTorto from '../../../../assets/editRoom/elements/decoration/vaso-torto.svg'
import vasoReto from '../../../../assets/editRoom/elements/decoration/vaso-reto.svg'
import bigLocker from '../../../../assets/editRoom/elements/decoration/bigLocker.svg'
import smallLocker from '../../../../assets/editRoom/elements/decoration/smallLocker.svg'
//Apenas pra teste

import columnGrey from '../../../../assets/editRoom/elements/masonry/columnGrey.svg'
import sofa from '../../../../assets/editRoom/elements/decoration/sofa.svg'

//Apenas pra teste
import tableIcon from '../../../../assets/table.svg'
import locker from '../../../../assets/locker.svg'
import column from '../../../../assets/column.svg'
import leftDoor from '../../../../assets/editRoom/elements/masonry/leftDoor.svg'
import rightDoor from '../../../../assets/editRoom/elements/masonry/rightDoor.svg'

import BLOCKS_LAYOUT from '../../../../config/blocks-layout.json'

const elementsImages = {
  //Mesas de Reunião
  meetTableWith2: meetTableWith2,
  meetTableWith4: meetTableWith4,
  meetTableWith6: meetTableWith6,
  meetTableWith7: meetTableWith7,
  meetTableWith8: meetTableWith8,
  meetTableWith12: meetTableWith12,

  //Mesas de trabalho
  tableWith1: tableWith1,
  tableWith2: tableWith2,
  tableWith2Uni: tableWith2Uni,
  tableWith3: tableWith3,
  tableWith3Uni: tableWith3Uni,
  tableWith4: tableWith4,
  tableWith4Dia: tableWith4Dia,
  tableWith4Uni: tableWith4Uni,
  tableWith5: tableWith5,
  tableWith5Dia: tableWith5Dia,
  tableWith7Dia: tableWith7Dia,
  tableWith9Dia: tableWith9Dia,
  tableWith5Uni: tableWith5Uni,
  tableWith6: tableWith6,
  tableWith6Uni: tableWith6Uni,
  tableWith7: tableWith7,
  tableWith8: tableWith8,
  tableWith9: tableWith9,
  tableWith10: tableWith10,

  //Decoração
  groupPlants: groupPlants,
  squarePlants: squarePlants,
  vasoTorto: vasoTorto,
  vasoReto: vasoReto,
  bigLocker: bigLocker,
  smallLocker: smallLocker,

  //Icones da aba Portas
  brickIcon: brickIcon,
  columnIcon: columnIcon,
  openDoorIcon: openDoorIcon,

  //Icones da aba Alvenaria
  deskIcon: deskIcon,
  businessMeetingIcon: businessMeetingIcon,

  tableIcon: tableIcon,
  locker: locker,
  column: column,
  leftDoor: leftDoor,
  rightDoor: rightDoor,
  sofa: sofa,
  columnGrey: columnGrey,
}

export function getElementImage(tableName) {
  return elementsImages[tableName]
}

const WORK_TABLE = 'WORK_TABLE'
const MEETING_TABLE = 'MEETING_TABLE'
const DECORATION = 'DECORATION'

const furnitures = [
  {
    name: 'Mesas',
    listItens: [
      {
        name: 'Porta',
        icon: 'openDoorIcon',
        itens: [
          { name: '2', parameter: 'Armário', icon: 'tableIcon', width: 1.4 * 83, height: 1.4 * 50, workstation: false },
          {
            name: '7',
            parameter: 'Porta L',
            icon: 'leftDoor',
            width: 1.4 * 50,
            height: 1.4 * 60,
            workstation: false,
            flipped: false,
          },
          {
            name: '6',
            parameter: 'Porta R',
            icon: 'rightDoor',
            width: 1.4 * 50,
            height: 1.4 * 60,
            workstation: false,
            flipped: false,
          },
          { name: '4', parameter: 'Coluna', icon: 'column', width: 1.4 * 50, height: 1.4 * 50, workstation: false },
        ],
      },
      {
        name: 'Coluna',
        icon: 'columnIcon',
        itens: [
          { name: '1', parameter: 'Coluna', icon: 'columnGrey', width: 1.4 * 35, height: 1.4 * 35, workstation: false },
        ],
      },
    ],
  },

  {
    name: 'Salas',
    listItens: [
      {
        name: 'Reunião',
        icon: 'businessMeetingIcon',
        itens: [
          {
            name: 'Mesa com 2 lugares',
            parameter: `${MEETING_TABLE}_2`,
            icon: 'meetTableWith2',
            width: 1.4 * 83,
            height: 1.4 * 50,
            workstation: false,
            places: 2,
          },
          {
            name: 'Mesa com 4 lugares',
            parameter: `${MEETING_TABLE}_4`,
            icon: 'meetTableWith4',
            width: 1.4 * 83,
            height: 1.4 * 83,
            workstation: false,
            places: 4,
          },
          {
            name: 'Mesa com 6 lugares',
            parameter: `${MEETING_TABLE}_6`,
            icon: 'meetTableWith6',
            width: 1.4 * 71.36,
            height: 1.4 * 75.26,
            workstation: false,
            places: 6,
          },
          {
            name: 'Mesa com 7 lugares',
            parameter: `${MEETING_TABLE}_7`,
            icon: 'meetTableWith7',
            width: 1.4 * 71.4,
            height: 1.4 * 64.37,
            workstation: false,
            places: 7,
          },
          {
            name: 'Mesa com 8 lugares',
            parameter: `${MEETING_TABLE}_8`,
            icon: 'meetTableWith8',
            width: 1.4 * 83.2,
            height: 1.4 * 64.37,
            workstation: false,
            places: 8,
          },
          {
            name: 'Mesa com 12 lugares',
            parameter: `${MEETING_TABLE}_12`,
            icon: 'meetTableWith12',
            width: 1.4 * 115.19,
            height: 1.4 * 61.44,
            workstation: false,
            places: 12,
          },
        ],
      },
      {
        name: 'Trabalho',
        icon: 'deskIcon',
        itens: [
          {
            name: 'Mesa com 1 lugar',
            parameter: `${WORK_TABLE}_1`,
            icon: 'tableWith1',
            width: 1.4 * 49.15,
            height: 1.4 * 43.81,
            workstation: true,
            places: 1,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 2 lugares',
            parameter: `${WORK_TABLE}_2`,
            icon: 'tableWith2',
            width: 1.4 * 49.15,
            height: 1.4 * 88.43,
            workstation: true,
            places: 2,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 2 lugares unilateral',
            parameter: `${WORK_TABLE}_2_UNILATERAL`,
            icon: 'tableWith2Uni',
            width: 1.4 * 98.89,
            height: 1.4 * 43.81,
            workstation: true,
            places: 2,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 3 lugares',
            parameter: `${WORK_TABLE}_3`,
            icon: 'tableWith3',
            width: 1.4 * 93.55,
            height: 1.4 * 88.43,
            workstation: true,
            places: 3,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 3 lugares unilateral',
            parameter: `${WORK_TABLE}_3_UNILATERAL`,
            icon: 'tableWith3Uni',
            width: 1.4 * 148.62,
            height: 1.4 * 43.81,
            workstation: true,
            places: 3,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 4 lugares',
            parameter: `${WORK_TABLE}_4`,
            icon: 'tableWith4',
            width: 1.4 * 98.89,
            height: 1.4 * 88.43,
            workstation: true,
            places: 4,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 4 lugares diagonal',
            parameter: `${WORK_TABLE}_4_DIAGONAL`,
            icon: 'tableWith4Dia',
            width: 1.4 * 147.6,
            height: 1.4 * 88.43,
            workstation: true,
            places: 4,
            layout: BLOCKS_LAYOUT.DIAGONAL,
          },
          {
            name: 'Mesa com 4 lugares unilateral',
            parameter: `${WORK_TABLE}_4_UNILATERAL`,
            icon: 'tableWith4Uni',
            width: 1.4 * 198.36,
            height: 1.4 * 43.81,
            workstation: true,
            places: 4,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 5 lugares',
            parameter: `${WORK_TABLE}_5`,
            icon: 'tableWith5',
            width: 1.4 * 143.29,
            height: 1.4 * 88.43,
            workstation: true,
            places: 5,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 5 lugares diagonal',
            parameter: `${WORK_TABLE}_5_DIAGONAL`,
            icon: 'tableWith5Dia',
            width: 1.4 * 182.2,
            height: 1.4 * 88.43,
            workstation: true,
            places: 5,
            layout: BLOCKS_LAYOUT.DIAGONAL,
          },
          {
            name: 'Mesa com 7 lugares diagonal',
            parameter: `${WORK_TABLE}_7_DIAGONAL`,
            icon: 'tableWith7Dia',
            width: 1.4 * 231.35,
            height: 1.4 * 88.43,
            workstation: true,
            places: 7,
            layout: BLOCKS_LAYOUT.DIAGONAL,
          },
          {
            name: 'Mesa com 9 lugares diagonal',
            parameter: `${WORK_TABLE}_9_DIAGONAL`,
            icon: 'tableWith9Dia',
            width: 1.4 * 280.5,
            height: 1.4 * 88.43,
            workstation: true,
            places: 9,
            layout: BLOCKS_LAYOUT.DIAGONAL,
          },
          {
            name: 'Mesa com 5 lugares unilateral',
            parameter: `${WORK_TABLE}_5_UNILATERAL`,
            icon: 'tableWith5Uni',
            width: 1.4 * 248.1,
            height: 1.4 * 43.81,
            workstation: true,
            places: 5,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 6 lugares',
            parameter: `${WORK_TABLE}_6`,
            icon: 'tableWith6',
            width: 1.4 * 148.62,
            height: 1.4 * 88.43,
            workstation: true,
            places: 6,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 6 lugares unilateral',
            parameter: `${WORK_TABLE}_6_UNILATERAL`,
            icon: 'tableWith6Uni',
            width: 1.4 * 297.83,
            height: 1.4 * 43.81,
            workstation: true,
            places: 6,
            layout: BLOCKS_LAYOUT.UNILATERAL,
          },
          {
            name: 'Mesa com 7 lugares',
            parameter: `${WORK_TABLE}_7`,
            icon: 'tableWith7',
            width: 1.4 * 193.02,
            height: 1.4 * 88.43,
            workstation: true,
            places: 7,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 8 lugares',
            parameter: `${WORK_TABLE}_8`,
            icon: 'tableWith8',
            width: 1.4 * 198.36,
            height: 1.4 * 88.43,
            workstation: true,
            places: 8,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 9 lugares',
            parameter: `${WORK_TABLE}_9`,
            icon: 'tableWith9',
            width: 1.4 * 242.76,
            height: 1.4 * 88.43,
            workstation: true,
            places: 9,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
          {
            name: 'Mesa com 10 lugares',
            parameter: `${WORK_TABLE}_10`,
            icon: 'tableWith10',
            width: 1.4 * 248.1,
            height: 1.4 * 88.43,
            workstation: true,
            places: 10,
            layout: BLOCKS_LAYOUT.NORMAL,
          },
        ],
      },
    ],
  },

  {
    name: 'Decoração',
    listItens: [
      {
        name: 'Ambiente',
        icon: '',
        itens: [
          {
            name: 'Vaso de flor ',
            parameter: `${DECORATION}_vaso1`,
            icon: 'vasoTorto',
            height: 1.4 * 30,
            width: 1.4 * 30,
            workstation: false,
          },
          {
            name: 'Vaso de flor ',
            parameter: `${DECORATION}_vaso2`,
            icon: 'vasoReto',
            height: 1.4 * 24,
            width: 1.4 * 25,
            workstation: false,
          },
          {
            name: 'Vaso de flor quadrado',
            parameter: `${DECORATION}_squarePlants`,
            icon: 'squarePlants',
            width: 1.4 * 30,
            height: 1.4 * 30,
            workstation: false,
          },
          {
            name: 'Vaso de flor grande ',
            parameter: `${DECORATION}_groupPlants`,
            icon: 'groupPlants',
            width: 1.4 * 70,
            height: 1.4 * 30,
            workstation: false,
          },
          {
            name: 'Armário grande ',
            parameter: `${DECORATION}_bigLocker`,
            icon: 'bigLocker',
            width: 1.4 * 56.32,
            height: 1.4 * 35.11,
            workstation: false,
          },
          {
            name: 'Armário pequeno ',
            parameter: `${DECORATION}_smallLocker`,
            icon: 'smallLocker',
            width: 1.4 * 35.84,
            height: 1.4 * 30.72,
            workstation: false,
          },
          {
            name: 'Sofá ',
            parameter: `${DECORATION}_sofa`,
            icon: 'sofa',
            height: 1.4 * 23,
            width: 1.4 * 23,
            workstation: false,
          },
        ],
      },
    ],
  },
]
export default furnitures
