import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    root: props => ({
      marginTop: props.isEdit ? 0 : theme.spacing(10),
    })
  },
  root:{
    flexGrow:'1',
  },
  card_paper: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      maxHeight: 700,
      minHeight: 350,
      height: '40%',
    },
  },
  content:{
    height:'100%',
  },
  container:{
    height:'100%',
  },
  skeleton: {
    borderRadius: '4px',
  },
  [theme.breakpoints.up('md')]: {
    mapPaper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
  },
  circularStyle: {
    alignSelf: 'center',
    marginLeft: '50%',
    marginTop: '25%',
  },
}))

export default useStyles
