import { makeStyles } from '@material-ui/core'
import {image_botton_margin, image_width} from '../../../utils/variaveis.js'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '95%',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  image: {
    width: image_width,
    marginBottom: image_botton_margin,
  },
  button: {
    fontSize: '15px',
    marginTop: '50px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    marginBottom: theme.spacing(2),
    width: '90%',
    height: '53px',
    textTransform: 'none',
    borderColor: '#835cbb',
    padding: '15px',
  },
}))

export default useStyles
