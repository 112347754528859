import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { Grid, Button, Box, CircularProgress, Typography, Snackbar } from '@material-ui/core'
import useStyles from './styles'
import AreaBoard from '../../areaBoard'
import { FirebaseContext } from '../../../contexts/firebase'
import useImage from 'use-image'
import MuiAlert from '@material-ui/lab/Alert'
import ZoomControl from './../../../components/zoomControl'

export default ({}) => {
  const { state } = useLocation()
  const { areaID, roomID } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [area, setArea] = useState()
  const [rooms, setRooms] = useState([])
  const [roomToEdit, setRoomToEdit] = useState()
  const [polygon, setPolygon] = useState(null)
  const [loading, setLoading] = useState(true)
  const firebase = useContext(FirebaseContext)
  const divRef = useRef()
  const all = useRef(true)
  const [img] = useImage(area?.imageResizedURL)
  const [zoom, setZoom] = useState(1)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertInvalidPosition, setShowAlertInvalidPosition] = useState(false)

  useEffect(() => {
    return () => (all.current = false)
  }, [])
  useEffect(() => {
    if (!all.current) return

    if (!areaID || !roomID) {
      history.push('/areas')
      return
    }

    if (!state) {
      history.push({ pathname: '/areas' })
      return
    }

    setRoomToEdit(state.room)

    firebase.getAreaByID(areaID).then(area => setArea(area))
    firebase.getRoomsByID(areaID).then(rooms => {
      setRooms(rooms.filter(room => state.room.id !== room.id)), setLoading(false)
    })
  }, [])
  const savePolygon = polygon => {
    if (!all.current) return
    setPolygon(polygon)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const handleCloseSnackBar = reason => {
    if (reason === 'clickaway') {
      return
    }
    setShowAlert(false)
    setShowAlertInvalidPosition(false)
  }

  const handleSubmit = () => {
    let validPolygon = true
    if (polygon) {
      polygon.forEach(point => {
        if (point.x > img.width || point.x < 0) {
          validPolygon = false
          return
        } else if (point.y > img.height || point.y < 0) {
          validPolygon = false
          return
        }
      })
      if (!validPolygon) {
        setShowAlertInvalidPosition(true)
        return
      }
    }
    if (!polygon) {
      setShowAlert(true)

      return
    }
    setLoading(true)

    history.push({
      pathname: './editor',
      state: { room: { ...roomToEdit, polygon }, isDuplicate: state.isDuplicate, pictures: state.pictures },
    })
  }
  const handleZoomIn = () => {
    if (!all.current) return
    setZoom(Math.min(zoom / 0.9, 3))
  }

  const handleZoomOut = () => {
    if (!all.current) return
    setZoom(Math.max(zoom * 0.9, 0.5))
  }

  const handleCentralize = () => {
    if (!all.current) return
    setZoom(1)
  }

  return (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {!loading && img && roomToEdit ? (
        <Grid container direction='row' justify='flex-start' className={classes.root}>
          <Grid container item xs={12} justify='flex-end'>
            <Grid item>
              <Button variant='contained' color='primary' onClick={handleSubmit} className={classes.button}>
                <Typography variant='subtitle1' color='inherit'>
                  {' '}
                  Avançar
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.divMap}>
            <Grid item className={classes.divarea}>
              <div ref={divRef} className={classes.areaBoard}>
                {img && roomToEdit ? (
                  <AreaBoard
                    width={img.width}
                    height={img.height}
                    image={img}
                    rooms={rooms.filter(room => room.nid !== roomID && room.polygon)}
                    roomToEdit={roomToEdit}
                    savePolygon={savePolygon}
                    divRef={divRef}
                    isEdit={true}
                    isScale={false}
                    isDuplicated={state.isDuplicate}
                    zoom={zoom}
                  />
                ) : null}
              </div>
            </Grid>
            <ZoomControl
              handleCentralize={() => handleCentralize()}
              handleZoomIn={() => handleZoomIn()}
              handleZoomOut={() => handleZoomOut()}
            />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress style={{ marginTop: '20%' }} />
      )}
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity='error'>
          Desenhe um polígono válido para continuar!
        </Alert>
      </Snackbar>
      <Snackbar open={showAlertInvalidPosition} autoHideDuration={3000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity='error'>
          Desenhe um polígono em uma posição válida para continuar!
        </Alert>
      </Snackbar>
    </Box>
  )
}
