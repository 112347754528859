import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '80%',
    margin: 'auto',
    padding: '24px',
  },
  button: {
    height: '42px',
    width: '156px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
  },
  divarea: {
    maxWidth: '95%',
    margin:'auto',
  },
  divMap:{
    display:'flex',
    justifyContent:'space-between',
    width:'100%',
    paddingTop:'25px',
  },
  areaBoard: {
    width: '100%',
    height:'70vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#58318A',
      transition: 'background .3s',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#AE8CC2',
    },
  }
}))
export default useStyles
