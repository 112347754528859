import RESERVATIONSTATUS from '../config/reservations-status.json'

const makeCodeByReservation = reservation => {
  if (reservation.type === 1) return `S${reservation.roomNid}B0M0`
  const { roomNid, block, table } = reservation
  return `S${roomNid}B${block}M${table}`
}

export const getReservationStatus = item => {
  if (item.status) {
    const hasStatus = type => item.status.some(i => i.type === type)
    if (hasStatus(RESERVATIONSTATUS.CANCELED)) return RESERVATIONSTATUS.CANCELED
    if (hasStatus(RESERVATIONSTATUS.ATTENDED)) return RESERVATIONSTATUS.ATTENDED
  }
  return RESERVATIONSTATUS.PENDING
}

function meetingBookedHour(reservationHour) {
  let hours = []
  let hour = 7

  for (let i = 0; i < 24; i += 2) {
    hours[i] = { hour: hour, minutes: 0 }

    hours[i + 1] = { hour: hour, minutes: 30 }

    hour++
  }

  return hours[reservationHour]
}

export const verifyCheckin = (reservations, checkinCode) => {
  const reservation = reservations.find(reservation => {
    if (reservation.type == 1) {
      const today = new Date()
      const startHour = meetingBookedHour(reservation.initDateSlot)
      const endHour = meetingBookedHour(reservation.endDateSlot)

      const startReservationDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        startHour.hour,
        startHour.minutes,
        0,
        0
      )
      const endReservationDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        endHour.hour,
        endHour.minutes,
        0,
        0
      )

      if (today >= startReservationDate && today <= endReservationDate && reservation.status.length == 1) {
        const rc = makeCodeByReservation(reservation)
        return rc == checkinCode
      }
      return false
    } else {
      const rc = makeCodeByReservation(reservation)
      return rc == checkinCode
    }
  })

  return reservation
}

export const verifySeatBlock = (blockCapacity, index, layout) => {
  switch (blockCapacity) {
    case 1:
      return 1

    case 2:
      var seatId = [1, 2]
      return seatId[index]

    case 3:
      var seatId = [1, 2, 3]
      return seatId[index]

    case 4:
      if (layout == 2) {
        var seatId = [1, 2, 3, 4]
        return seatId[index]
      } else {
        var seatId = [3, 1, 4, 2]
        return seatId[index]
      }
    case 5:
      if (layout == 2) {
        var seatId = [1, 2, 3, 4, 5]
        return seatId[index]
      } else {
        var seatId = [3, 1, 4, 2, 5]
        return seatId[index]
      }
    case 6:
      if (layout == 2) {
        var seatId = [1, 2, 3, 4, 5, 6]
        return seatId[index]
      } else {
        var seatId = [4, 1, 5, 2, 6, 3]
        return seatId[index]
      }
    case 7:
      var seatId = [4, 1, 5, 2, 6, 3, 7]
      return seatId[index]

    case 8:
      var seatId = [5, 1, 6, 2, 7, 3, 8, 4]
      return seatId[index]

    case 9:
      var seatId = [5, 1, 6, 2, 7, 3, 8, 4, 9]
      return seatId[index]

    case 10:
      var seatId = [5, 10, 4, 9, 3, 8, 2, 7, 1, 6]
      return seatId[index]

    default:
      return index
  }
}

export const getRoomCapacity = room => {
  let lotationCount = 0

  if (!room || !room.elements) {
    return lotationCount
  }

  const tables = room.elements.filter(t => t.workstation)

  tables.forEach(table => {
    if (table.places % 2 === 0) {
      lotationCount += table.places
    } else {
      if (table.places === 1) {
        lotationCount++
      } else {
        if (table.unilateral) {
          lotationCount += table.places
        } else {
          lotationCount += table.places
        }
      }
    }
  })

  return lotationCount
}
