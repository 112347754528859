import React, { useEffect, useState, useRef, useContext } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import { useHistory, useParams } from 'react-router-dom'
import ZoomControl from '../../components/zoomControl'

import { Box, Button, Container, Grid, GridList, GridListTile } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import useStyles from './styles'
import QrCode from './qrcode'
import RoomMap from '../Rooms/roomMap'
import ReactToPrint from 'react-to-print'
import PrintIcon from '@material-ui/icons/Print'
import { convertPointsToLine } from '../../utils/points-to-line'

const PrintPage = () => {
  const firebase = useContext(FirebaseContext)
  const { id } = useParams()

  const [room, setRoom] = useState([])
  const classes = useStyles()
  const componentRef = useRef()
  const [data, setData] = useState([])
  const [line, setLine] = useState([])
  const [zoom, setZoom] = useState(1)

  const MAX_ZOOM = 2
  const MIN_ZOOM = 0.5

  const history = useHistory()
  useEffect(() => {
    const getData = async () => {
      await firebase.getRoom(id).then(room => setRoom(room))
    }
    getData()
  }, [id])

  const zoomIn = () => {
    if (zoom < MAX_ZOOM) {
      setZoom(0.1 + zoom)
    }
  }

  const zoomOut = () => {
    if (zoom > MIN_ZOOM) {
      setZoom(zoom - 0.1)
    }
  }

  const centralize = () => {
    setZoom(1)
  }
  useEffect(() => {
    convertPointsToLine(room.walls || [], setLine)
  }, [room])

  const handleBack = () => {
    history.push({
      pathname: `/rooms`,
    })
  }

  useEffect(() => {
    const handleQRCode = () => {
      if (room.type === 1) {
        setData([{ codigo: `S${room.nid}B0M0`, bloco: `Sala de reunião - ${room.name}` }])
        return
      }
      const qrcodes =
        room.elements &&
        room.elements.map(block => {
          if (!block.workstation) return
          let ret = []
          for (let i = 0; i < block.places; i++) {
            const sid = `S${room.nid}`
            const bid = `B${block.index}`
            const mid = `M${i + 1}`
            const bloco = `BLOCO ${block.index}`
            const mesa = `MESA ${i + 1}`
            ret.push({
              codigo: `https://lockdesk.enforcegroup.com.br/checkin/${sid}${bid}${mid}`,
              bloco: bloco,
              mesa: mesa,
            })
          }

          return ret
        })

      let items = []
      qrcodes &&
        qrcodes.map(qrcode => {
          if (!qrcode) return
          qrcode.map(item => {
            items.push(item)
            setData(items)
          })
        })
    }
    handleQRCode()
  }, [room])

  return (
    <Box>
      <Button startIcon={<ArrowBack />} className={classes.backButton} onClick={() => handleBack()}>
        Voltar
      </Button>
      <Container className={classes.root}>
        <ReactToPrint
          trigger={() => (
            <Button variant='contained' color='default' className={classes.buttonImp} startIcon={<PrintIcon />}>
              Imprimir
            </Button>
          )}
          content={() => componentRef.current}
          style={{ margin: '20px 0' }}
        />
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={11} className={classes.root} ref={componentRef}>
            <RoomMap roomProps={room} line={line} zoom={zoom} />
            <Box className={classes.boxList}>
              <GridList cellHeight={300} className={`${classes.gridListTile} ${classes.pagePrint}`} cols={3}>
                {data &&
                  data.map((item, index) => {
                    const { codigo, bloco, mesa } = item
                    return (
                      <GridListTile
                        cols={1}
                        key={index}
                        className={`${classes.gridListTile} ${classes.pagePrint} ${classes.pageQrCode}`}
                      >
                        <QrCode codigo={codigo} bloco={bloco} mesa={mesa} />
                      </GridListTile>
                    )
                  })}
              </GridList>
            </Box>
          </Grid>
          <Box className={classes.zoomContainer}>
            <ZoomControl handleZoomIn={zoomIn} handleZoomOut={zoomOut} handleCentralize={centralize} />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default PrintPage
