import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  '@media print': {
    pagePrint: {
      display: 'block',
      float: 'left',
      pageBreakAfter: 'auto',
      pageBreakBefore: 'auto',
      pageBreakInside: 'avoid',
      textAlign: 'center',
      verticalAlign: 'middle',
      width: '210mm',
      minHeight: '99mm',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    pageQrCode: {
      border: '1px solid black',
    },
  },
  backButton: {
    margin: '20px 0 40px 20px',
  },
  button: {
    height: 42,
  },
  paper: {
    paddingBottom: '18px',
  },
  boxList: {
    marginTop: theme.spacing(15),
  },
  gridListTile: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '75px',
  },
  button: {},
  buttonImp: {
    marginTop: '15px',
    marginLeft: '25px',
  },
}))

export default useStyles
