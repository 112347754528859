import React from 'react'
import { Stage, Layer, Arc, Text } from 'react-konva'

const NoShowPie = ({ perc, dim,colors }) => {
  perc = Math.min(perc, 100)
  perc = Math.round(perc)
  const center = [dim[0] / 2, dim[1] / 2]
  const pi = Math.PI
  const distance = 15
  const a = Math.sin((perc * pi) / 100)
  const b = Math.cos((perc * pi) / 100)
  const xDif = perc && perc < 100 ? 20 + Math.abs(Math.max(perc, 100 - perc) - 90) / 5 : -25
  const yDif = perc && perc < 100 ? 10 : 10

  return (
    <Stage width={dim[0]} height={dim[1]}>
      <Layer>
        <Arc
          x={center[0] + (b * distance) / 2}
          y={center[1] + (a * distance) / 2}
          outerRadius={100}
          angle={(360 * perc) / 100}
          innerRadius={0}
          fill={colors[0]}
        />
        <Arc
          x={center[0] - (b * distance) / 2}
          y={center[1] - (a * distance) / 2}
          rotation={(360 * perc) / 100}
          outerRadius={100}
          angle={360 * (1 - perc / 100)}
          innerRadius={0}
          fill={colors[1]}
        />
        {perc >= 10 && (
          <Text
            text={`${perc}%`}
            x={center[0] + b * (90 + distance) * 0.6 - xDif}
            y={center[1] + a * (90 + distance) * 0.6 - yDif}
            width={60}
            height={30}
            fontSize={23}
            fontStyle={'bold'}
            align='center'
            verticalAlign='center'
            fill='white'
          />
        )}
        {perc <= 90 && (
          <Text
            text={`${100 - perc}%`}
            x={center[0] - b * (90 + distance) * 0.6 - xDif}
            y={center[1] - a * (90 + distance) * 0.6 - yDif}
            width={60}
            height={30}
            fontSize={23}
            fontStyle={'bold'}
            align='center'
            verticalAlign='center'
            fill='white'
          />
        )}
      </Layer>
    </Stage>
  )
}

export default NoShowPie
