import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import useStyles from './styles'
import { Card, CardContent, Typography, CardActions, Grid, CircularProgress } from '@material-ui/core'

const GraphicCard = ({
  title,
  seeMore,
  subTitle,
  legend,
  colors,
  children,
  graph,
  isLoading,
  currentDisplayMonth,
  path,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Grid container direction='row' justify='space-between'>
          <Typography variant='h6' className={classes.cardTitle}>
            {title}
          </Typography>
          {seeMore && (
            <Typography className={classes.link}>
              {currentDisplayMonth && (
                <Link to={`${path}/${currentDisplayMonth.url}`} className={classes.link}>
                  Ver todas{' '}
                </Link>
              )}
            </Typography>
          )}
        </Grid>
        <Typography variant='body2' className={classes.cardSubTitle}>
          {subTitle}
        </Typography>
        {!isLoading ? (
          <>
            <Grid align={'center'}>
              {children}
              {graph}
            </Grid>
            <CardActions style={{ gap: '20px 30px', flexWrap: 'wrap', justifyContent: 'center' }}>
              <Grid container>
                {legend &&
                  legend.map((lg, index) =>
                    index <= 2 ? (
                      <Grid item xs={legend.length > 2 ? 6 : 12} key={index} className={classes.align}>
                        <div style={{ backgroundColor: `${colors[index]}` }} className={classes.balls} />
                        <Typography variant='body2'>{lg.roomName || lg.label}</Typography>
                      </Grid>
                    ) : (
                      index == 3 && (
                        <Grid item xs={legend.length > 2 ? 6 : 12} key={index} className={classes.align}>
                          <div style={{ backgroundColor: `${colors[index]}` }} className={classes.balls} />
                          <Typography variant='body2'>Outras</Typography>
                        </Grid>
                      )
                    )
                  )}
              </Grid>
            </CardActions>
          </>
        ) : (
          <CircularProgress className={classes.circularStyle} />
        )}
      </CardContent>
    </Card>
  )
}

export default GraphicCard
