const getLastMonth = date => {
  const actualMonth = date.getMonth()
  const nextYear = date.getFullYear() + 1

  while (date.getMonth() - 1 >= actualMonth && date.getFullYear() <= nextYear) {
    date.setDate(date.getDate() - 1)
  }
  date.setHours(0, 0, 0, 0)

  return date
}

const getNextMonth = date => {
  const actualMonth = date.getMonth()
  const nextYear = date.getFullYear() + 1

  while (date.getMonth() <= actualMonth && date.getFullYear() <= nextYear) {
    date.setDate(date.getDate() + 1)
  }

  return date
}

export const getCurrentMonth = () => {
  if (new Date().getMonth()+1 < 10) {
    return `${new Date().getFullYear()}-0${new Date().getMonth() + 1}`
  } else {
    return `${new Date().getFullYear()}-${new Date().getMonth() + 1}` 
  }
  
}

export const getPreviousMonth = () => {

  if (new Date().getMonth() === 0) {

    return `${new Date().getFullYear() - 1}-12`

  }

  if (new Date().getMonth() < 10) {

    return `${new Date().getFullYear()}-0${new Date().getMonth()}`

  } else {

    return `${new Date().getFullYear()}-${new Date().getMonth()}`

  }

}

export const getMonthRange = date => ({
  start: getLastMonth(new Date(date)),
  end: getNextMonth(new Date(date)),
})

export const getDateRange = date => {
  const start = new Date(date.setHours(0, 0, 0, 0))
  const end = new Date(date.setHours(23, 59, 59))
  return {
    start,
    end,
  }
}

export const monthYearToDateName = (year, month) => {
  const { capitalize } = require('./string-manipulation')
  const date = new Date(year, month - 1, 1)
  const monthName = date.toLocaleString('pt-BR', { month: 'long' })
  return capitalize(`${monthName} de ${year}`)
}