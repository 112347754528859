import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    width: '100%',
    height: '189px',
    border: '2px dashed #53318A',
    boxSizing: 'border-box',
    borderRadius: '7px',
    cursor: 'pointer',
  },
  inputImage: {
    width: '100%',
    margin: '20px 0',
  },

}))
export default useStyles