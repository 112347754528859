import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
  },
  divtop: {
    fontSize: '12px',
    lineHeight: '24px',
    fontFeatureSettings: 'pnum on, lnum on',

    color: '#34313F',
  },
  divmargin: {
    color: '#34313F',
    flex: '1 1 100%',
    margin: '0 0 20px',
  },
  h1: {
    fontStyle: 'normal',
    fontSize: '21px',
    letterSpacing: '1px',
    color: '#34313F',
    flex: '1 1 100%',
    fontWeight: 'bold',
    margin: '0 0 5px',
  },
  divtitle: {
    width: '100%',
    marginTop: 'auto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  color: {
    color: '#707070',
  },
  divbutton: {
    width: '100%',
    marginTop: '28px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    height: '85%',
  },
  autocompletemes: {
    width: '49%',
  },

  autocompletesala: {
    width: '49%',
  },

  divautocomplete: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  button: {
    fontSize: '15px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    width: '15%',
    textTransform: 'none',
    borderColor: '#835cbb',
    display: "block",
  

  },
  gridButton:{
    display:"flex",
    justifyContent  :'flex-end',
    marginBottom: "18px",
    alignIems: "center"
  },
  buttonclean: {
    '&:hover': {
      borderColor: '#835cbb',
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#53318a',
    },
    height: '41px',
    width: '104px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '0',
    marginRight: '12px',
    textTransform: 'none',
    color: '#835cbb',
    borderColor: '#835cbb',
  },

  buttonfiltre: {
    '&:hover': {
      borderColor: '#835cbb',
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#53318a',
    },
    height: '41px',
    width: '104px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '0',
    textTransform: 'none',
    color: '#835cbb',
    borderColor: '#835cbb',
  },
}))
export default useStyles
