import { makeStyles, MenuItem, Menu, withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  MenuButton: {
    padding: '0px',
    margin: '0px',
  },
  paper: {
    background: '#FFFFFF',
    border: '1px solid #EEEEEE',
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visually_hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  box_half: {
    width: '100%',
  },
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: '#707070',
    '&:focus': {
      backgroundColor: '#fff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      background: '#53318a',
      color: '#fff',
      '& svg': {
        color: '#fff',
      },
    },
  },
}))(MenuItem)

const StyledMenu = withStyles(() => ({
  paper: {
    border: '1px solid #d3d4d5',
  },
}))(Menu)

export { useStyles, StyledMenu, StyledMenuItem }
