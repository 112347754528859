import { Grid, Paper, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useStyles from './styles'
import './styles'

const CustomFilter = ({ fields }) => {
  const classes = useStyles()
  return (
    <Paper variant='outlined' className={classes.paper}>
      <Typography variant='subtitle2' className={classes.divtitle}>
        Filtrar
      </Typography>
      <Grid container spacing={1} className={classes.grid}>
        {fields.map((field, index) => {
          return (
            <Grid item xs key={index}>
              <Autocomplete
                key={field.name}
                disableClearable={false}
                value={field.value}
                onChange={(e, value) => field.onChange(e, value, field.name)}
                options={field.options}
                renderInput={params => <TextField {...params} placeholder={field.label} variant='outlined' />}
              />
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default CustomFilter
