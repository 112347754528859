import React, { useState, useEffect } from 'react'

import { Stage, Layer, Line, Rect, Text, Group, Image } from 'react-konva'
import imageToolTipSVG from '../../../assets/tooltipNoShadow.svg'
import useImage from 'use-image'

const BarChart = ({ data, range, width, maxValue }) => {
  const height = 260
  const [imageToolTip] = useImage(imageToolTipSVG)
  const graphHeight = 204
  const colorGrid = '#CCC'
  const barWidth = 21
  const [lines, setLines] = useState([])
  const [bars, setBars] = useState([])
  const [yAxis, setYAxis] = useState([])
  const [tooltipData, setTooltipData] = useState({ x: 0, y: 0, title: '', sub: '' })
  const toolTipDif = [2, 60]
  const leftMargin = 30
  const rightMargin = 29
  const gridWidth = width - leftMargin - rightMargin
  const labelYWidth = leftMargin - 8
  const fontSize = 11
  const fontDeltaY = fontSize / 2
  const highValue = data ? Math.max(...data.map(x => x.reservations)) + 1 : 0
  const [showTooltip, setShowTooltip] = useState(false)
  const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

  useEffect(() => {
    const dataBars = () => {
      let bars = []
      let limit = data.slice(range[0], range[1])
      let size = limit.length
      let split = gridWidth / size
      let delta = split / 2 - barWidth / 2
      let ratio = graphHeight / highValue
      for (let i = 0; i < size; i++) {
        let h = limit[i].reservations * ratio
        let day = new Date(limit[i].timestamp)

        bars.push({
          labelWidth: split,
          labelX: i * split,
          x: i * split + delta,
          y: graphHeight - h,
          height: h,
          day: day.getDate(),
          week: daysOfWeek[day.getDay()],
          reservations: limit[i].reservations,
        })
      }

      return bars
    }

    const gridLines = () => {
      let lines = []
      let limit = data.slice(range[0], range[1])
      let size = limit.length
      let split = gridWidth / size
      for (let i = 0; i < size; i++) {
        lines.push({
          x: i * split + split,
        })
      }

      return lines
    }

    const getYAxis = () => {
      let values = [highValue, Math.round(highValue / 1.5), Math.round(highValue / 3), 0]
      let axis = []
      let space = graphHeight / (values.length - 1)
      for (let i = 0; i < values.length; i++) {
        axis.push({
          x: 0,
          y: i * space,
          label: values[i],
        })
      }
      return axis
    }

    setLines(gridLines())
    setBars(dataBars())
    setYAxis(getYAxis())
  }, [data, range, width])

  const setCurrentBar = bar => {
    setTooltipData({
      x: bar.x - toolTipDif[0],
      y: bar.height > graphHeight - (toolTipDif[1] + 10) ? 10 : bar.y - toolTipDif[1],
      title: 'Dia ' + bar.day,
      sub: 'Reservas: ' + bar.reservations,
    })
  }

  return (
    <Stage
      width={width}
      height={height}
      style={{ display: 'flex', alignItems: 'center' }}
      onMouseOver={() => {
        setShowTooltip(false)
      }}
    >
      <Layer>
        {yAxis.map((axis, index) => {
          return (
            <Text
              fontSize={fontSize}
              key={index}
              text={axis.label}
              align='right'
              y={axis.y}
              x={axis.x}
              width={labelYWidth}
            />
          )
        })}
        <Group x={leftMargin} y={fontDeltaY}>
          <Rect x={0} y={0} width={gridWidth} height={graphHeight} stroke={colorGrid} strokeWidth={1} />
          <Line x={0} y={graphHeight / 1.5} stroke={colorGrid} strokeWidth={1} points={[0, 0, gridWidth, 0]} />
          <Line x={0} y={graphHeight / 3} stroke={colorGrid} strokeWidth={1} points={[0, 0, gridWidth, 0]} />
          {lines.map((line, index) => {
            return (
              <Line x={line.x} y={0} stroke={colorGrid} strokeWidth={1} points={[0, 0, 0, graphHeight]} key={index} />
            )
          })}
          {bars.map((bar, index) => {
            return (
              <React.Fragment key={index}>
                <Rect
                  x={bar.x}
                  y={bar.y}
                  width={barWidth}
                  height={bar.height}
                  fill='#7F7FD5'
                  cornerRadius={[4, 4, 0, 0]}
                  onMouseOver={evt => {
                    evt.cancelBubble = true
                    setCurrentBar(bar)
                    setShowTooltip(true)
                  }}
                />
                <Text text={`${bar.week}`} x={bar.labelX} y={graphHeight + 10} width={bar.labelWidth} align='center' />
                <Text text={`${bar.day}`} x={bar.labelX} y={graphHeight + 25} width={bar.labelWidth} align='center' />
              </React.Fragment>
            )
          })}
        </Group>
        <Group
          x={tooltipData.x}
          y={tooltipData.y}
          visible={showTooltip}
          onMouseOver={evt => {
            evt.cancelBubble = true
            setShowTooltip(true)
          }}
        >
          <Image
            image={imageToolTip}
            x={0}
            y={0}
            shadowColor='black'
            shadowBlur={5}
            shadowOffset={{ x: 3, y: 3 }}
            shadowOpacity={0.25}
          />
          <Text
            x={0}
            y={12}
            text={tooltipData.title}
            width={80}
            align='center'
            fontSize={10}
            fontFamily='Poppins'
            fontStyle='bold'
            fill='#34313F'
          />
          <Text
            x={0}
            y={28}
            text={tooltipData.sub}
            width={80}
            align='center'
            fontSize={8}
            fontFamily='Poppins'
            fill='#B9B9B9'
          />
        </Group>
      </Layer>
    </Stage>
  )
}

export default BarChart
