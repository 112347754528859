import { createMuiTheme } from '@material-ui/core'
import { ptBR } from '@material-ui/core/locale'

const materialTheme = createMuiTheme(
  {
    typography: {
      fontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontWeight: 600,
        fontSize: '50px',
        lineHeight: '75px',
        color: '#000000',
        fontStyle: 'normal',
      },
      h2: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '30px',
        lineHeight: '36px',
        color: '#000000',
      },
      h3: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '28px',
        lineHeight: '31px',
        color: '#000000',
      },
      h4: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '30px',
        color: '#000000',
      },
      h5: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        color: '#000000',
      },
      h6: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '17px',
        lineHeight: '25px',
        color: '#000000',
      },
      subtitle1: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '22px',
        color: '#000000',
      },
      subtitle2: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '22px',
        color: '#000000',
      },
      body1: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '19px',
        color: '#000000',
      },
      body2: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#000000',
      },
      button: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '15px',
        color: '#000000',
      },
    },
    overrides: {
      MuiTypography:{
        subtitle2:{
          fontWeight: 'bold',
        }
      },
      MuiTableRow: {
        root: {
          selected: {
            '&:hover': { backgroundColor: '#000ddd' },
            backgroundColor: '#000ddd',
          },
        },
      },
      MuiTableCell: {
        root: {
          fontSize: '1rem',
        },
      },
      MuiGrid:{
        "align-items-xs-center":{
          alignItems:'flex-start',
        }
      },
      MuiButton:{
        root:{
          textTransform:'initial',
          fontSize: '0.875rem',
        },
        containedPrimary:{
          backgroundColor: '#53318A',
          marginBottom:'30px',
          '&:hover': {
            boxShadow: 'none',
            color: 'white',
            backgroundColor: '#835cbb',
          },
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          color: '#53318A',
          '&$checked': {
            color: '#53318A',
          },
        },
      },
    },
  },
  ptBR
)

export { materialTheme }
