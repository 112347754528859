import { Grid, Typography, LinearProgress, Button } from '@material-ui/core'
import useStyles from './styles'

export default function ImageInfo({ image, handleDelete }) {
  const classes = useStyles()

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={2}>
        <img src={URL.createObjectURL(image)} className={classes.image} />
      </Grid>
      <Grid item xs={8}>
        <Typography variant='subtitle1' color='textSecondary'>
          {image.name}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {new Date(image.lastModifiedDate).toLocaleDateString()}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.progressContainer}>
        <Button onClick={handleDelete}>X</Button>
      </Grid>
    </Grid>
  )
}
