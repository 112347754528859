import React from 'react'
import {Grid,Box, Card, CardContent} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

const LoadingCard = (isLoading) => {
    return(
        isLoading  && (
        <Card variant="outlined" style={{ height: '100%'}}>
            <CardContent>
                <Grid item>
                    <Skeleton animation="wave" variant="text" width={200}/>
                </Grid>
                <Box
                style={{
                    display: 'flex',
                    justifyContent:"space-between"
                }}
                >
                    <Skeleton animaton="wave" variant="text" width={30} height={30}/>
                    <Skeleton animation="wave" variant="text" width={50} height={30}/>
                
                </Box>
            </CardContent>
        </Card>  
        )     
    )
} 

export default LoadingCard;