import React, { useContext, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import * as ROUTES from '../../../config/routes'
import PropTypes from 'prop-types'
import flower from '../../../assets/rafiki.svg'
import {
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  TablePagination,
  TableContainer,
  TableSortLabel,
  TableBody,
} from '@material-ui/core'
import useStyles from './styles'
import { FirebaseContext } from '../../../contexts/firebase'
import { SessionContext } from '../../../contexts/session'
import FilterComponent from '../../../components/filterComponent/filter'
import ShowModal from '../../../components/modal'
import RowUserEdit from './row'
import { useResizeDetector } from 'react-resize-detector'

export default function PageRooms() {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  let location = useLocation()
  const firebase = useContext(FirebaseContext)
  const { authUser } = useContext(SessionContext)
  const [rooms, setRooms] = useState([])
  const user = location.state.user
  const [accessRoom, setAccessRoom] = useState([])
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [isRemove, setRemove] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState([])
  const [hasChanges, setChanges] = useState(false)
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('')
  const [valueFiltered, setValueFiltered] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const rowsPerPageOptions = [1, 2, 3, 4, 5, 10, 15, 20, 30]
  const { height, ref } = useResizeDetector()
  const [page, setPage] = useState(0)
  const [lastHeight, setLastHeight] = useState(0)
  const [action, setAction] = useState()

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const headCells = [
    { id: 'nid', numeric: false, disablePadding: true, label: 'Id sala' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Sala' },
    { id: 'área', numeric: false, disablePadding: true, label: 'Área' },
    { id: 'places', numeric: false, disablePadding: false, label: 'Lugares' },
    { id: 'manager', numeric: false, disablePadding: false, label: 'Gerente' },
    { id: 'public', numeric: false, disablePadding: false, label: 'Tipo da Sala' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  ]

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props
    const createSortHandler = property => event => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              className={classes.allChecks}
              checked={rooms.length > 0 && selected.length === rooms.length}
              indeterminate={selected.length > 0 && selected.length < rooms.length}
              onChange={handleSelectAllClick}
              color='primary'
            />
          </TableCell>

          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ paddingLeft: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF' }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visually_hidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  }

  const isSelected = name => selected.indexOf(name) !== -1

  useEffect(() => {
    const handleFetchData = async () => {
      setRooms(await firebase.getAllRooms())
      setAccessRoom(await firebase.getRooms(user.uid))
      setLoading(false)
      setLastHeight(height)
      setRows(rooms)
    }
    handleFetchData()
  }, [hasChanges, firebase, user.uid])
  useEffect(() => {
    const pagination = rowsPerHeight => {
      for (let amountDisplayInTable of rowsPerPageOptions) {
        if (amountDisplayInTable <= rowsPerHeight) {
          setRowsPerPage(amountDisplayInTable)
          setLastHeight(height)
        } else {
          return
        }
      }
    }
    if (lastHeight !== height && !loading) {
      pagination(parseInt(heightPerRow()))
    }
  }, [height, ref, rowsPerPageOptions, loading])

  const heightPerRow = () => {
    return Math.round(rows)
  }

  const handleRowPerMonth = e => {}

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const userFilter = (e, newSearch) => {
    setPage(0)

    setValueFiltered(newSearch)
  }

  const filterValues = room => {
    if (room.areaName || room.name) {
      return room.areaName === valueFiltered.areaName || room.name === valueFiltered.name
    }
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const nonPublicRooms = rooms.filter(n => {return !n.public})
      const newSelecteds = nonPublicRooms.map(n => n.id)
      setSelected(newSelecteds)

      return
    }
    setSelected([])
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }

    if (b[orderBy] > a[orderBy]) {
      return 1
    }

    return 0
  }

  const listAutoComplete = [
    {
      title: 'Sala',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(rooms, getComparator('asc', 'name')),
      comparator: 'name',
    },
    {
      title: 'Área',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(rooms, getComparator('asc', 'areaName')),
      comparator: 'areaName',
      returnField: 'areaName',
    },
    {
      title: 'Status',
      disableClear: false,
      value: valueFiltered ? valueFiltered : '',
      onChange: userFilter,
      options: stableSort(rooms, getComparator('asc', 'public')),
      comparator: 'public',
    },
  ]

  //Page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Open dialog after removing the useu room
  const handleOpen = (isRemove, room, action) => {
    setAction(action)
    setOpen(true)
    setRemove(isRemove)
    setSelectedRoom(room)
  }
  //Close dialog
  const handleClose = () => {
    setOpen(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleOpenModal = row => {
    setSelectedRow(row)
    setOpenModal(true)
  }

  const handleAccessUser = async () => {
    selected.map(async item => {
      if (isRemove) {
        await firebase.removeUserFromRoom(user.uid, item, authUser.permission)
      } else {
        await firebase.addUserToRoom(user.uid, item, authUser.permission)
      }
      setLoading(true)
      setChanges(!hasChanges)
      handleClose()
    })
    setSelected([])
  }

  const modalInformation = {
    title: {
      vincular:`Tem certeza que deseja adicionar?`, 
      desvincular:`Tem certeza que deseja remover?`,
    }, 
    message: {
      vincular:`Tem certeza que deseja adicionar a sala para ${user.displayName}?`, 
      desvincular:`Tem certeza que deseja remover a sala para ${user.displayName}?`,
    },
  }

  return (
    <Grid container spacing={0} direction='row' justify='flex-start' className={classes.root}>
      <Grid container item justify='space-between' alignItems='flex-start'>
        <Grid item xs={6}>
          <Typography variant='h5'> Salas </Typography>
          <Typography className={classes.label}>
            <Link to={ROUTES.USERS} className={classes.link}>
              Usuários
            </Link>{' '}
            &gt;
            {user.displayName}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nextButton}>
          <Button
            className={classes.button}
            variant='outlined'
            color='primary'
            onClick={() => handleOpen(true, selectedRoom, 'desvincular')}
            disabled={selected.length === 0}
          >
            <Typography variant='subtitle1' color='inherit'>
              Desvincular
            </Typography>
          </Button>
          <Button
            className={classes.button2}
            variant='contained'
            color='primary'
            onClick={() => handleOpen(false, selectedRoom, 'vincular')}
            disabled={selected.length === 0}
          >
            <Typography variant='subtitle1' color='inherit'>
              Vincular
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filter}>
        <FilterComponent listAutoComplete={listAutoComplete} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperTable} elevation={0}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby='tableTitle' size='medium' aria-label='enhanced table'>
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rooms.length}
              />
              <TableBody>
                {stableSort(rooms, getComparator(order, orderBy))
                  .filter(room => (valueFiltered ? filterValues(room) : room))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((room, index) => {
                    const isItemSelected = isSelected(room.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <RowUserEdit
                        room={room}
                        isItemSelected={isItemSelected}
                        selected={selected}
                        key={index + '-' + new Date().getTime()}
                        labelId={labelId}
                        accessRoom={accessRoom}
                        handleClick={handleClick}
                        handleOpenModal={handleOpenModal}
                      />
                    )
                  })}
              </TableBody>
            </Table>

            <TablePagination
              onClick={e => handleRowPerMonth(e)}
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={rooms.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={'Linhas por página:'}
            />
          </TableContainer>
        </Paper>
      </Grid>
      <ShowModal
        isOpen={open}
        image={flower}
        title={modalInformation.title[action]}
        message={modalInformation.message[action]}
        mainAction={handleAccessUser}
        mainActionLabel='Confirmar'
        secondActionLabel='Cancelar'
        handleClose={handleClose}
      />
    </Grid>
  )
}
