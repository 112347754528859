import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: '24px',
  },
  divtitle: {
    width: '100%',
    marginTop: 'auto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#707070',
  },
  color: {
    color: '#707070',
  },
  breadcrumbs: {
    display: 'flex',
    gap: "5px",
    alignItems: 'center',
  },
  button: {
    fontSize: '15px',
    background: '#53318A',
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#835cbb',
    },
    width: '15%',
    textTransform: 'none',
    borderColor: '#835cbb',
    display: "block",
  

  },
  gridButton:{
    display:"flex",
    justifyContent  :'flex-end',
    marginBottom: "18px",
    alignIems: "center"
  },
}));
export default useStyles;
