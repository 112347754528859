import BLOCKS_LAYOUT from '../../../config/blocks-layout.json'
import { setTextOffset, setIconOffset } from './offset'
import { verifySeatBlock } from '../../../utils/checkin'

// Position of each single table in the block
export const setPos = (element, reserved, selected, singleTableImage, sideTableImage) => {
  const singleTableWidth = 49.15 * 1.4
  const singleTableHeight = 43.81 * 1.4

  const sideTableWidth = (element.layout === BLOCKS_LAYOUT.DIAGONAL ? 83.72 : 43.81) * 1.4
  const sideTableHeight = (element.layout === BLOCKS_LAYOUT.DIAGONAL ? 72.87 : 59.24) * 1.4
  const defaultOffset = { x: singleTableWidth / 2, y: singleTableHeight / 2 }

  let pos = []
  const textOffset = setTextOffset()
  const iconOffset = setIconOffset()
  let offsetIndex = 0

  // if block is unilateral, tables lie side by side
  if (element.layout === BLOCKS_LAYOUT.UNILATERAL) {
    let offsetIndex = (element.rotation / 45) * 2
    for (let i = 0; i < element.places; i++) {
      let hasReservation = reserved && reserved.some(res => res.table === i + 1)
      pos.push({
        image: singleTableImage,
        x: singleTableWidth * i,
        y: 0,
        id: i + 1,
        rotation: 0,
        iconOffset: {
          x: defaultOffset.x - iconOffset.x[offsetIndex],
          y: defaultOffset.y - iconOffset.y[offsetIndex],
        },
        textOffset: {
          x: defaultOffset.x - textOffset.x[offsetIndex],
          y: defaultOffset.y - textOffset.y[offsetIndex],
        },
        available: !hasReservation,
        selected: false,
        reserved: hasReservation,
        numPlaces: element.places,
        tablesLayout: element.layout,
      })
    }
    return pos
  }
  // if block is not unilateral, two front side share tables lie side by side
  for (let i = 0; i < element.places - (element.places % 2); i++) {
    offsetIndex = (element.rotation / 45) * 2 + (i % 2)
    let hasReservation = reserved && reserved.some(res => res.table === verifySeatBlock(element.places, i))
    pos.push({
      image: singleTableImage,
      x: singleTableWidth * ((i / 2) >> 0) - 1 * (i % 2),
      y: singleTableHeight * (1 - (i % 2)) + 1 * (1 - (i % 2)),
      width: singleTableWidth,
      height: singleTableHeight,
      id: verifySeatBlock(element.places, i),
      rotation: 180 * (i % 2),
      iconOffset: {
        x: defaultOffset.x - iconOffset.x[offsetIndex],
        y: defaultOffset.y - iconOffset.y[offsetIndex],
      },
      textOffset: {
        x: defaultOffset.x - textOffset.x[offsetIndex],
        y: defaultOffset.y - textOffset.y[offsetIndex],
      },
      available: !hasReservation,
      selected: false,
      reserved: hasReservation,
      numPlaces: element.places,
      tablesLayout: element.layout,
    })
  }

  let hasReservation = reserved && reserved.some(res => res.table === element.places)
  if (element.places % 2) {
    offsetIndex = element.rotation / 45 + (element.layout === BLOCKS_LAYOUT.DIAGONAL ? 24 : 16)
    pos.push({
      image: sideTableImage,
      x: singleTableWidth * (((element.places - 1) / 2) >> 0),
      y: element.layout === BLOCKS_LAYOUT.DIAGONAL ? 0 : singleTableHeight / 4 + 5,
      width: sideTableWidth,
      height: sideTableHeight,
      id: element.places,
      rotation: 0,
      available: !hasReservation,
      selected: false,
      reserved: hasReservation,
      numPlaces: element.places,
      tablesLayout: element.layout,
      iconOffset: {
        x: defaultOffset.x - iconOffset.x[offsetIndex],
        y: defaultOffset.y - iconOffset.y[offsetIndex],
      },
      textOffset: {
        x: defaultOffset.x - textOffset.x[offsetIndex],
        y: defaultOffset.y - textOffset.y[offsetIndex],
      },
    })
  }

  return pos
}
