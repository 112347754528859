import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto',
      paddingTop: theme.spacing(10)
     },
  },
  media: {
    minHeight: '150px',
    width: 'auto',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  description:{
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  hoverStarFavorite: {   
    width: '10%',
    alignItems:'center',
    justifyContent:'flex-end',
    '& svg': {
      color: '#FFAD0E',
    },
    cursor: 'pointer',
    opacity: 0.75,
  },
  hoverStarUnfavorite: {
    cursor: 'pointer',
    width: '10%',
    alignItems:'center',
    justifyContent:'flex-end',
    '& svg': {
      color: 'black',
    },
    opacity: 0.75,
  },
  likeStyle: {
    width: '100%',
    justifContent: 'flex-end',
    marginBottom: theme.spacing(-6),
  },
}))

export default useStyles
