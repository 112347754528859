import { useState, useEffect, useRef } from 'react'
import { Group, Rect, Line, Text, Circle } from 'react-konva'
import { getCentroid, alignPolygon } from '../../utils/geometry'

export default ({
  x,
  y,
  points,
  closed,
  updatePointsPosition,
  updateAllPointsPosition,
  insertPoint,
  setPoints,
  newPos,
  scale,
  id,
  layerRef,
  valid,
  lineScale,
  setShowAlign,
}) => {
  const [line, setLine] = useState([])
  const [showPoints, setShowPoints] = useState(!closed || lineScale)
  const [widthRec, setWidthRec] = useState(0)
  let pointRef = useRef()
  const all = useRef(true)

  useEffect(() => {
    return () => (all.current = false)
  }, [])

  const centroid = getCentroid(points)

  useEffect(() => {
    if (!all.current) return
    let lineAux = []
    points.forEach(point => {
      lineAux.push(point.x)
      lineAux.push(point.y)
    })
    setLine(lineAux)
  }, [points])

  useEffect(() => {
    if (!layerRef || !layerRef.current || !all.current) return
    let context = layerRef.current.getContext()._context
    context.font = '16px Poppins'
    setWidthRec(context.measureText(id).width + 10)
  }, [layerRef])

  useEffect(() => {
    if (closed && !id) {
      setShowAlign(closed && !id)
    }
  }, [closed])

  return (
    <Group
      x={x}
      y={y}
      draggable={!id && !lineScale}
      onDragEnd={e => {
        updateAllPointsPosition(e)
      }}
    >
      <Group
        onClick={e => {
          if (closed && !id) {
            if (!e.evt.shiftKey) {
              setShowPoints(!!(showPoints ^ true) || lineScale)
            } else alignPolygon(points, setPoints, x, y)
          }
        }}
        onDblClick={e => {
          if (closed && !id && !lineScale) insertPoint(e, points, scale, setShowPoints, setPoints, newPos, x, y)
        }}
      >
        <Line
          strokeWidth={!id ? 2 / scale : 0}
          points={line}
          stroke={lineScale ? '#53318A' : id ? '#000000' : valid ? '#EA1B64' : '#FF0000'}
          closed={closed}
          fill={id ? 'rgba(83, 49, 138, 0.31)' : valid ? 'rgba(000, 000, 000, 0)' : 'rgba(255, 108, 108, 0.29)'}
          dash={id ? [0] : [6, 6]}
        />
        {showPoints &&
          points.map((point, index) => (
            <Group
              x={point.x}
              y={point.y}
              onDragMove={e => {
                setLine([
                  ...line.map((pointLine, indexLine) => {
                    if (Math.floor(indexLine / 2) === e.target.attrs.id) {
                      return indexLine % 2 === 1 ? e.target.attrs.y : e.target.attrs.x
                    } else return pointLine
                  }),
                ])
              }}
              onDragEnd={e => {
                updatePointsPosition(e.target.attrs.x, e.target.attrs.y, e.target.attrs.id)
              }}
              draggable={!id || lineScale}
              id={index}
              key={index}
              ref={pointRef}
            >
              <Circle
                radius={3.24 / scale}
                fill={lineScale ? '#FFFFFF' : valid ? '#EA1B64' : '#FF0000'}
                stroke={lineScale ? '#53318A' : 'rgba(0, 0, 0, 0)'}
                strokeWidth={lineScale ? 2 / scale : 0}
              />
              {lineScale && (
                <Text
                  x={10}
                  y={-5}
                  fill={'#53318A'}
                  text={index % 2 ? '1 m' : 0}
                  fontFamily='Poppins'
                  fontStyle='bold'
                  fontSize={10}
                />
              )}
            </Group>
          ))}
        {id && (
          <Group x={centroid.x} y={centroid.y}>
            <Rect cornerRadius={4} width={widthRec} height={20} fill='#FFFFFF' offset={{ x: widthRec / 2, y: 10 }} />
            <Text
              fontFamily='Poppins'
              align='center'
              width={widthRec}
              text={id.toString()}
              fill='#53318A'
              fontSize={16}
              offset={{ x: widthRec / 2, y: 6 }}
            />
          </Group>
        )}
      </Group>
    </Group>
  )
}
