import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: '24px',
  },

  circularStyle: {
    alignSelf: 'center',
    marginTop: '15%',
  },

  welcomeOverline: {
    color: '#34313F',
    fontSize: '25px',
    fontWeight: 'normal',
    marginBottom: 0,
  },
  welcomeTitle: {
    fontSize: '31px',
    fontWeight: 600,
    color: '#34313F',
    margin: '0 0 43px',
  },
  variationGraphic: {
    marginTop: 24,
    marginBottom: 42,
    width: '100%',
    padding: '45px 0 20px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  gridCards: {
    gap: '47px',
  },
  gridMetricsCards: {
    marginTop: '15px',
  },
  boxTitle: {
    marginTop: '7px',
  },
  graphTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#34313F',
  },
}))

export default useStyles
